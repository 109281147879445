import React, { useEffect, useState, useRef } from "react";
import Modal from "react-modal";
import styles from "./style.module.css";
import Header from "../partials/header/header";
import Loader from "../partials/Loader";
import { useSelector } from "react-redux";
import "sweetalert2/dist/sweetalert2.min.css";
import { toast } from "react-toastify";
import { Link, useNavigate, useParams } from "react-router-dom";
import backgroundMusic from "../../assets/audio/start_question.mp3";
import backgroundMusicClick from "../../assets/audio/click.mp3";
import backgroundMusicLose from "../../assets/audio/wahwah.mp3";
import backgroundMusicWin from "../../assets/audio/win.mp3";
import { Record } from "../question/Record";
import io from "socket.io-client";
import DownloadRec from "../../assets/new-design/download.png";
import FinishGame from "../../assets/new-design/re-start.png";
import ShareButtons from "./ShareButtons";
const url = process.env.REACT_APP_APP_BACK_URL;
const socket = io.connect(url);
function getWindowDimensions() {
  const { innerWidth: width, innerHeight: height } = window;
  return {
    width,
    height,
  };
}

const DashboardScr = ({ streaming_url, recordingStarting }) => {
  let navigate = useNavigate();
  const { id } = useParams();
  let slug = "invid";
  const [loader, setLoader] = useState(true);
  const [isFloating, setIsFloating] = useState(false);
  const [isCallFloating, setIsCallFloating] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isDisplay, setIsDisplay] = useState(false);
  const [isLockerOpen, setIsLockerOpen] = useState(false);
  const [backgroundAudio] = useState(new Audio(backgroundMusic));
  const [backgroundAudioClick] = useState(new Audio(backgroundMusicClick));
  const [backgroundAudioLose] = useState(new Audio(backgroundMusicLose));
  const [backgroundAudioWin] = useState(new Audio(backgroundMusicWin));
  const [isGameEnd, setIsGameEnd] = useState(false);
  const [prize, setPrize] = useState("");
  const [winnername, setwinnername] = useState("");
  const [selectedUsers, setselectedUsers] = useState([]);
  const [getLocker, setLocker] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedLocker, setselectedLocker] = useState("");
  const [isModalOpen2, setIsModalOpen2] = useState(false);
  const [isModalOpen3, setIsModalOpen3] = useState(false);
  const [isModalOpen4, setIsModalOpen4] = useState(false);
  const [isReadyModalOpen, setIsReadyModalOpen] = useState(false);
  const [recordingShow, setRecordingShow] = useState(false);
  const [winnerLocker, setWinnerLocker] = useState();
  const [localPlayer, setLocalPlayer] = useState();
  const [localPlayersDetail, setLocalPlayersDetail] = useState();
  const [playerData, setPlayerData] = useState();
  const [currentPlayer, setCurrentPlayer] = useState();
  const [playersDetail, setPlayersDetail] = useState();
  const [gamePlayer, setGamePlayer] = useState();
  const [modalButton, setModalButton] = useState(false);
  const queryParams = new URLSearchParams(window.location.search);
  const player = queryParams.get("p");
  const position = parseInt(queryParams.get("position")) || 0;
  const [seletedPlayers, setSeletedPlayers] = useState([]);
  const [relayNumber, setRelayNumber] = useState();
  const [download, setDownload] = useState(false);
  const [disablelocker, setdisablelocker] = useState(true);
  const [videoSrc, setVideoSrc] = useState(null);
  const [recordingLoading, setRecordingLoading] = useState(false);
  const [imageError, setImageError] = useState(false);
  const [timeRemaining, setTimeRemaining] = useState(0);
  let count = 0;
  useEffect(() => {
    return () => {
      // Clean up: Stop the background music when the component unmounts
      backgroundAudio.pause();
      backgroundAudio.currentTime = 0;
    };
  }, [backgroundAudio]);
  useEffect(() => {
    if (recordingShow) {
      const timer = setInterval(() => {
        setTimeRemaining((prevTime) => {
          return prevTime - 1;
        });
      }, 1000);

      // When timeRemaining reaches 0, move to the next question
      if (timeRemaining === 0) {
        setRecordingLoading(true);
      }

      // Clear the timer when the component unmounts or when moving to the next question
      return () => clearInterval(timer);
    }
    // }
  }, [timeRemaining, recordingShow]);
  const handleBoxClick = (locker_number) => {};

  useEffect(() => {
    siteLockers();
  }, []);

  const handleVideoClick = () => {
    const { width } = getWindowDimensions();
    const surfaceTabletWidth = 768;

    if (width <= surfaceTabletWidth) {
      setIsFloating(!isFloating);
      setIsCallFloating(false);
    } else {
      setIsFloating(false);
    }
  };

  useEffect(() => {
    const script = document.createElement("script");
    script.src =
      "https://cdn.jsdelivr.net/npm/rtsp-relay@1.7.0/browser/index.js";
    script.async = true;
    script.onload = () => {
      /* eslint-disable no-undef */
      loadPlayer({
        url: `${streaming_url}/stream/url/5`, // Replace with your desired channel ID
        canvas: document.getElementById("canvas"),
      });
      /* eslint-enable no-undef */
    };
    document.head.appendChild(script);
    return () => {
      // Clean up the script element if the component unmounts
      document.head.removeChild(script);
    };
  }, [streaming_url]);

  useEffect(() => {
    if (isGameEnd) {
      backgroundAudio.pause();
      backgroundAudio.currentTime = 0;
    }
  }, [isGameEnd, backgroundAudio]);
  useEffect(() => {
    winnerLockers();
    getPlayers();
  }, []);
  useEffect(() => {
    socket.emit("gameID", player);
    socket.on("showCashPrize", (data) => {
      setIsModalOpen2(true);
      if (parseInt(recordingStarting) > 1) {
        setTimeout(() => {
          // setIsModalOpen3(false);
          count = 0;
          getRecordings();
        }, 6000);
      }
      setselectedLocker(data.locker);
      setPrize(data.prize);
      setwinnername(data.name);
      setIsModalOpen4(false);
      setdisablelocker(false);
    });
    socket.on("showMemesPrize", (data) => {
      setIsModalOpen3(true);
      if (parseInt(recordingStarting) > 1) {
        setTimeout(() => {
          // setIsModalOpen3(false);
          count = 0;
          getRecordings();
        }, 6000);
      }

      setselectedLocker(data.locker);
      setPrize(data.prize);
      setwinnername(data.name);
      setIsModalOpen4(false);
      setdisablelocker(false);
      console.log("check this :", disablelocker);
    });
  }, [recordingStarting]);
  // get all locker questions
  const userPrize = async () => {
    try {
      let headers = new Headers();
      headers.append("Content-Type", "application/json");
      headers.append("Access-Control-Allow-Origin", "*");
      headers.append("Access-Control-Allow-Credentials", "true");
      const response = await fetch(
        `${url}/api/multiplayer/winner/locker/status/${id}`,
        {
          mode: "cors",
          method: "GET",
          headers: headers,
        }
      );
      const json = await response.json();
      if (json.success) {
        backgroundAudio.pause();
        backgroundAudio.currentTime = 0;
      } else {
        toast.error(json.error);
        navigate("/");
      }
    } catch (error) {
      console.error(error);
    }
  };
  // get selected player to show user prizes
  const getPlayers = async () => {
    try {
      let headers = new Headers();
      headers.append("Content-Type", "application/json");
      headers.append("Access-Control-Allow-Origin", "*");
      headers.append("Access-Control-Allow-Credentials", "true");

      const response = await fetch(
        `${url}/api/multiplayer/get/loser/player/${id}`,
        {
          mode: "cors",
          method: "GET",
          headers: headers,
        }
      );
      const json = await response.json();

      if (json.success) {
        setSeletedPlayers(json.selectedPlayers);
        setselectedUsers(json.selectedUsers);
        setLocalPlayer(json.localPlayers);
        setLocalPlayersDetail(json.localPlayerData);
        setPlayerData(json.players);

        setIsModalOpen4(true);
      }
    } catch (error) {
      toast.error(error.message);
    }
  };

  const getRecordings = async () => {
    try {
      let headers = new Headers();
      headers.append("Content-Type", "application/json");
      headers.append("Access-Control-Allow-Origin", "*");
      headers.append("Access-Control-Allow-Credentials", "true");
      const response = await fetch(
        `${url}/api/multiplayer/get/player/recording/${id}`,
        {
          mode: "cors",
          method: "GET",
          headers: headers,
        }
      );
      const json = await response.json();
      if (json.success) {
        setIsModalOpen2(false);
        setIsModalOpen3(false);
        setRecordingShow(true);
        setTimeRemaining(7);
        count = parseInt(count) + 1;
        if (json.recording === "no") {
          // socket.emit("stopRecording", {
          //   player: seletedPlayers,
          // });
          setTimeout(() => {
            if(count<3){
              getRecordings();
            }
          }, 1000);
        } else if (
          parseInt(recordingStarting) > 2 &&
          json.isRecordingStop === "yes"
        ) {
        } else if (
          json.recording !== "no" &&
          parseInt(recordingStarting) <= 2
        ) {
          setVideoSrc(json.recording);
        }
      }
    } catch (error) {
      toast.error(error.message);
    }
  };
  const getCurrentPlayer = async () => {
    try {
      let headers = new Headers();
      headers.append("Content-Type", "application/json");
      headers.append("Access-Control-Allow-Origin", "*");
      headers.append("Access-Control-Allow-Credentials", "true");

      const response = await fetch(
        `${url}/api/multiplayer/get/current/player/${player}`,
        {
          mode: "cors",
          method: "GET",
          headers: headers,
        }
      );
      const json = await response.json();

      if (json.success) {
        setCurrentPlayer(json.currentPlayer);
      }
    } catch (error) {
      toast.error(error.message);
    }
  };

  const getGamePlayer = async () => {
    try {
      let headers = new Headers();
      headers.append("Content-Type", "application/json");
      headers.append("Access-Control-Allow-Origin", "*");
      headers.append("Access-Control-Allow-Credentials", "true");

      const response = await fetch(
        `${url}/api/multiplayer/get/game/player/${id}/${player}`,
        {
          mode: "cors",
          method: "GET",
          headers: headers,
        }
      );
      const json = await response.json();
      console.log(json, "json plaer data");
      if (json.success) {
        setGamePlayer(json.gamePlayer._id);
      }
    } catch (error) {
      toast.error(error.message);
    }
  };

  useEffect(() => {
    getCurrentPlayer();
    getGamePlayer();
  }, []);

  useEffect(() => {
    setLoader(true);
    const timerId = setTimeout(() => {
      setLoader(false);
      userPrize();
    }, 5000); // 3000 milliseconds = 3 seconds
    return () => clearTimeout(timerId);
  }, []);

  // get all site
  const siteLockers = async () => {
    try {
      let headers = new Headers();
      headers.append("Content-Type", "application/json");
      headers.append("Access-Control-Allow-Origin", "*");
      headers.append("Access-Control-Allow-Credentials", "true");
      const response = await fetch(`${url}/api/sites/lockers/${slug}`, {
        mode: "cors",
        method: "GET",
        headers: headers,
      });
      const json = await response.json();

      if (json.success) {
        setLocker(json.lockers);
        setselectedLocker("");
        setIsDisplay(true);
      } else {
        toast.error(json.error);
      }
    } catch (error) {
      toast.error(error.message);
    }
  };
  // open locker
  const openLocker = async (locker_id) => {
    let state = "2";
    let headers = new Headers();
    headers.append("Content-Type", "application/json");
    headers.append("Access-Control-Allow-Origin", "*");
    headers.append("Access-Control-Allow-Credentials", "true");
    setIsLockerOpen(true);
    setIsLoading(true);

    const response = await fetch(`${url}/api/prizes/open/show/locker`, {
      mode: "cors",
      method: "POST",
      headers: headers,
      body: JSON.stringify({
        id: id,
        locker_id: locker_id,
        state: state,
      }),
    });

    setIsLockerOpen(false);
    const json = await response.json();
    if (json.success) {
      // socket.emit("stopRecording", seletedPlayers);
      if (json.prizes.type === "money") {
        socket.emit("cashPrize", {
          player: seletedPlayers,
          locker: locker_id,
          prize: json.prizes.amount,
          name: winnerLocker ? winnerLocker.name : "",
        });

        setIsModalOpen2(true);
        if (parseInt(recordingStarting) > 1) {
          setTimeout(() => {
            getRecordings();
          }, 6000);
        }

        setPrize(json.prizes.amount);
        backgroundAudioWin.play();
      } else {
        socket.emit("memePrize", {
          player: seletedPlayers,
          locker: locker_id,
          prize: json.prizes.memes,
          name: winnerLocker ? winnerLocker.name : "",
        });

        setIsModalOpen3(true);
        if (parseInt(recordingStarting) > 1) {
          setTimeout(() => {
            getRecordings();
          }, 6000);
        }
        setPrize(json.prizes.memes);
        backgroundAudioLose.play();
      }
    } else {
      toast.error(json.error);
    }

    setIsLoading(false);
    siteLockers();
  };

  const backtohome = () => {
    localStorage.removeItem(player);
    backgroundAudioLose.pause();
    backgroundAudioLose.currentTime = 0;
    backgroundAudioWin.pause();
    backgroundAudioWin.currentTime = 0;
    navigate("/");
  };

  const handleConfirm = () => {
    if (parseInt(recordingStarting) > 1) {
      socket.emit("stopRecording", {
        player: seletedPlayers,
      });
    }
    openLocker(selectedLocker);
    socket.emit("closeModal", {
      gamePlayers: gamePlayer,
    });
    setIsReadyModalOpen(false);
  };

  const displayOpen = async (id, relay) => {
    // if (currentPlayer.type === 'main') {
    backgroundAudioClick.play();
    socket.emit("openModal", {
      gamePlayers: gamePlayer,
      relay,
      id,
    });
    setIsReadyModalOpen(true);
    setselectedLocker(id);
    setRelayNumber(relay);
    setModalButton(true);
    // }
  };

  useEffect(() => {
    socket.on("showOpenModal", (data) => {
      setIsReadyModalOpen(true);
      setRelayNumber(data.relay);
      setModalButton(false);
      // Add any additional logic you want to handle when the selected answer is received
    });
    socket.on("hideCloseModal", (data) => {
      // setdisablelocker(false);
      setIsReadyModalOpen(false);

      // Add any additional logic you want to handle when the selected answer is received
    });
    console.log("check this :", disablelocker);
  }, []);

  const handleDownloadClick = () => {
    setDownload(true);
  };
  const closeModal = () => {
    socket.emit("closeModal", {
      gamePlayers: gamePlayer,
    });
    setIsReadyModalOpen(false);
    setselectedLocker("");
    setIsModalOpen(false);
  };

  const winnerLockers = async () => {
    try {
      let headers = new Headers();
      headers.append("Content-Type", "application/json");
      headers.append("Access-Control-Allow-Origin", "*");
      headers.append("Access-Control-Allow-Credentials", "true");

      const response = await fetch(
        `${url}/api/multiplayer/winners/locker/${id}`,
        {
          mode: "cors",
          method: "GET",
          headers: headers,
        }
      );

      const json = await response.json();

      if (json.success) {
        setWinnerLocker(json.winner);
      }
    } catch (error) {
      toast.error(error.message);
    }
  };

  const sortedUsers = selectedUsers.slice().sort((a, b) => b.score - a.score);
  return (
    <div className={styles.heading}>
      <div className="body-wrap mt-4">
        <div
          className="container-fluid px-0 position-relative"
          style={{ width: "100%" }}
        >
          <div className="canvas-container" style={{ position: "relative" }}>
            <canvas
              id="canvas"
              height="100%"
              style={{
                width: "100%",
                position: "relative",
              }}
              // onClick={handleVideoClick}
            ></canvas>

            <table
              className="table-overlay-new"
              style={{
                position: "absolute",
                top: "25%",
                left: "20%",
                width: "28%",
                // height: "50px",
                // minHeight: "100%",
                borderCollapse: "collapse",
                pointerEvents: "none",
                transform: "skewY(8deg)",
              }}
            >
              <tbody>
                <tr style={{ height: "19.66%" }}>
                  {" "}
                  <td
                    rowSpan="2"
                    style={{
                      width: "20%",
                      pointerEvents: "auto",
                      cursor: "pointer",
                      position: "relative",
                      border: "1px solid red",
                    }}
                    // onClick={() => displayOpen(getLocker[0]._id, 1)}
                    onClick={
                      disablelocker
                        ? () => displayOpen(getLocker[0]._id, 1)
                        : null
                    }
                  >
                    {/* {winnerLocker && winnerLocker._id === player && (
                  <div style={{
                    position: 'absolute',
                    top: 0,
                    left: 0,
                    right: 0,
                    bottom: 0,

                  }}></div>
                )} */}
                  </td>
                  <td
                    rowSpan="2"
                    style={{
                      width: "20%",
                      pointerEvents: "auto",
                      cursor: "pointer",
                      position: "relative",
                      border: "1px solid red",
                    }}
                    onClick={
                      disablelocker
                        ? () => displayOpen(getLocker[3]._id, 4)
                        : null
                    }
                  >
                    {/* {winnerLocker && winnerLocker._id === player && (
                    <div style={{
                      position: 'absolute',
                      top: 0,
                      left: 0,
                      right: 0,
                      bottom: 0,

                    }}></div>
                  )} */}
                  </td>
                  <td
                    style={{
                      width: "20%",
                      pointerEvents: "auto",
                      cursor: "pointer",
                      position: "relative",
                      border: "1px solid red",
                    }}
                    onClick={
                      disablelocker
                        ? () => displayOpen(getLocker[6]._id, 7)
                        : null
                    }
                  >
                    {/* {winnerLocker && winnerLocker._id === player && (
                    <div style={{
                      position: 'absolute',
                      top: 0,
                      left: 0,
                      right: 0,
                      bottom: 0,

                    }}></div>
                  )} */}
                  </td>
                  <td
                    rowSpan="2"
                    style={{
                      width: "20%",

                      pointerEvents: "auto",
                      cursor: "pointer",
                      position: "relative",
                      border: "1px solid red",
                    }}
                    onClick={
                      disablelocker
                        ? () => displayOpen(getLocker[10]._id, 11)
                        : null
                    }
                  >
                    {/* <div style={{
                    position: 'absolute',
                    top: 0,
                    left: 0,
                    right: 0,
                    bottom: 0,

                  }}></div> */}
                  </td>
                  <td
                    rowSpan="2"
                    style={{
                      width: "20%",

                      pointerEvents: "auto",
                      cursor: "pointer",
                      position: "relative",
                      border: "1px solid red",
                    }}
                    onClick={
                      disablelocker
                        ? () => displayOpen(getLocker[13]._id, 14)
                        : null
                    }
                  >
                    {/* <div style={{
                    position: 'absolute',
                    top: 0,
                    left: 0,
                    right: 0,
                    bottom: 0,

                  }}></div> */}
                  </td>
                </tr>
                <tr style={{ height: "12.66%" }}>
                  {" "}
                  {/* 16.66 */}
                  <td
                    style={{
                      width: "20%",

                      pointerEvents: "auto",
                      cursor: "pointer",
                      position: "relative",
                      border: "1px solid red",
                    }}
                    onClick={
                      disablelocker
                        ? () => displayOpen(getLocker[7]._id, 8)
                        : null
                    }
                  >
                    {/* <div style={{
                    position: 'absolute',
                    top: 0,
                    left: 0,
                    right: 0,
                    bottom: 0,

                  }}></div> */}
                  </td>
                </tr>
                <tr style={{ height: "13.66%" }}>
                  {" "}
                  {/* 16.66 , 11.66*/}
                  <td
                    rowSpan="2"
                    style={{
                      width: "20%",

                      pointerEvents: "auto",
                      cursor: "pointer",
                      position: "relative",
                      border: "1px solid red",
                    }}
                    onClick={
                      disablelocker
                        ? () => displayOpen(getLocker[1]._id, 2)
                        : null
                    }
                  >
                    {/* <div style={{
                    position: 'absolute',
                    top: 0,
                    left: 0,
                    right: 0,
                    bottom: 0,

                  }}></div> */}
                  </td>
                  <td
                    rowSpan="2"
                    style={{
                      width: "20%",

                      pointerEvents: "auto",
                      cursor: "pointer",
                      position: "relative",
                      border: "1px solid red",
                    }}
                    onClick={
                      disablelocker
                        ? () => displayOpen(getLocker[4]._id, 5)
                        : null
                    }
                  >
                    {/* <div style={{
                    position: 'absolute',
                    top: 0,
                    left: 0,
                    right: 0,
                    bottom: 0,

                  }}></div> */}
                  </td>
                  <td
                    style={{
                      width: "20%",
                      pointerEvents: "auto",
                      cursor: "pointer",
                      position: "relative",
                      border: "1px solid red",
                    }}
                    // onClick={() => handleBoxClick(12)}
                  >
                    {/* <div style={{
                    position: 'absolute',
                    top: 0,
                    left: 0,
                    right: 0,
                    bottom: 0,

                  }}></div> */}
                  </td>
                  <td
                    rowSpan="2"
                    style={{
                      width: "20%",

                      pointerEvents: "auto",
                      cursor: "pointer",
                      position: "relative",
                      border: "1px solid red",
                    }}
                    onClick={
                      disablelocker
                        ? () => displayOpen(getLocker[11]._id, 12)
                        : null
                    }
                  >
                    {/* <div style={{
                    position: 'absolute',
                    top: 0,
                    left: 0,
                    right: 0,
                    bottom: 0,

                  }}></div> */}
                  </td>
                  <td
                    rowSpan="2"
                    style={{
                      width: "20%",

                      pointerEvents: "auto",
                      cursor: "pointer",
                      position: "relative",
                      border: "1px solid red",
                    }}
                    onClick={
                      disablelocker
                        ? () => displayOpen(getLocker[14]._id, 15)
                        : null
                    }
                  >
                    {/* <div style={{
                    position: 'absolute',
                    top: 0,
                    left: 0,
                    right: 0,
                    bottom: 0,

                  }}></div> */}
                  </td>
                </tr>
                <tr style={{ height: "13.66%" }}>
                  {" "}
                  {/* 16.66 , 13.66*/}
                  <td
                    style={{
                      width: "20%",

                      pointerEvents: "auto",
                      cursor: "pointer",
                      position: "relative",
                      border: "1px solid red",
                    }}
                  >
                    {/* <div style={{
                    position: 'absolute',
                    top: 0,
                    left: 0,
                    right: 0,
                    bottom: 0,

                  }}></div> */}
                  </td>
                </tr>
                <tr style={{ height: "12.66%" }}>
                  {" "}
                  {/* 16.66 */}
                  <td
                    rowSpan="2"
                    style={{
                      width: "20%",

                      pointerEvents: "auto",
                      cursor: "pointer",
                      position: "relative",
                      border: "1px solid red",
                    }}
                    onClick={
                      disablelocker
                        ? () => displayOpen(getLocker[2]._id, 3)
                        : null
                    }
                  >
                    {/* <div style={{
                    position: 'absolute',
                    top: 0,
                    left: 0,
                    right: 0,
                    bottom: 0,

                  }}></div> */}
                  </td>
                  <td
                    rowSpan="2"
                    style={{
                      width: "20%",

                      pointerEvents: "auto",
                      cursor: "pointer",
                      position: "relative",
                      border: "1px solid red",
                    }}
                    onClick={
                      disablelocker
                        ? () => displayOpen(getLocker[5]._id, 6)
                        : null
                    }
                  >
                    {/* <div style={{
                    position: 'absolute',
                    top: 0,
                    left: 0,
                    right: 0,
                    bottom: 0,

                  }}></div> */}
                  </td>
                  <td
                    style={{
                      width: "20%",

                      pointerEvents: "auto",
                      cursor: "pointer",
                      position: "relative",
                      border: "1px solid red",
                    }}
                    onClick={
                      disablelocker
                        ? () => displayOpen(getLocker[8]._id, 9)
                        : null
                    }
                  >
                    {/* <div style={{
                    position: 'absolute',
                    top: 0,
                    left: 0,
                    right: 0,
                    bottom: 0,

                  }}></div> */}
                  </td>
                  <td
                    rowSpan="2"
                    style={{
                      width: "20%",

                      pointerEvents: "auto",
                      cursor: "pointer",
                      position: "relative",
                      border: "1px solid red",
                    }}
                    onClick={
                      disablelocker
                        ? () => displayOpen(getLocker[12]._id, 13)
                        : null
                    }
                  >
                    {/* <div style={{
                    position: 'absolute',
                    top: 0,
                    left: 0,
                    right: 0,
                    bottom: 0,

                  }}></div> */}
                  </td>
                  <td
                    rowSpan="2"
                    style={{
                      width: "20%",

                      pointerEvents: "auto",
                      cursor: "pointer",
                      position: "relative",
                      border: "1px solid red",
                    }}
                    onClick={
                      disablelocker
                        ? () => displayOpen(getLocker[15]._id, 16)
                        : null
                    }
                  >
                    {/* <div style={{
                    position: 'absolute',
                    top: 0,
                    left: 0,
                    right: 0,
                    bottom: 0,

                  }}></div> */}
                  </td>
                </tr>
                <tr style={{ height: "19.66%" }}>
                  {" "}
                  {/* 16.66 */}
                  <td
                    style={{
                      width: "20%",

                      pointerEvents: "auto",
                      cursor: "pointer",
                      position: "relative",
                      border: "1px solid red",
                    }}
                    onClick={
                      disablelocker
                        ? () => displayOpen(getLocker[9]._id, 10)
                        : null
                    }
                  >
                    {/* <div style={{
                    position: 'absolute',
                    top: 0,
                    left: 0,
                    right: 0,
                    bottom: 0,

                  }}></div> */}
                  </td>
                </tr>
              </tbody>
            </table>
            {loader ? (
              <Loader />
            ) : (
              <>
                {/* <Header /> */}
                <div className="dashboard-locker">
                  <div
                    className="container-fluid text-center p-2 "
                    style={{ position: "relative" }}
                  >
                    {/* <img src={LockerImage} style={{ width: '100%' }} /> */}
                  </div>
                </div>
              </>
            )}
          </div>
        </div>
        {isReadyModalOpen && (
          <div className="isReadyModal d-flex justify-content-center align-items-center">
            <div
              className="p-3 w-sm-50 h-sm-50 col-10 rounded-3"
              style={{ background: "#456e01" }}
            >
              <div className="d-flex flex-row">
                <h2 className="win-heading">Ready</h2>
              </div>
              <div
                className="p-4 rounded-2 w-sm-25 d-flex justify-content-center align-items-center flex-column gap-4" //h-75
                style={{ background: "#7bb717" }}
              >
                <h2 className="text-center ">
                  Do you want to open locker no{" "}
                  <span className="badge bg-warning">{relayNumber}</span> ?
                </h2>
                {/* {currentPlayer && currentPlayer.type === "main" && ( */}
                {modalButton && (
                  <div className="d-flex justify-content-center align-items-center flex-row gap-3">
                    <button
                      className="btn btn-modal w-auto px-4"
                      onClick={handleConfirm}
                    >
                      Yes
                    </button>
                    <button
                      className="btn btn-modal w-auto px-4"
                      onClick={closeModal}
                    >
                      No
                    </button>
                  </div>
                )}
                {/* )} */}
              </div>
            </div>
          </div>
        )}
        {isModalOpen3 && (
          <div className="loserModal d-flex justify-content-center align-items-center">
            <div
              className="p-3 w-sm-50 h-sm-50 col-10 rounded-3"
              style={{ background: "#456e01" }}
            >
              <div className="d-flex flex-row">
                <h2 className="win-heading">
                  {winnerLocker && winnerLocker._id === player
                    ? "You"
                    : winnername}{" "}
                  Lose
                </h2>
              </div>
              <div
                className="p-4 rounded-2 w-sm-25 d-flex justify-content-center align-items-center flex-column gap-3" //h-75
                style={{ background: "#7bb717" }}
              >
                {!imageError ? (
                  <img
                    width="100%"
                    height="100%"
                    className="video-area"
                    src={prize}
                    onError={() => setImageError(true)}
                  />
                ) : (
                  <div className="text-center">
                    <h6>Meme Not Found</h6>
                  </div>
                )}
                {parseInt(recordingStarting) > 1 && (
                  <div className="screen-loader video-area ">
                    <div
                      className="lds-spinner"
                      style={{ display: "flex", left: "35%" }}
                    >
                      <div></div>
                      <div></div>
                      <div></div>
                      <div></div>
                      <div></div>
                      <div></div>
                      <div></div>
                      <div></div>
                      <div></div>
                      <div></div>
                      <div></div>
                      <div></div>
                    </div>
                    <div></div>
                    <h6 className="mt-5">
                      Please Wait For Screen Recording...
                    </h6>
                  </div>
                )}
                <button className="btn btn-modal w-auto" onClick={backtohome}>
                  Try Again
                </button>
                {/* <Record/> */}
              </div>
            </div>
          </div>
        )}

        {isModalOpen2 && (
          <div className="winnerModal d-flex justify-content-center align-items-center">
            <div
              className="p-3 w-sm-50 h-sm-50 col-8 rounded-3"
              style={{ background: "#456e01" }}
            >
              <div className="d-flex flex-row">
                <h2 className="win-heading">
                  {winnerLocker && winnerLocker._id === player
                    ? "You"
                    : winnername}{" "}
                  Win
                </h2>
              </div>
              <div
                className="p-4 rounded-2 w-sm-25 d-flex justify-content-center align-items-center flex-column gap-3" //h-75
                style={{ background: "#7bb717" }}
              >
                {/* <img src={Gift} alt="gift" className="emoji-style-win" /> */}

                <div className="score-card-winner">
                  <p>${prize}</p>
                </div>
                {parseInt(recordingStarting) > 1 && (
                  <div className="screen-loader video-area ">
                    <div
                      className="lds-spinner "
                      style={{ display: "flex", left: "35%" }}
                    >
                      <div></div>
                      <div></div>
                      <div></div>
                      <div></div>
                      <div></div>
                      <div></div>
                      <div></div>
                      <div></div>
                      <div></div>
                      <div></div>
                      <div></div>
                      <div></div>
                    </div>
                    <div></div>
                    <h6 className="mt-5">
                      Please Wait For Screen Recording...
                    </h6>
                  </div>
                )}
                <button
                  className="btn btn-modal w-auto mt-2"
                  onClick={backtohome}
                >
                  Play Again
                </button>
                {/* <Record/>
                 */}
              </div>
            </div>
          </div>
        )}

        {recordingShow && (
          <div>
            <div className="winnerModal d-flex justify-content-center align-items-center">
              <div
                className="p-3 w-sm-50 h-sm-50 col-10 rounded-3"
                style={{ background: "#456e01" }}
              >
                <div
                  className="p-2 rounded-2 w-sm-25 d-flex justify-content-center align-items-center flex-column gap-3" //h-75
                  style={{ background: "#7bb717" }}
                >
                  {videoSrc ? (
                    <video
                      className="video-area"
                      style={{
                        width: "100%",
                        height: "100%",
                        textAlign: "center",
                      }}
                      controls
                      src={videoSrc}
                    />
                  ) : (
                    <div className="screen-loader video-area ">
                      {parseInt(recordingStarting) > 2 ? (
                        <>
                          <h6 className="mt-2">
                            Recording has been stopped by the host.
                          </h6>
                        </>
                      ) : (
                        <>
                          {recordingLoading ? (
                            <h6 className="mt-5">
                              Something went wrong when loading recording !
                            </h6>
                          ) : (
                            <>
                              <div
                                className="lds-spinner "
                                style={{ display: "flex", left: "35%" }}
                              >
                                <div></div>
                                <div></div>
                                <div></div>
                                <div></div>
                                <div></div>
                                <div></div>
                                <div></div>
                                <div></div>
                                <div></div>
                                <div></div>
                                <div></div>
                                <div></div>
                              </div>
                              <div></div>
                              <h6 className="mt-5">
                                Please Wait For Screen Recording...
                              </h6>
                            </>
                          )}
                        </>
                      )}
                    </div>
                  )}

                  <div className="d-flex justify-content-center align-items-center flex-row gap-3">
                    {videoSrc && (
                      <a
                        className="btn btn-modal w-auto"
                        href={videoSrc}
                        download={videoSrc}
                        rel="noreferrer"
                        target="_blank"
                      >
                        <img src={DownloadRec} alt="download recording" />
                      </a>
                    )}
                    {(parseInt(recordingStarting) > 2 ||
                      videoSrc ||
                      recordingLoading) && (
                      <a className="btn btn-modal w-auto" onClick={backtohome}>
                        <img src={FinishGame} alt="finish game" />
                      </a>
                    )}
                  </div>
                  {videoSrc && (
                    <ShareButtons
                      url={videoSrc}
                      title="Check out game recording!"
                    />
                  )}
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default DashboardScr;

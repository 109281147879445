// AnswerComponent.js
import React, { useEffect, useRef, useState } from "react";
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";
import Modal from "../partials/Modal";
import DashboardScr from "../dashboard/ShowLockersUpdate.js";
// import { Record } from "./Record";
// import { Question } from "@trivia-api/models";
import "./style.css";
import "../../puzzlestyle.css";
import { StringeeUtil, StringeeClient, StringeeCall } from "stringee";
// import StringeeClients from '../../config/latest.sdk.bundle.min';
import Player1 from "../../assets/icons/player-1.svg";
import Player3 from "../../assets/icons/player-3.svg";
import DecVideCall from "../../assets/new-design/dec-video-call.png";
import SwtchVideo from "../../assets/new-design/unmute_vdo_icon.svg";
import MuteSwitch from "../../assets/new-design/muted_mic_icon.svg";
import UnmuteSwitch from "../../assets/new-design/unmuted_mic_ico n.svg";
import UnmuteVideo from "../../assets/new-design/muted_vdo_icon.svg";
import RecordingIcon from "../../assets/new-design/rec-red-icon.png";
import { useSelector } from "react-redux";
import io from "socket.io-client";

const url = process.env.REACT_APP_APP_BACK_URL;
const streaming_url = process.env.REACT_APP_STREAMIN_SOCKET_URL;
const socket = io.connect(url);

function getWindowDimensions() {
  const { innerWidth: width, innerHeight: height } = window;
  return {
    width,
    height,
  };
}

export const Answer = ({
  question,
  gameEnd,
  index,
  onGuessAnswer,
  isCorrect,
  isFalse,
  currentQuestionIndex,
  gif,
  remainingTime,
  gametype,
  selectedAnswers,
  isAnswered,
  ready,
  iscameraon,
  isquestionshow,
  showSearching,
  isRecord,
  isStop,
  recordingStart,
  recordingStop,
}) => {
  const { slug } = useParams();
  const [modalIsOpen, setIsOpen] = React.useState(false);
  const [selectedAnswer, setSelectedAnswer] = useState(null);
  const [isFloating, setIsFloating] = useState(false);
  const [isCallFloating, setIsCallFloating] = useState(false);
  const [isMuted, setIsMuted] = useState(false);
  const [timeRemaining, setTimeRemaining] = useState(remainingTime);
  const [sdkVersion, setSdkVersion] = useState("");
  const [playersDetail, setPlayersDetail] = useState();
  const [playerData, setPlayerData] = useState();
  const [loggedUserId, setLoggedUserId] = useState("");
  const [accessToken, setAccessToken] = useState("");
  const [stringeeClient, setStringeeClient] = useState(null);
  const [callConnectionDestroy, setCallConnectionDestroy] = useState(false);
  const [isCallActive, setIsCallActive] = useState(false);
  const [ringingCall, setRingingCall] = useState(false);
  const [callerBusy, setCallerBusy] = useState(false);
  const [number, setNumber] = useState(0);
  const [duration, setDuration] = useState(0);
  const [localStream, setLocalStream] = useState(null);
  const [remoteStream, setRemoteStream] = useState(null);
  const [callModalOpen, setCallModalOpen] = useState(false);
  const [loaderShow, setLoaderShow] = useState(true);
  const [callDisconnected, setCallDisconnected] = useState(false);
  const [destroyMessage, setDestroyMessage] = useState(false);

  const [call, setCall] = useState(null);
  const [timeoutStats, setTimeoutStats] = useState(null);
  const [videoShow, setVideoShow] = useState(false);
  const [isOnline, setIsOnline] = useState(navigator.onLine);
  const [localPlayer, setLocalPlayer] = useState("");
  const [isRefresh, setIsRefresh] = useState(false);
  const localVideoRef = useRef(null);
  const remoteVideoRef = useRef(null);
  const mainlocalVideoRef = useRef(null);
  const mainremoteVideoRef = useRef(null);
  const leaveCallButtonRef = useRef(null);
  const ringCall = useRef(null);
  const clickButton = useRef(null);
  const clickJoin = useRef(null);
  const clickCallButton = useRef(null);
  const [muted, setMuted] = useState(false);
  const [videoEnabled, setVideoEnabled] = useState(true);
  const [offline, setOffline] = useState(false);
  const [removRecIcon, setRemovRecIcon] = useState(false);
  const [recordingsStart, setRecordingStart] = useState(false);
  const [elapsedTime, setElapsedTime] = useState(0);
  const [showCallButton, setShowCallButton] = useState(false);
  const { id } = useParams();
  const queryParams = new URLSearchParams(window.location.search);
  const playerId = queryParams.get("p");
  const type = queryParams.get("type");
  const isAuthenticated = useSelector((state) => state.user);
// if(parseInt(recordingStart) > 2){
//   setRecordingStart(false);
// }
  const callAudioRef = useRef(null);
  useEffect(() => {
    setSdkVersion(
      `${StringeeUtil.version().version}_build_${
        StringeeUtil.version().build
      }_sample_1`
    );
    getAccessToken();
  }, []);

  useEffect(() => {
    console.log("game :", gameEnd);
    // setisRecord(false)
    // setisStop(true);
  }, [gameEnd,recordingStart,recordingStop]);

  useEffect(() => {
    if (isRecord) {
      console.log("isRecord is now true");
    }
  }, [isRecord]);

  useEffect(() => {
    const updateOnlineStatus = () => {
      setIsOnline(navigator.onLine);
      if (navigator.onLine) {
        //   setTimeout(() => {
        //     console.log('this is user:', type)
        //     if (type === "main") {
        //       if (clickCallButton.current) {
        //         clickCallButton.current.click();
        //       }
        //     }
        //     console.log('user is online')
        // }, 5000);
      } else {
        if (leaveCallButtonRef.current) {
          leaveCallButtonRef.current.click();
        }
        // toast.error('Please Check your internet connection.');
      }
    };

    window.addEventListener("online", updateOnlineStatus);
    window.addEventListener("offline", updateOnlineStatus);

    // Cleanup event listeners on component unmount
    return () => {
      window.removeEventListener("online", updateOnlineStatus);
      window.removeEventListener("offline", updateOnlineStatus);
    };
  }, []);

  const handleVideoClick = () => {
    const { width } = getWindowDimensions();
    const surfaceTabletWidth = 768;
    if (width < surfaceTabletWidth) {
      setIsFloating(!isFloating);
      setIsCallFloating(false);
    } else {
      setIsFloating(false);
    }
  };

  useEffect(() => {
    return () => {
      if (call) {
        call.hangup();
        setCall(null);
        setLocalStream(null);
        setRemoteStream(null);
        setCallModalOpen(false);
        setCallConnectionDestroy(false);
      }
    };
  }, [call]);

  useEffect(() => {
    const script = document.createElement("script");
    script.src =
      "https://cdn.jsdelivr.net/npm/rtsp-relay@1.7.0/browser/index.js";
    script.async = true;
    script.onload = () => {
      /* eslint-disable no-undef */
      loadPlayer({
        url: `${streaming_url}/stream/url/5`, // Replace with your desired channel ID
        canvas: document.getElementById("canvas"),
      });
      /* eslint-enable no-undef */
    };
    document.head.appendChild(script);
    return () => {
      // Clean up the script element if the component unmounts
      document.head.removeChild(script);
    };
  }, [streaming_url]);

  useEffect(() => {
    socket.on("waitingResponse", (data) => {
      setCallerBusy(true);
      setNumber(data.number);

      setDuration(data.duration);
    });
  }, [callConnectionDestroy]);
  function updateElapsedTime() {
    setElapsedTime((prevElapsedTime) => prevElapsedTime + 1);
  }
  const getUserMedia = async () => {
    try {
      const stream = await navigator.mediaDevices.getUserMedia({
        video: true,
        audio: {
          echoCancellation: true,
          noiseSuppression: true,
          autoGainControl: true,
        },
      });
      if (mainlocalVideoRef.current) {
        mainlocalVideoRef.current.srcObject = stream;
        // mainlocalVideoRef.current.muted = true;
        // mainlocalVideoRef.current.playsInline = true;
        // mainlocalVideoRef.current.play();
      }
      return stream;
    } catch (error) {
      console.error("Error accessing media devices.", error);
      throw error;
    }
  };
  const getUserMedia1 = async () => {
    try {
      const stream = await navigator.mediaDevices.getUserMedia({
        video: true,
        audio: {
          echoCancellation: true,
          noiseSuppression: true,
          autoGainControl: true,
        },
      });
      if (localVideoRef.current) {
        localVideoRef.current.srcObject = stream;
        // localVideoRef.current.muted = true;
        // localVideoRef.current.playsInline = true;
        // localVideoRef.current.play();
      }
      return stream;
    } catch (error) {
      console.error("Error accessing media devices.", error);
      throw error;
    }
  };

  const intervalIdRef = useRef(null);
  const generateUserId = async () => {
    return "playerId";
  };

  const getAccessToken = async () => {
    try {
      const loginuser = "user_" + playerId;

      const headers = new Headers();
      headers.append("Content-Type", "application/json");
      headers.append("Access-Control-Allow-Origin", "*");
      headers.append("Access-Control-Allow-Credentials", "true");
      const response = await fetch(
        `${url}/api/auth/get-token?userId=${loginuser}`,
        {
          mode: "cors",
          method: "GET",
          headers: headers,
        }
      );
      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }
      const json = await response.json();

      if (json.success) {
        setAccessToken(json.token);
        if (type === "main") {
          await getUserMedia();
          handleLogin(json.token);
        } else {
          await getUserMedia1();
          handleLogin1(json.token);
        }
      }
    } catch (error) {
      console.error(error);
    }
  };
  const onStop = () => {
    if (timeoutStats) {
      clearTimeout(timeoutStats);
    }
  };

  useEffect(() => {
    socket.emit("gameIDs", playerId);
    // Reset timeRemaining to 10 seconds when the question changes
    getPlayers();
    socket.on("updateScore", () => {
      getPlayers("sockets");
    });
    socket.on("removeRecordingIcon", (type) => {
      if (type === "start") {
        setRecordingStart(true);
      } else {
        setRemovRecIcon(true);
      }
    });
    socket.on("recordingStart", () => {
      setRecordingStart(true);
    });
  }, []);
  useEffect(() => {
    hardRefreshPage();
    socket.on("callReconnect", () => {
      setCall(null);
      setLocalStream(null);
      setRemoteStream(null);
      if (call) {
        call.hangup();
      }
      if (type === "main") {
        if (clickCallButton.current) {
          clickCallButton.current.click();
        }
      }
    });
  }, [type]);
  const handleLogin = async (token) => {
    const client = new StringeeClient();

    client.on("connect", () => {
      console.log("StringeeClient connected", client);
    });

    client.on("authen", (res) => {
      if (res.r === 0) {
        console.log("authen", res);
        socket.emit("login", res.userId);
        setLoggedUserId(res.userId);
        getPlayer();
      } else {
        setLoggedUserId(res.message);
      }
    });

    client.on("disconnect", () => {
      console.log("StringeeClient disconnected");
      handleHangup();
    });

    client.on("incomingcall", (incomingcall) => {
      setCall(incomingcall);
      setLoaderShow(false);
      handleIncomingCall(incomingcall);
      setIsCallActive(true);
      incomingcall.ringing(() => {});
      // }
    });

    client.on("requestnewtoken", () => {});

    setStringeeClient(client);
    client.connect(token);
  };
  const handleLogin1 = async (token) => {
    const client = new StringeeClient();
    client.on("connect", () => {});

    client.on("authen", (res) => {
      if (res.r === 0) {
        console.log("authen", res);
        socket.emit("login", res.userId);
        setLoggedUserId(res.userId);
      } else {
        setLoggedUserId(res.message);
      }
    });

    client.on("disconnect", () => {
      console.log("StringeeClient disconnected");
    });

    client.on("incomingcall", (incomingcall) => {
      setCall(incomingcall);
      setLoaderShow(false);
      handleIncomingCall(incomingcall);
      setIsCallActive(true);
      incomingcall.ringing(() => {});
      // }
    });

    client.on("requestnewtoken", () => {});

    setStringeeClient(client);
    client.connect(token);
  };

  const handleIncomingCall = async (incomingcall) => {
    // Set the current call to the incoming call
    // setCall(incomingcall);
    // Handle the call connection
    setLoaderShow(false);
    await callConnectionReceiver(incomingcall);
    // Set call as active
    setIsCallActive(true);

    setCallConnectionDestroy(true);
    // Show call interface
    setTimeout(() => {
      if (clickJoin.current) {
        clickJoin.current.click();
      }
    }, 1000);

    // Handle incoming call events
    incomingcall.ringing(function (res) {});
  };

  function joinRoom() {
    setCallConnectionDestroy(true);

    setIsCallActive(true);

    if (call) {
      call.answer(function (res) {});
    }
  }

  const handleHangup = () => {
    try {
      if (call) {
        call.hangup();
        setCall(null);
        setLocalStream(null);
        setRemoteStream(null);
        setShowCallButton(true);
        setLoaderShow(false);
        setCallDisconnected(true);
        setOffline(false);
        onStop();
      }
    } catch (error) {
      console.error("Error hanging up the call:", error);
    }
  };

  const handleVideoCall = async () => {
    setTimeout(() => {
      callConnection();
      setCallConnectionDestroy(true);
      setIsCallActive(true);
      setLoaderShow(true);
      setCallDisconnected(false);
      setShowCallButton(false);
      setOffline(false);
    }, 3000);
  };

  const callConnection = async () => {
    if (!stringeeClient) {
      console.error("StringeeClient is not initialized");
      return;
    }

    if (!loggedUserId) {
      console.error("Logged user ID is not set");
      return;
    }

    if (!localPlayer) {
      console.error("Local player ID is not set");
      return;
    }

    setVideoShow(true);
    const fromNumber = "user_" + playerId;
    const toNumber = "user_" + localPlayer;
    const isVideoCall = true;

    const newCall = new StringeeCall(
      stringeeClient,
      fromNumber,
      toNumber,
      isVideoCall
    );
    console.log("new user call", newCall);
    // newCall.addStream(localStream);
    newCall.on("addlocalstream", (stream) => {
      if (mainlocalVideoRef.current) {
        setLoaderShow(false);
        mainlocalVideoRef.current.srcObject = stream;
        // mainlocalVideoRef.current.muted = true; // Mute local stream for autoplay
        mainlocalVideoRef.current.playsInline = true;
        mainlocalVideoRef.current.play().catch((error) => {
          // console.error("Error playing local stream:", error);
        });
      }
    });
    newCall.on("addremotestream", (stream) => {
      if (mainremoteVideoRef.current) {
        mainremoteVideoRef.current.srcObject = stream;
        // mainremoteVideoRef.current.muted = true; // Mute local stream for autoplay
        mainremoteVideoRef.current.playsInline = true;
        mainremoteVideoRef.current.play().catch((error) => {
          // console.error("Error playing local stream:", error);
        });
      }
    });

    newCall.makeCall((res) => {
      console.log("make call callbackdd: " + JSON.stringify(res));
      setCall(newCall);
    });

    newCall.on("signalingstate", (state) => {
      if (state.code === 2) {
        setRingingCall(true);
        setCallerBusy(false);
        setIsCallActive(false);
        setCallDisconnected(false);
        setOffline(false);
      } else if (state.code === 3) {
        setLoaderShow(false);
        setRingingCall(false);
        setIsCallActive(true);
        setCallerBusy(false);
        setCallDisconnected(false);
        setOffline(false);
      } else if (state.code === 5) {
        setRingingCall(false);
        setIsCallActive(false);
        setCallerBusy(true);
      } else if (state.code === 6) {
        if (leaveCallButtonRef.current) {
          leaveCallButtonRef.current.click();
        }
        setRingingCall(false);
        setIsCallActive(false);
        setCallerBusy(false);
        setShowCallButton(true);
        setCallConnectionDestroy(false);
        setOffline(true);
      } else {
        setRingingCall(false);
        setIsCallActive(false);
        setCallerBusy(true);
      }
    });
  };

  const callConnectionReceiver = async (newCall) => {
    // setCallDisconnected(false);
    newCall.on("addlocalstream", (stream) => {
      if (localVideoRef.current) {
        setLoaderShow(false);
        localVideoRef.current.srcObject = stream;
        // localVideoRef.current.muted = true; // Mute local stream for autoplay
        localVideoRef.current.playsInline = true;
        localVideoRef.current.play().catch((error) => {
          // console.error("Error playing local stream:", error);
        });
        setCallDisconnected(false);
      }
    });
    newCall.on("addremotestream", (stream) => {
      if (remoteVideoRef.current) {
        setLoaderShow(false);
        remoteVideoRef.current.srcObject = stream;
        // remoteVideoRef.current.muted = true; // Mute local stream for autoplay
        remoteVideoRef.current.playsInline = true;
        remoteVideoRef.current.play().catch((error) => {
          // console.error("Error playing local stream:", error);
        });
        setCallDisconnected(false);
      }
    });
    newCall.on("error", function (info) {});

    newCall.on("signalingstate", function (state) {
      if (state.code === 2) {
        setCallDisconnected(false);
        setDestroyMessage(false);
      } else if (state.code === 3) {
        setCallDisconnected(false);
        setDestroyMessage(false);
      } else if (state.code === 5) {
        setDestroyMessage(false);
      } else if (state.code === 6) {
        setCall(null);
        setLocalStream(null);
        setRemoteStream(null);
        setCallModalOpen(false);
        setCallConnectionDestroy(false);
        setCallDisconnected(true);
        setDestroyMessage(true);
      } else {
        setCall(null);
        setLocalStream(null);
        setRemoteStream(null);
      }
    });

    newCall.on("mediastate", function (state) {});

    newCall.on("info", function (info) {});

    newCall.on("otherdevice", function (data) {
      if (
        (data.type === "CALL_STATE" && data.code >= 200) ||
        data.type === "CALL_END"
      ) {
        // callEnded();
      }
    });
    // if (!modal) {
    //   setTimeout(() => {
    //     joinRoom();
    //   }, 3000); // 3000 milliseconds = 3 seconds
    // }
  };

  useEffect(() => {
    socket.on("destroyConnection", () => {
      try {
        setCallConnectionDestroy(false);
      } catch (error) {
        console.error("Error handling destroyConnection event:", error);
      }
    });
  }, []);

  useEffect(() => {
    // Reset timeRemaining to 60 seconds when the question changes

    setTimeRemaining(6000);

    // Set up the timer when the question changes
    const timer = setInterval(() => {
      setTimeRemaining((prevTime) => {
        if (prevTime > 0) {
          // Decrease time remaining by 1 second
          return prevTime - 1;
        } else {
          // If time is up, move to the next question
          clearInterval(timer); // Stop the timer
          return 0;
        }
      });
    }, 1000);
    // Clear the timer when the component unmounts or when moving to the next question
    return () => clearInterval(timer);
  }, [currentQuestionIndex]);

  const handleMuteToggle = () => {
    if (call) {
      call.mute(!muted);
      setMuted(!muted);
    }

    if (clickButton.current) {
      clickButton.current.click();
    }
  };
  const handleVideoToggle = () => {
    if (call) {
      const updatedVideoEnabled = !videoEnabled;
      call.enableLocalVideo(updatedVideoEnabled);
      setVideoEnabled(updatedVideoEnabled);
    }
    if (clickButton.current) {
      clickButton.current.click();
    }
  };

  const getPlayers = async (ans) => {
    try {
      let headers = new Headers();
      headers.append("Content-Type", "application/json");
      headers.append("Access-Control-Allow-Origin", "*");
      headers.append("Access-Control-Allow-Credentials", "true");

      const response = await fetch(
        `${url}/api/multiplayer/get/players/details/${id}?p=${playerId}`,
        {
          mode: "cors",
          method: "GET",
          headers: headers,
        }
      );

      const json = await response.json();
      if (json.success) {
        setPlayersDetail(json.players);
        setPlayerData(json.player);
        const player = json.player;
        const localPlayer = json.players.find(
          (player) => player.type === "local"
        );
        if (isRefresh) {
          socket.emit("reloadPageCheck", 131313);
        }
        setLocalPlayer(json.localplayer ? json.localplayer._id : "");
      }
    } catch (error) {
      toast.error(error.message);
    }
  };
  const getPlayer = async (ans) => {
    try {
      let headers = new Headers();
      headers.append("Content-Type", "application/json");
      headers.append("Access-Control-Allow-Origin", "*");
      headers.append("Access-Control-Allow-Credentials", "true");

      const response = await fetch(
        `${url}/api/multiplayer/get/players/details/${id}?p=${playerId}`,
        {
          mode: "cors",
          method: "GET",
          headers: headers,
        }
      );

      const json = await response.json();
      if (json.success) {
        const player = json.player;
        if (!isCallActive && iscameraon) {
          if (clickCallButton.current) {
            clickCallButton.current.click();
          }
          // }, 2000);
        }
      }
    } catch (error) {
      toast.error("Internal Server Error !");
    }
  };
  const hardRefreshPage = async (ans) => {
    try {
      let headers = new Headers();
      headers.append("Content-Type", "application/json");
      headers.append("Access-Control-Allow-Origin", "*");
      headers.append("Access-Control-Allow-Credentials", "true");

      const response = await fetch(
        `${url}/api/multiplayer/get/players/details-page/${id}?p=${playerId}`,
        {
          mode: "cors",
          method: "GET",
          headers: headers,
        }
      );

      const json = await response.json();
      if (json.success) {
        let reloadCount = parseInt(localStorage.getItem(playerId)) || 0;

        reloadCount += 1;
        if (reloadCount > 1) {
          socket.emit(
            "reloadPageCheck",
            json.localplayer ? json.localplayer._id : ""
          );
        }
        // Update the reload count in sessionStorage
        localStorage.setItem(playerId, reloadCount);
      }
    } catch (error) {
      toast.error(error.message);
    }
  };
  const mainButtonRef = useRef(null);

  useEffect(() => {
    // Auto-click the button when the component mounts if the player type is "main"
    if (mainButtonRef.current) {
      mainButtonRef.current.click();
    }
  }, [playersDetail]);
  let allAnswers = [];
  if (question !== 0) {
    allAnswers = question
      ? [question.correctAnswer, ...question.incorrectAnswers].sort((a, b) =>
          a < b ? -1 : 1
        )
      : [];
  }

  return (
    <>
      <div className="row justify-content-center">
        <div
          className="col-12 col-md-5 col-lg-5 mt-2 "
          style={{ maxWidth: "345px" }}
        >
          {!gameEnd && allAnswers && question && (
            <div
              className="bg-white rounded p-2 progress-box"
              style={{ maxWidth: "345px" }}
            >
              <span className="d-flex flex-row justify-content-center fw-bold ">
                Progress
              </span>
              {playersDetail?.map((player, index) =>
                player._id === playerId ? (
                  <>
                    <div className="position-relative mt-1">
                      <div
                        className="bg-info rounded "
                        style={{
                          border: "1px solid red",
                          opacity: player.status === "left" ? 0.5 : 1,
                        }}
                      >
                        <div className="d-flex justify-content-between  align-items-center flex-row gap-1 p-1">
                          <img src={Player3} alt="" height={25} width={25} />
                          <div className="d-flex justify-content-center align-items-start flex-column ">
                            <span
                              className="text-capitalize"
                              style={{ fontSize: "9px", fontWeight: "700" }}
                            >
                              {player.name ? player.name.split(" ")[0] : "Host"}{" "}
                            </span>
                            <span
                              className="text-capitalize"
                              style={{
                                fontSize: "7px",
                                fontWeight: "700",
                                color:
                                  player.status === "join" ? "green" : "red",
                              }}
                            >
                              {player.status === "left" ? "Left" : "Active"}
                            </span>
                          </div>
                          {player.type === "local" ? (
                            <div className="coins____body d-flex justify-content-center align-items-center">
                              <span className="coins-number">
                                {player.correct ? player.correct : "0"}
                              </span>
                            </div>
                          ) : (
                            <div className="coins____body___2 d-flex justify-content-center align-items-center"></div>
                          )}
                        </div>
                      </div>
                    </div>
                  </>
                ) : (
                  <>
                    <div className="position-relative mt-1">
                      <div
                        className="bg-white rounded "
                        style={{
                          border: "1px solid red",
                          opacity: player.status === "left" ? 0.5 : 1,
                        }}
                      >
                        <div className="d-flex justify-content-between align-items-center flex-row gap-1 p-1">
                          <img src={Player1} alt="" height={25} width={25} />
                          <div className="d-flex justify-content-center align-items-center flex-column ">
                            <span
                              className="text-capitalize"
                              style={{ fontSize: "9px", fontWeight: "700" }}
                            >
                              {player.name ? player.name.split(" ")[0] : "Host"}{" "}
                            </span>
                            <span
                              className="text-capitalize"
                              style={{
                                fontSize: "7px",
                                fontWeight: "700",
                                color:
                                  player.status === "join" ? "green" : "red",
                              }}
                            >
                              {player.status === "left" ? "Left" : "Active"}
                            </span>
                          </div>
                          {player.type === "local" ? (
                            <div className="coins____body d-flex justify-content-center align-items-center">
                              <span className="coins-number">
                                {player.correct ? player.correct : "0"}
                              </span>
                            </div>
                          ) : (
                            <div className="coins____body___2 d-flex justify-content-center align-items-center"></div>
                          )}
                        </div>
                      </div>
                    </div>
                  </>
                )
              )}
            </div>
          )}

          <div className="d-flex justify-content-center flex-row flex-wrap gap-2 mt-2">
            <div
              className="calling-area"
              style={{
                maxWidth: "90vw",
                maxHeight: "100vh",
                overflow: "hidden",
              }}
            >
              <div className="user-video-screens mb-3 local">
                {loaderShow && (
                  <>
                    <div
                      className="lds-spinner"
                      style={{ display: "flex", top: "65px" }}
                    >
                      <div></div>
                      <div></div>
                      <div></div>
                      <div></div>
                      <div></div>
                      <div></div>
                      <div></div>
                      <div></div>
                      <div></div>
                      <div></div>
                      <div></div>
                      <div></div>
                    </div>
                    <div className="center-wraper">
                      <h5 className="text-black d-flex justify-content-center align-items-center ">
                        {playerData && playerData.type === "main"
                          ? "Connecting Host..."
                          : "Connecting Player..."}
                      </h5>
                    </div>
                  </>
                )}
                {callDisconnected && (
                  <>
                    <div className="player-disconnect">
                      <h5 className="text-red d-flex justify-content-center align-items-center ">
                        {playerData &&
                          playerData.type !== "main" &&
                          "Player Call Disconnected"}
                      </h5>
                    </div>

                    <div className="reconnect-call mb-3 start-call-btn">
                      {playerData &&
                        playerData.type === "main" &&
                        showCallButton && (
                          <button
                            className="btn btn-reconnect btn-styling w-50"
                            onClick={handleVideoCall}
                          >
                            Start Call
                          </button>
                        )}
                    </div>
                  </>
                )}
                {parseInt(recordingStart) === 2 &&
                  (recordingStop === "start" || recordingsStart) &&
                  !removRecIcon && (
                    <div
                      className="recording-icon"
                      style={{
                        position: "absolute",
                        top: "10px",
                        right: "20px",
                        zIndex: "100",
                      }}
                    >
                      <img
                        src={RecordingIcon}
                        alt="Recording"
                        width={15}
                        height={15}
                      />
                    </div>
                  )}
                {callConnectionDestroy && (
                  <div
                    className=""
                    style={{
                      // background: "linear-gradient(#D3D3D3, #D3D3D3)",
                      position: "relative",
                      width: "100%",
                      height: "100%",
                      overflow: "hidden",
                    }}
                  >
                    <div>
                      {type === "main" ? (
                        <>
                          <div>
                            <video
                              ref={mainlocalVideoRef}
                              autoPlay
                              muted // Mute local video to prevent feedback
                              width="100%"
                              controls={false}
                              onLoadedMetadata={() => {
                                mainlocalVideoRef.current
                                  .play()
                                  .catch((error) => {
                                    console.error(
                                      "Error playing remote stream:",
                                      error
                                    );
                                  });
                              }}
                              onCanPlay={() => {
                                mainlocalVideoRef.current.style.width = "100%";
                                mainlocalVideoRef.current.style.height = "100%";
                              }}
                            ></video>
                          </div>
                        </>
                      ) : (
                        <>
                          <video
                            ref={localVideoRef}
                            autoPlay
                            muted // Mute local video to prevent feedback
                            className="local-streaming"
                            // style={{
                            //   position: "absolute",
                            //   top: "5px",
                            //   right: "5px",
                            // }}
                            // width="30%"
                            // height="30%"
                            width="100%"
                            controls={false}
                            onLoadedMetadata={() => {
                              localVideoRef.current.play().catch((error) => {
                                console.error(
                                  "Error playing remote stream:",
                                  error
                                );
                              });
                            }}
                            onCanPlay={() => {
                              localVideoRef.current.style.width = "100%";
                              localVideoRef.current.style.height = "100%";
                            }}
                          ></video>
                        </>
                      )}

                      {playerData.type === "main" && (
                        <div className="bottom-bar-call seperat-row mb-2">
                          <div className="icons-wrap-end-call ">
                            <div className="icon-call">
                              {videoEnabled ? (
                                <img
                                  src={SwtchVideo}
                                  alt="Unmute Video"
                                  className="mx-2 d-flex justify-content-center"
                                  width={27}
                                  height={27}
                                  onClick={handleVideoToggle}
                                />
                              ) : (
                                <img
                                  src={UnmuteVideo}
                                  alt="Mute Video"
                                  className="mx-2 d-flex justify-content-center  rounded"
                                  width={27}
                                  height={27}
                                  onClick={handleVideoToggle}
                                />
                              )}
                            </div>

                            <div
                              className="icon-call dec-call-video"
                              onClick={() => handleHangup()}
                            >
                              <img src={DecVideCall} alt="" />
                            </div>
                            <div className="icon-call">
                              {!muted ? (
                                <img
                                  src={UnmuteSwitch}
                                  alt="UnMute Call"
                                  className="mx-2 d-flex justify-content-center"
                                  width={27}
                                  height={27}
                                  onClick={handleMuteToggle}
                                />
                              ) : (
                                <img
                                  src={MuteSwitch}
                                  alt="Mute Call"
                                  className="mx-2 d-flex justify-content-center rounded "
                                  width={27}
                                  height={27}
                                  onClick={handleMuteToggle}
                                />
                              )}
                            </div>
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                )}
              </div>

              <div className="player-video-screens mb-3">
                {loaderShow && (
                  <>
                    <div
                      className="lds-spinner"
                      style={{ display: "flex", top: "65px" }}
                    >
                      <div></div>
                      <div></div>
                      <div></div>
                      <div></div>
                      <div></div>
                      <div></div>
                      <div></div>
                      <div></div>
                      <div></div>
                      <div></div>
                      <div></div>
                      <div></div>
                    </div>

                    <div className="center-wraper">
                      <h5 className="text-black d-flex justify-content-center align-items-center ">
                        {playerData && playerData.type === "main"
                          ? "Connecting Player..."
                          : "Connecting Host..."}
                      </h5>
                    </div>
                  </>
                )}
                {!offline && callDisconnected && (
                  <>
                    <div className="player-disconnect">
                      <h5 className="text-red d-flex justify-content-center align-items-center ">
                        {playerData && playerData.type === "main"
                          ? "Player Call Disconnected"
                          : "Host Call Disconnected"}{" "}
                      </h5>
                    </div>
                  </>
                )}

                {offline && (
                  <>
                    <div className="player-offline">
                      <h5 className="text-red d-flex justify-content-center align-items-center ">
                        {playerData && playerData.type === "main"
                          ? "Player Call Not Connected"
                          : ""}{" "}
                      </h5>
                    </div>
                  </>
                )}
                {parseInt(recordingStart) === 2 &&
                  (recordingStop === "start" || recordingsStart) &&
                  !removRecIcon && (
                    <div
                      className="recording-icon"
                      style={{
                        position: "absolute",
                        top: "10px",
                        right: "20px",
                        zIndex: "100",
                      }}
                    >
                      <img
                        src={RecordingIcon}
                        alt="Recording"
                        width={15}
                        height={15}
                      />
                    </div>
                  )}
                {callConnectionDestroy && (
                  <div
                    className=""
                    style={{
                      // background: "linear-gradient(#D3D3D3, #D3D3D3)",
                      position: "relative",
                      width: "100%",
                      height: "100%",
                      overflow: "hidden",
                    }}
                  >
                    <div>
                      {type === "main" ? (
                        <>
                          {!mainremoteVideoRef.current?.srcObject && (
                            <div
                              className="lds-spinner"
                              style={{ display: "flex", top: "65px" }}
                            >
                              <div></div>
                              <div></div>
                              <div></div>
                              <div></div>
                              <div></div>
                              <div></div>
                              <div></div>
                              <div></div>
                              <div></div>
                              <div></div>
                              <div></div>
                              <div></div>
                            </div>
                          )}
                          <div>
                            <video
                              ref={mainremoteVideoRef}
                              autoPlay
                              muted={false} // Unmute remote video
                              controls={false}
                              width="100%"
                              onLoadedMetadata={() => {
                                mainremoteVideoRef.current
                                  .play()
                                  .catch((error) => {
                                    console.error(
                                      "Error playing remote stream:",
                                      error
                                    );
                                  });
                              }}
                            ></video>
                          </div>
                          {!mainremoteVideoRef.current?.srcObject && (
                            <h5
                              className="text-center call-signal"
                              style={{
                                marginTop:
                                  playerData.type === "main" ? "-55px" : "-5px",
                              }}
                            >
                              Connecting Player...
                            </h5>
                          )}
                        </>
                      ) : (
                        <>
                          {!remoteVideoRef.current?.srcObject && (
                            <div
                              className="lds-spinner"
                              style={{ display: "flex", top: "65px" }}
                            >
                              <div></div>
                              <div></div>
                              <div></div>
                              <div></div>
                              <div></div>
                              <div></div>
                              <div></div>
                              <div></div>
                              <div></div>
                              <div></div>
                              <div></div>
                              <div></div>
                            </div>
                          )}{" "}
                          <div>
                            <video
                              ref={remoteVideoRef}
                              autoPlay
                              muted={false} // Unmute remote video
                              className="remote-stream"
                              controls={false}
                              width="100%"
                              onLoadedMetadata={() => {
                                remoteVideoRef.current.play().catch((error) => {
                                  console.error(
                                    "Error playing remote stream:",
                                    error
                                  );
                                });
                              }}
                            ></video>
                          </div>
                          {!remoteVideoRef.current?.srcObject && (
                            <h5
                              className="text-center call-signal"
                              style={{
                                marginTop:
                                  playerData.type === "main" ? "-55px" : "-5px",
                              }}
                            >
                              Connecting Host...
                            </h5>
                          )}
                        </>
                      )}
                    </div>
                  </div>
                )}
              </div>
              {/* {gameEnd ? (
                <Record
                  isRecord={false}
                  isStop={true}
                  gameEnd={true}
                  type={type}
                />
              ) : (
                <Record
                  isRecord={isRecord}
                  isStop={isStop}
                  gameEnd={false}
                  type={type}

                />
              )} */}
            </div>
          </div>
        </div>

        <div className="col-12 col-md-12 col-lg-7">
          {gameEnd ? (
            <DashboardScr
              streaming_url={streaming_url}
              recordingStarting={recordingStart}
            />
          ) : (
            <>
              {allAnswers && question && (
                <div className="d-flex flex-column justify-content-center overflow-hidden ">
                  <div className=" d-flex flex-column flex-sm-row gap-3 justify-content-end mt-2 ">
                    <div
                      className="bg-white text-black p-4 mx-auto container text-center"
                      style={{
                        maxWidth: "800px",
                        clipPath: "polygon(1% 0%, 99% 0%, 100% 100%, 0% 100%)",
                        borderRadius: "10px",
                        height: "min-content",
                      }}
                    >
                      <h4 className="question-text">
                        {allAnswers && question ? question.question : ""}
                      </h4>
                    </div>
                  </div>

                  <div className="d-flex gap-3 justify-content-center align-item-center mt-4">
                    <h1 className="timer-text ">
                      {currentQuestionIndex === 10
                        ? currentQuestionIndex
                        : currentQuestionIndex + 1}
                      /10
                    </h1>

                    {isCorrect && gametype === "coin" && (
                      <div className="right___ans____header d-flex justify-content-center align-items-center">
                        <div className="right___ans____body d-flex justify-content-center align-items-center">
                          <span className="right___ans___number">+5</span>
                        </div>
                      </div>
                    )}
                    {isFalse && gametype === "coin" && (
                      <div className="wrong___ans____header d-flex justify-content-center align-items-center">
                        <div className="wrong___ans____body d-flex justify-content-center align-items-center">
                          <span className="wrong___ans___number">-5</span>
                        </div>
                      </div>
                    )}
                  </div>

                  <div className="mx-auto mt-4">
                    <div className="row row-cols-2 g-4 mx-auto">
                      {allAnswers.map((answer, i) => (
                        <div key={i} className="col">
                          <div
                            className={` w-100 m-1 text-black p-2 text-center rounded questions-back ${
                              (isCorrect || isFalse) &&
                              answer == question.correctAnswer
                                ? "correct__answer"
                                : "questions-backColor"
                            } ${
                              isFalse &&
                              answer != question.correctAnswer &&
                              (i == selectedAnswer || i == selectedAnswers)
                                ? "wrong__answer"
                                : "questions-backColor"
                            }`}
                            onClick={() => {
                              if (
                                playerData.type !== "main" &&
                                !isCorrect &&
                                !isFalse &&
                                !isAnswered
                              ) {
                                setSelectedAnswer(i);
                                onGuessAnswer(
                                  answer,
                                  timeRemaining,
                                  playerData,
                                  i
                                );
                              }
                            }}
                          >
                            <p className="questions-css question-text">
                              {answer}
                            </p>
                          </div>
                        </div>
                      ))}
                    </div>
                  </div>
                </div>
              )}
              <div className="row mt-5 pb-5">
                <div className="col-xl-7 mx-auto p-3 text-center">
                  <canvas
                    id="canvas"
                    height="200"
                    width="100%"
                    style={{
                      width: isFloating ? "300px" : "100%",
                      position: isFloating ? "fixed" : "static",
                      bottom: "10px",
                      right: "10px",
                      display: allAnswers && question ? "inline-block" : "none",
                      zIndex: isFloating ? "99999" : "0",
                    }}
                    onClick={handleVideoClick}
                  ></canvas>
                </div>
              </div>
            </>
          )}
        </div>
        <a ref={leaveCallButtonRef} onClick={handleHangup}></a>
        <a ref={clickJoin} onClick={joinRoom}></a>
        <a ref={clickCallButton} onClick={handleVideoCall}></a>
      </div>
    </>
  );
};

// export default Answer;

import React, { useEffect, useState, useRef } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import { Answer } from "../question/ShowQuestionAnswer.js";
import logo from "../../assets/logo-dark.png";
import ins1 from "../../assets/ins1.png";
import ins2 from "../../assets/ins2.png";
import ins3 from "../../assets/ins3.png";
import Profile from "../../images/question-profile.gif";
import { toast } from "react-toastify";
import Modal from "react-modal";
import "../../puzzlestyle.css";
import "./style.css";
import backgroundMusic from "../../assets/audio/QuestionStartSound.wav";
import backgroundMusicClick from "../../assets/audio/click.mp3";
import backgroundMusicIncorrect from "../../assets/audio/AnswerWrongSound.mp3";
import backgroundMusicCorrect from "../../assets/audio/AnswerCorrectSoung.mp3";
import { useDispatch, useSelector } from "react-redux";
import { logout } from "../Redux/Authentication";
import IconLogout from "../../assets/icons/icon-logout.svg";
import Dashboard from "../../assets/icons/icon-doors.svg";
import Play from "../../assets/icons/Play.svg";
import User from "../../assets/user-white.png";
import Loader from "../partials/Loader";
import io from "socket.io-client";
import DownloadRec from "../../assets/new-design/download.png";
import FinishGame from "../../assets/new-design/re-start.png";
import ShareButtons from "../dashboard/ShareButtons.js";
const url = process.env.REACT_APP_APP_BACK_URL;

const socket = io.connect(url);
const MultiQuestionPage = () => {
  let navigate = useNavigate();
  const { id } = useParams();
  const [game_new_id, setGame_new_id] = useState(null);
  const [loader, setLoader] = useState(false);
  const [questions, setQuestions] = useState([]);
  const [gif, setGif] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  // const [, setIsLoading] = useState(false);
  const [isRefresh, setIsRefresh] = useState(true);
  const [gametype, setGameType] = useState("");
  const [currentQuestionIndex, setCurrentQuestionIndex] = useState(0);
  const [questionInc, setQuestionInc] = useState(0);
  const [points, setPoints] = useState(100);
  const [passpoints, setpasspoints] = useState(100);
  const [isQuestionHandled, setIsQuestionHandled] = useState(false);
  const [winningNumber, setWinningNumber] = useState(0);
  const [timeRemaining, setTimeRemaining] = useState(6000);
  const [isinstructionModalOpen, setisinstructionModalOpen] = useState(false);
  const [isReadyModalOpen, setIsReadyModalOpen] = useState(false);
  const [playerOnline, setIsPlayerOnline] = useState(false);
  const [handleQuestion, setHandleQuestion] = useState(false);
  const currentQuestion = questions[currentQuestionIndex];
  const remainingNumberOfQuestions = questions.length - currentQuestionIndex;
  const [backgroundAudioIncorrect] = useState(
    new Audio(backgroundMusicIncorrect)
  );
  const [backgroundAudioCorrect] = useState(new Audio(backgroundMusicCorrect));
  const [backgroundAudio] = useState(new Audio(backgroundMusic));
  const [isGameEnd, setIsGameEnd] = useState(false);
  const [ready, setIsReady] = useState(false);
  const [isGameStart, setIsGameStart] = useState(true);
  const [playerData, setPlayerData] = useState([]);
  const queryParams = new URLSearchParams(window.location.search);
  const player = queryParams.get("p");
  const position = parseInt(queryParams.get("position")) || 0;
  const [playersDetail, setPlayersDetail] = useState();
  const isAuthenticated = useSelector((state) => state.user);
  const [winnerLocker, setWinnerLocker] = useState();
  const [updatestatus, setupdatestatus] = useState();
  const [opacitylow, setopacitylow] = useState(1);
  const [seletedPlayers, setSeletedPlayers] = useState([]);
  const [gameEnd, setGameEnd] = useState(false);
  const [mainPlayrLeft, setMainPlayrLeft] = useState(false);
  const [isFailModalOpen, setIsFailModalOpen] = useState(false);
  const [mainPlayerGame, setMainPlayerGame] = useState("");
  const [selectedAnswers, setSelectedAnswer] = useState(null);
  const [isAnswered, setIsAnswered] = useState(false);
  const [credentials, setCredentials] = useState({ name: "", recording: "" });
  const [isCorrect, setIsCorrect] = useState(false);
  const [isFalse, setIsFalse] = useState(false);
  const [currentPlayer, setCurrentPlayer] = useState(false);
  const [iscameraon, SetCameraOn] = useState(true);
  const [isquestionshow, setQuestionShow] = useState(false);
  const [isOnline, setIsOnline] = useState(navigator.onLine);
  const [recordingShow, setRecordingShow] = useState(false);
  const [isRecord, setisRecord] = useState(false);
  const [isStop, setisStop] = useState(false);
  const [videoSrc, setVideoSrc] = useState(null);
  const [isrecordingStart, setIsrecordingStart] = useState("0");
  const [isRecordingStop, setIsRecordingStop] = useState("no");
  const answerComponentRef = useRef(null);
  const audioRef = useRef(null);
  const dispatch = useDispatch();
  const [checked, setChecked] = useState(false);
  const [recordingLoading, setRecordingLoading] = useState(false);
  const [timeRemainings, setTimeRemainings] = useState(0);
  let count=0;
  const handleCheckboxChange = (e) => {
    const { name, checked } = e.target;
    setChecked(checked);

    // If the checkbox is checked, add 'yes' to the state; otherwise, set 'no'
    if (checked) {
      setCredentials({ ...credentials, [name]: "yes" });
    } else {
      setCredentials({ ...credentials, [name]: "no" });
    }
  };
  const handleCheckboxChange1 = (e) => {
    const { name, checked } = e.target;
    setChecked(checked);
    // If the checkbox is checked, add 'yes' to the state; otherwise, set 'no'
  };
  const gameType = async (game_type) => {
    setGameType(game_type);
  };

  useEffect(() => {
    socket.emit("gameID", player);
    socket.on("userStartGame", () => {
      setIsGameStart(true);
      setQuestionShow(true);
      handleAgainPlay();
    });
    socket.on("startNewQuestion", () => {
      // setIsQuestionHandled(false);
      // getQuestions();

      handleNextQuestion();
    });
    socket.on("eliminateGame", () => {
      checkPlayer();
    });
    socket.on("displayOnlineUsers", () => {
      setQuestionShow(false);
      setIsGameStart(true);
      setIsReady(true);
      SetCameraOn(true);
      getPlayers();
    });

    // When timeRemaining reaches 0, move to the next question
  }, []);
  useEffect(() => {
    if (recordingShow) {
      const timer = setInterval(() => {
        setTimeRemainings((prevTime) => {
          return prevTime - 1;
        });
      }, 1000);

      // When timeRemaining reaches 0, move to the next question
      if (timeRemainings === 0) {
        setRecordingLoading(true);
      }

      // Clear the timer when the component unmounts or when moving to the next question
      return () => clearInterval(timer);
    }
    // }
  }, [timeRemaining, recordingShow]);
  useEffect(() => {
    const updateOnlineStatus = () => {
      setIsOnline(navigator.onLine);
      if (navigator.onLine) {
        // setTimeout(() => {
        //   console.log('you are online again',seletedPlayers)
        //   socket.emit("joinUser", seletedPlayers);
        // }, 10000);
      } else {
        // toast.error('Please Check your internet connection.');
      }
    };

    window.addEventListener("online", updateOnlineStatus);
    window.addEventListener("offline", updateOnlineStatus);

    // Cleanup event listeners on component unmount
    return () => {
      window.removeEventListener("online", updateOnlineStatus);
      window.removeEventListener("offline", updateOnlineStatus);
    };
  }, []);

  useEffect(() => {
    // getCurrentPlayer();
    localStorage.setItem("gameID", id);
  }, [id]);
  useEffect(() => {
    // getCurrentPlayer();
    getQuestions();
  }, [game_new_id]);

  useEffect(() => {
    const handlePageHide = async (event) => {
      event.preventDefault(); // Cancel the default behavior
      await UpdatePlayerStatus(seletedPlayers);
    };
    window.addEventListener("pagehide", handlePageHide);
    return () => {
      window.removeEventListener("pagehide", handlePageHide);
    };
  }, [seletedPlayers]);

  useEffect(() => {
    if (isGameEnd) {
      backgroundAudio.pause();
      backgroundAudio.currentTime = 0;
    }
    return () => {
      // Clean up: Stop the background music when the component unmounts
      backgroundAudio.pause();
      backgroundAudio.currentTime = 0;
    };
  }, [isGameEnd, backgroundAudio]);
  const handleInput = (e) => {
    setCredentials({ ...credentials, [e.target.name]: e.target.value });
  };
  // const handleCheckboxChange = (e) => {
  //   const { value, checked, name } = e.target;

  //   // If the checkbox is checked, add the value to the state; otherwise, remove it
  //   if (checked) {
  //     setCredentials({ ...credentials, [name]: value });
  //   } else {
  //     setCredentials({ ...credentials, [name]: "no" });
  //   }
  // };
  const [isMuted, setIsMuted] = useState(true);

  const handleToggleMute = async () => {
    {
      if (!isMuted) {
        backgroundAudio.play();
      } else {
        backgroundAudio.pause();
      }
      setIsMuted(!isMuted);
    }
  };

  const getQuestions = async () => {
    try {
      let headers = new Headers();
      headers.append("Content-Type", "application/json");
      headers.append("Access-Control-Allow-Origin", "*");
      headers.append("Access-Control-Allow-Credentials", "true");

      const response = await fetch(
        `${url}/api/ShowQuestion/get/question/${localStorage.getItem(
          "gameID"
        )}/${player}`,
        {
          mode: "cors",
          method: "GET",
          headers: headers,
        }
      );
      const json = await response.json();

      if (json.success) {
        setCurrentPlayer(json.currentPlayer);
        setIsrecordingStart(json.game.isRecordingStart);
        setIsRecordingStop(json.game.isRecordingStop);
        // if (json.currentPlayer.status == "left") {
        //   toast.error("You already left the game !");
        //   navigate(`/`);
        // }

        // if (json.mainPlayer.status == "left") {
        //   navigate("/");
        // }

        if (
          json.game.current_question == 0 &&
          json.currentPlayer.name != "" &&
          json.game.start == "no" &&
          json.currentPlayer.type === "local"
        ) {
          setIsPlayerOnline(true);
          setIsReady(true);
          // setIsGameStart(false);
        }

        if (
          json.game.current_question == 0 &&
          json.currentPlayer.type === "main" &&
          json.game.start == "no"
        ) {
          setIsPlayerOnline(true);
          // setIsGameStart(false);
        } else if (
          json.game.current_question > 9 &&
          json.game.openLocker == "no"
        ) {
          // toast.error("Game already played !");

          setIsGameEnd(true);

          if (json.currentPlayer.type === "local") {
            if (json.currentPlayer.correct >= 3) {
              // navigate(
              //   `/show/winner/lockers/${localStorage.getItem(
              //     "gameID"
              //   )}?p=${player}`
              // );

              setGameEnd(true);
            } else {
              if (parseInt(json.game.isRecordingStart) > 1) {
                setTimeout(() => {
                  count=0;
                  getRecordings();
                }, 6000);
              }
              setIsFailModalOpen(true);
            }
          } else if (json.currentPlayer.type === "main") {
            const localPlayer = json.players.find(
              (player) => player.type === "local"
            );

            if (localPlayer.correct >= 3) {
              // navigate(

              //   `/show/winner/lockers/${localStorage.getItem(
              //     "gameID"
              //   )}?p=${player}`
              // );
              // handleOpenUpdatedLocker();
              setGameEnd(true);
            } else {
              if (parseInt(json.game.isRecordingStart) > 1) {
                console.log("=====", player);
                socket.emit("stopMainRecording", {
                  player: player,
                });
                setTimeout(() => {
                  count=0;
                  getRecordings();
                }, 6000);
              }

              setIsFailModalOpen(true);
            }
          }
        } else if (
          json.game.current_question > 9 &&
          json.game.openLocker == "yes"
        ) {
          // toast.error("Game already played !");
          setGame_new_id("");
          // audioRef.current.pause();
          setIsGameEnd(true);
          navigate(`/`);
        } else if (
          json.game.start == "yes" &&
          json.currentPlayer.name != "" &&
          json.currentPlayer.type === "local"
        ) {
          const convertedData = await json.game.questions.map((item, index) =>
            JSON.parse(item)
          );
          if (!audioRef.current) {
            audioRef.current = new Audio(backgroundMusic);
            audioRef.current.play(); // Play music for the next question
          }

          setIsCorrect(false);
          setIsFalse(false);
          setTimeRemaining(6000);
          setpasspoints(100);
          setCurrentQuestionIndex(parseInt(json.game.current_question));
          setQuestions(convertedData);
          setQuestionShow(true);

          // if (audioRef.current) {
          //   audioRef.current.play();  // Ensure music plays when questions are loaded
          // }
        } else if (
          json.game.start == "yes" &&
          json.currentPlayer.type === "main"
        ) {
          if (!audioRef.current) {
            audioRef.current = new Audio(backgroundMusic);
            audioRef.current.play(); // Play music for the next question
          }
          const convertedData = await json.game.questions.map((item, index) =>
            JSON.parse(item)
          );

          setIsCorrect(false);
          setIsFalse(false);
          setTimeRemaining(6000);
          setpasspoints(100);
          setCurrentQuestionIndex(parseInt(json.game.current_question));
          setQuestions(convertedData);
          setQuestionShow(true);
          if (parseInt(json.game.isRecordingStart) > 1 && json.game.isRecordingStop==="no") {
            const newWindow = window.open(
              `/recording/${id}?type=main&p=${player}`,
              "_blank",
              "width=800,height=600"
            );

            if (newWindow) {
              newWindow.focus();
            }

            // if (!localStorage.getItem(`rec_${id}`)) {
            //   localStorage.setItem(`rec_${id}`, newWindow);
            //   console.log("main window", newWindow);
            // }
          }
        }

        if (
          json.currentPlayer.name === "" &&
          json.currentPlayer.type === "local"
        ) {
          setIsReadyModalOpen(true);
          // setIsGameStart(false);
        }
      }
    } catch (error) {
      toast.error(error.message);
    }
  };
  // display user recordings
  const getRecordings = async () => {
    try {
      let headers = new Headers();
      headers.append("Content-Type", "application/json");
      headers.append("Access-Control-Allow-Origin", "*");
      headers.append("Access-Control-Allow-Credentials", "true");
      const response = await fetch(
        `${url}/api/multiplayer/get/player/recording/${id}`,
        {
          mode: "cors",
          method: "GET",
          headers: headers,
        }
      );
      const json = await response.json();
      if (json.success) {
        setRecordingShow(true);
        setIsFailModalOpen(false);
        setTimeRemainings(7);
        count=parseInt(count)+1;
        if (json.recording === "no") {
          // socket.emit("stopRecording", {
          //   player: seletedPlayers,
          // });
          setTimeout(() => {
            if(count<3){
              getRecordings();
            }
          }, 2000);
        } else if (
          parseInt(isrecordingStart) > 2
        ) {

        }  else if (json.recording !== "no" && parseInt(isrecordingStart) <= 2) {
          setVideoSrc(json.recording);
        }
      }
    } catch (error) {
      toast.error(error.message);
    }
  };
  //  const savesetpoints = ()=>({
  const savesetpoints = async (points, answer) => {
    try {
      let headers = new Headers();
      headers.append("Content-Type", "application/json");
      headers.append("Access-Control-Allow-Origin", "*");
      headers.append("Access-Control-Allow-Credentials", "true");
      const response = await fetch(
        `${url}/api/ShowQuestion/update/points/${player}`,
        {
          mode: "cors",
          method: "Post",
          headers: headers,
          body: JSON.stringify({
            points: points,
            game_id: id,
            answer: answer,
          }),
        }
      );
      const json = await response.json();
      if (json.success) {
        setpasspoints(points);
        socket.emit("saveScore", seletedPlayers);
        if (json.mainLeft) {
          socket.emit("mianleft", seletedPlayers);
        }
        if (json.attemp) {
          displayNextQuestion();
        }
      }
    } catch (error) {
      toast.error("Internal Server Error awnswes !");
    }
  };
  const checkPlayer = async () => {
    try {
      let headers = new Headers();
      headers.append("Content-Type", "application/json");
      headers.append("Access-Control-Allow-Origin", "*");
      headers.append("Access-Control-Allow-Credentials", "true");

      const response = await fetch(
        `${url}/api/ShowQuestion/update/game/playerleft/${player}`,
        {
          mode: "cors",
          method: "Post",
          headers: headers,
          body: JSON.stringify({
            game_id: id,
          }),
        }
      );

      const json = await response.json();

      if (json.success) {
        if (json.mainLeft) {
          setMainPlayrLeft(true);
          toast.error("Main Player left  the game");
          setTimeout(() => {
            // navigate("/");
          }, 1500);
        } else if (json.localLeft) {
          // setMainPlayrLeft(true);
          toast.error("Player left  the game");
          setTimeout(() => {
            navigate("/");
          }, 1500);
        }
        if (json.attemp) {
          socket.emit("handleNext", seletedPlayers);
        } else {
          socket.emit("saveScore", seletedPlayers);
        }
      }
    } catch (error) {
      toast.error("Internal Server Error awnswes !");
    }
  };
  const incorrect = async () => {
    try {
      let headers = new Headers();
      headers.append("Content-Type", "application/json");
      headers.append("Access-Control-Allow-Origin", "*");
      headers.append("Access-Control-Allow-Credentials", "true");
      const response = await fetch(
        `${url}/api/ShowQuestion/update/answer/${player}`,
        {
          mode: "cors",
          method: "Post",
          headers: headers,
          body: JSON.stringify({
            game_id: id,
          }),
        }
      );
      const json = await response.json();
      if (json.success) {
        setpasspoints(0);
        socket.emit("saveScore", seletedPlayers);
        console.log("attemp", json.attemp);
        if (json.attemp) {
          displayNextQuestion();
        }
      }
    } catch (error) {
      toast.error("Internal Server Error awnswes !");
    }
  };

  const stopAndResetAudio = () => {
    if (audioRef.current) {
      audioRef.current.pause();
      audioRef.current.currentTime = 0;
      audioRef.current.src = "";
      audioRef.current.load();
      audioRef.current = null;
    }
  };

  const handleGuessAnswer = (guess, time, player, i) => {
    if (isAnswered) return; // Prevent double clicks
    setIsAnswered(true);
    updateQuestion();
    socket.emit("selectedAnswer", { guess, playerId: mainPlayerGame, ans: i });
    setHandleQuestion(true);
    setQuestionInc(parseInt(questionInc) + 1);

    stopAndResetAudio();

    if (guess === currentQuestion.correctAnswer) {
      if (
        player.question == currentQuestionIndex &&
        player.attempted == "yes"
      ) {
        // toast.error(
        //   "Already attempted to this question.Please wait for next question"
        // );
      } else {
        savesetpoints(
          100 - 17 * (5 - Math.floor(parseInt(time) / 10)),
          "correct"
        );
      }
      backgroundAudioCorrect.play();
      setIsCorrect(true);
    } else {
      if (
        player.question == currentQuestionIndex &&
        player.attempted == "yes"
      ) {
        // toast.error(
        //   "Already attempted to this question.Please wait for next question"
        // );
      } else {
        savesetpoints(0, "incorrect");
      }
      backgroundAudioIncorrect.play();
      setIsFalse(true);
    }
    setTimeout(() => {
      socket.emit("handleNext", seletedPlayers);
      if (isAnswered) {
        handleNextQuestion();
      }
      setIsAnswered(false);
    }, 1500);
    // if (score >= 10) {
    //   // setPoints(points + 50);
    // }
  };

  useEffect(() => {
    socket.on("displaySelectedAnswer", (data) => {
      setSelectedAnswer(data.ans);
      if (currentQuestion && data.guess === currentQuestion.correctAnswer) {
        backgroundAudioCorrect.play();
        setIsCorrect(true);
      } else {
        backgroundAudioIncorrect.play();
        setIsFalse(true);
      }

      // Add any additional logic you want to handle when the selected answer is received
    });
  }, [currentQuestion]);

  const displayNextQuestion = async () => {
    // await updateQuestion();

    socket.emit("handleNext", seletedPlayers);
  };
  let i = 0;
  const handleNextQuestion = async () => {
    setHandleQuestion(false);

    await getQuestions();
  };
  const updateQuestion = async () => {
    try {
      let headers = new Headers();
      headers.append("Content-Type", "application/json");
      headers.append("Access-Control-Allow-Origin", "*");
      headers.append("Access-Control-Allow-Credentials", "true");
      const response = await fetch(
        `${url}/api/ShowQuestion/update/question/${id}`,
        {
          mode: "cors",
          method: "GET",
          headers: headers,
        }
      );
      const json = await response.json();
      if (json.success) {
        socket.emit("saveScore", seletedPlayers);
      }
    } catch (error) {
      toast.error("Internal Server Error ! update quest");
    }
  };
  const updateToStart = async (id) => {
    try {
      let headers = new Headers();
      headers.append("Content-Type", "application/json");
      headers.append("Access-Control-Allow-Origin", "*");
      headers.append("Access-Control-Allow-Credentials", "true");
      const response = await fetch(
        `${url}/api/ShowQuestion/update/to/start/${id}`,
        {
          mode: "cors",
          method: "GET",
          headers: headers,
        }
      );
      const json = await response.json();
      if (json.success) {
      }
    } catch (error) {
      toast.error("Internal Server Error ! update start");
    }
  };

  useEffect(() => {
    if (isRecord) {
      // Perform any actions that depend on isRecord being true
      console.log("isRecord is now true");
    }
  }, [isRecord]);

  const handleAgainPlay = async () => {
    setIsReady(false);
    await getQuestions();
    setIsMuted(true);
    setTimeRemaining(6000);
    setIsPlayerOnline(false);
    setIsGameStart(true);
    setPoints(0);
    setCurrentQuestionIndex(0);
    setIsGameEnd(false);

    if (
      remainingNumberOfQuestions > 1 &&
      backgroundAudio.paused &&
      backgroundAudio.currentTime === 0
    ) {
      backgroundAudio.play();
    }
  };

  const [isDropdownVisible, setDropdownVisible] = useState(false);
  const handleImageClick = () => {
    setDropdownVisible(!isDropdownVisible);
  };
  const handleUserLogout = async (e) => {
    const logouts = localStorage.removeItem("token");
    dispatch(logout());
    navigate("/");
  };

  const letsPlay = async () => {
    if (checked) {
      checkRecording("yes");
    }

    setIsGameStart(true);
    setpasspoints(100);
    socket.emit("gamestart", seletedPlayers);
    updateToStart(id);
    handleAgainPlay();
  };

  const checkRecording = async (value) => {
    try {
      let headers = new Headers();
      headers.append("Content-Type", "application/json");
      headers.append("Access-Control-Allow-Origin", "*");
      headers.append("Access-Control-Allow-Credentials", "true");

      const response = await fetch(
        `${url}/api/ShowQuestion/update/recording/${id}`,
        {
          mode: "cors",
          method: "GET",
          headers: headers,
        }
      );
      const json = await response.json();
    } catch (error) {
      toast.error("Internal Server Error ! current player");
    }
  };
  const getCurrentPlayer = async () => {
    try {
      let headers = new Headers();
      headers.append("Content-Type", "application/json");
      headers.append("Access-Control-Allow-Origin", "*");
      headers.append("Access-Control-Allow-Credentials", "true");

      const response = await fetch(
        `${url}/api/ShowQuestion/get/current/player/${player}`,
        {
          mode: "cors",
          method: "GET",
          headers: headers,
        }
      );
      setIsLoading(false);
      const json = await response.json();
      if (json.success) {
        setCurrentPlayer(json.currentPlayer);
        // if (json.currentPlayer.name === '') {
        //   setIsReadyModalOpen(true);
        // } else {
        //   setIsPlayerOnline(true)
        // }
      }
    } catch (error) {
      toast.error("Internal Server Error ! current player");
    }
  };

  const getPlayers = async () => {
    try {
      let headers = new Headers();
      headers.append("Content-Type", "application/json");
      headers.append("Access-Control-Allow-Origin", "*");
      headers.append("Access-Control-Allow-Credentials", "true");

      const response = await fetch(
        `${url}/api/ShowQuestion/get/player/${localStorage.getItem("gameID")}`,
        {
          mode: "cors",
          method: "GET",
          headers: headers,
        }
      );

      const json = await response.json();
      if (json.success) {
        setPlayersDetail(json.players);

        const mainPlayer1 = json.players.find(
          (player) => player.type === "main"
        );

        if (mainPlayer1) {
          // If main player exists, set it
          setMainPlayerGame(mainPlayer1._id);
        } else {
          console.log("Main player not found.");
          // Handle case where main player is not found
        }
        setSeletedPlayers(json.selectedPlayers);
      }
    } catch (error) {
      toast.error("Internal Server Error !");
    }
  };

  useEffect(() => {
    getPlayers();
  }, []);

  const openLockers = async () => {
    try {
      let headers = new Headers();
      headers.append("Content-Type", "application/json");
      headers.append("Access-Control-Allow-Origin", "*");
      headers.append("Access-Control-Allow-Credentials", "true");

      const response = await fetch(
        `${url}/api/ShowQuestion/winners/locker/${id}`,
        {
          mode: "cors",
          method: "GET",
          headers: headers,
        }
      );

      const json = await response.json();
      if (json.success) {
        setWinnerLocker(json.winner);
      }
    } catch (error) {
      toast.error("Internal Server Eror locker !");
    }
  };
  useEffect(() => {
    openLockers();
  }, []);

  const formSubmit = async (e) => {
    try {
      e.preventDefault();
      if (credentials.name === "") {
        toast.error("Name cannot be empty");
        return;
      }
      // if (credentials.email != "" || credentials.number != "") {
      let headers = new Headers();
      headers.append("Content-Type", "application/json");
      headers.append("Access-Control-Allow-Origin", "*");
      headers.append("Access-Control-Allow-Credentials", "true");
      setIsLoading(true);
      const response = await fetch(
        `${url}/api/ShowQuestion/update/player/name/${player}/${id}`,
        {
          mode: "cors",
          method: "POST",
          headers: headers,
          body: JSON.stringify({
            name: credentials.name,
            recording: credentials.recording,
          }),
        }
      );
      setIsLoading(false);
      const json = await response.json();
      if (json.success) {
        toast.success("Name Updated Successfully");
        setCredentials({
          name: "",
        });
        setIsReadyModalOpen(false);
        setIsPlayerOnline(true);
        // setIsGameStart(false);
        setIsReady(true);
        socket.emit("joinUser", seletedPlayers);
        socket.emit("saveScore", seletedPlayers);
      } else {
        setIsLoading(false);
        toast.error(json.error);
      }
    } catch (error) {
      toast.error("Internal Server Error form submit");
    }
  };
  const handleOpenUpdatedLocker = async () => {
    try {
      let headers = new Headers();
      headers.append("Content-Type", "application/json");
      headers.append("Access-Control-Allow-Origin", "*");
      headers.append("Access-Control-Allow-Credentials", "true");
      headers.append("auth-token", localStorage.getItem("token"));
      setIsLoading(true);
      const response = await fetch(`${url}/api/prizes/winer/locker/add`, {
        mode: "cors",
        method: "POST",
        headers: headers,
        body: JSON.stringify({
          game: id,
        }),
      });
    } catch (error) {
      toast.error("Internal Server Eror !");
    }
  };
  const UpdatePlayerStatus = async (seletedPlayers, currentPlayer) => {
    try {
      let headers = new Headers();
      headers.append("Content-Type", "application/json");
      headers.append("Access-Control-Allow-Origin", "*");
      headers.append("Access-Control-Allow-Credentials", "true");
      socket.emit("mianleft", seletedPlayers);
      const response = await fetch(
        `${url}/api/ShowQuestion/update/player/status/${player}`,
        {
          mode: "cors",
          method: "GET",
          headers: headers,
        }
      );
      setIsLoading(false);

      const json = await response.json();
    } catch (error) {
      toast.error("Internal Server Error ! current player");
    }
  };
  const backtohome = () => {
    localStorage.removeItem(player);
    navigate("/");
  };
  return (
    <div className="question-container">
      {loader ? (
        <Loader />
      ) : (
        <>
          <header className="header postion-relative">
            <div className="container">
              <div className="row">
                <div className="d-flex justify-content-between align-items-center">
                  <div className="header__section">
                    <div className="d-flex justify-content-start align-items-center">
                      <Link to={"/"} className="">
                        <img
                          src={logo}
                          alt="logo"
                          className="object-fit-contain w-75 mx-auto"
                        />
                      </Link>
                    </div>
                  </div>

                  {/* <div className="header__section ">
                    <div className="d-flex  justify-content-center justify-content-sm-center">
                      <a
                        className=" d-flex flex-column bg-white text-black p-2 text-decoration-none text-center rounded-3"
                        style={{ width: "auto", height: "auto" }}
                        onClick={handleToggleMute}
                      >
                        {isMuted ? (
                          <img src={Mute} alt="muted" />
                        ) : (
                          <img src={UnMute} alt="unmuted" />
                        )}
                      </a>
                    </div>
                  </div> */}

                  <div className="header__section">
                    <div className="position-relative d-flex justify-content-end flex-row ">
                      <img
                        src={isAuthenticated?.image || Profile}
                        alt="logo"
                        className="mt-2"
                        height={60}
                        style={{
                          cursor: "pointer",
                          width: "50px",
                          height: "50px",
                          borderRadius: "50px",
                          objectFit: "cover",
                        }}
                        onClick={handleImageClick}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </header>
          {isDropdownVisible && (
            <div
              style={{
                position: "absolute",
                top: "60px",
                right: "7rem",
                border: "1px solid #ccc",
                background: "#fff",
                zIndex: "99999999999",
              }}
              className="popup__menu rounded p-1"
            >
              <ul className="p-1 header__dropdownMenu">
                <li
                  onClick={() => navigate("/user/dashboard")}
                  className="header__dropdownMenuItem px-4 d-flex align-items-center gap-2"
                >
                  <img src={Dashboard} alt="icon" />
                  <span>Dashboard</span>
                </li>
                <li
                  onClick={handleUserLogout}
                  className="header__dropdownMenuItem px-4 d-flex align-items-center gap-2"
                >
                  <img src={IconLogout} alt="icon" />
                  <span>Logout</span>
                </li>
              </ul>
            </div>
          )}

          {/* {isRefresh && ( */}

          <div
            className="container"
            style={{ marginTop: "2vh", overflowY: "scroll" }}
          >
            {/* {isGameStart && currentQuestion && (
              <>
                <Answer
                  isCorrect={isCorrect}
                  isFalse={isFalse}
                  question={currentQuestion}
                  index={currentQuestionIndex}
                  currentQuestionIndex={currentQuestionIndex}
                  onGuessAnswer={handleGuessAnswer}
                  gif={gif}
                  remainingTime={timeRemaining}
                  gametype={gametype}
                  passpoints={passpoints}
                  opacitylow={opacitylow}
                  gameEnd={gameEnd}
                  selectedAnswers={selectedAnswers}
                />
              </>
            )} */}

            {isGameStart || gameEnd ? (
              <>
                {console.log("Passing isRecord to Answer component:", isRecord)}
                <Answer
                  isCorrect={isCorrect}
                  isFalse={isFalse}
                  question={currentQuestion}
                  index={currentQuestionIndex}
                  currentQuestionIndex={currentQuestionIndex}
                  onGuessAnswer={handleGuessAnswer}
                  gif={gif}
                  remainingTime={timeRemaining}
                  gametype={gametype}
                  gameEnd={gameEnd}
                  ready={ready}
                  selectedAnswers={selectedAnswers}
                  isAnswered={isAnswered}
                  iscameraon={iscameraon}
                  isquestionshow={isquestionshow}
                  showSearching={true}
                  isRecord={isRecord}
                  isStop={isStop}
                  recordingStart={isrecordingStart}
                  recordingStop={isRecordingStop}
                />
              </>
            ) : (
              <></>
              // Render something else if the game is not ongoing or has not ended
            )}

            {/* Content to render if the game is not ongoing or has not ended */}
          </div>
          {/* )} */}
        </>
      )}

      {/* <Modal
        isOpen={isReadyModalOpen}
        contentLabel="Example Modal"
        className="d-flex flex-column justify-content-center p-3 col-lg-4 m-auto mt-5 h-100 align-items-center "
        shouldCloseOnOverlayClick={false}
        style={{
          content: {
            fontFamily: "Arial",
            fontWeight: "800",
            fontSize: "30px",
          },
          overlay: {
            backgroundColor: "transparent", // Set the overlay background color
          },
        }}
      >
        <div
          className="p-4 w-sm-50 h-sm-50 col-10 rounded-3"
          style={{ background: "#fff" }}
        >
          <div className="d-flex flex-row">
            <h4 className="win-heading-2 text-dark text-center">
              Enter Your Name
            </h4>
          </div>
          <form onSubmit={formSubmit}>
            <div
              className="p-1 rounded-2 w-sm-25 " //h-75
              style={{ background: "#fff" }}
            >
              <div>
                <label
                  htmlFor="text"
                  className="form-label"
                  style={{ fontSize: "14px", fontWeight: "500" }}
                >
                  Name
                </label>
                <input
                  type="text"
                  className="form-control"
                  id="name"
                  name="name"
                  value={credentials?.name}
                  onChange={handleInput}
                />
              </div>
              <div className="d-flex justify-content-between align-items-center flex-row flex-wrap gap-2 mt-3">
                <Link
                  onClick={() => setIsReadyModalOpen(false)}
                  className="bg-white border border-2 border-blue rounded-pill px-4 py-2 shadow "
                  style={{
                    fontWeight: "700",
                    fontFamily: "Arial",
                    fontSize: "12px",
                    textDecoration: "none",
                    color: "#012267",
                  }}
                >
                  Cancel
                </Link>
                <button
                  type="submit"
                  className="bg-blue text-white  border border-2 border-blue rounded-pill px-4 py-2 shadow"
                  style={{
                    fontWeight: "700",
                    fontFamily: "Arial",
                    fontSize: "12px",
                    textDecoration: "none",
                  }}
                >
                  Send
                </button>
              </div>
            </div>
          </form>
        </div>
      </Modal> */}

      {isReadyModalOpen && (
        <div
          className="d-flex flex-column justify-content-center waiting_modal p-3 col-lg-4 m-auto align-items-center m-auto"
          style={{
            fontFamily: "Arial",
            fontWeight: "800",
            fontSize: "30px",
            position: "absolute",
            top: "30%",
            left: "50%",
          }}
        >
          <div
            className="p-4 w-sm-50 h-sm-50 col-10 rounded-3"
            style={{ background: "#fff" }}
          >
            <div className="d-flex flex-row">
              <h4 className="win-heading-2 text-dark text-center">
                Enter Your Name
              </h4>
            </div>
            <form onSubmit={formSubmit}>
              <div
                className="p-1 rounded-2 w-sm-25 " //h-75
                style={{ background: "#fff" }}
              >
                <div>
                  <label
                    htmlFor="text"
                    className="form-label"
                    style={{ fontSize: "14px", fontWeight: "500" }}
                  >
                    Name
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    id="name"
                    name="name"
                    value={credentials?.name}
                    onChange={handleInput}
                  />
                  <div className="mt-3 text-white d-flex align-items-center">
                    <input
                      type="checkbox"
                      id="consentCheckbox"
                      name="recording"
                      value="yes"
                      checked={checked}
                      onChange={handleCheckboxChange}
                      style={{
                        marginRight: "10px",
                        alignSelf: "flex-start",
                        marginTop: "4px",
                      }}
                    />
                    <label htmlFor="consentCheckbox">
                      <h6 style={{ fontSize: "9px", color: "gray" }}>
                        By joining, you consent to the game being recorded,
                        including access to your camera and audio, and shared on
                        social media.
                      </h6>
                    </label>
                  </div>
                </div>
                <div className="d-flex justify-content-between align-items-center flex-row flex-wrap gap-2 mt-3">
                  <Link
                    onClick={() => setIsReadyModalOpen(false)}
                    className="bg-white border border-2 border-blue rounded-pill px-4 py-2 shadow "
                    style={{
                      fontWeight: "700",
                      fontFamily: "Arial",
                      fontSize: "12px",
                      textDecoration: "none",
                      color: "#012267",
                    }}
                  >
                    Cancel
                  </Link>
                  <button
                    type="submit"
                    className="bg-blue text-white  border border-2 border-blue rounded-pill px-4 py-2 shadow"
                    style={{
                      fontWeight: "700",
                      fontFamily: "Arial",
                      fontSize: "12px",
                      textDecoration: "none",
                    }}
                    disabled={isLoading}
                  >
                    {isLoading ? "Joining..." : "Join"}
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      )}
      <Modal
        isOpen={recordingShow}
        contentLabel="Internet Disconnected"
        className="d-flex flex-column justify-content-center p-3 col-lg-6 m-auto mt-5 h-100 align-items-center "
        shouldCloseOnOverlayClick={false}
        style={{
          content: {
            fontFamily: "Arial",
            fontWeight: "800",
            fontSize: "30px",
          },
          overlay: {
            backgroundColor: "rgba(0, 0, 0, 0.5)", // Set the overlay background color
          },
        }}
      >
        {/* {recordingShow && ( */}
        <div>
          <div className="winnerModal d-flex justify-content-center align-items-center">
            <div
              className="p-3 w-sm-50 h-sm-50 col-12 rounded-3"
              style={{ background: "#456e01" }}
            >
              <div
                className="p-2 rounded-2 w-sm-25 d-flex justify-content-center align-items-center flex-column gap-3" //h-75
                style={{ background: "#7bb717" }}
              >
                {videoSrc ? (
                  <video
                    className="video-area"
                    style={{
                      width: "100%",
                      height: "100%",
                      textAlign: "center",
                    }}
                    controls
                    src={videoSrc}
                  />
                ) : (
                  <div className="screen-loader video-area ">
                    {parseInt(isrecordingStart) > 2 ? (
                      <>
                        <h6 className="mt-2">
                          Recording has been stopped by the host.
                        </h6>
                      </>
                    ) : (
                      <>
                        {recordingLoading ? (
                          <h6 className="mt-5">
                            Something went wrong when loading recording !
                          </h6>
                        ) : (
                          <>
                            <div
                              className="lds-spinner "
                              style={{ display: "flex", left: "35%" }}
                            >
                              <div></div>
                              <div></div>
                              <div></div>
                              <div></div>
                              <div></div>
                              <div></div>
                              <div></div>
                              <div></div>
                              <div></div>
                              <div></div>
                              <div></div>
                              <div></div>
                            </div>
                            <div></div>
                            <h6 className="mt-5">
                              Please Wait For Screen Recording...
                            </h6>
                          </>
                        )}
                      </>
                    )}
                  </div>
                )}

                <div className="d-flex justify-content-center align-items-center flex-row gap-3">
                  {videoSrc && (
                    <a
                      className="btn btn-modal w-auto"
                      href={videoSrc}
                      download={videoSrc}
                      rel="noreferrer"
                      target="_blank"
                    >
                      <img src={DownloadRec} alt="download recording" />
                    </a>
                  )}
                  {(parseInt(isrecordingStart) > 2 || videoSrc || recordingLoading) && (
                    <a className="btn btn-modal w-auto" onClick={backtohome}>
                      <img src={FinishGame} alt="finish game" />
                    </a>
                  )}
                </div>

                {videoSrc && (
                  <ShareButtons
                    url={videoSrc}
                    title="Check out game recording! "
                  />
                )}
              </div>
            </div>
          </div>
        </div>
        {/* )} */}
      </Modal>
      {/* <Modal
        isOpen={playerOnline}
        contentLabel="Example Modal"
        className="d-flex flex-column justify-content-center p-3 col-lg-4 m-auto mt-5 h-100 align-items-center "
        shouldCloseOnOverlayClick={false}
        style={{
          content: {
            fontFamily: "Arial",
            fontWeight: "800",
            fontSize: "30px",
          },
          overlay: {
            backgroundColor: "rgba(0, 0, 0, 0.5)", // Set the overlay background color
          },
        }}
      >
        <div
          className="p-3 w-sm-50 h-sm-50 col-10 rounded-3"
          style={{ background: "#012269" }}
        >
          <div className="d-flex flex-row">
            <h4 className="win-heading ">Waiting...</h4>
          </div>
          <div
            className="p-4 rounded-2 w-sm-25 " //h-75
            style={{ background: "#031947" }}
          >
            <h5
              className="text-center text-white mt-2 "
              style={{
                fontFamily: "Arial",
                fontSize: "25px",
                fontWeight: "700",
              }}
            >
              {currentPlayer.type === "local"
                ? "Waiting for host to start the game"
                : "Waiting for players to join"}
            </h5>

            <div className="d-flex flex-row flex-wrap  gap-3 justify-content-center align-items-center mt-4">
              {playersDetail?.map(
                (player, index) =>
                  player?.status === "join" &&
                  player?._id != currentPlayer._id && (
                    <div
                      className="d-flex justify-content-between align-items-center flex-row gap-2"
                      style={{
                        border: "2px solid white",
                        borderRadius: "10px",
                        padding: "0 10px",
                        width: "auto",
                        maxWidth: "400px",
                      }}
                    >
                      <img src={User} />
                      <h5
                        className="text-center text-white mt-2 text-capitalize"
                        key={index}
                      >
                        {player.name ? player.name : "Host"}
                      </h5>
                    </div>
                  )
              )}
            </div>

            {currentPlayer.type === "main" &&
              playersDetail &&
              playersDetail.filter((player) => player.status === "join")
                .length > 1 && (
                <div className="d-flex flex-row gap-5 justify-content-center align-items-center mt-4">
                  <img
                    src={Play}
                    alt="play"
                    onClick={letsPlay}
                    height={50}
                    width={50}
                  />
                </div>
              )}
          </div>
        </div>
      </Modal> */}

      <div
        className={`d-flex flex-column justify-content-center waiting_modal p-3 col-lg-4 m-auto mt-5 align-items-center ${playerOnline ? "d-block" : "d-none"
          }`}
        style={{
          fontFamily: "Arial",
          fontWeight: "800",
          fontSize: "30px",
          position: "absolute",
          top:
            currentPlayer.type === "main" && currentPlayer.status === "join"
              ? "10%"
              : "22%",
          left: "51%",
        }}
      >
        <div
          className="p-3 w-sm-50 h-sm-50 col-lg-12 col-sm-6  rounded-3"
          style={{ background: "#012269" }}
        >
          <div className="d-flex flex-row">
            {currentPlayer.type === "main" &&
              playersDetail &&
              playersDetail.filter((player) => player.status === "join").length >
              1 ? (
              <h4 className="win-heading">Joined</h4>
            ) : (
              <h4 className="win-heading">Waiting...</h4>
            )}
          </div>
          <div
            className="p-2 rounded-2 w-sm-25"
            style={{ background: "#031947" }}
          >
            <div
              className="p-3 rounded-2 w-sm-25"
              style={{ background: "#031947" }}
            >
              <h5
                className="text-center text-white mt-2"
                style={{
                  fontFamily: "Arial",
                  fontSize: "20px",
                  fontWeight: "700",
                }}
              >
                {/* {currentPlayer.type === "local"
                  ? "Waiting for host to start the game"
                  : "Waiting for players to join"} */}

                {currentPlayer.type === "main" &&
                  playersDetail &&
                  playersDetail.filter((player) => player.status === "join")
                    .length > 1
                  ? "Player Has Joined The Game.  Click The Play Icon To Start The Game"
                  : currentPlayer.type === "local"
                    ? "Waiting For Host To Start The Game"
                    : "Waiting For Player To Join"}
              </h5>

              <div className="d-flex flex-row flex-wrap gap-3 justify-content-center align-items-center mt-4">
                {playersDetail?.map(
                  (player, index) =>
                    player?.status === "join" &&
                    player?._id !== currentPlayer._id && (
                      <div
                        className="d-flex justify-content-between align-items-center flex-row gap-2"
                        style={{
                          border: "2px solid white",
                          borderRadius: "10px",
                          padding: "0 10px",
                          width: "auto",
                          maxWidth: "400px",
                        }}
                        key={index}
                      >
                        <img src={User} alt="user" />
                        <h5 className="text-center text-white mt-2 text-capitalize">
                          {player.name ? player.name : "Host"}
                        </h5>
                      </div>
                    )
                )}
              </div>

              {currentPlayer.type === "main" &&
                playersDetail &&
                playersDetail.filter((player) => player.status === "join")
                  .length > 1 && (
                  <>
                    <div className="mt-3 text-white d-flex align-items-center">
                      <input
                        type="checkbox"
                        id="consentCheckbox"
                        name="mainplayer"
                        value="yes"
                        checked={checked}
                        onChange={handleCheckboxChange1}
                        style={{
                          marginRight: "10px",
                          alignSelf: "flex-start",
                          marginTop: "3px",
                        }}
                      />
                      <label htmlFor="consentCheckbox">
                        <h6 style={{ fontSize: "9px", color: "gray" }}>
                          By joining, you consent to the game being recorded,
                          including access to your camera and audio, and to
                          being shared on social media.
                        </h6>
                      </label>
                    </div>
                    <div className="d-flex flex-row gap-5 justify-content-center align-items-center mt-4">
                      <img
                        src={Play}
                        alt="play"
                        onClick={letsPlay}
                        height={50}
                        width={50}
                      />
                    </div>
                  </>
                )}
            </div>
          </div>
        </div>
      </div>

      {/* instruction modal   */}

      <Modal
        isOpen={isinstructionModalOpen}
        contentLabel="Example Modal"
        className="d-flex flex-column justify-content-center p-3 col-lg-4 m-auto mt-5 h-100 align-items-center "
        shouldCloseOnOverlayClick={false}
        style={{
          content: {
            fontFamily: "Arial",
            fontWeight: "800",
            fontSize: "30px",
          },
          overlay: {
            backgroundColor: "rgba(0, 0, 0, 0.5)", // Set the overlay background color
          },
        }}
      >
        <div
          className="p-3 w-sm-50 h-sm-50 col-10 rounded-3"
          style={{ background: "#012269" }}
        >
          <div className="d-flex flex-row">
            <h4 className="win-heading ">How to Play</h4>
            <Link
              className="mt-1"
              onClick={() => setisinstructionModalOpen(false)}
              style={{
                fontSize: "20px",
                textDecoration: "none",
              }}
            >
              X
            </Link>
          </div>
          <div
            className="p-4 rounded-2 w-sm-25 " //h-75
            style={{ background: "#031947" }}
          >
            <div
              id="carouselExampleIndicators"
              className="carousel slide"
              data-bs-ride="carousel"
            >
              <div className="carousel-indicators mb-0">
                <button
                  type="button"
                  data-bs-target="#carouselExampleIndicators"
                  data-bs-slide-to="0"
                  className="active"
                  aria-current="true"
                  aria-label="Slide 1"
                ></button>
                <button
                  type="button"
                  data-bs-target="#carouselExampleIndicators"
                  data-bs-slide-to="1"
                  aria-label="Slide 2"
                ></button>
                <button
                  type="button"
                  data-bs-target="#carouselExampleIndicators"
                  data-bs-slide-to="2"
                  aria-label="Slide 3"
                ></button>
              </div>
              <div className="carousel-inner">
                <div className="carousel-item active">
                  <p style={{ fontSize: "20px" }}>
                    Enter your name to start the game
                  </p>
                  <img
                    src={ins1}
                    alt="logo"
                    className="object-fit-contain w-100 mx-auto"
                  />
                </div>
                <div className="carousel-item">
                  <p style={{ fontSize: "20px" }}>
                    Answer the question with right answer to get maximum points
                  </p>
                  <img
                    src={ins2}
                    alt="logo"
                    className="object-fit-contain w-100 mx-auto"
                  />
                </div>
                <div className="carousel-item">
                  <p style={{ fontSize: "20px" }}>
                    Answer the question with right answer to get maximum points
                  </p>
                  <img
                    src={ins3}
                    alt="logo"
                    className="object-fit-contain w-100 mx-auto"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </Modal>
      <Modal
        isOpen={mainPlayrLeft}
        contentLabel="Example Modal"
        className="d-flex flex-column justify-content-center p-3 col-lg-4 m-auto mt-5 h-100 align-items-center "
        shouldCloseOnOverlayClick={false}
        style={{
          content: {
            fontFamily: "Arial",
            fontWeight: "800",
            fontSize: "30px",
          },
          overlay: {
            backgroundColor: "rgba(0, 0, 0, 0.5)", // Set the overlay background color
          },
        }}
      >
        <div
          className="p-3 w-sm-50 h-sm-50 col-10 rounded-3"
          style={{ background: "#012269" }}
        >
          <div className="d-flex flex-row">
            <h4 className="win-heading ">Game Ended</h4>
          </div>
          <div
            className="p-4 rounded-2 w-sm-25 " //h-75
            style={{ background: "#031947" }}
          >
            <h5
              className="text-center text-white mt-2 "
              style={{
                fontFamily: "Arial",
                fontSize: "25px",
                fontWeight: "700",
              }}
            >
              Main Player Left The Game
            </h5>

            <div className="d-flex flex-row gap-5 justify-content-center align-items-center mt-4">
              <Link to={"/"}>
                <img src={Play} alt="play" height={50} width={50} />
              </Link>
            </div>
          </div>
        </div>
      </Modal>

      <Modal
        isOpen={isFailModalOpen}
        contentLabel="Example Modal"
        className="d-flex flex-column justify-content-center p-3 col-lg-6 m-auto mt-5 h-100 align-items-center "
        shouldCloseOnOverlayClick={false}
        style={{
          content: {
            fontFamily: "Arial",
            fontWeight: "800",
            fontSize: "30px",
          },
          overlay: {
            backgroundColor: "rgba(0, 0, 0, 0.5)", // Set the overlay background color
          },
        }}
      >
        <div
          className="p-3 w-sm-50 h-sm-50 col-10 rounded-3"
          style={{ background: "#012269" }}
        >
          <div className="d-flex flex-row">
            <h4 className="win-heading ">You Lose</h4>
          </div>
          <div
            className="p-4 rounded-2 w-sm-25 " //h-75
            style={{ background: "#031947" }}
          >
            <h5
              className="text-center text-white mt-2 "
              style={{
                fontFamily: "Arial",
                fontSize: "25px",
                fontWeight: "700",
              }}
            >
              You Need To Answer Minimum 3 Questions To Choose The Locker
            </h5>
            {parseInt(isrecordingStart) > 1 ? (
              <div className="screen-loader video-area ">
                <div
                  className="lds-spinner"
                  style={{ display: "flex", left: "40%" }}
                >
                  <div></div>
                  <div></div>
                  <div></div>
                  <div></div>
                  <div></div>
                  <div></div>
                  <div></div>
                  <div></div>
                  <div></div>
                  <div></div>
                  <div></div>
                  <div></div>
                </div>
                <div></div>
                <h6 className="mt-5 text-white text-center">
                  Please Wait For Screen Recording...
                </h6>
              </div>
            ) : (
              <div className="d-flex flex-row gap-5 justify-content-center align-items-center mt-4">
                <button>
                  <img
                    onClick={backtohome}
                    src={Play}
                    alt="play"
                    height={50}
                    width={50}
                  />
                </button>
              </div>
            )}
          </div>
        </div>
      </Modal>

      <Modal
        isOpen={!isOnline}
        contentLabel="Internet Disconnected"
        className="d-flex flex-column justify-content-center p-3 col-lg-4 m-auto mt-5 h-100 align-items-center "
        shouldCloseOnOverlayClick={false}
        style={{
          content: {
            fontFamily: "Arial",
            fontWeight: "800",
            fontSize: "30px",
          },
          overlay: {
            backgroundColor: "rgba(0, 0, 0, 0.5)", // Set the overlay background color
          },
        }}
      >
        <div
          className="p-3 w-sm-50 h-sm-50 col-10 rounded-3"
          style={{ background: "#012269" }}
        >
          <div className="d-flex flex-row">
            <h4 className="win-heading">Internet Disconnected</h4>
          </div>
          <div
            className="p-4 rounded-2 w-sm-25"
            style={{ background: "#031947" }}
          >
            <h5
              className="text-center text-white mt-2"
              style={{
                fontFamily: "Arial",
                fontSize: "25px",
                fontWeight: "700",
              }}
            >
              Internet Disconnected.
            </h5>
          </div>
        </div>
      </Modal>
    </div>
  );
};

export default MultiQuestionPage;

import "./Modal.css"; // You can style your modal using CSS

const Modal = ({ isOpen, onClose, children }) => {
  if (!isOpen) return null;

  return (
    <div className="modal">
      <div className="modal-dialog">
        <div className="modal-content" style={{ maxWidth: "100%" }}>
          {children}
        </div>
      </div>
    </div>
  );
};

export default Modal;

// AnswerComponent.js
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";
// import { Question } from "@trivia-api/models";
import "./style.css";
import "../../puzzlestyle.css";
import { Link } from "react-router-dom";
import Player1 from "../../assets/icons/player-1.svg";
import Player3 from "../../assets/icons/player-3.svg";
import { useSelector } from "react-redux";
import io from "socket.io-client";
import TickGreen from "../../assets/icons/tick-gren.png";
import TickRed from "../../assets/icons/tick-red.png";

const url = process.env.REACT_APP_APP_BACK_URL;

const socket = io.connect(url);

export const Answer = ({
  question,
  index,
  onGuessAnswer,
  isCorrect,
  isFalse,
  currentQuestionIndex,
  gif,
  remainingTime,
  gametype,
  passpoints,
  opacitylow,
}) => {
  const [selectedAnswer, setSelectedAnswer] = useState(null);
  const [isMuted, setIsMuted] = useState(false);
  const [timeRemaining, setTimeRemaining] = useState(remainingTime);
  const [playersDetail, setPlayersDetail] = useState();
  const [playerData, setPlayerData] = useState();
  const { id } = useParams();
  const queryParams = new URLSearchParams(window.location.search);
  const playerId = queryParams.get("p");
  const isAuthenticated = useSelector((state) => state.user);
  const handleToggleMute = async () => {
    {
      setIsMuted(!isMuted);
    }
  };
  // const getQuestions = async () => {
  //   try {
  //     let headers = new Headers();
  //     headers.append("Content-Type", "application/json");
  //     headers.append("Access-Control-Allow-Origin", "*");
  //     headers.append("Access-Control-Allow-Credentials", "true");

  //     const response = await fetch(
  //       `${url}/api/multiplayer/get/question/${id}`,
  //       {
  //         mode: "cors",
  //         method: "GET",
  //         headers: headers,
  //       }
  //     );
  //     const json = await response.json();
  //     if (json.success) {
  //       setPlayerData(json.game)

  //     }
  //   } catch (error) {
  //     toast.error("Internal Server Error !");
  //   }
  // };
  // useEffect(() => {
  //   getQuestions();
  //   // setIsReadyModalOpen(true);
  // }, []);
  useEffect(() => {
    // Reset timeRemaining to 10 seconds when the question changes
    getPlayers("usee effect");
    socket.on("updateScore", () => {
      getPlayers("sockets");
    });
  }, []);

  useEffect(() => {
    // Reset timeRemaining to 60 seconds when the question changes

    setTimeRemaining(60);

    // Set up the timer when the question changes
    const timer = setInterval(() => {
      setTimeRemaining((prevTime) => {
        if (prevTime > 0) {
          // Decrease time remaining by 1 second
          return prevTime - 1;
        } else {
          // If time is up, move to the next question
          clearInterval(timer); // Stop the timer
          return 0;
        }
      });
    }, 1000);
    // Clear the timer when the component unmounts or when moving to the next question
    return () => clearInterval(timer);
  }, [currentQuestionIndex]);

  const getPlayers = async (ans) => {
    try {
      let headers = new Headers();
      headers.append("Content-Type", "application/json");
      headers.append("Access-Control-Allow-Origin", "*");
      headers.append("Access-Control-Allow-Credentials", "true");

      const response = await fetch(
        `${url}/api/multiplayer/get/players/details/${id}?p=${playerId}`,
        {
          mode: "cors",
          method: "GET",
          headers: headers,
        }
      );

      const json = await response.json();
      if (json.success) {
        setPlayersDetail(json.players);
        setPlayerData(json.player);
      }
    } catch (error) {
      toast.error("Internal Server Error !");
    }
  };


  const allAnswers = [
    question.correctAnswer,
    ...question.incorrectAnswers,
  ].sort((a, b) => (a < b ? -1 : 1));

  return (
    <>
      <div className="row justify-content-center">
        <div className=" col-md-3 mt-5 " style={{ maxWidth: "160px" }}>
          <div className="bg-white rounded p-2">
            <span className="d-flex flex-row justify-content-center fw-bold ">
              Progress
            </span>
            {playersDetail?.map((player, index) =>
              player._id === playerId ? (
                <>
                  <div className="position-relative mt-1">
                    <div
                      className="bg-info rounded "
                      style={{ border: "1px solid red", opacity: player.status === 'left' ? 0.5 : 1 }}
                    >
                      <div className="d-flex justify-content-between  align-items-center flex-row gap-1 p-1">
                        <img src={Player3} alt="" height={25} width={25} />
                        <div className="d-flex justify-content-center align-items-start flex-column ">
                          <span
                            className="text-capitalize"
                            style={{ fontSize: "9px", fontWeight: "700" }}
                          >
                            {player.name
                              ? player.name.split(" ")[0]
                              : "Player1"}{" "}
                          </span>
                          <span
                            className="text-capitalize"
                            style={{
                              fontSize: "7px",
                              fontWeight: "700",
                              color: player.status === "join" ? "green" : "red",
                            }}
                          >
                            {player.status === 'left' ? "Left" : "Active"}
                          </span>
                        </div>
                        <div className="coins____body d-flex justify-content-center align-items-center">
                          <span className="coins-number">
                            {player.correct ? player.correct : "0"}
                          </span>
                        </div>
                      </div>
                    </div>
                    {/* <div className="player-tick">
                    <div className="">
                      {player.answer === "incorrect" && (
                        <img src={TickRed} width={18} />
                      )}
                      {player.answer === "correct" && (
                        <img src={TickGreen} width={18} />
                      )}
                    </div>
                  </div> */}
                  </div>
                </>
              ) : (
                <>
                  <div className="position-relative mt-1">
                    <div
                      className="bg-white rounded "
                      style={{ border: "1px solid red", opacity: player.status === 'left' ? 0.5 : 1 }}
                    >
                      <div className="d-flex justify-content-between align-items-center flex-row gap-1 p-1">
                        <img src={Player1} alt="" height={25} width={25} />
                        <div className="d-flex justify-content-center align-items-center flex-column ">
                          <span
                            className="text-capitalize"
                            style={{ fontSize: "9px", fontWeight: "700" }}
                          >
                            {player.name
                              ? player.name.split(" ")[0]
                              : "Player1"}{" "}
                          </span>
                          <span
                            className="text-capitalize"
                            style={{
                              fontSize: "7px",
                              fontWeight: "700",
                              color: player.status === "join" ? "green" : "red",
                            }}
                          >
                            {player.status === 'left' ? "Left" : "Active"}
                          </span>
                        </div>
                        <div className="coins____body d-flex justify-content-center align-items-center">
                          <span className="coins-number">
                            {player.correct ? player.correct : "0"}
                          </span>
                        </div>
                      </div>
                    </div>
                    {/* <div className="player-tick">
                    <div className="">
                      {player.answer === "incorrect" && (
                        <img src={TickRed} width={18} />
                      )}
                      {player.answer === "correct" && (
                        <img src={TickGreen} width={18} />
                      )}
                    </div>
                  </div> */}
                  </div>
                </>
              )
            )}
          </div>
        </div>
        <div className="col-md-9">
          <div className="d-flex flex-column justify-content-center overflow-hidden ">
            <div className="d-flex justify-content-center align-items-center flex-row flex-wrap gap-3">
              {playersDetail?.map((player, index) =>
                player._id === playerId ? (
                  <>
                    <div className="position-relative mt-1">
                      <div
                        className="bg-info rounded "
                        style={{ border: "1px solid red", opacity: player.status === 'left' ? 0.5 : 1 }}
                      >
                        <div className="d-flex justify-content-center align-items-center flex-row gap-1 p-1">
                          <img src={Player3} alt="" height={25} width={25} />
                          <div className="d-flex justify-content-center align-items-start flex-column ">
                            <span
                              className="text-capitalize"
                              style={{ fontSize: "9px", fontWeight: "700" }}
                            >
                              {player.name ? player.name : "Player1"}{" "}
                            </span>
                            <span
                              className="text-capitalize"
                              style={{
                                fontSize: "7px",
                                fontWeight: "700",
                                color:
                                  player.status === "join" ? "green" : "red",
                              }}
                            >
                              {player.status === 'left' ? "Left" : "Active"}
                            </span>
                          </div>
                          <div className="coins____body d-flex justify-content-center align-items-center">
                            <span className="coins-number">
                              {player.score ? player.score : "0"}
                            </span>
                          </div>
                        </div>
                      </div>
                      <div className="player-tick">
                        <div className="">
                          {player.answer === "incorrect" && (
                            <img src={TickRed} width={18} />
                          )}
                          {player.answer === "correct" && (
                            <img src={TickGreen} width={18} />
                          )}
                        </div>
                      </div>
                    </div>
                  </>
                ) : (
                  <>
                    <div className="position-relative mt-1">
                      <div
                        className="bg-white rounded "
                        style={{ border: "1px solid red", opacity: player.status === 'left' ? 0.5 : 1 }}
                      >
                        <div className="d-flex justify-content-center align-items-center flex-row gap-1 p-1">
                          <img src={Player1} alt="" height={25} width={25} />
                          <div className="d-flex justify-content-center align-items-start flex-column ">
                            <span
                              className="text-capitalize"
                              style={{ fontSize: "9px", fontWeight: "700" }}
                            >
                              {player.name ? player.name : "Player1"}{" "}
                            </span>
                            <span
                              className="text-capitalize"
                              style={{
                                fontSize: "7px",
                                fontWeight: "700",
                                color: player.status === "join" ? "green" : "red",
                              }}
                            >
                              {player.status === 'left' ? "Left" : "Active"}
                            </span>
                          </div>
                          <div className="coins____body d-flex justify-content-center align-items-center">
                            <span className="coins-number">
                              {player.score ? player.score : "0"}
                            </span>
                          </div>
                        </div>
                      </div>
                      <div className="player-tick">
                        <div className="">
                          {player.answer === "incorrect" && (
                            <img src={TickRed} width={18} />
                          )}
                          {player.answer === "correct" && (
                            <img src={TickGreen} width={18} />
                          )}
                        </div>
                      </div>
                    </div>
                  </>
                )
              )}
            </div>
            <div className=" d-flex flex-column flex-sm-row gap-3 justify-content-end mt-2 ">
              <div
                className="bg-white text-black p-4 mx-auto container text-center"
                style={{
                  maxWidth: "800px",
                  clipPath: "polygon(1% 0%, 99% 0%, 100% 100%, 0% 100%)",
                  borderRadius: "10px",
                  height: "min-content",
                }}
              >
                <h4 className="question-text">{question.question}</h4>
              </div>
            </div>

            <div className="d-flex gap-3 justify-content-center align-item-center mt-4">
              <h1 className="timer-text ">
                {currentQuestionIndex === 10
                  ? currentQuestionIndex
                  : currentQuestionIndex + 1}
                /10
              </h1>

              <div className="timer-border d-flex align-item-center">
                <div className="timer-body d-flex align-item-center">
                  <div className="timer-style d-flex align-item-center">
                    <h5 className="">{gif ? 0 : timeRemaining} </h5>
                  </div>
                </div>
              </div>
              <div className="right___ans____header d-flex justify-content-center align-items-center">
                <div className="right___ans____body d-flex justify-content-center align-items-center">
                  <span className="right___ans___number">
                    {passpoints ? passpoints : "0"}
                  </span>
                </div>
              </div>
              {isCorrect && gametype === "coin" && (
                <div className="right___ans____header d-flex justify-content-center align-items-center">
                  <div className="right___ans____body d-flex justify-content-center align-items-center">
                    <span className="right___ans___number">+5</span>
                  </div>
                </div>
              )}
              {isFalse && gametype === "coin" && (
                <div className="wrong___ans____header d-flex justify-content-center align-items-center">
                  <div className="wrong___ans____body d-flex justify-content-center align-items-center">
                    <span className="wrong___ans___number">-5</span>
                  </div>
                </div>
              )}
            </div>

            <div className="mx-auto mt-4">
              <div className="row row-cols-2 g-4 mx-auto">
                {allAnswers.map((answer, i) => (
                  <div key={i} className="col">
                    <div
                      className={` w-100 m-1 text-black p-2 text-center rounded questions-back ${(isCorrect || isFalse) &&
                        answer == question.correctAnswer
                        ? "correct__answer"
                        : "questions-backColor"
                        } ${isFalse &&
                          answer != question.correctAnswer &&
                          i == selectedAnswer
                          ? "wrong__answer"
                          : "questions-backColor"
                        }`}
                      onClick={() => {
                        if (!isCorrect && !isFalse) {
                          setSelectedAnswer(i);
                          onGuessAnswer(answer, timeRemaining, playerData);
                        }
                      }}
                    >
                      <p className="questions-css question-text">{answer}</p>
                    </div>
                  </div>
                ))}
              </div>
            </div>
            <div className="row mt-5 pb-5">
              <div
                className="col-xl-7 mx-auto p-3 text-center"
                style={{ backgroundColor: "#00C2FF" }}
              >
                Google AdSense size 970x90 px
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

// export default Answer;

import React, { useEffect, useState } from "react";
import Header from "../partials/PuzzleHeader2";
import LandingImage from "../../assets/banner.png";
import A from "../../assets/Questions.png";
import B from "../../assets/speech-bubble.png";
import Section2 from "../../images/section-two-img.png";
import counter1 from "../../assets/icons/counter-icon-1.svg";
import counter2 from "../../assets/icons/counter-icon-2.svg";
import counter3 from "../../assets/icons/counter-icon-3.svg";
import SendIcon from "../../assets/icons/send_icon.svg";
import CrossIcon from "../../assets/icons/cross_icon.svg";
import UserIcon from "../../assets/icons/user_icon.svg";
import "../../puzzlestyle.css";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";
import { Collapse } from "antd";
import Footer from "../partials/footer";
import FAQS from "../../utils/faqs.json";
import Modal from "react-modal";
import InputMask from "react-input-mask";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import ScrollToTop from "../../assets/icons/top-symbol.svg";
function PhoneInput(props) {
  return (
    <InputMask
      mask="+9999 999 99999"
      value={props.value}
      onChange={props.onChange}
      placeholder="+999 999 999"
      className="Input-text-field"
    ></InputMask>
  );
}

const Panel = Collapse.Panel;
const LandingPage = () => {
  let navigate = useNavigate();
  const [isCollapsed, setIsCollapsed] = useState(true);
  const [totalWinner, setTotalWinner] = useState({});
  const [totalAmount, setTotalAmount] = useState({});
  const [totalGames, setTotalGames] = useState({});
  const [selectPlayerModal, setSelectPlayerModal] = useState(false);
  const [player1Invitation, setPlayer1Invitation] = useState(false);
  const [player2Invitation, setPlayer2Invitation] = useState(false);
  const [isInvitationSent, setIsInvitationSent] = useState(false);
  const [player, setPlayer] = useState(0);
  const [gameId, setGameId] = useState("");
  const [questions, setQuestions] = useState();
  const [sendInviteModal, setSendInviteModal] = useState(false);
  const url = process.env.REACT_APP_APP_BACK_URL;
  const [credentials, setCredentials] = useState({
    email: "",
    number: "",
    player: "",
  });
  const [phone, setPhone] = useState("");
  const handlePhoneInput = (e) => {
    setPhone(e.target.value);
  };
  const handleInput = (e) => {
    setCredentials({ ...credentials, [e.target.name]: e.target.value });
  };
  const toggleCollapse = () => {
    setIsCollapsed(!isCollapsed);
  };
  const isAuthenticated = useSelector((state) => state.user);
  const [isLoading, setIsLoading] = useState(false);
  const totalGamess = async () => {
    try {
      let headers = new Headers();
      headers.append("Content-Type", "application/json");
      headers.append("Access-Control-Allow-Origin", "*");
      headers.append("Access-Control-Allow-Credentials", "true");
      const totalGamesRes = await fetch(`${url}/api/prizes/total/games`, {
        mode: "cors",
        method: "GET",
        headers: headers,
      });

      if (totalGamesRes.ok) {
        console.log(totalGamesRes);
        const totalGamesData = await totalGamesRes.json();
        console.log(totalGamesData);

        setTotalGames(totalGamesData?.data);
      } else {
        console.error("Error here");
      }
    } catch (error) {
      console.error(error);
    }
  };
  const getQuestions = async () => {
    try {
      const response = await fetch(
        "https://the-trivia-api.com/api/questions?limit=10&&region=US&&difficulties=easy&&categories=music,sport_and_leisure,film_and_tv,food_and_drink"
      );
      const questions = await response.json();
      console.log(questions);
      setQuestions(questions);
    } catch (error) { }
  };
  useEffect(() => {
    totalGamess();
    getQuestions();
  }, []);

  const handleModal = () => {
    setSelectPlayerModal(true);
    // window.addEventListener("beforeunload", handleBeforeUnload);
  };
  const handleInviteModal = (playerId) => {
    setPlayer(playerId);
    setSendInviteModal(true);
    setSelectPlayerModal(false);
  };
  const formSubmit = async (e) => {
    try {

      e.preventDefault();

      if (isInvitationSent) {
        toast.error("Invitation has already been sent.");
        return;
      }

      if (credentials.email != "" || phone != "") {
        let headers = new Headers();
        headers.append("Content-Type", "application/json");
        headers.append("Access-Control-Allow-Origin", "*");
        headers.append("Access-Control-Allow-Credentials", "true");
        setIsLoading(true);
        const response = await fetch(`${url}/api/ShowQuestion/send/invitation`, {
          mode: "cors",
          method: "POST",
          headers: headers,
          body: JSON.stringify({
            email: credentials.email,
            number: phone,
            player: player,
            questions: questions,
            gameId: gameId,
          }),
        });
        setIsLoading(false);
        const json = await response.json();
        if (json.success) {
          toast.success("Invitation Send Successfully");
          if (gameId == "") {
            setGameId(json.game._id);
          }
          setSendInviteModal(false);
          setSelectPlayerModal(true);
          setCredentials({
            email: "",
          });
          setPhone("");
          setIsInvitationSent(true);
        } else {
          setIsLoading(false);
          toast.error(json.error);
        }
      } else {
        toast.error("Please Enter Email or Phone");
      }
    } catch (error) {
      setIsLoading(false);
      toast.error(error.message);
    }
  };
  const goToGame = async () => {
    try {
      let headers = new Headers();
      headers.append("Content-Type", "application/json");
      headers.append("Access-Control-Allow-Origin", "*");
      headers.append("Access-Control-Allow-Credentials", "true");

      const response = await fetch(
        `${url}/api/ShowQuestion/goto/game/${gameId}`,
        {
          mode: "cors",
          method: "GET",
          headers: headers,
        }
      );

      const json = await response.json();
      if (json.success) {
        const timestamp = new Date().getTime();
        navigate(`/show/question-page/${gameId}?p=${json.player}&&_=${timestamp}&type=main`);
      } else {
        toast.error(json.error);
      }
    } catch (error) {
      toast.error("Internal Server Error");
    }
  };
  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };
  const handleInvitedModal = () => {
    setSendInviteModal(false);
    setSelectPlayerModal(true);
  };

  useEffect(() => {
    const handleBeforeUnload = (event) => {
      // Cancel the event
      event.preventDefault();
      // Chrome requires returnValue to be set
      event.returnValue = '';
    };
    window.addEventListener('beforeunload', handleBeforeUnload);
    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload);
    };
  }, []);

  return (
    <>
      <section className="header-section position-relative">
        <div className="container-fluid pb-5">
          <Header />
          <div className=" container row row-cols-sm-2 py-3">
            <div className="container d-flex align-items-center pb-5">
              <div className="container ps-5 text-white ">
                <div className="text-center text-sm-start">
                  <h4 style={{ fontWeight: "700" }}>LOK3R Trivia</h4>
                  <h1 style={{ fontWeight: "700" }}>
                    WIN BIG, LAUGH <br />
                    HARD!
                  </h1>
                  <p>
                    Dive into the thrilling world of Lok3r Trivia, where every
                    correct answer unlocks a chance to win cash prizes from our
                    virtual lockers!
                  </p>
                  <div className="button-container d-flex justify-content-start align-items-center flex-row flex-wrap gap-2">
                    <Link
                      to={"/question-page"}
                      className="btn bg-blue text-white  border border-2 border-white rounded-pill px-5 shadow "
                      style={{
                        fontWeight: "700",
                        fontFamily: "Arial",
                      }}
                    >
                      PLAY NOW
                    </Link>
                    <Link
                      onClick={handleModal}
                      className="btn bg-blue text-white  border border-2 border-white rounded-pill px-5 shadow"
                      style={{
                        fontWeight: "700",
                        fontFamily: "Arial",
                      }}
                    >
                      Show
                    </Link>
                    
                  </div>
                </div>
              </div>
            </div>
            <div className="">
              <div className="d-flex justify-content-center">
                <img className="w-75" src={LandingImage} />
              </div>
            </div>
            <div className="scroll-btn">
              {/* Scroll To Top button here */}
              <Link
                onClick={scrollToTop}
                className="bg-white px-2 py-1 border border-3 border-black rounded shadow "
                style={{
                  position: "fixed",
                  bottom: "20px",
                  right: "20px",
                  zIndex: "9999",
                  textDecoration: "none",
                }}
              >
                <img src={ScrollToTop} alt="" />
              </Link>
            </div>
          </div>
          <div className="row  pb-5">
            <div
              className="col-xl-7 mx-auto p-3 text-center"
              style={{ backgroundColor: "#00C2FF" }}
            >
              Google AdSense size 970x90 px
            </div>
          </div>
        </div>
        <div className=" row row-cols-3 w-75 position-absolute start-50 bottom-n5 rounded translate-middle bg-light p-2 bg-primary text-white d-none d-sm-flex">
          <div className="d-flex justify-content-center align-items-center gap-2">
            <img src={counter1} style={{ width: "3rem" }} alt="" />
            <span style={{ lineHeight: 2 }}>
              <h1 className="p-0 m-0">
                {totalGames && totalGames.totalGames >= 1000
                  ? `${(totalGames.totalGames / 1000).toFixed(1)}K`
                  : (totalGames && totalGames.totalGames) || 0}
              </h1>
              <span style={{ color: "#0ECDB9", fontWeight: "700" }}>
                Games Played
              </span>
            </span>
          </div>
          <div className="d-flex justify-content-center align-items-center gap-2">
            <img src={counter2} style={{ width: "3rem" }} alt="" />
            <span style={{ lineHeight: 2, marginTop: "13px" }}>
              <h1 className="p-0 m-0">
                {totalGames?.totalWins >= 1000
                  ? `${(totalGames.totalWins / 1000).toFixed(1)}K`
                  : totalGames?.totalWins}
              </h1>
              <p style={{ color: "#0ECDB9", fontWeight: "700" }}>Winners</p>
            </span>
          </div>
          <div className="d-flex justify-content-center align-items-center gap-2">
            <img src={counter3} style={{ width: "3rem" }} alt="" />
            <span style={{ lineHeight: 2, marginTop: "10px" }}>
              <h1 className="p-0 m-0">
                $
                {totalGames?.totalAmountWon >= 1000
                  ? `${(totalGames.totalAmountWon / 1000).toFixed(1)}K`
                  : totalGames?.totalAmountWon}
              </h1>
              <p style={{ color: "#0ECDB9", fontWeight: "700" }}>Win Prizes</p>
            </span>
          </div>
        </div>
      </section>
      <section className="header-section-two">
        <div className="section-header">
          <div className="row align-items-center">
            <div className="col-xl-6">
              <div className="landing-img-2">
                <img src={Section2} alt="imege" className="section-two-img" />
              </div>
            </div>
            <div className="col-xl-6 d-flex flex-column gap-4">
              <p className="fw-bold">The Ultimate Brain-Bending Challenge</p>
              <h1 className="fw-bold">Welcome to Lok3r Trivia</h1>
              <p className="">
                Test your knowledge across various categories for a shot at
                unlocking the virtual locker and claiming cash prizes. Dive into
                a world of questions, unlock the right answers, and see if you
                have the key to unlock the jackpot. It's more than just trivia;
                it's your chance to crack the code and open the door to
                winnings. Are you ready to be the Lok3r Trivia champion?
              </p>
              <div className="d-flex justify-content-center justify-content-sm-start">
                <Link
                  to="question-page"
                  type="button"
                  className="btn bg-blue text-white  border border-2 border-white rounded-pill px-5"
                  style={{
                    fontWeight: "700",
                    fontFamily: "Arial",
                  }}
                >
                  GET STARTED
                </Link>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="header-section-three">
        <div className="container my-4 pb-5">
          <div
            className="col-xl-7 mx-auto p-3 text-center"
            style={{ backgroundColor: "#00C2FF" }}
          >
            Google AdSense size 970x90 px
          </div>
        </div>
        <div className="row">
          <div className="col-lg-8">
            <img className="ml-auto object-fit-contain w-100" src={A} />
          </div>
          <div className="col-lg-4 d-flex align-items-center ">
            <img
              className="object-fit-contain  "
              style={{ width: "80%" }}
              src={B}
              alt=""
            />
            {/* <div className="container-card w-100">
              <img style={{ width: "10rem" }} src={A} alt="" />
              <img style={{ width: "10rem" }} src={B} alt="" />
            </div>
            <div className="container-card mt-4">
              <img style={{ width: "10rem" }} src={C} alt="" />
              <img style={{ width: "10rem" }} src={D} alt="" />
            </div> */}
          </div>
        </div>
        <div className="row my-5">
          <p className="text-center">
            Step into the spotlight with Laugh & Win Ex travaganza the quiz show
            that blends brainpower with belly laughs! Crack the code to our
            virtual lockers with each correct answer and unlock a world of big
            cash prizes.
          </p>
          <div className="d-flex justify-content-center">
            <Link
              to={"/question-page"}
              className="btn bg-blue text-white border border-2 border-white rounded-pill px-5"
              style={{
                fontWeight: "700",
                fontFamily: "Arial",
              }}
            >
              PLAY NOW
            </Link>{" "}
          </div>
        </div>
      </section>
      <section className="">
        <div className="container">
          <div className="top-heading-five">
            <div
              className="top-content-five text-center"
              style={{ textTransform: "uppercase" }}
            >
              <h2>Frequently Asked Questions</h2>
              <h1>If you have questions we have answer FAQS</h1>
            </div>
            <div className="row px-5">
              <div className="col-12 mt-5">
                <div className="questions-list w-100">
                  {FAQS.map((faq, i) => (
                    <div
                      className="text-white rounded p-4 bg-blue rounded container align-items-center"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target={`#q-${i}`}
                      aria-expanded="false"
                      aria-controls={`q-${i}`}
                    >
                      <div className="d-flex justify-content-between align-items-center ">
                        <h5>{faq.question}</h5>
                        <div>
                          <button
                            className="rounded-pill bg-white border-0"
                            style={{ width: "2rem", height: "2rem" }}
                          >
                            +
                          </button>
                        </div>
                      </div>
                      <div className="my-2 collapse" id={`q-${i}`}>
                        <div className="text-white">{faq.answer}</div>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </div>
          <div className="container my-4 pb-5">
            <div
              className="col-xl-7 mx-auto p-3 text-center"
              style={{ backgroundColor: "#00C2FF" }}
            >
              Google AdSense size 970x90 px
            </div>
          </div>
        </div>
      </section>
      <Modal
        isOpen={selectPlayerModal}
        contentLabel="Example Modal"
        className="d-flex flex-column justify-content-center p-3 col-lg-4 m-auto mt-3 h-100 align-items-center "
        shouldCloseOnOverlayClick={false}
        style={{
          content: {
            fontFamily: "Arial",
            fontWeight: "800",
            fontSize: "30px",
          },
          overlay: {
            backgroundColor: "rgba(0, 0, 0, 0.5)", // Set the overlay background color
          },
        }}
      >
        <div
          className="p-4 w-sm-50 h-sm-50 col-10 rounded-3"
          style={{ background: "#fff" }}
        >
          <div className="d-flex justify-content-between align-items-center flex-row">
            <img src={UserIcon} alt="send" />
            <img
              src={CrossIcon}
              alt="cross"
              onClick={() => setSelectPlayerModal(false)}
            />
          </div>
          <div>
            {/* <h5 className="m-2" style={{ fontWeight: "700", fontSize: "14px" }}>
              Select Player and Send Invitation
            </h5> */}
          </div>

          <div
            className="p-2 rounded-2 w-sm-25  " //h-75
            style={{ background: "#fff" }}
          >
            <div className="d-flex justify-content-center align-items-center flex-row flex-wrap gap-2 mt-3">
              <>
                {" "}
                {!isInvitationSent && (
                <Link
                  onClick={() => handleInviteModal(2)}
                  className="bg-blue text-white border border-2 border-blue rounded-pill px-4 py-2 shadow"
                  style={{
                    fontWeight: "700",
                    fontFamily: "Arial",
                    fontSize: "12px",
                    textDecoration: "none",
                  }}
                >
                  Invite Player
                </Link>
              )}
                {gameId && (
                  <Link
                    onClick={goToGame}
                    className="bg-blue text-white  border border-2 border-blue rounded-pill px-4 py-2 shadow"
                    style={{
                      fontWeight: "700",
                      fontFamily: "Arial",
                      fontSize: "12px",
                      textDecoration: "none",
                    }}
                  >
                    Go To Game
                  </Link>
                )}
              </>
              {player1Invitation && player2Invitation && (
                <button
                  className="bg-blue text-white border border-2 border-green rounded-pill px-4 py-2 shadow"
                  style={{
                    fontWeight: "700",
                    fontFamily: "Arial",
                    fontSize: "12px",
                  }}
                  onClick={() => navigate("/multiplayer/question-page")}
                >
                  Play Game
                </button>
              )}
            </div>
          </div>
        </div>
      </Modal>
      <Modal
        isOpen={sendInviteModal}
        contentLabel="Example Modal"
        className="d-flex flex-column justify-content-center p-3 col-lg-4 m-auto mt-3 h-100 align-items-center "
        shouldCloseOnOverlayClick={false}
        style={{
          content: {
            fontFamily: "Arial",
            fontWeight: "800",
            fontSize: "30px",
          },
          overlay: {
            backgroundColor: "rgba(0, 0, 0, 0.5)", // Set the overlay background color
          },
        }}
      >
        <div
          className="p-4 w-sm-50 h-sm-50 col-10 rounded-3"
          style={{ background: "#fff" }}
        >
          <div className="d-flex justify-content-between align-items-center flex-row">
            <img src={SendIcon} alt="send" />
            <img src={CrossIcon} alt="cross" onClick={handleInvitedModal} />
          </div>
          <div>
            <h5 className="fs-6 m-2" style={{ fontWeight: "700" }}>
              Invite Via Email/Phone Number
            </h5>
          </div>

          <div
            className="p-2 rounded-2 w-sm-25  " //h-75
            style={{ background: "#fff" }}
          >
            <form onSubmit={formSubmit}>
              <div className="d-flex flex-column">
                <div>
                  <label
                    htmlFor="email"
                    className="form-label"
                    style={{ fontSize: "14px", fontWeight: "500" }}
                  >
                    Email
                  </label>
                  <input
                    type="email"
                    className="form-control"
                    id="email"
                    name="email"
                    value={credentials.email}
                    onChange={handleInput}
                  />
                </div>

                <div>
                  <label
                    htmlFor="number"
                    className="form-label"
                    style={{ fontSize: "14px", fontWeight: "400" }}
                  >
                    Phone Number
                  </label>
                   <PhoneInput
                    value={phone}
                    onChange={handlePhoneInput}
                  ></PhoneInput> 
                </div>
              </div>
              <div className="d-flex justify-content-between align-items-center flex-row flex-wrap gap-2 mt-3">
                <Link
                  onClick={handleInvitedModal}
                  className="bg-white  border border-2 border-blue rounded-pill px-4 py-2 shadow "
                  style={{
                    fontWeight: "700",
                    fontFamily: "Arial",
                    fontSize: "12px",
                    textDecoration: "none",
                    color: "#012267",
                  }}
                >
                  Cancel
                </Link>

                <button
                  type="submit"
                  className="bg-blue text-white  border border-2 border-blue rounded-pill px-4 py-2 shadow"
                  style={{
                    fontWeight: "700",
                    fontFamily: "Arial",
                    fontSize: "12px",
                    textDecoration: "none",
                  }}
                  disabled={isLoading}
                >
                  {isLoading ? "Sending..." : "Send"}
                </button>
              </div>
            </form>
          </div>
        </div>
      </Modal>
      <Footer />
    </>
  );
};

export default LandingPage;

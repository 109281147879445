import React, { useState, useEffect } from 'react';

const  InActivity = () => {
  const idleTimeoutInSeconds = 10; // 5 minutes (adjust as needed)
  const [isLoggedOut, setIsLoggedOut] = useState(false);
  let idleTimer;

  // Function to reset the idle timer
  const resetIdleTimer = () => {
    clearTimeout(idleTimer);
    idleTimer = setTimeout(logout, idleTimeoutInSeconds * 1000);
  };

  // Function to simulate a logout action
  const logout = () => {
    // Perform the logout action (e.g., clear user session)
    setIsLoggedOut(true);
  };

  // Initialize/reset the idle timer on user activity
  useEffect(() => {
    resetIdleTimer();

    const activityEvents = ['mousemove', 'keydown', 'mousedown'];
    const onActivity = () => {
      resetIdleTimer();
    };

    activityEvents.forEach(event => {
      window.addEventListener(event, onActivity);
    });

    return () => {
      activityEvents.forEach(event => {
        window.removeEventListener(event, onActivity);
      });
    };
  }, []);

  // Render content based on the user's login status
  return (
    <div>
      {isLoggedOut ? (
        <div>
          <p>You have been logged out due to inactivity.</p>
          {/* You can add a button or link to log in again */}
        </div>
      ) : (
        <div>
          <p>Welcome to the application.</p>
          {/* Your main application content goes here */}
        </div>
      )}
    </div>
  );
};

export default InActivity;

import "./App.css";
import React, { useEffect } from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";

import Signup from "./Components/auth/Signup";
// import Login from "./Components/auth/Login";
import { loadStripe } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";
import Code from "./Components/Code";
import LoginPage from "./Components/pin_code/LoginPage";
import SiteUser from "./Components/pin_code/SiteUser";
import DashboardScr from "./Components/dashboard/DashboardScr";
import PrivateRoute from "./Components/auth/PrivateRoute";
import Dashboardcopy from "./Components/dashboard/Dashboardcopy";
import TimeScr from "./Components/dashboard/TimeScr";
import InActivity from "./Components/dashboard/InActivity";
import SiteList from "./Components/site/Site";
import Add from "./Components/site/Add";
import Edit from "./Components/site/Edit";
import Lockers from "./Components/Lockers/List";
import LockersEdit from "./Components/Lockers/Edit";
import Profile from "./Components/profile/Profile";
import EditProfile from "./Components/profile/EditProfile";
import AdminPage from "./Components/pin_code/AdminPage";
import NewDashboard from "./Components/dashboard/NewDashboard";
import ForgotPassword from "./Components/pin_code/ForgotPassword";
import ResetPassword from "./Components/pin_code/ResetPassword";
import DashboardNew from "./Components/dashboard/DashboardNew";
import Logo from "./Components/site/Logo";
import PuzzleLogin from "./Components/puzzle_auth/LoginPage";
import PuzzleSignup from "./Components/puzzle_auth/SignupPage";
import PuzzleForget from "./Components/puzzle_auth/ForgetPage";
import LandingPage from "./Components/landing_page/LandingPage";
import ShowPage from "./Components/landing_page/ShowPage";
import QuestionPage from "./Components/question_page/QuestionPage";
import UserDashboard from "./Components/UserDashboard/PuzzleUser";
import GameDetails from "./Components/users/GameDetails";
import AddPrizes from "./Components/prizes/Add";
import Prizes from "./Components/prizes/Prizes";
import UserList from "./Components/users/UserList";
import BankDetails from "./Components/users/BankDetails";
import AboutUs from "./Components/footer/AboutUs";
import ContactUs from "./Components/contact-us";
import Settings from "./Components/settings/Setting";
import AddAds from "./Components/settings/Add";
import EditAds from "./Components/settings/Edit";
import MultiplayerQuestion from "./Components/question_page/MultiplayerQuestion";
import ShowQuestion from "./Components/question_page/ShowQuestion";
import ManualLocker from "./Components/dashboard/ManualLockersUpdate";
import ShowLocker from "./Components/dashboard/ShowLockersUpdate";

import Loader from "./Components/partials/Loader";
import Payment from "./Components/payments/Add";
import LockerPage from "./Components/question/LockerPage";
import Players from "./Components/players/Players";
import Record from "./Components/question/Record";
import RecordingPermissions from "./Components/question/RecordingPermissions";


import MultiPlayerDashboard from "./Components/dashboard/MultiPlayerDashboard";
const stripePromise = loadStripe('pk_test_51Kz28VJ02vlPsvqy3qqGNCDF35q0So64eWCrVSlqMPUuTlaheNWS14EYwkK4Y55rzJ6vKPbudQ7I9HOnd8Hnpoun00sfcQ6LAe');
function App() {
  useEffect(() => {
    const handleTouchMove = (e) => {
      // Prevent default behavior for two-finger swipe or zoom
      if (e.touches.length === 2) {
        e.preventDefault();
      }
    };
    // Add touch move event listener
    document.addEventListener("touchmove", handleTouchMove, { passive: false });
    return () => {
      // Remove touch move event listener on component unmount
      document.removeEventListener("touchmove", handleTouchMove);
    };
  }, []);
  // const stripePromise = loadStripe('pk_test_51Kz28VJ02vlPsvqy3qqGNCDF35q0So64eWCrVSlqMPUuTlaheNWS14EYwkK4Y55rzJ6vKPbudQ7I9HOnd8Hnpoun00sfcQ6LAe');
  return (
    <>
      <Router>
        <Routes>
          {/* <Route exact path="/" element={<SiteUser />} /> */}
          <Route exact path="/signup" element={<Signup />} />
          <Route exact path="/recording/:id" element={<Record />} />
          <Route exact path="/recording-permissions" element={<RecordingPermissions />} />
          
          <Route exact path="/code/:slug" element={<Code />} />
          <Route exact path="/:slug/pin" element={<LoginPage />} />
          <Route exact path="/dashboard" element={<Dashboardcopy />} />
          <Route exact path="/:slug/newdashboard" element={<NewDashboard />} />
          <Route exact path="/admin" element={<AdminPage />} />
          <Route exact path="/forgot/password" element={<ForgotPassword />} />
          <Route exact path="/loader" element={<Loader />} />

          <Route
            exact
            path="/reset/password/:token"
            element={<ResetPassword />}
          />

          <Route exact path="/puzzle-login" element={<PuzzleLogin />} />
          <Route exact path="/puzzle-signup" element={<PuzzleSignup />} />
          <Route exact path="/puzzle-forget" element={<PuzzleForget />} />
          <Route exact path="/about-us" element={<AboutUs />} />
          <Route exact path="/contact-us" element={<ContactUs />} />
          <Route exact path="/" element={<LandingPage />} />
          <Route exact path="/show" element={<ShowPage />} />
          
          <Route exact path="/question-page" element={<QuestionPage />} />
          <Route exact path="/multiplayer/question-page/:id" element={<MultiplayerQuestion />} />
          <Route exact path="/show/question-page/:id" element={<ShowQuestion />} />
          <Route exact path="/winer/lockers/:id" element={<DashboardScr />} />
          {/* <Route exact path="/multiplayer/winer/lockers/:id" element={<MultiPlayerDashboard />} /> */}
          <Route exact path="/multiplayer/winner/lockers/:id" element={<ManualLocker />} />
          <Route exact path="/show/winner/lockers/:id" element={<ShowLocker />} />
          <Route exact path="/call-test" element={<LockerPage />} />
          
          <Route element={<PrivateRoute />}>
            <Route
              exact
              path="user/dashboard"
              element={
                <Elements stripe={stripePromise}>
                  <UserDashboard />
                </Elements>
              }
            />
            <Route
              exact
              path="/payments"
              element={
                <Elements stripe={stripePromise}>
                  <Payment />
                </Elements>
              }
            />
            <Route exact path="/:slug/timer" element={<TimeScr />} />
            <Route exact path="/players" element={<Players />} />
            
            <Route exact path="inActivity" element={<InActivity />} />
            <Route exact path="/sites" element={<SiteList />} />
            <Route exact path="/site/add" element={<Add />} />
            <Route exact path="/site/logo/add" element={<Logo />} />
            <Route exact path="/edit/site/:id" element={<Edit />} />
            <Route exact path="/:slug/lockers/" element={<Lockers />} />
            <Route exact path="/user/list" element={<UserList />} />
            <Route exact path="/edit/locker/:id" element={<LockersEdit />} />
            <Route exact path="/profile" element={<Profile />} />
            <Route exact path="/profile/edit" element={<EditProfile />} />
            {/* add prizes  */}
            <Route exact path="/add/prizes" element={<AddPrizes />} />
            <Route exact path="/prizes/list" element={<Prizes />} />
            <Route exact path="/game/details/:id" element={<GameDetails />} />
            <Route exact path="/bank/details/:id" element={<BankDetails />} />
            <Route exact path="/game/settings" element={<Settings />} />
            <Route exact path="/add/question/ads" element={<AddAds />} />
            <Route exact path="/edit/question/ads/:id" element={<EditAds />} />

          </Route>
        </Routes>
      </Router>
    </>
  );
}
export default App;

import React from "react";
import { Navigate, Outlet, Route } from "react-router-dom";
import { useDispatch } from "react-redux";
import { logout } from "../Redux/Authentication";
// Utils
const PrivateRoute = ({ children }) => {
  const dispatch = useDispatch();
  const authed = localStorage.getItem("token");
  const storedToken = localStorage.getItem("token-expiration");
  const currentTime = Math.floor(Date.now() / 1000);
  if (authed && storedToken > currentTime) {
    return <Outlet />;
  } else {
    const logouts = localStorage.removeItem("token");
    dispatch(logout());
    return <Navigate to="/puzzle-login" replace />;
  }
};
//return authed ? children : <Navigate to="/" replace /> };
export default PrivateRoute;

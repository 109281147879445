import React from "react";
import ReactDOM from "react-dom/client";
import 'bootstrap/dist/css/bootstrap.min.css';
import "./index.css";
import App from "./App";

import { Provider } from "react-redux";
import store from './store';
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "react-phone-input-2/lib/style.css";
import { PersistGate } from "redux-persist/integration/react";


const persistedState = localStorage.getItem("reduxState")
  ? JSON.parse(localStorage.getItem("reduxState"))
  : {};
store.dispatch({ type: "SET_INITIAL_STATE", payload: persistedState });
ReactDOM.createRoot(document.getElementById("root")).render(
  <Provider store={store}>
  <ToastContainer />
  <App />
</Provider>,
);

// AnswerComponent.js
import React, { useEffect, useRef, useState } from "react";
import StopRec from "../../assets/new-design/stop-rec.png";
import { useParams } from "react-router-dom";
import DownloadRec from "../../assets/new-design/download.png";
import StartRec from "../../assets/new-design/start-rec.png";
import io from "socket.io-client";
import { toast } from "react-toastify";
const url = process.env.REACT_APP_APP_BACK_URL;
const back_url = process.env.REACT_APP_APP_BACK_URL;
const socket = io.connect(back_url);
export const Record = ({ isRecord, isStop, gameEnd }) => {
  const { id } = useParams();
  const [isRecording, setIsRecording] = useState(false);
  const [mediaStream, setMediaStream] = useState(null);
  const mediaRecorderRef = useRef(null);
  const recordedChunksRef = useRef([]);
  const clickStart = useRef(null);
  const clickJoin = useRef(null);
  const queryParams = new URLSearchParams(window.location.search);
  const player = queryParams.get("p");
  const type = queryParams.get("type");
  const clickStop = useRef(null);

  useEffect(() => {
    socket.emit("player", player);
    // return () => {
    if (type === "main") {
      if (clickStart.current) {
        clickStart.current.click();
      }
    }
    // };
  }, [isRecord]);
  useEffect(() => {
    const handlePageHide = async (event) => {
      event.preventDefault(); // Cancel the default behavior
      await stopRecordings1(id);
    };
    window.addEventListener("pagehide", handlePageHide);
    return () => {
      window.removeEventListener("pagehide", handlePageHide);
    };
  }, [id]);
  const stopRecordings = async (type) => {
    try {
      let headers = new Headers();
      headers.append("Content-Type", "application/json");
      headers.append("Access-Control-Allow-Origin", "*");
      headers.append("Access-Control-Allow-Credentials", "true");

      const response = await fetch(
        `${url}/api/multiplayer/stop/recording/${id}?type=${type}`,
        {
          mode: "cors",
          method: "GET",
          headers: headers,
        }
      );

      if (!response.ok) {
        throw new Error("Network response was not ok");
      }
      const data = await response.json();
      if (data.success) {
        socket.emit("stopPlayerRecordings", {player:data.selectedUserIds,type:type});
      }
    } catch (error) {
      toast.error("Error on stop rec");
    }
  };
  const stopRecordings1 = async (id) => {
    try {
      let headers = new Headers();
      headers.append("Content-Type", "application/json");
      headers.append("Access-Control-Allow-Origin", "*");
      headers.append("Access-Control-Allow-Credentials", "true");

      const response = await fetch(
        `${url}/api/multiplayer/stop/recording1/${id}`,
        {
          mode: "cors",
          method: "GET",
          headers: headers,
        }
      );

      if (!response.ok) {
        throw new Error("Network response was not ok");
      }
      const data = await response.json();
      if (data.success) {
        socket.emit("stopPlayerRecordings", {player:data.selectedUserIds,type:'yes'});
      }
    } catch (error) {
      toast.error("Error on stop rec");
    }
  };

  // useEffect(() => {
  //   stopRecordings()
  // }, [])
  // useEffect(() => {
  //   console.log('check is this game end :',isRecord,isStop)
  //       if (isStop && type==='main') {
  //         console.log('Recording is Stopped : ', isStop)
  //         clickStop.current.click();
  //           setTimeout(() => {
  //               clickJoin.current.click();
  //           }, 2500);
  //       }
  // }, [gameEnd]);

  useEffect(() => {
    socket.on("stopRecordings", () => {
      if (type === "main") {
        clickStop.current.click();
        setTimeout(() => {
          clickJoin.current.click();
        }, 2500);
      }
    });
  }, [type]);
  const mainButtonRef = useRef(null);
  // const startRecording = async () => {
  //   try {
  //     // Request screen media (display media)
  //     let stream;
  //     if (navigator.mediaDevices && navigator.mediaDevices.getDisplayMedia) {
  //         // Attempt to get the screen media
  //         stream = await navigator.mediaDevices.getDisplayMedia({
  //           video: true,
  //           audio: true, // Optional: set to false if you don't want to capture audio
  //         });

  //       // Set the media stream
  //       setMediaStream(stream);

  //       // Initialize the MediaRecorder with the stream
  //       const mediaRecorder = new MediaRecorder(stream);
  //       mediaRecorderRef.current = mediaRecorder;

  //       // Handle the data available event to collect recorded data
  //       mediaRecorder.ondataavailable = (event) => {
  //         if (event.data.size > 0) {
  //           recordedChunksRef.current.push(event.data);
  //         }
  //       };

  //       // Start recording
  //       mediaRecorder.start();

  //       // Update recording state
  //       setIsRecording(true);

  //     } else if (navigator.mediaDevices && navigator.mediaDevices.getUserMedia) {
  //       toast.warn("Screen recording is not supported on this device");
  //     } else {
  //       throw new Error("Screen recording is not supported on this device.");
  //     }

  //   } catch (error) {
  //     // Handle any errors that occur during screen recording setup
  //      toast.error('Screen recording is not supported on this device');
  //     console.error("Error accessing screen recording:", error);
  //   }
  // };
  const startRecording = async () => {
    try {
      let stream;
      if (navigator.mediaDevices && navigator.mediaDevices.getDisplayMedia) {
        // Attempt to get the screen media
        stream = await navigator.mediaDevices.getDisplayMedia({
          video: {
            displaySurface: "browser", // Only allow capturing the browser tab
            cursor: "always", // Ensures the cursor is captured
            surfaceSwitching: "include", // Allow surface switching if needed
            selfBrowserSurface: "include", // Ensure current tab is included
            systemAudio: "include", // Include system audio if needed
          },
          audio: true, // Set to false if you don't want to capture audio
        });

        // Set the media stream
        setMediaStream(stream);

        // Initialize the MediaRecorder with the stream
        const mediaRecorder = new MediaRecorder(stream);
        mediaRecorderRef.current = mediaRecorder;

        // Handle the data available event to collect recorded data
        mediaRecorder.ondataavailable = (event) => {
          if (event.data.size > 0) {
            recordedChunksRef.current.push(event.data);
          }
        };

        // Handle the stop event to create a video file
        mediaRecorder.onstop = () => {
          const blob = new Blob(recordedChunksRef.current, {
            type: "video/webm",
          });
          stopRecordings('yes');
          // const url = URL.createObjectURL(blob);
          // const a = document.createElement("a");
          // a.style.display = "none";
          // a.href = url;
          // a.download = "screen-recording.webm";
          // document.body.appendChild(a);
          // a.click();
          // window.URL.revokeObjectURL(url);
        };

        // Start recording
        mediaRecorder.start();
        stopRecordings('start');
        // Update recording state
        setIsRecording(true);
      } else {
        throw new Error("Screen recording is not supported on this device.");
      }
    } catch (error) {
      // Handle any errors that occur during screen recording setup
      await stopRecordings1(id);
      toast.warn("Screen recording is not supported on this device");
      console.error("Error accessing screen recording:", error);
    }
  };

  const stopRecording = () => {
    if (mediaRecorderRef.current) {
      mediaRecorderRef.current.stop();
      mediaStream.getTracks().forEach((track) => track.stop());
      setIsRecording(false);
    }
  };

  const downloadRecording = async () => {
    const blob = new Blob(recordedChunksRef.current, {
      type: "video/webm",
    });
    // const url = URL.createObjectURL(blob);
    // const a = document.createElement("a");
    // document.body.appendChild(a);
    // a.style.display = "none";
    // a.href = url;
    // a.download = "recording.webm";
    // // a.click();
    // window.URL.revokeObjectURL(url);
    const file = new File([blob], "recording.webm", { type: "video/webm" });
    const formData = new FormData();
    formData.append("file", file);
    try {
      const response = await fetch(
        `${back_url}/api/ShowQuestion/saveRecording/${id}`,
        {
          method: "POST",
          body: formData,
        }
      );
      const json = await response.json();
      if (json.success) {
        // if (window.opener) {
        //   window.opener.postMessage(blob, window.location.origin);
        // }
        // localStorage.getItem(`rec_${id}`).close();
        // // Handle success
        // console.log('Recording updated successfully');
      } else {
        // Handle failure
        console.error("Error:", json.error);
      }
    } catch (error) {
      toast.error("Internal Server Error awnswes !");
    }
    recordedChunksRef.current = [];
  };

  return (
    <>
      {/* {playerData && playerData.type === "local" && ( */}
      {/* <div className="recording-btns">
        <div className=" mt-3 d-flex justify-content-start gap-2 flex-row flex-wrap">
          <a
            className="icon-call-recording"
            href="#start"
            // onClick={startRecording}
            onClick={(e) => {
              e.preventDefault();
              if (!isRecording) startRecording();
            }}
            disabled={isRecording}
            style={{ opacity: isRecording ? 0.5 : 1 }}
          >
            <img src={StartRec} alt="start recording" />
          </a>
          <a
            className="icon-call-recording"
            href="#stop"
            // onClick={stopRecording}
            onClick={(e) => {
              e.preventDefault();
              if (isRecording) stopRecording();
            }}
            disabled={!isRecording}
            style={{ opacity: !isRecording ? 0.5 : 1 }}
          >
            <img src={StopRec} alt="stop recording" />
          </a>
          <a
            className="icon-call-recording"
            href="#download"
            onClick={(e) => {
              e.preventDefault();
              if (!isRecording && recordedChunksRef.current.length > 0)
                downloadRecording();
            }}
            disabled={isRecording || recordedChunksRef.current.length === 0}
            style={{
              opacity: recordedChunksRef.current.length === 0 ? 0.5 : 1,
            }}
          >
            <img src={DownloadRec} alt="download recording" />
          </a>
        </div>
      </div> */}
      <a ref={clickStart} onClick={startRecording}></a>
      <a ref={clickStop} onClick={stopRecording}></a>
      <a ref={clickJoin} onClick={downloadRecording}></a>
    </>
  );
};

export default Record;

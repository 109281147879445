import React, { useEffect, useState } from "react";
import BackIcon from '../images/icon-back.png'
import styles from './style.module.css'
import { useSelector } from 'react-redux';
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";
const url = process.env.REACT_APP_APP_BACK_URL;

const Code = () => {
  const isAuthenticated = useSelector((state) => state.user);
  let { slug } = useParams();
  const [isLoading, setIsLoading] = useState(false);
  const [getSite, setSite] = useState({});

  useEffect(() => {
    siteData();
  }, []);
  const [credentials, setCredentials] = useState({ name: "" });
  const handleInput = (e) => {
    setCredentials({ ...credentials, [e.target.name]: e.target.value });
  };

  const formSubmit = async (e) => {
    e.preventDefault();
    let headers = new Headers();
    headers.append("Content-Type", "application/json");
    headers.append("Access-Control-Allow-Origin", "*");
    headers.append("Access-Control-Allow-Credentials", "true");
    setIsLoading(true);

    const response = await fetch(
      `${url}/api/auth/code`,
      {
        mode: "cors",
        method: "POST",
        headers: headers,
        body: JSON.stringify({
          name: credentials.name,
          slug: slug
        }),
      }
    );
    setIsLoading(false);

    const json = await response.json();

    if (json.success) {

      setCredentials({
        name: '',
      });
      toast.success('Qrcode Type Created Successfully');

    } else {

      toast.error(json.error);

    }
  };
  // get all site
  const siteData = async () => {
    try {
      let headers = new Headers();
      headers.append("Content-Type", "application/json");
      headers.append("Access-Control-Allow-Origin", "*");
      headers.append("Access-Control-Allow-Credentials", "true");
      const response = await fetch(`${url}/api/sites/getSite/${slug}`, {
        mode: "cors",
        method: "GET",
        headers: headers,
      });
      const json = await response.json();
      setSite(json.site);
    } catch (error) {
      console.error(error);
    }
  };
  const iocnStyle = {
    display: isLoading ? 'inline-block' : 'none',
  };

  const [isActive, setIsActive] = useState(false);

  // Function to toggle the active state
  const toggleActive = () => {
    setIsActive(!isActive);
  };

  // Define the CSS classes based on the isActive state
  const divClassName = isActive ? 'sidebar active' : 'sidebar';

  return (
    <div className={styles.heading}>
      <div className="body-wrap">
        <section className={styles.sidebar_wrap}>
          <div className="container-fluid px-0">
            <div className="row">

              <div className="col col-dashboard">

                <div className="general-dashboard">
                  <div className="container-fluid">
                    <div className="dashboard-top-row add-new-top-row">
                      <div className="row">
                        <div className="col-lg-6 my-auto">
                          <div className="user-wrap user-back-wrap">
                            <h3>{getSite ? getSite.name : ''}</h3>

                          </div>
                        </div>
                        <div className="col-lg-6 text-end"></div>
                      </div>
                    </div>
                    {/* row */}
                    <form onSubmit={formSubmit}>
                      <div className="form-box card-box">
                        <div className="row">
                          <div className="col-lg-3">
                          </div>
                          <div className="col-lg-6">
                            {/* input row */}
                            <div className="row">

                              <div className="col-xl-8 col-lg-8">
                                <div className="inpt-wrap">
                                  <input
                                    type="text"
                                    className="form-control"
                                    id="fuserName"
                                    name="name"
                                    placeholder="Enter code.."
                                    value={credentials.name}
                                    onChange={handleInput}
                                    required
                                  />
                                </div>
                              </div>
                            </div>
                            {/* input row */}
                          </div>
                          <div className="col-lg-3">
                          </div>

                          <div className="col-lg-12 text-center">
                            <div className="form-btn-wrap mb-5 pb-5">

                              <button type="submit" disabled={isLoading} href="" className="btn btn-prim w-auto ms-5">
                                <i className="fa fa-spinner fa-spin" style={iocnStyle}></i> Create
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </div>
  );
};

export default Code;

import React, { useState } from "react";
import Header2 from "../../Components/partials/Header2";
import Sidebar from "../../Components/partials/Sidebar";
import BackIcon from "../../images/icon-back.png";
// import UploadIcon from '../../../images/icon-upload.png'
import { useSelector } from "react-redux";
import styles from "./style.module.css";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import axios from "axios";
const Logo = () => {
  // const isAuthenticated = useSelector((state) => state.user);
  const url = process.env.REACT_APP_APP_BACK_URL;
  // const [appendedHTML, setAppendedHTML] = useState("");
  // const [seletectedFile, setSelectedFile] = useState("");
  const [logoImage, setLogoImage] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [isActive, setIsActive] = useState(false);
  const toggleActive = () => {
    setIsActive(!isActive);
  };
  const divClassName = isActive ? "sidebar active" : "sidebar";

  const formSubmit = async (e) => {
    try {
      e.preventDefault();
      const formData = new FormData();
      console.log("logoImage");
      console.log(logoImage);
      if (logoImage) {
        formData.append("file", logoImage);
        formData.append("id", "6571c02cf75ff95a1dca6c1e");
      }
      console.log(formData);
      const response = await axios.post(`${url}/api/sites/logo/add`, formData);
      const json = await response;
      if (json.success) {
        toast.success({
          message: "Success",
          description: "Image uploaded Successfully",
          duration: 3,
        });
      } else {
        toast.error({
          message: "Failed",
          description: "failed to upload image",
          duration: 3,
        });
      }
    } catch (error) {
      console.log(error);
    }
  };
  const handleLogo = (e) => {
    setLogoImage(e.target.files[0]);
  };
  console.log(logoImage);
  return (
    <div className={styles.heading}>
      <div className="body-wrap">
        <section className={styles.sidebar_wrap}>
          <div className="container-fluid px-0">
            <div className="d-flex">
              <Sidebar />
              <div className="p-3 w-100 main__content">
                <Header2 isActive={isActive} toggleActive={toggleActive} />
                <div className="general-dashboard mt-5">
                  <div className="container-fluid">
                    <div className="dashboard-top-row add-new-top-row">
                      <div className="row">
                        <div className="col-lg-6 my-auto">
                          <div className="user-wrap user-back-wrap">
                            <Link to="/sites">
                              <div className="back-icon">
                                <img src={BackIcon} alt="" />
                              </div>
                            </Link>
                            <div className="user-back-det">
                              <h3>Upload Site Logo</h3>
                            </div>
                          </div>
                        </div>
                        <div className="col-lg-6 text-end"></div>
                      </div>
                    </div>
                    {/* row */}
                    <form onSubmit={formSubmit} encType="multipart/form-data">
                      <div className="form-box card-box">
                        <div className="row">
                          <div className="col-lg-6">
                            {/* input row */}
                            <div className="row">
                              <div className="col-xl-5 col-lg-5">
                                <div className="label-wrap">
                                  <h4>Image</h4>
                                  <p>Upload the image</p>
                                </div>
                              </div>
                              <div className="col-xl-7 col-lg-7">
                                <div className="inpt-wrap">
                                  <input
                                    type="file"
                                    name="file"
                                    onChange={handleLogo}
                                  />
                                </div>
                              </div>
                            </div>
                            {/* input row */}
                          </div>

                          <div className="col-lg-12 text-end">
                            <div className="form-btn-wrap mb-5 pb-5">
                              <button
                                type="submit"
                                className="btn btn-prim w-auto ms-5"
                                disabled={isLoading}
                              >
                                {isLoading ? "Uploading..." : "Upload"}
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </div>
  );
};
export default Logo;

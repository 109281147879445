import React, { useState } from "react";
import logo from "../../../assets/logo.png";
import "../../../simplelocker.css";
import Profile from "../../../images/question-profile.gif";
import "./style.css";
import { Link, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { logout } from "../../Redux/Authentication";
import IconLogout from "../../../assets/icons/icon-logout.svg";
import Dashboard from "../../../assets/icons/icon-doors.svg";

const Header = () => {
  const dispatch = useDispatch();
  let navigate = useNavigate();
  const handleUserLogout = async (e) => {
    const logouts = await localStorage.removeItem("token");
    dispatch(logout());
    navigate("/");
  };
  const [isDropdownVisible, setDropdownVisible] = useState(false);

  const handleImageClick = () => {
    setDropdownVisible(!isDropdownVisible);
  };

  const isAuthenticated = useSelector((state) => state.user);
  console.log(isAuthenticated);
  return (
    <header className="header__common container mt-2 mb-5 px-2 py-1 bg-primary rounded-pill">
      <div className="row align-items-center">
        <div className="col-6">
          <Link to={'/'} className="text-capitalize mx-4" style={{ color: "#012267" }}>
            <img height={30} src={logo} alt="logo" className="logo___img" />
          </Link>
        </div>
        <div className="col-6">
          <div className="">
            {!isAuthenticated && (
              <div className="py-1 d-flex align-items-center gap-2 justify-content-end">
                <Link
                  to={"/puzzle-login"}
                  className="btn text-white btn-sm rounded-pill"
                >
                  LOGIN
                </Link>
                <Link
                  to={"/puzzle-signup"}
                  className="btn btn-light btn-sm rounded-pill"
                  style={{ backgroundColor: "white" }}
                >
                  SIGN UP
                </Link>
              </div>
            )}
            {isAuthenticated && (

              <div className="d-flex justify-content-end align-items-center text-end">
                <div className="text-white p-0 mx-2 d-flex flex-column">
                  <span className="header__loggedUsername">
                    {isAuthenticated?.name}
                  </span>
                  <span className="header__loggedUseremail">
                    {isAuthenticated?.email}
                  </span>
                </div>
                <img
                  src={isAuthenticated?.image || Profile}
                  alt="logo"
                  className=" profile_____img"
                  height={60}
                  style={{ cursor: "pointer", width: "45px", height: "45px", padding: "3px", borderRadius: "50px", objectFit: "cover" }}
                  onClick={handleImageClick}
                />
                {isDropdownVisible && (
                  <div
                    style={{
                      position: "absolute",
                      top: "50px",
                      right: "50px",
                      border: "1px solid #ccc",
                      background: "#fff",
                    }}
                    className="rounded p-1"
                  >
                    <ul className="p-1 header__dropdownMenu">
                      <li
                        onClick={() => navigate("/user/dashboard")}
                        className="header__dropdownMenuItem px-4 d-flex align-items-center gap-2"
                      >
                        <img src={Dashboard} alt="icon" />
                        <span>Dashboard</span>
                      </li>
                      <li
                        onClick={handleUserLogout}
                        className="header__dropdownMenuItem px-4 d-flex align-items-center gap-2"
                      >
                        <img src={IconLogout} alt="icon" />
                        <span>Logout</span>
                      </li>
                    </ul>
                  </div>
                )}
              </div>
            )}
          </div>
        </div>
      </div>
    </header>
  );
};
export default Header;

// AnswerComponent.js
import React, { useEffect, useState } from "react";
// import { Question } from "@trivia-api/models";
import "./style.css";
import "../../puzzlestyle.css";
import { Link } from "react-router-dom";


export const Answer = ({
  question,
  index,
  onGuessAnswer,
  isCorrect,
  isFalse,
  currentQuestionIndex,
  gif,
  remainingTime,
  gametype
}) => {
  const [selectedAnswer, setSelectedAnswer] = useState(null);
  const [isMuted, setIsMuted] = useState(false);
  const [timeRemaining, setTimeRemaining] = useState(remainingTime);
  const handleToggleMute = async () => {
    {
      setIsMuted(!isMuted);
    }
  };
  useEffect(() => {
    // Reset timeRemaining to 10 seconds when the question changes
    setTimeRemaining(10);

    // Set up the timer when the question changes
    const timer = setInterval(() => {
      setTimeRemaining((prevTime) => prevTime - 1);
    }, 1000);

    // Clear the timer when the component unmounts or when moving to the next question
    return () => clearInterval(timer);
  }, [currentQuestionIndex]);
  const allAnswers = [
    question.correctAnswer,
    ...question.incorrectAnswers,
  ].sort((a, b) => (a < b ? -1 : 1));

  return (
    <div className="d-flex flex-column justify-content-center overflow-hidden ">

      <div className=" d-flex flex-row justify-content-end mt-0 " >
        <div
          className="bg-white text-black p-4 mx-auto container text-center"
          style={{
            maxWidth: "800px",
            clipPath: "polygon(1% 0%, 99% 0%, 100% 100%, 0% 100%)",
            borderRadius: "10px",

          }}
        >
          <h4 className="question-text">{question.question}</h4>


        </div>


      </div>
      <div className="d-flex gap-3 justify-content-center align-item-center mt-4">
        <h1 className="timer-text ">
          {currentQuestionIndex === 10
            ? currentQuestionIndex
            : currentQuestionIndex + 1}
          /10
        </h1>
        <div className="timer-border d-flex align-item-center">
          <div className="timer-body d-flex align-item-center">
            <div className="timer-style d-flex align-item-center">
              <h5 className="">{gif ? 0 : timeRemaining} </h5>
            </div>
          </div>
        </div>
        {(isCorrect && gametype==='coin') && (
          <div className="right___ans____header d-flex justify-content-center align-items-center">
            <div className="right___ans____body d-flex justify-content-center align-items-center">
              <span className="right___ans___number">+5</span>
            </div>
          </div>
        )}
        {(isFalse && gametype==='coin') && (
          <div className="wrong___ans____header d-flex justify-content-center align-items-center">
            <div className="wrong___ans____body d-flex justify-content-center align-items-center">
              <span className="wrong___ans___number">-5</span>
            </div>
          </div>
        )}
      </div>

      <div className="mx-auto mt-4">
        <div className="row row-cols-2 g-4 mx-auto">
          {allAnswers.map((answer, i) => (
            <div key={i} className="col">
              <div
                className={` w-100 m-1 text-black p-2 text-center rounded questions-back ${(isCorrect || isFalse) && answer == question.correctAnswer
                  ? "correct__answer"
                  : "questions-backColor"
                  } ${isFalse &&
                    answer != question.correctAnswer &&
                    i == selectedAnswer
                    ? "wrong__answer"
                    : "questions-backColor"
                  }`}
                onClick={() => {
                  if (!isCorrect && !isFalse) {
                    setSelectedAnswer(i);
                    onGuessAnswer(answer);
                  }
                }}
              >
                <p className="questions-css question-text">{answer}</p>
              </div>
            </div>
          ))}
        </div>
      </div>
      <div className="row mt-5 pb-5">
        <div
          className="col-xl-7 mx-auto p-3 text-center"
          style={{ backgroundColor: "#00C2FF" }}
        >
          Google AdSense size 970x90 px
        </div>
      </div>
    </div >
  );
};

// export default Answer;

import React from 'react'
import "../../puzzlestyle.css";
import Loading from "../../assets/Gift/man-Loader.gif"
import SpinnerLoading from "../../assets/Gift/Spinner-Loader.gif"

const Loader = () => {
    return (
        <div className='loader-container'>

            <div className='d-flex flex-column justify-content-center col-lg-12 col-sm-12 m-auto p-3 h-100 align-items-center '>
                <img src={Loading} alt='loader' />
            </div>
        </div>
    )
}

export default Loader
import React, { useEffect, useState } from "react";
import styles from "./style.module.css";
import Modal from "react-modal";
import Sidebar from "../partials/Sidebar";
import Header from "../partials/Header2";
import { Link, useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import profileImg from "../../images/editProfile.png";
import avatar from "../../images/avatar1.png";
import { useDispatch } from "react-redux";
import { loginSuccess } from "../Redux/Authentication";
import InputMask from "react-input-mask";
import { toast } from "react-toastify";
import { motion } from "framer-motion";
import ReactPaginate from "react-paginate";
import Swal from "sweetalert2";
import Delete from "../../assets/icons/delete.svg"
import Edit from "../../assets/icons/edit.svg"
import HashLoader from "react-spinners/ClipLoader";
const EditProfile = () => {
  const dispatch = useDispatch();
  const url = process.env.REACT_APP_APP_BACK_URL;
  const [data, setData] = useState([]);
  const isAuthenticated = useSelector((state) => state.user);
  const [ProLoading, setProLoading] = useState(false);
  const [PassLoading, setPassLoading] = useState(false);
  const [ImgLoading, setImgLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const itemsPerPage = 5;
  const [isNotFound, setIsNotFound] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isDisplay, setIsDisplay] = useState(false);
  const [isReadyModalOpen, setIsReadyModalOpen] = useState(false);
  const [currentAd, setCurrentAd] = useState({});
  const [winNumber, setWinningNumber] = useState({
    number: "",
  });

  const [credentials, setCredentials] = useState({
    name: isAuthenticated ? isAuthenticated.name : "",
    email: isAuthenticated ? isAuthenticated.email : "",
    current_password: "",
    new_password: "",
    confirm_password: "",
  });
  const [isHovered, setIsHovered] = useState(false);

  useEffect(() => {
    winNumberData();
  }, []);
  useEffect(() => {
    fetchData(credentials);
  }, [currentPage]);
  const fetchData = async (fetchCredentials) => {
    try {
      let headers = new Headers();
      headers.append("Content-Type", "application/json");
      headers.append("Access-Control-Allow-Origin", "*");
      headers.append("Access-Control-Allow-Credentials", "true");
      setIsLoading(true);
      setIsDisplay(false);
      setIsNotFound(false);
      const response = await fetch(`${url}/api/setting/ads/list`, {
        mode: "cors",
        method: "GET",
        headers: headers,
      });
      setIsLoading(false);
      if (!response.ok) {
        throw new Error("Network response was not ok");
      }
      const json = await response.json();
      if (json.ads.length > 0) {
        setIsDisplay(true);
        setData(json.ads);
      } else {
        setIsNotFound(true);
      }
      setTotalPages(json.totalPages);
    } catch (error) {
      console.error(error);
    }
  };
  const handleInput = (e) => {
    setCredentials({ ...credentials, [e.target.name]: e.target.value });
  };
  const [phone, setPhone] = useState(
    isAuthenticated ? isAuthenticated.phone : ""
  );
  const winNumberData = async () => {
    try {
      let headers = new Headers();
      headers.append("Content-Type", "application/json");
      headers.append("Access-Control-Allow-Origin", "*");
      headers.append("Access-Control-Allow-Credentials", "true");
      const response = await fetch(`${url}/api/setting/get/winning`, {
        mode: "cors",
        method: "GET",
        headers: headers,
      });
      const json = await response.json();
      setWinningNumber(json.number);
    } catch (error) {
      console.error(error);
    }
  };
  const handlePhoneInput = (e) => {
    setPhone(e.target.value);
  };

  const [profileImage, setProfileImg] = useState(
    isAuthenticated?.image || avatar
  );
  const [error, setError] = useState(null);
  const [uploadProgress, setUploadProgress] = useState(0);
  const [seletectedFile, setSelectedFile] = useState("");

  const handleFileSelect = async (e) => {
    setWinningNumber({ ...winNumber, [e.target.name]: e.target.value });
  };

  const winFormSubmit = async (e) => {
    try {
      e.preventDefault();
      let headers = new Headers();
      headers.append("Content-Type", "application/json");
      headers.append("Access-Control-Allow-Origin", "*");
      headers.append("Access-Control-Allow-Credentials", "true");
      setImgLoading(true);
      const response = await fetch(`${url}/api/setting/update/winning`, {
        mode: "cors",
        method: "POST",
        headers: headers,
        body: JSON.stringify({
          number: winNumber.number,
        }),
      });
      setImgLoading(false);

      const json = await response.json();
      if (json.success) {
        toast.success("Number Updated Successfully");
      } else {
        toast.error("failed to update number");
      }
    } catch (error) {
      console.log(error);
    }
  };
  const handleDelete = async (id) => {
    try {
      const result = await Swal.fire({
        title: "Are you sure?",
        text: "You won't be able to revert this!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Yes, delete it!",
        cancelButtonText: "No, cancel",
        reverseButtons: true,
      });
      if (result.isConfirmed) {
        let headers = new Headers();
        headers.append("Content-Type", "application/json");
        headers.append("Access-Control-Allow-Origin", "*");
        headers.append("Access-Control-Allow-Credentials", "true");
        const response = await fetch(`${url}/api/setting/delete/${id}`, {
          mode: "cors",
          method: "DELETE",
          headers: headers,
        });
        if (response.ok) {
          fetchData(credentials); // Refresh the records list after successful deletion
          Swal.fire("Deleted!", "Ad deleted successfully!", "success");
        } else {
          Swal.fire(
            "Error",
            "An error occurred while deleting the record.",
            "error"
          );
        }
      }
    } catch (error) {
      console.log("Error deleting record:", error);
      Swal.fire(
        "Error",
        "An error occurred while deleting the record.",
        "error"
      );
    }
  };
  const handlePageChange = (selectedPage) => {
    setCurrentPage(selectedPage.selected + 1); // react-paginate uses zero-based indexing
  };
  const [isActive, setIsActive] = useState(false);
  const toggleActive = () => {
    setIsActive(!isActive);
  };

  // Define the CSS classes based on the isActive state
  const divClassName = isActive ? "sidebar active" : "sidebar";
  return (
    <div className={styles.heading}>
      <div className="body-wrap">
        <section className={styles.sidebar_wrap}>
          <div className="container-fluid px-0">
            <div className="d-flex">
              <Sidebar />
              <div className="p-3 w-100 main__content">
                <Header isActive={isActive} toggleActive={toggleActive} />
                <div className="general-dashboard">
                  <div className="container-fluid">
                    <div className="mb-3">
                      <div className="row">
                        <div className="col-lg-6 my-auto">
                          <div className="user-wrap">
                            <h3>Ads Settings</h3>
                          </div>
                        </div>
                        <div className="col-lg-6 text-end"></div>
                      </div>
                    </div>
                    {/* row */}
                    <div className="row">
                      <div className="col-lg-9">
                        <div className="form-box card-box">
                          <div className="col-12 text-end">
                            <Link
                              to={'/add/question/ads'}
                              className="btn btn-prim w-auto"
                            >
                              Add
                            </Link>
                          </div>
                          {/* <h6 style={{ color: "#170187", fontWeight: "700" }}>
                             P
                          </h6> */}
                          <div className="table-wrap">
                            <div className="row">
                              <div className="col-12">
                                <div className={styles.card_box}>
                                  <div className={styles.tables_wrap}>
                                    <div className="table-responsive">
                                      <table className="table align-middle">
                                        <thead>
                                          <tr>
                                            <th style={{ fontWeight: "700", fontSize: "12px" }}>Question</th>
                                            <th style={{ fontWeight: "700", fontSize: "12px" }}>Time</th>
                                            <th style={{ fontWeight: "700", fontSize: "12px" }}>Link</th>
                                            <th style={{ fontWeight: "700", fontSize: "12px" }}>File</th>
                                            <th style={{ fontWeight: "700", fontSize: "12px" }}>Description</th>
                                            <th></th>
                                          </tr>
                                        </thead>
                                        <tbody>
                                          {isLoading && (
                                            <>
                                              <tr>
                                                <td
                                                  colSpan={5}
                                                  className="text-center"
                                                  style={{
                                                    background: "transparent",
                                                  }}
                                                >
                                                  <HashLoader
                                                    color={`#0b0d41`}
                                                    loading={isLoading}
                                                    size={40}
                                                    aria-label="Loading Spinner"
                                                    data-testid="loader"
                                                  />
                                                </td>
                                              </tr>
                                            </>
                                          )}
                                          {isDisplay &&
                                            data.map((item, i) => (
                                              <motion.tr
                                                key={item._id}
                                                initial={{ opacity: 0 }}
                                                animate={{ opacity: 1 }}
                                                transition={{
                                                  duration: 1.2,
                                                  delay: i * 0.1,
                                                }}
                                              >
                                                <td>{item.number}</td>
                                                <td>{item.time}</td>
                                                <td className="text-lowercase">{item.link}</td>
                                                <td>
                                                  <img
                                                    height={50}
                                                    width={50}
                                                    src={item.file}
                                                    style={{
                                                      borderRadius: "4px",
                                                    }}
                                                  />
                                                </td>
                                                <td>
                                                  <span dangerouslySetInnerHTML={{ __html: `${item.description.slice(0, 23)}${item.description.length > 23 ? '...' : ''}` }} />
                                                </td>

                                                <td>
                                                  <Link
                                                    onClick={() =>
                                                      handleDelete(item._id)
                                                    }
                                                  >
                                                    <img src={Delete} alt="Delete" />
                                                  </Link>
                                                </td>
                                              </motion.tr>
                                            ))}
                                          {isNotFound && (
                                            <tr>
                                              <td
                                                colSpan={6}
                                                className="text-center"
                                              >
                                                <p className="text-center" style={{ color: "red" }}>No Data Found</p>

                                              </td>
                                            </tr>
                                          )}
                                        </tbody>
                                      </table>
                                    </div>
                                    <div className="table-num">
                                      {data.length > 0 && (
                                        <ReactPaginate
                                          previousLabel={"<"}
                                          nextLabel={">"}
                                          breakLabel={"..."}
                                          pageCount={totalPages}
                                          forcePage={currentPage - 1} // react-paginate uses zero-based indexing
                                          onPageChange={handlePageChange}
                                          containerClassName={" dig-num"}
                                          pageClassName={"dig-num"}
                                          previousClassName={"num-btns"}
                                          nextClassName={"num-btns"}
                                          disabledClassName={
                                            "pagination-disabled"
                                          }
                                          activeClassName={"pagination-active"}
                                        />
                                      )}
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div className="col-lg-3">
                        <form onSubmit={winFormSubmit}>
                          <div className="form-box card-box">
                            <h6
                              style={{ color: "#170187", fontWeight: "700" }}
                              className="mb-5"
                            >
                              Enter Number of correct answers
                            </h6>
                            <div className="inpt-wrap mt-2 ">
                              <input
                                type="number"
                                className="form-control"
                                name="number"
                                value={winNumber.number}
                                placeholder="Winning number"
                                onChange={handleFileSelect}
                                required
                              />
                            </div>

                            <div className="col-12 text-end">
                              <button
                                type="submit"
                                className="btn btn-prim w-auto"
                                disabled={ImgLoading}
                              >
                                Update
                              </button>
                            </div>
                          </div>
                        </form>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
      <Modal
        isOpen={isReadyModalOpen}
        contentLabel="Example Modal"
        className="d-flex flex-column justify-content-center p-3 col-lg-4 m-auto mt-5 h-100 align-items-center "
        shouldCloseOnOverlayClick={false}
        style={{
          content: {
            fontFamily: "Arial",
            fontWeight: "800",
            fontSize: "30px",
          },
          overlay: {
            backgroundColor: "rgba(0, 0, 0, 0.5)", // Set the overlay background color
          },
        }}
      >
        <div
          className="p-3 w-sm-50 h-sm-50 col-10 rounded-3"
          style={{ background: "#012269" }}
        >
          <div className="d-flex flex-row">
            <h4 className="win-heading ">Read More</h4>

          </div>
          <div
            className="p-4 rounded-2 w-sm-25 " //h-75
            style={{ background: "#031947" }}
          >

            <div className="d-flex flex-row gap-5 justify-content-center align-items-center mt-4">
              <span dangerouslySetInnerHTML={{
                __html: currentAd.description
              }} />
            </div>
          </div>

        </div>
      </Modal>
    </div>
  );
};

export default EditProfile;

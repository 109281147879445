import React, { useEffect, useState } from "react";
import styles from "./style.module.css";
import Header from "../partials/Header";
import login1 from "../../images/login1.svg";
import CalcLeft from "../../images/calc-left.png";
import CalDel from "../../images/cal-del.png";
import { useSelector } from "react-redux";
import { loginSuccess } from "../Redux/Authentication";
import { Link, useNavigate, useParams } from "react-router-dom";
import { useDispatch } from "react-redux";
import logo from "../../images/logo.svg";
import { toast } from "react-toastify";
const site_logo = localStorage.getItem("site_logo");
const url = process.env.REACT_APP_APP_BACK_URL;
const baseUrl = process.env.REACT_APP_BASE_URL;
const LoginPage = () => {
  let navigate = useNavigate();
  const dispatch = useDispatch();
  const isAuthenticated = useSelector((state) => state.user);
  let { slug } = useParams();
  const [isLoading, setIsLoading] = useState(false);
  const [getSite, setSite] = useState({});

  const [credentials, setCredentials] = useState({ name: "" });
  const handleInput = (e) => {
   
    setCode((prevCode) =>  e.target.value);
    // setCredentials({ ...credentials, [e.target.name]: prevCode + e.target.value });
  };
  const [code, setCode] = useState("");

  // Function to handle digit button click
  const handleDigitClick = (digit) => {
    setCode((prevCode) => prevCode + digit);
  };
  const handleDeleteDigit = () => {
    setCode((prevCode) => prevCode.slice(0, -1));
  };
  const formSubmit = async () => {
    try {
      let headers = new Headers();
      headers.append("Content-Type", "application/json");
      headers.append("Access-Control-Allow-Origin", "*");
      headers.append("Access-Control-Allow-Credentials", "true");
      setIsLoading(true);

      const response = await fetch(`${url}/api/auth/code`, {
        mode: "cors",
        method: "POST",
        headers: headers,
        body: JSON.stringify({
          name: code,
          slug: slug,
        }),
      });
      setIsLoading(false);

      const json = await response.json();

      if (json.success) {
        const user = json.user;
        const expirationTime = Math.floor(Date.now() / 1000) + 7200;
        localStorage.setItem("token", json.authToken);
        localStorage.setItem("token-expiration", expirationTime);
        dispatch(loginSuccess(json.user));
        setCode('');
        // toast.success(" Successfully login Successfully");
        if (user.role === "user") {
          navigate(`/${slug}/dashboard`);
        } else if (user.role === "admin") {
          navigate(`/${slug}/timer`);
        } else {
          toast.error("Enter valid pin");
        }
      } else {
        toast.error(json.error);
      }
    } catch (error) {
      setIsLoading(false);
      toast.error(error.message);
      console.log(error);
    }
  };

  const iocnStyle = {
    display: isLoading ? "inline-block" : "none",
  };

  const [isActive, setIsActive] = useState(false);

  // Function to toggle the active state
  const toggleActive = () => {
    setIsActive(!isActive);
  };

  // Define the CSS classes based on the isActive state
  const divClassName = isActive ? "sidebar active" : "sidebar";
  return (
    <div className={styles.heading}>
      <div className="body-wrap">
        <div className="container-fluid px-0">
          <Header />
          <div className={styles.body_wrap}>
            <div className="login-container">
             
              <div className="calc-wrap-main">
                <div className="row text-center">
                  
                  <div className="col-lg-12">
                    <div className="calc-wrap">
                    
                      <div className="cal-screen">
                        <input
                          type="number"
                          id="result"
                          onChange={handleInput}
                          value={code}
                         placeholder="Enter Pin"
                        />
                      </div>
                      <div className="keys-wrap">
                        <div className="row ">
                          <div className="col-4">
                            <button
                              className="btn-key"
                              onClick={() => handleDigitClick("1")}
                            >
                              <i>1</i>
                            </button>
                          </div>
                          <div className="col-4">
                            <button
                              className="btn-key"
                              onClick={() => handleDigitClick("2")}
                            >
                              <i>2</i> <span>ABC</span>
                            </button>
                          </div>
                          <div className="col-4">
                            <button
                              className="btn-key"
                              onClick={() => handleDigitClick("3")}
                            >
                              <i>3</i> <span>ABC</span>
                            </button>
                          </div>
                          <div className="col-4">
                            <button
                              className="btn-key"
                              onClick={() => handleDigitClick("4")}
                            >
                              <i>4</i> <span>ABC</span>
                            </button>
                          </div>
                          <div className="col-4">
                            <button
                              className="btn-key"
                              onClick={() => handleDigitClick("5")}
                            >
                              <i>5</i> <span>ABC</span>
                            </button>
                          </div>
                          <div className="col-4">
                            <button
                              className="btn-key"
                              onClick={() => handleDigitClick("6")}
                            >
                              <i>6</i> <span>ABC</span>
                            </button>
                          </div>
                          <div className="col-4">
                            <button
                              className="btn-key"
                              onClick={() => handleDigitClick("7")}
                            >
                              <i>7</i> <span>ABC</span>
                            </button>
                          </div>
                          <div className="col-4">
                            <button
                              className="btn-key"
                              onClick={() => handleDigitClick("8")}
                            >
                              <i>8</i> <span>ABC</span>
                            </button>
                          </div>
                          <div className="col-4">
                            <button
                              className="btn-key"
                              onClick={() => handleDigitClick("9")}
                            >
                              <i>9</i> <span>ABC</span>
                            </button>
                          </div>
                          <div className="col-4">
                            <div className="empty-div-cal"></div>
                          </div>

                          <div className="col-4">
                            <button
                              className="btn-key"
                              onClick={() => handleDigitClick("0")}
                            >
                              <i>0</i>
                            </button>
                          </div>
                          <div className="col-4">
                            <button
                              className="btn-delt"
                              onClick={handleDeleteDigit}
                            >
                              <img src={CalDel} alt="" />{" "}
                            </button>
                          </div>
                        </div>
                      </div>
                      <div className="cont-btn-wrap">
                        <button className="continue-btn"  disabled={isLoading} onClick={formSubmit}>
                        <i className="fa fa-spinner fa-spin" style={iocnStyle}></i>{" "}
                          Continue
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default LoginPage;

import React from "react";
import styles from "./style.module.css";
import Header from "../partials/Header";
import yeslocker from "../../images/yes-locker.png";
import nolocker from "../../images/no-lockers.png";

const DashboardScr = () => {
  return (
    <div className={styles.heading}>
      <div className="body-wrap">
        <div className="container-fluid px-0">
          <Header />
          <div className="container text-center px-0">
            <div className="lockers-dashboard">
              <div className="row">
                <div className="col-lg-3 col-sm-6">
                  <div className="lockers">
                    <div className="locker-number">01</div>
                    <img src={yeslocker} alt="yes" />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DashboardScr;

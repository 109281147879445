import React, { useEffect, useState } from "react";
import Header2 from "../../Components/partials/Header2";
import Sidebar from "../../Components/partials/Sidebar";
import BackIcon from "../../images/icon-back.png";
import UploadIcon from "../../images/icon-upload.png";
import { useSelector } from "react-redux";
import styles from "./style.module.css";
import { Link, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
const Add = () => {
  const isAuthenticated = useSelector((state) => state.user);
  const url = process.env.REACT_APP_APP_BACK_URL;
  const [appendedHTML, setAppendedHTML] = useState("");
  const [amount, setAmount] = useState(false);
  const [memes, setMemes] = useState(false);
  const [coin, setCoins] = useState(false);
  const [memesData, setmemesData] = useState([]);

  useEffect(() => {
    getMemes();
  }, []);
  const [credentials, setCredentials] = useState({
    name: "",
    memes: "",
    amount: "",
    coins: "",
    type: "",
  });
  const [isLoading, setIsLoading] = useState(false);
  const handleInput = (e) => {
    setCredentials({ ...credentials, [e.target.name]: e.target.value });
  };
  const handleType = (e) => {
    if (e.target.value === "money") {
      setAmount(true);
      setMemes(false);
      setCoins(false);
    }
    if (e.target.value === "memes") {
      setMemes(true);
      setAmount(false);
      setCoins(false);
    }
    if (e.target.value === "coins") {
      setMemes(false);
      setAmount(false);
      setCoins(true);
    }
    setCredentials({ ...credentials, [e.target.name]: e.target.value });
  };
  const handleInputSlug = (e) => {
    const alphanumericRegex = /^[a-zA-Z0-9]*$/;

    // Check if the entered value matches the pattern
    if (alphanumericRegex.test(e.target.value) || e.target.value === "") {
      setCredentials({ ...credentials, [e.target.name]: e.target.value });
    }
  };
  const formSubmit = async (e) => {
    e.preventDefault();
    let headers = new Headers();
    headers.append("Content-Type", "application/json");
    headers.append("Access-Control-Allow-Origin", "*");
    headers.append("Access-Control-Allow-Credentials", "true");
    setIsLoading(true);
    const response = await fetch(`${url}/api/prizes/add`, {
      mode: "cors",
      method: "POST",
      headers: headers,
      body: JSON.stringify({
        name: credentials.name,
        type: credentials.type,
        amount: credentials.amount,
        coins: credentials.coins,
        memes: credentials.memes,
      }),
    });
    setIsLoading(false);
    const json = await response.json();
    if (json.success) {
      setCredentials({
        name: "",
        type: "",
        amount: "",
        memes: "",
        coins: "",
      });
      toast.success("Prize Created Successfully");
    } else {
      setIsLoading(false);
      toast.error(json.error);
    }
  };
  const getMemes = async (e) => {
    const response = await fetch(`https://api.imgflip.com/get_memes`);
    const memes = await response.json();
    setmemesData(memes.data.memes);
  };
  const [isActive, setIsActive] = useState(false);
  // Function to toggle the active state
  const toggleActive = () => {
    setIsActive(!isActive);
  };
  // Define the CSS classes based on the isActive state
  const divClassName = isActive ? "sidebar active" : "sidebar";
  return (
    <div className={styles.heading}>
      <div className="body-wrap">
        <section className={styles.sidebar_wrap}>
          <div className="container-fluid px-0">
            <div className="d-flex">
              <Sidebar />
              <div className="p-3 w-100 main__content">
                <Header2 isActive={isActive} toggleActive={toggleActive} />
                <div className="general-dashboard mt-5">
                  <div className="container-fluid">
                    <div className="dashboard-top-row add-new-top-row">
                      <div className="row">
                        <div className="col-lg-6 my-auto">
                          <div className="user-wrap user-back-wrap">
                            <Link to="/prizes/list">
                              <div className="back-icon">
                                <img src={BackIcon} alt="" />
                              </div>
                            </Link>
                            <div className="user-back-det">
                              <h3>Create Prize</h3>
                              <p>Add new prize in the system</p>
                            </div>
                          </div>
                        </div>
                        <div className="col-lg-6 text-end"></div>
                      </div>
                    </div>
                    {/* row */}
                    <form onSubmit={formSubmit}>
                      <div className="form-box card-box">
                        <div className="row">
                          <div className="col-lg-6">
                            {/* input row */}
                            <div className="row">
                              <div className="col-xl-5 col-lg-5">
                                <div className="label-wrap">
                                  <h4>Prize Name</h4>
                                  <p>Enter the name of the prize</p>
                                </div>
                              </div>
                              <div className="col-xl-7 col-lg-7">
                                <div className="inpt-wrap">
                                  <input
                                    type="text"
                                    className="form-control"
                                    id="sitec"
                                    placeholder="Prize Name"
                                    name="name"
                                    value={credentials.name}
                                    onChange={handleInput}
                                    required
                                  />
                                </div>
                              </div>
                            </div>
                            {/* input row */}
                          </div>
                          <div className="col-lg-6">
                            {/* input row */}
                            <div className="row">
                              <div className="col-xl-5 col-lg-5">
                                <div className="label-wrap">
                                  <h4>Prize Type</h4>
                                  <p>Select prize type</p>
                                </div>
                              </div>
                              <div className="col-xl-7 col-lg-7">
                                <div className="inpt-wrap">
                                  <select
                                    name="type"
                                    value={credentials.type}
                                    onChange={handleType}
                                    required
                                  >
                                    <option value="">Choose Type</option>
                                    <option value="memes">Memes</option>
                                    <option value="money">Money</option>
                                    <option value="coins">Coins</option>
                                  </select>
                                </div>
                              </div>
                            </div>
                            {/* input row */}
                          </div>
                          {amount && (
                            <>
                              <div className="col-lg-6">
                                {/* input row */}
                                <div className="row">
                                  <div className="col-xl-5 col-lg-5">
                                    <div className="label-wrap">
                                      <h4>Amount</h4>
                                      <p>Enter the number of the amount</p>
                                    </div>
                                  </div>
                                  <div className="col-xl-7 col-lg-7">
                                    <div className="inpt-wrap">
                                      <input
                                        type="text"
                                        className="form-control"
                                        id="amount"
                                        placeholder="Enter Amount"
                                        name="amount"
                                        value={credentials.amount}
                                        onChange={handleInput}
                                      />
                                    </div>
                                  </div>
                                </div>
                                {/* input row */}
                              </div>
                            </>
                          )}
                          {coin && (
                            <>
                              <div className="col-lg-6">
                                {/* input row */}
                                <div className="row">
                                  <div className="col-xl-5 col-lg-5">
                                    <div className="label-wrap">
                                      <h4>Coins</h4>
                                      <p>Enter the number of the coins</p>
                                    </div>
                                  </div>
                                  <div className="col-xl-7 col-lg-7">
                                    <div className="inpt-wrap">
                                      <input
                                        type="text"
                                        className="form-control"
                                        id="coins"
                                        placeholder="Enter Coins.."
                                        name="coins"
                                        value={credentials.coins}
                                        onChange={handleInput}
                                      />
                                    </div>
                                  </div>
                                </div>
                                {/* input row */}
                              </div>
                            </>
                          )}
                          {memes && (
                            <div className="col-lg-6">
                              {/* input row */}
                              <div className="row">
                                <div className="col-xl-5 col-lg-5">
                                  <div className="label-wrap">
                                    <h4>Memes</h4>
                                    <p>Enter the number of the amount</p>
                                  </div>
                                </div>
                                <div className="col-xl-7 col-lg-7">
                                  <div className="inpt-wrap">
                                    <select
                                      name="memes"
                                      value={credentials.memes}
                                      onChange={handleType}
                                      required
                                    >
                                      <option value="">Choose Memes</option>
                                      {memesData.map((item, i) => (
                                        <option key={i} value={item.url}>
                                          {item.name}
                                        </option>
                                      ))}
                                    </select>
                                    {credentials.memes && (
                                      <img
                                        className="mt-3"
                                        src={credentials.memes}
                                        height={150}
                                        width={150}
                                        alt="Selected Meme"
                                      />
                                    )}
                                  </div>
                                </div>
                              </div>
                              {/* input row */}
                            </div>
                          )}

                          <div className="col-lg-12 text-end">
                            <div className="form-btn-wrap mb-5 pb-5">
                              <a
                                href=""
                                className="btn btn-prim btn-transparent w-auto"
                              >
                                Reset Form
                              </a>
                              <button
                                type="submit"
                                className="btn btn-prim w-auto ms-5"
                                disabled={isLoading}
                              >
                                {isLoading ? "Creating..." : "create prize"}
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </div>
  );
};
export default Add;

import React, { useState } from "react";
import logo from "../../assets/logo.png";
import "../../simplelocker.css";
import "../../index.css";
import Profile from "../../images/question-profile.gif";
import { Link, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { logout } from "../Redux/Authentication";
import IconLogout from "../../assets/icons/icon-logout.svg";
import Dashboard from "../../assets/icons/icon-doors.svg";

const Header2 = () => {
  const dispatch = useDispatch();
  let navigate = useNavigate();
  const [isDropdownVisible, setDropdownVisible] = useState(false);
  const handleUserLogout = async (e) => {
    const logouts = await localStorage.removeItem("token");
    dispatch(logout());
    navigate("/");
  };

  const handleImageClick = () => {
    setDropdownVisible(!isDropdownVisible);
  };

  const isAuthenticated = useSelector((state) => state.user);
  return (
    <div>
      <header className="px-5">
        <div className="row my-3">
          <div className="prof-sett">
            <div className="col-lg-6">
              <div
                className="main-heading2 text-capitalize"
                style={{ color: "#012267", fontSize: "1000" }}
              >
                <Link to={'/'}>
                  <img src={logo} alt="logo" />
                </Link>
              </div>
            </div>
            <div className="col-lg-6 ">
              <div className="prof-wrap">
                {!isAuthenticated && (
                  <>
                    <Link to={"/puzzle-login"} className="btn text-white login-buttn" style={{ fontWeight: "700" }}>
                      LOGIN
                    </Link>
                    <Link
                      to={"/puzzle-signup"}
                      className="btn text-white sign-up-buttn"
                      style={{ fontWeight: "700" }}

                    >
                      SIGN UP
                    </Link>
                  </>
                )}
                {isAuthenticated && (
                  <div className="position-relative">
                    <img
                      src={isAuthenticated?.image || Profile}
                      alt="logo"
                      height={60}
                      style={{ cursor: "pointer", width: "50px", height: "50px", borderRadius: "50px", objectFit: "cover" }}
                      onClick={handleImageClick}
                    />
                    {isDropdownVisible && (
                      <div
                        style={{
                          position: "absolute",
                          top: "40px",
                          right: "50px",
                          border: "1px solid #ccc",
                          background: "#fff",
                        }}
                        className="rounded p-1"
                      >
                        <ul className="p-1 header__dropdownMenu">
                          <li
                            onClick={() => navigate("/user/dashboard")}
                            className="header__dropdownMenuItem px-4 d-flex align-items-center gap-2"
                          >
                            <img src={Dashboard} alt="icon" />
                            <span>Dashboard</span>
                          </li>
                          <li
                            onClick={handleUserLogout}
                            className="header__dropdownMenuItem px-4 d-flex align-items-center gap-2"
                          >
                            <img src={IconLogout} alt="icon" />
                            <span>Logout</span>
                          </li>
                        </ul>
                      </div>
                    )}
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </header>
    </div>
  );
};
export default Header2;

import React, { useEffect, useState, useRef } from "react";
import Modal from "react-modal";
import styles from "./style.module.css";
import Header from "../partials/header/header";
import Loader from "../partials/Loader";
import { useSelector } from "react-redux";
import yeslocker from "../../images/new images/Default-Locker.png";
import openlocker from "../../images/new images/Selected-Locker.png";
import logo from "../../assets/logo-dark.png";
import "sweetalert2/dist/sweetalert2.min.css";
import { toast } from "react-toastify";
import { Link, useNavigate, useParams } from "react-router-dom";
import backgroundMusic from "../../assets/audio/start_question.mp3";
import backgroundMusicClick from "../../assets/audio/click.mp3";
import memesMusicClick from "../../assets/audio/wahwah.mp3";
import Firework1 from "../../assets/Gift/firework3.gif";
import Close from "../../assets/icons/close_btn.svg";
import Tick from "../../assets/icons/Tick.svg";
import Home from "../../assets/icons/Home.svg";
import Retry from "../../assets/icons/Retry.svg";
import GiftBox from "../../assets/icons/gift_img.svg";
import YellowStars from "../../assets/yellow-star.png";

import Play from "../../assets/icons/Play.svg";
import { useDispatch } from "react-redux";
import { loginSuccess } from "../Redux/Authentication";
const url = process.env.REACT_APP_APP_BACK_URL;
const base_url = process.env.REACT_APP_BASE_URL;
const DashboardScr = () => {
  const isAuthenticated = useSelector((state) => state.user);
  const dispatch = useDispatch();
  let navigate = useNavigate();
  const { id } = useParams();
  let slug = "lockergame";
  const [loader, setLoader] = useState(true);
  const [isLoading, setIsLoading] = useState(false);
  const [isLoading1, setIsLoading1] = useState(false);
  const [isDisplay, setIsDisplay] = useState(false);
  const [islogin, setIslogin] = useState(false);
  const [dashboard, setDashboard] = useState(true);
  const [issingup, setIsSignup] = useState(false);
  const [backgroundAudio] = useState(new Audio(backgroundMusic));
  const [backgroundAudioClick] = useState(new Audio(backgroundMusicClick));
  const [backgroundAudioMemes] = useState(new Audio(memesMusicClick));
  const [isGameEnd, setIsGameEnd] = useState(false);
  const [prize, setPrize] = useState("");
  const [coin, setCoin] = useState("");
  const [prizeType, setPrizeType] = useState("");
  const [getWinner, setWinerId] = useState("");
  const [getLocker, setLocker] = useState([]);
  const [amounts, setAmounts] = useState([]);
  const [selectedLocker, setselectedLocker] = useState("");
  const [selectedLockerNumber, setselectedLockerNumber] = useState("");
  const [isModalOpen2, setIsModalOpen2] = useState(false);
  const [isModalOpen3, setIsModalOpen3] = useState(false);
  const [isModalOpen4, setIsModalOpen4] = useState(false);
  const [isModalOpen5, setIsModalOpen5] = useState(false);
  const [isReadyModalOpen, setIsReadyModalOpen] = useState(false);

  useEffect(() => {
    return () => {
      // Clean up: Stop the background music when the component unmounts
      backgroundAudio.pause();
      backgroundAudio.currentTime = 0;
    };
  }, [backgroundAudio]);

  useEffect(() => {
    if (isGameEnd) {
      backgroundAudio.pause();
      backgroundAudio.currentTime = 0;
    }
  }, [isGameEnd, backgroundAudio]);
  const [credentials, setCredentials] = useState({ email: "", password: "" });
  const [credentials1, setCredentials1] = useState({
    name: "",
    email: "",
    password: "",
    password_con: "",
  });
  const handleInput = (e) => {
    setCredentials({ ...credentials, [e.target.name]: e.target.value });
  };
  const handleInput1 = (e) => {
    setCredentials1({ ...credentials1, [e.target.name]: e.target.value });
  };
  const signUp = () => {
    setIslogin(false);
    setIsSignup(true);
  };
  const login = () => {
    setIslogin(true);
    setIsSignup(false);
  };
  const claimPrize = () => {
    setIslogin(true);
    setDashboard(false);
    setIsModalOpen2(false);
  };
  useEffect(() => {
    setLoader(true);
    const timerId = setTimeout(() => {
      setLoader(false);
      userPrize();
    }, 6000); // 3000 milliseconds = 3 seconds
    return () => clearTimeout(timerId);
  }, []);
  const handlePlay = () => {
    setIsModalOpen4(false);
    setIsModalOpen5(false);
    siteLockers();
  };
  // gell user prize
  const userPrize = async () => {
    try {
      let headers = new Headers();
      headers.append("Content-Type", "application/json");
      headers.append("Access-Control-Allow-Origin", "*");
      headers.append("Access-Control-Allow-Credentials", "true");
      const response = await fetch(
        `${url}/api/prizes/winner/prize/status/${id}`,
        {
          mode: "cors",
          method: "GET",
          headers: headers,
        }
      );
      const json = await response.json();

      if (json.success) {
        setAmounts(json.amounts);
        if (json.game_type === "cash") {
          setIsModalOpen4(true);
        } else {
          setIsModalOpen5(true);
        }

        // siteLockers();
        backgroundAudio.pause();
        backgroundAudio.currentTime = 0;
        // backgroundAudio.play();
      } else {
        toast.error(json.error);
        navigate("/question-page");
      }
    } catch (error) {
      toast.error(error);
    }
  };
  // get all site
  const siteLockers = async () => {
    try {
      let headers = new Headers();
      headers.append("Content-Type", "application/json");
      headers.append("Access-Control-Allow-Origin", "*");
      headers.append("Access-Control-Allow-Credentials", "true");
      const response = await fetch(`${url}/api/sites/lockers/${slug}`, {
        mode: "cors",
        method: "GET",
        headers: headers,
      });
      const json = await response.json();

      if (json.success) {
        setIsDisplay(true);
        setLocker(json.lockers);
      } else {
        toast.error(json.error);
      }
    } catch (error) {
      toast.error(error);
    }
  };
  // open locker
  const openLocker = async (locker_id) => {
    try {
      let headers = new Headers();
      headers.append("Content-Type", "application/json");
      headers.append("Access-Control-Allow-Origin", "*");
      headers.append("Access-Control-Allow-Credentials", "true");
      setIsLoading(true);
      const response = await fetch(`${url}/api/prizes/open/locker`, {
        mode: "cors",
        method: "POST",
        headers: headers,
        body: JSON.stringify({
          id: id,
          user_id: isAuthenticated ? isAuthenticated._id : "",
          locker_id: locker_id,
        }),
      });

      const json = await response.json();
      if (json.success) {
        setWinerId(json.winer._id);
        dispatch(loginSuccess(json.user));
        if (json.prizes.type === "memes") {
          setIsModalOpen3(true);
          setPrize(json.prizes.memes);
          deleteLockerPrize();
        } else {
          setPrizeType(json.prizes.type);
          setIsModalOpen2(true);
          setCoin(json.prizes.coin);
          setPrize(json.prizes.amount);
        }
      } else {
        toast.error(json.error);
      }
      backgroundAudioMemes.play();
      setIsLoading(false);
      // siteLockers();
    } catch (error) {
      toast.error(error);
    }
  };
  // delete current game locker prizes
  const deleteLockerPrize = async (id) => {
    try {
      let headers = new Headers();
      headers.append("Content-Type", "application/json");
      headers.append("Access-Control-Allow-Origin", "*");
      headers.append("Access-Control-Allow-Credentials", "true");
      const response = await fetch(`${url}/api/prizes/delete/locker/prizes`, {
        mode: "cors",
        method: "POST",
        headers: headers,
        body: JSON.stringify({
          game: id,
        }),
      });

      const json = await response.json();
    } catch (error) {
      toast.error(error.message);
    }
  };
  const handleConfirm = () => {
    openLocker(selectedLocker);
    setIsReadyModalOpen(false);
    // setselectedLocker("");
  };
  const displayOpen = async (relay, id) => {
    backgroundAudioClick.play();
    setselectedLockerNumber(relay);
    setIsReadyModalOpen(true);
    setselectedLocker(id);
  };

  const closeModal = () => {
    setIsReadyModalOpen(false);
    setselectedLocker("");
  };

  const formSubmit = async (e) => {
    try {
      e.preventDefault();
      let headers = new Headers();
      headers.append("Content-Type", "application/json");
      headers.append("Access-Control-Allow-Origin", "*");
      headers.append("Access-Control-Allow-Credentials", "true");

      setIsLoading(true);
      const prize = localStorage.getItem("prize");
      const response = await fetch(`${url}/api/auth/login`, {
        mode: "cors",
        method: "POST",
        headers: headers,
        body: JSON.stringify({
          email: credentials.email,
          prize: getWinner,
          password: credentials.password,
          base_url: base_url,
        }),
      });
      setIsLoading(false);

      const json = await response.json();
      if (json.success) {
        const user = json.user;
        const expirationTime = Math.floor(Date.now() / 1000) + 7200;
        localStorage.setItem("token", json.authToken);
        localStorage.setItem("token-expiration", expirationTime);
        dispatch(loginSuccess(json.user));
        setIslogin(false);
        setIsSignup(false);
        navigate("/user/dashboard");
        // localStorage.removeItem("prize");

        toast.success(" Successfully login Successfully");
      } else {
        toast.error(json.error);
      }
    } catch (error) {
      setIsLoading(false);
      toast.error(error.message);
    }
  };
  const formSubmit1 = async (e) => {
    try {
      e.preventDefault();
      if (credentials1.password === credentials1.password_con) {
        let headers = new Headers();
        headers.append("Content-Type", "application/json");
        headers.append("Access-Control-Allow-Origin", "*");
        headers.append("Access-Control-Allow-Credentials", "true");

        setIsLoading1(true);
        const prize = localStorage.getItem("prize");
        const response = await fetch(`${url}/api/auth/createUser`, {
          mode: "cors",
          method: "POST",
          headers: headers,
          body: JSON.stringify({
            name: credentials1.name,
            email: credentials1.email,
            password: credentials1.password,
            status: "active",
            prize: getWinner,
          }),
        });
        setIsLoading1(false);

        const json = await response.json();
        if (json.success) {
          const user = json.user;
          const expirationTime = Math.floor(Date.now() / 1000) + 7200;
          localStorage.setItem("token", json.authToken);
          localStorage.setItem("token-expiration", expirationTime);
          // socket.emit("login", json.user._id);
          dispatch(loginSuccess(json.user));
          localStorage.removeItem("prize");
          setIslogin(false);
          setIsSignup(false);
          navigate("/user/dashboard");
          toast.success("Sign Up Successfully");
        } else {
          toast.error(json.error);
        }
      } else {
        setIsLoading1(false);
        toast.error("Password not match");
      }
    } catch (error) {
      setIsLoading1(false);
      toast.error(error.message);
    }
  };
  const iocnStyle = {
    display: isLoading ? "inline-block" : "none",
  };
  const iocnStyle1 = {
    display: isLoading1 ? "inline-block" : "none",
  };
  return (
    <div className={styles.heading}>
      <div className="body-wrap">
        <div className="container-fluid px-0">
          {loader ? (
            <Loader />
          ) : (
            <>
              <Header />
              <div className="dashboard-lockers" style={{ marginTop: "-15px" }}>
                <div className="container-fluid-locker d-flex flex-column justify-content-center align-items-baseline col-8 text-center ">
                  <>
                    {dashboard && (
                      <>
                        <div className="row gap-2 locker-row  overflow-hidden">
                          {getLocker.map((locker, index) => (
                            <div
                              key={locker._id}
                              className={`col-1-7  ${isLoading && selectedLocker !== locker._id
                                ? "loading-disabled"
                                : ""
                                }`}
                            >
                              <div className="lockers">
                                {selectedLocker === locker._id ? (
                                  <>
                                    <span className="locker-num-open">
                                      {index < 9
                                        ? "0" + locker.relay
                                        : locker.relay}
                                    </span>
                                    <img src={openlocker} alt="yes" />
                                  </>
                                ) : (
                                  <>
                                    <span className="locker-num">
                                      {index < 9
                                        ? "0" + locker.relay
                                        : locker.relay}
                                    </span>
                                    <img
                                      src={yeslocker}
                                      alt="yes"
                                      onClick={() =>
                                        displayOpen(locker.relay, locker._id)
                                      }
                                    />
                                  </>
                                )}
                              </div>
                            </div>
                          ))}
                        </div>
                      </>
                    )}
                    <div className="row justify-content-center">
                      {islogin && (
                        <div className="col-lg-12 text-center mob-1">
                          <div className={styles.cont_form_wrap}>
                            <div className={styles.signup_wrap}>
                              <div className={styles.logo_wrap}>
                                <Link to={"/"}>
                                  <img src={logo} alt="logo" />
                                </Link>
                              </div>
                              <h1 className="mt-4">Welcome Back</h1>
                              <form className="form-wrap" onSubmit={formSubmit}>
                                <div className="row justify-content-center">
                                  <div className="col-xl-12 col-sm-12">
                                    <div className="row">
                                      <div className="col-xl-6 col-lg-8 col-6 ">
                                        <div className="mb-3">
                                          <label
                                            htmlFor="email"
                                            className="form-label"
                                          >
                                            Email
                                          </label>
                                          <input
                                            type="email"
                                            className="form-control"
                                            id="email"
                                            name="email"
                                            value={credentials.email}
                                            onChange={handleInput}
                                          />
                                        </div>
                                      </div>
                                      <div className="col-xl-6 col-sm-12 col-6 ">
                                        <div className="mb-3">
                                          <label
                                            htmlFor="pass"
                                            className="form-label"
                                          >
                                            Password
                                          </label>
                                          <input
                                            type="password"
                                            className="form-control"
                                            id="password"
                                            name="password"
                                            value={credentials.password}
                                            onChange={handleInput}
                                          />
                                        </div>
                                      </div>
                                    </div>

                                    <button
                                      type="submit"
                                      className="btn btn-prim"
                                      disabled={isLoading}
                                    >
                                      <i
                                        className="fa fa-spinner fa-spin"
                                        style={iocnStyle}
                                      ></i>{" "}
                                      Login
                                    </button>
                                    <div className="row">
                                      <div className="col-xl-6 col-lg-8 col-6 text-start">
                                        <Link onClick={signUp}>Sign Up</Link>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </form>
                            </div>
                          </div>
                        </div>
                      )}
                      {issingup && (
                        <div className="col-lg-12 text-center mob-1">
                          <div className={styles.cont_form_wrap}>
                            <div className={styles.signup_wrap}>
                              <div className={styles.logo_wrap}>
                                <Link to={"/"}>
                                  <img src={logo} alt="logo" />
                                </Link>
                              </div>
                              <h1>let’s register yourself</h1>
                              <form
                                className="form-wrap"
                                onSubmit={formSubmit1}
                              >
                                <div className="row justify-content-center">
                                  <div className="col-xl-12 col-lg-12">
                                    <div className="row">
                                      <div className="col-xl-6 col-lg-8 col-6 ">
                                        <div className="mb-3">
                                          <label
                                            htmlFor="name"
                                            className="form-label"
                                          >
                                            Full Name
                                          </label>
                                          <input
                                            type="name"
                                            className="form-control"
                                            id="name"
                                            name="name"
                                            value={credentials1.name}
                                            onChange={handleInput1}
                                          />
                                        </div>
                                      </div>
                                      <div className="col-xl-6 col-lg-8 col-6 ">
                                        <div className="mb-3">
                                          <label
                                            htmlFor="email"
                                            className="form-label"
                                          >
                                            Email
                                          </label>
                                          <input
                                            type="email"
                                            className="form-control"
                                            id="email"
                                            name="email"
                                            value={credentials1.email}
                                            onChange={handleInput1}
                                          />
                                        </div>
                                      </div>
                                    </div>
                                    <div className="row">
                                      <div className="col-xl-6 col-lg-8 col-6 ">
                                        <div className="mb-3">
                                          <label
                                            htmlFor="pass"
                                            className="form-label"
                                          >
                                            Password
                                          </label>
                                          <input
                                            type="password"
                                            className="form-control"
                                            id="password"
                                            name="password"
                                            value={credentials1.password}
                                            onChange={handleInput1}
                                          />
                                        </div>
                                      </div>
                                      <div className="col-xl-6 col-lg-8 col-6 ">
                                        <div className="mb-3">
                                          <label
                                            htmlFor="pass"
                                            className="form-label"
                                          >
                                            Confirm Password
                                          </label>
                                          <input
                                            type="password"
                                            className="form-control"
                                            id="password_con"
                                            name="password_con"
                                            value={credentials1.password_con}
                                            onChange={handleInput1}
                                          />
                                        </div>
                                      </div>
                                    </div>

                                    <button
                                      type="submit"
                                      className="btn btn-prim"
                                      disabled={isLoading1}
                                    >
                                      <i
                                        className="fa fa-spinner fa-spin"
                                        style={iocnStyle1}
                                      ></i>{" "}
                                      SignUp
                                    </button>
                                    <div className="row">
                                      <div className="col-xl-6 col-lg-8 col-6 text-start">
                                        <Link onClick={login}>Login</Link>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </form>
                            </div>
                          </div>
                        </div>
                      )}
                    </div>
                  </>
                </div>
              </div>
            </>
          )}

          <Modal
            isOpen={isModalOpen2}
            contentLabel="Example Modal"
            className="d-flex flex-column justify-content-center p-3 col-lg-5 m-auto mt-5 h-100 align-items-center "
            shouldCloseOnOverlayClick={false}
            style={{
              content: {
                fontFamily: "Arial",
                fontWeight: "800",
                fontSize: "30px",
              },
              overlay: {
                backgroundColor: "rgba(0, 0, 0, 0.5)", // Set the overlay background color
              },
            }}
          >
            <div
              className="p-3 w-sm-50 h-sm-50 col-10 rounded-3 position-relative"
              style={{
                background: "#7bb717",
              }}
            >
              <h2 className="win-heading">You Win</h2>
              <div
                className=" rounded-2 w-sm-25 h-sm-25 p-3"
                style={{ background: "#578115" }}
              >
                <img src={Firework1} alt="gift" className="emoji-style-win" />

                <div className="d-flex justify-content-center align-items-center">
                  <img src={YellowStars} alt="star" />
                </div>
                <div
                  className=" d-flex justify-content-center align-items-center"
                  style={{
                    fontFamily: "Arial",
                    fontSize: "60px",
                    color: "white",
                  }}
                >
                  {prizeType === "money" ? (
                    <p>${prize}</p>
                  ) : (
                    <div
                      className="coins____header d-flex justify-content-center align-items-center mt-3"
                      style={{ width: "82px", height: "82px" }}
                    >
                      <div
                        className="coins____body d-flex justify-content-center align-items-center"
                        style={{ width: "80px", height: "76px" }}
                      >
                        <span
                          className="coins-number"
                          style={{ fontSize: "28px" }}
                        >
                          {coin}
                        </span>
                      </div>
                    </div>
                  )}
                </div>

                <div className="polygan-shape mt-2 ">
                  {isAuthenticated ? (
                    <>
                      <Link
                        className="btn btn-modal mt-4 d-flex justify-content-center "
                        to={"/user/dashboard"}
                      >
                        Claim Prize
                      </Link>
                    </>
                  ) : (
                    <Link
                      className="btn btn-modal mt-4  d-flex justify-content-center"
                      onClick={claimPrize}
                    >
                      Claim Prize
                    </Link>
                  )}
                </div>
              </div>
            </div>
          </Modal>

          <Modal
            isOpen={isModalOpen3}
            contentLabel="Example Modal"
            className="d-flex flex-column justify-content-center p-3 col-lg-5 m-auto mt-5 h-100 align-items-center "
            shouldCloseOnOverlayClick={false}
            style={{
              content: {
                fontFamily: "Arial",
                fontWeight: "800",
                fontSize: "30px",
              },
              overlay: {
                backgroundColor: "rgba(0, 0, 0, 0.5)", // Set the overlay background color
              },
            }}
          >
            <div
              className="p-3 w-sm-50 h-sm-50 col-10 rounded-3"
              style={{
                background: "linear-gradient(to bottom, #F94C4C, #A30909)",
              }}
            >
              <h2 className="win-heading">Try Again</h2>
              <div
                className="rounded-2 w-sm-25  p-3"
                style={{ background: "#600606" }}
              >
                <div className="w-full d-flex justify-content-center ">
                  <img
                    className="align-items-center mx-auto  object-fit-contain  mb-3 "
                    src={prize}
                    width="100%"
                    height="100%"
                  />
                </div>

                <div className="d-flex flex-row gap-5 justify-content-center align-items-center mt-4">
                  <img
                    src={Retry}
                    alt="Home"
                    onClick={() => navigate("/question-page")}
                    height={50}
                    width={50}
                  />
                  <img
                    src={Home}
                    alt="Home"
                    onClick={() => navigate("/user/dashboard")}
                    height={50}
                    width={50}
                  />
                </div>

                {/* <Link className="btn btn-modal " to={"/question-page"}>
                  Play again for a chance at a Cash Prize
                </Link>
                <Link className="btn btn-modal my-3" to={"/user/dashboard"}>
                  Go To Dashboard
                </Link> */}
              </div>
            </div>
          </Modal>
        </div>
      </div>

      <Modal
        isOpen={isReadyModalOpen}
        contentLabel="Example Modal"
        className="d-flex flex-column justify-content-center p-3 col-lg-5 m-auto mt-5 h-100 align-items-center "
        shouldCloseOnOverlayClick={false}
        style={{
          content: {
            fontFamily: "Arial",
            fontWeight: "800",
            fontSize: "30px",
          },
          overlay: {
            backgroundColor: "rgba(0, 0, 0, 0.5)",
          },
        }}
      >
        <div
          className="p-3 w-sm-50 h-sm-50 col-8 rounded-3"
          style={{ background: "#012269" }}
        >
          <h2 className="win-heading ">Are You Sure?</h2>
          <div
            className="p-2 rounded-2 w-sm-25 p-3"
            style={{ background: "#031947" }}
          >
            <h2
              className="text-center text-white m-3"
              style={{ fontFamily: "Arial" }}
            >
              Do you want to open locker{" "}
              <span className="badge rounded-pill bg-warning ">
                {selectedLockerNumber < 9
                  ? "0" + selectedLockerNumber
                  : selectedLockerNumber}
              </span>
              {""}?
            </h2>
            <div className="d-flex flex-row gap-5 justify-content-center align-items-center mt-4">
              <img
                src={Close}
                alt="Close"
                onClick={closeModal}
                height={50}
                width={50}
              />
              <img
                src={Tick}
                alt="Yes"
                onClick={handleConfirm}
                height={50}
                width={50}
              />
            </div>
            {/* <button className="btn btn-modal " onClick={handleConfirm}>
              Yes
            </button>
            <button className="btn btn-modal mt-3" onClick={closeModal}>
              No
            </button> */}
          </div>
        </div>
      </Modal>

      <Modal
        isOpen={isModalOpen4}
        contentLabel="Example Modal"
        className="d-flex flex-column justify-content-center p-3 col-lg-5 m-auto mt-3 h-100 align-items-center "
        shouldCloseOnOverlayClick={false}
        style={{
          content: {
            fontFamily: "Arial",
            fontWeight: "800",
          },
          overlay: {
            backgroundColor: "rgba(0, 0, 0, 0.5)", // Set the overlay background color
          },
        }}
      >
        <div
          className="p-3 w-sm-50 h-sm-50 col-10 rounded-3 "
          style={{
            background: "#012269",
          }}
        >
          <h2 className="win-heading ">Cash Prize</h2>
          <div
            className="p-2 rounded-2 w-sm-25  p-3"
            style={{
              background: "#031947",
            }}
          >
            <div className="d-flex align-items-center justify-content-center ">
              <img src={GiftBox} height={150} width={150} />
            </div>
            <h5
              className="text-center text-white"
              style={{ fontFamily: "Arial" }}
            >
              {amounts.map((item, i) => (
                <>
                  One locker contains a cash prize of{" "}
                  <span className="badge bg-warning ">${item.amount}</span>
                  <br />
                </>
              ))}
              The rest contain memes <br />
              <br />
              <span style={{ fontSize: "30px", fontWeight: "700" }}>
                GOOD LUCK!!
              </span>
            </h5>
            <div className="d-flex flex-row gap-5 justify-content-center align-items-center mt-4">
              <img
                src={Play}
                alt="Close"
                onClick={handlePlay}
                height={50}
                width={50}
              />
            </div>
            {/* <button className="btn btn-modal mt-2" onClick={handlePlay}>
              Play
            </button> */}
          </div>
        </div>
      </Modal>

      <Modal
        isOpen={isModalOpen5}
        contentLabel="Example Modal"
        className="d-flex flex-column justify-content-center p-3 col-lg-5 m-auto mt-3 h-100 align-items-center "
        shouldCloseOnOverlayClick={false}
        style={{
          content: {
            fontFamily: "Arial",
            fontWeight: "800",
          },
          overlay: {
            backgroundColor: "rgba(0, 0, 0, 0.5)", // Set the overlay background color
          },
        }}
      >
        <div
          className="p-3 w-sm-50 h-sm-50 col-10 rounded-3 "
          style={{
            background: "#012269",
          }}
        >
          <h2 className="win-heading ">Coins Prize</h2>

          <div
            className="p-2 rounded-2 w-sm-25  p-3"
            style={{
              background: "#031947",
            }}
          >
            <div className="d-flex align-items-center justify-content-center ">
              <img src={GiftBox} height={150} width={150} />
            </div>
            <h5
              className="text-center text-white"
              style={{ fontFamily: "Arial" }}
            >
              One locker contains{" "}
              <span className="badge bg-warning mb-1">100</span> coins
              <br />
              One locker contains <span className="badge bg-warning ">
                10
              </span>{" "}
              coins
              <br />
              The rest contain memes <br />
              <br />
              <span style={{ fontSize: "30px", fontWeight: "700" }}>
                GOOD LUCK!!
              </span>
            </h5>
            <div className="d-flex flex-row gap-5 justify-content-center align-items-center mt-4">
              <img
                src={Play}
                alt="Close"
                onClick={handlePlay}
                height={50}
                width={50}
              />
            </div>
            {/* <button className="btn btn-modal mt-2" onClick={handlePlay}>
              Play
            </button> */}
          </div>
        </div>
      </Modal>
    </div>
  );
};

export default DashboardScr;

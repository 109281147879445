import React, { useEffect, useState } from "react";
import Header from "../partials/Header2";
import Sidebar from "../partials/Sidebar";
import { useSelector } from "react-redux";
import { Link, useNavigate, useParams } from "react-router-dom";
import TenantIcon from "../../images/icon-tenant.png";
import EmailIcon from "../../images/icon-email.png";
import PhoneIcon from "../../images/icon-ph.png";
import BackIcon from "../../images/icon-back.png";
import Coinses from "../../assets/icons/coin-btn.svg";
import GiftBox from "../../assets/icons/gift_img.svg";
import { useDispatch } from "react-redux";
import { loginSuccess } from "../Redux/Authentication";
import styles from "./style.module.css";
import { toast } from "react-toastify";
const BankDetails = () => {
  const dispatch = useDispatch();
  const { id } = useParams();
  const url = process.env.REACT_APP_APP_BACK_URL;
  const isAuthenticated = useSelector((state) => state.user);
  const imageUrl = isAuthenticated.image;
  const [profileImg, setProfileImg] = useState(
    isAuthenticated?.image || "/path/to/placeholder-image.png"
  );
  const [error, setError] = useState(null);
  const [uploadProgress, setUploadProgress] = useState(0);
  const [counter, setCounter] = useState([]);
  const handleFileSelect = async (event) => {
    const file = event.target.files[0];
    if (file) {
      const allowedTypes = ["image/jpeg", "image/png"];
      if (allowedTypes.includes(file.type)) {
        setError(null);
        // Create a FileReader
        const reader = new FileReader();
        // Add a progress event listener to the FileReader
        reader.onprogress = (e) => {
          if (e.lengthComputable) {
            const percentUploaded = Math.round((e.loaded / e.total) * 100);
            setUploadProgress(percentUploaded);
          }
        };
        reader.onload = (e) => {
          const dataURL = e.target.result; // This is the data URL of the selected image
          setProfileImg(dataURL); // Set the data URL as the profileImg
        };
        await formSubmit(file);
        // Read the file (you can also add other event listeners like 'onload')
        reader.readAsDataURL(file);
      } else {
        //setFile(null);
        setError("Invalid file type. Please select an image file.");
        setTimeout(() => {
          setError(null);
        }, 3000);
      }
    }
  };
  const formSubmit = async (file) => {
    try {
      const formData = new FormData();
      if (file) {
        formData.append("file", file);
      }
      formData.append("id", isAuthenticated._id);
      const response = await fetch(`${url}/api/auth/profile/image/change`, {
        method: "POST",
        body: formData,
      });
      const json = await response.json();
      if (json.success) {
        dispatch(loginSuccess(json.user));
        toast.success("Image uploaded Successfully");
      } else {
        toast.error("failed to upload image");
      }
    } catch (error) {
      console.log(error);
    }
  };
  const fetchData = async () => {
    try {
      let headers = new Headers();
      headers.append("Content-Type", "application/json");
      headers.append("Access-Control-Allow-Origin", "*");
      headers.append("Access-Control-Allow-Credentials", "true");
      headers.append("auth-token", localStorage.getItem("token"));
      const response = await fetch(`${url}/api/auth/profile/counter/${id}`, {
        mode: "cors",
        method: "GET",
        headers: headers,
      });
      if (!response.ok) {
        throw new Error("Network response was not ok");
      }
      const json = await response.json();

      setCounter(json.data);
    } catch (error) {
      console.error(error);
    }
  };
  useEffect(() => {
    fetchData();
  }, []);
  const [isActive, setIsActive] = useState(false);

  // Function to toggle the active state
  const toggleActive = () => {
    setIsActive(!isActive);
  };

  // Define the CSS classes based on the isActive state
  const divClassName = isActive ? "sidebar active" : "sidebar";
  return (
    <div className={styles.heading}>
      <div className="body-wrap">
        <section className={styles.sidebar_wrap}>
          <div className="container-fluid px-0">
            <div className="d-flex">
              <Sidebar />
              <div className="p-3 w-100 main__content">
                <Header isActive={isActive} toggleActive={toggleActive} />
                <div className="general-dashboard">
                  <div className="container-fluid">
                    <div className="mb-3">
                      <div className="row">
                        <div className="col-lg-6 my-auto">
                          <div className="user-wrap user-back-wrap">
                            <Link to="/user/list">
                              <div className="back-icon">
                                <img src={BackIcon} alt="" />
                              </div>
                            </Link>
                            <div>
                              <h3>Account Details</h3>
                              <p>User bank account details</p>
                            </div>
                          </div>
                        </div>
                        <div className="col-lg-6 text-end"></div>
                      </div>
                    </div>
                    {/* row */}
                    <div className="profile-main-wrap">
                      <div className="cont-row">
                        <div className="row">
                          <div className="col-lg-9 col-cust-9">
                            <div className="cont-profile-wrap text-capitalize">
                              <h4>
                                {counter.findUser && counter.findUser.name}
                              </h4>
                              <div className="cont-detail d-flex">
                                <a href="#">
                                  <img src={TenantIcon} alt="" />{" "}
                                  {counter.findUser && counter.findUser.role}
                                </a>
                                <a href="mailto:alexleveque@email.com" className="text-lowercase">
                                  <img src={EmailIcon} alt="" />{" "}
                                  {counter.findUser && counter.findUser.email}
                                </a>
                                <a href="tel:202-555-0168">
                                  <img src={PhoneIcon} alt="" />{" "}
                                  {counter.findUser && counter.findUser.phone}
                                </a>
                              </div>
                              <p>{isAuthenticated?.bio}</p>
                            </div>
                          </div>
                        </div>
                        <div className="row">
                          <div className="col-lg-12 col-cust-12">
                            <div className="cont-profile-wrap">
                              <h4>Bank Details</h4>
                              <div className="cont-detail d-flex text-capitalize">
                                <a href="#">
                                  Title:
                                  <b> {counter.bank && counter.bank.name}</b>
                                </a>
                                <a href="#">
                                  Bank:
                                  <b>
                                    {" "}
                                    {counter.bank && counter.bank.bank_name}
                                  </b>
                                </a>
                                <a href="#">
                                  Account :
                                  <b> {counter.bank && counter.bank.account}</b>
                                </a>
                                <a href="tel:202-555-0168">
                                  <img src={PhoneIcon} alt="" />{" "}
                                  {counter.bank && counter.bank.phone}
                                </a>
                              </div>
                              <p>{isAuthenticated?.bio}</p>
                            </div>
                          </div>
                        </div>
                        {/* row */}
                      </div>
                      <div className="row">
                        <div className="col-lg-6">
                          <div className="row">

                            <div className="col-lg-6 col-md-6">
                              <div className="profile-details">
                                <div className="icons-wrap danger-box">
                                  <img src={GiftBox} alt="" height={100} width={100} />
                                </div>
                                <div className="profile-content">
                                  <span style={{ fontWeight: "700" }}>
                                    {counter.length !== 0 ? counter.wins : 0}
                                  </span>
                                  <div className="cont-inner">
                                    <i>Wins</i>
                                  </div>
                                </div>
                              </div>
                            </div>

                            <div className="col-lg-6 col-md-6">
                              <div className="profile-details">
                                <div className="icons-wrap warning-box">
                                  <img src={Coinses} alt="" height={50} width={50} />
                                </div>
                                <div className="profile-content">
                                  <span style={{ fontWeight: "600" }}>
                                    $
                                    {counter.length !== 0
                                      ? counter.totalAmountWon
                                      : 0}
                                  </span>
                                  <div className="cont-inner">
                                    <i>Payments</i>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="row"></div>
                          {/* row */}
                        </div>

                        {/* row */}
                      </div>
                      {/* row */}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </div>
  );
};
export default BankDetails;

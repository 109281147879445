import axios from "axios";
const apiUrl = process.env.REACT_APP_APP_BACK_URL;
export async function checkFileExists(filePath) {
  try {
    if (filePath) console.log("ssa");
    const response = await axios.post(`${apiUrl}/api/sites/checkFile`, {
      path: filePath,
    });
    console.log(response);
    if (response.data.success) {
      if (response.data.success) {
        console.log(`File ${filePath} exists.`);
        return true;
      } else {
        console.log(`File ${filePath} does not exist.`);
        return false;
      }
    } else {
      console.log(`Failed to check file existence. Status: ${response.status}`);
      return false;
    }
    return false;
  } catch (error) {
    console.log(error);
  }
}

export const formattedDateTime = (currentDateTime) =>
  currentDateTime.toLocaleString("en-US", {
    hour: "2-digit",
    minute: "2-digit",
    second: "2-digit",
    timeZone: "America/New_York",
  });
// const hours = currentDateTime.getHours().toString().padStart(2, '0');
// const minutes = currentDateTime.getMinutes().toString().padStart(2, '0');
// const seconds = currentDateTime.getSeconds().toString().padStart(2, '0');
export const formattedDateTime1 = (currentDateTime) =>
  currentDateTime.toLocaleString("en-US", {
    year: "numeric",
    month: "2-digit",
    day: "2-digit",
    timeZone: "America/New_York",
  });

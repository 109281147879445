import React from "react";
import Header from "../partials/header/header";
import Footer from "../partials/footer";
import PhoneInput from "react-phone-input-2";

const ContactUs = () => {
  return (
    <div>
      <Header />
      <div className="main">
        <div className="mx-auto w-75 card bg-white my-3 p-5">

          <h2 className="fw-bold">Contact Us</h2>
          <p>

            You can easily reach out to us through our contact us page. We are ready to answer your questions and assist you.
            For any issues, suggestions, or feedback, please feel free to contact us.
          </p>
          <div className="mx-2 d-flex flex-column gap-4 w-100">
            <div className="d-flex gap-4 w-100">
              <div className="text-start flex-grow-1 justify-content-start">
                <span className="">Name</span>
                <input className="form-control" type="text" name="name" id="" />
              </div>
              <div className="text-start flex-grow-1">
                <span className="">Email</span>
                <input
                  className="form-control"
                  type="text"
                  name="email"
                  id=""
                />
              </div>
            </div>
            <div className="text-start">
              <span className="">Phone Number</span>
              <PhoneInput
                country={"us"}
                inputStyle={{ width: "100%" }}
              // value={this.state.phone}
              // onChange={(phone) => this.setState({ phone })}
              />
            </div>
            <div className="text-start">
              <span className="">Request & Help</span>
              <textarea
                rows={5}
                className="form-control"
                type="text"
                name=""
                id=""
              />
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default ContactUs;

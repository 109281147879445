import React, { useEffect, useState } from "react";
import Logo from "../../assets/logo-dark.png";
import LogoIcon from "../../assets/logo-icon.png";
import IconUser from "../../assets/SidebarIcon/new-user.svg";
import IconLogout from "../../assets/SidebarIcon/lagout.svg";
import IconDoors from "../../assets/SidebarIcon/Lockers.svg";
import IconMedia from "../../assets/SidebarIcon/subscription.svg";
import Settings from "../../assets/SidebarIcon/Setting.svg";
import { Link, useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { logout } from "../Redux/Authentication";
import "../style.module.css";
function getWindowDimensions() {
  const { innerWidth: width, innerHeight: height } = window;
  return {
    width,
    height,
  };
}

const Sidebar = () => {
  const dispatch = useDispatch();
  let navigate = useNavigate();
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);
  const [isMobile, setisMobile] = useState(false);
  useEffect(() => {
    const { width } = getWindowDimensions();
    if (width < 550) {
      setIsSidebarOpen(false);
      setisMobile(true);
    }
    console.log(width);
  }, []);

  const handleToggleSidebar = () => {
    setIsSidebarOpen(!isSidebarOpen);
  };

  const handleUserLogout = async (e) => {
    const logouts = await localStorage.removeItem("token");
    dispatch(logout());
    navigate("/");
  };

  return (
    <div
      className={`bg-white ${isMobile ? "p-1" : "p-4"} ${isSidebarOpen ? "open" : "closed"
        }`}
      style={{ height: "100%", minHeight: "100vh" }}
    >
      <div
        className={`d-flex mb-3 ${isSidebarOpen ? "justify-content-end" : "justify-content-center"
          }`}
        onClick={handleToggleSidebar}
        style={{ transition: "transform 0.3s ease-out" }}
      >
        {isSidebarOpen ? <i className="fas fa-times" style={{ color: "#012267" }}></i> : <i className="fas fa-bars" style={{ color: "#012267" }}></i>}
      </div>
      <section className={`${isMobile ?? "p-3"}`}>
        {
          <>
            <div className="">
              <img
                src={isSidebarOpen ? Logo : LogoIcon}
                alt="logo"
                className="logo-sidebar-wrap"
                height={isSidebarOpen ? 50 : 30}
                width={isSidebarOpen ? 110 : 35}
                style={{ transition: "height 0.2s, width 0.2s" }}
                onClick={() => navigate("/")}
              />
            </div>
            <ul>
              <li className="py-3">
                <Link
                  className={`sidebar__link text-decoration-none d-flex align-items-center gap-3 ${!isSidebarOpen && "justify-content-center"
                    }`}
                  style={{ filter: "brightness(1)", opacity: "1" }}
                  to="/user/list"
                >
                  <img src={IconUser} alt="icon" height={15} width={15} />
                  {isSidebarOpen && <span>Users</span>}
                </Link>
              </li>
              <li className="py-3">
                <Link
                  className={`sidebar__link text-decoration-none d-flex align-items-center gap-3 ${!isSidebarOpen && "justify-content-center"
                    }`}
                  to="/lockergame/lockers"
                >
                  <img src={IconDoors} alt="icon" height={15} width={15} />
                  {isSidebarOpen && <span>Lockers</span>}
                </Link>
              </li>
              <li className="py-3">
                <Link
                  className={`sidebar__link text-decoration-none d-flex align-items-center gap-3 ${!isSidebarOpen && "justify-content-center"
                    }`}
                  to="/prizes/list"
                >
                  <img src={IconMedia} alt="icon" height={15} width={15} />
                  {isSidebarOpen && <span>Prizes</span>}
                </Link>
              </li>
              <li className="py-3">
                <Link
                  className={`sidebar__link text-decoration-none d-flex align-items-center gap-3 ${!isSidebarOpen && "justify-content-center"
                    }`}
                  to="/sites"
                >
                  <img src={IconDoors} alt="icon" height={15} width={15} />
                  {isSidebarOpen && <span>Sites</span>}
                </Link>
              </li>
              <li className="py-3">
                <Link
                  className={`sidebar__link text-decoration-none d-flex align-items-center gap-3 ${!isSidebarOpen && "justify-content-center"
                    }`}
                  to="/players"
                >
                  <img src={IconDoors} alt="icon" height={15} width={15} />
                  {isSidebarOpen && <span>Palyers</span>}
                </Link>
              </li>
              <li className="py-3">
                <Link
                  className={`sidebar__link text-decoration-none d-flex align-items-center gap-3 ${!isSidebarOpen && "justify-content-center"
                    }`}
                  to="/game/settings"
                >
                  <img src={Settings} alt="icon" height={15} width={15} />
                  {isSidebarOpen && <span>Settings</span>}
                </Link>
              </li>

              <li className="py-3">
                <Link
                  className={`sidebar__link text-decoration-none d-flex align-items-center gap-3 ${!isSidebarOpen && "justify-content-center"
                    }`}
                  onClick={handleUserLogout}
                >
                  <img src={IconLogout} alt="icon" height={15} width={15} />
                  {isSidebarOpen && <span>Logout</span>}
                </Link>
              </li>
            </ul>
          </>
        }
      </section>
    </div>
  );
};

export default Sidebar;

import React, { useEffect, useState, useRef } from "react";
import Modal from "react-modal";
import styles from "./style.module.css";
import Header from "../partials/header/header";
import Loader from "../partials/Loader";
import { useSelector } from "react-redux";
import yeslocker from "../../images/new images/longyes.png";
import nolocker from "../../images/new images/longno.png";
import shortyes from "../../images/new images/shortyes.png";
import shortno from "../../images/new images/shortno.png";
import lock from "../../images/Lock.svg";
import Swal from "sweetalert2";
import mockup from "../../images/new images/gray.png";
import CrossIcon from "../../assets/icons/cross_icon.svg";
import "sweetalert2/dist/sweetalert2.min.css";
import User from "../../assets/user-white.png";
import { toast } from "react-toastify";
import { Link, useNavigate, useParams } from "react-router-dom";
import backgroundMusic from "../../assets/audio/start_question.mp3";
import backgroundMusicClick from "../../assets/audio/click.mp3";
import backgroundMusicLose from "../../assets/audio/wahwah.mp3";
import backgroundMusicWin from "../../assets/audio/win.mp3";
import io from "socket.io-client";
const url = process.env.REACT_APP_APP_BACK_URL;
const socket = io.connect(url);
const DashboardScr = () => {
  const isAuthenticated = useSelector((state) => state.user);
  let navigate = useNavigate();
  const { id } = useParams();
  let slug = "invid";
  const [loader, setLoader] = useState(true);
  const [isLoading, setIsLoading] = useState(false);
  const [isDisplay, setIsDisplay] = useState(false);
  const [isLockerOpen, setIsLockerOpen] = useState(false);
  const [backgroundAudio] = useState(new Audio(backgroundMusic));
  const [backgroundAudioClick] = useState(new Audio(backgroundMusicClick));
  const [backgroundAudioLose] = useState(new Audio(backgroundMusicLose));
  const [backgroundAudioWin] = useState(new Audio(backgroundMusicWin));
  const [isGameEnd, setIsGameEnd] = useState(false);
  const [prize, setPrize] = useState("");
  const [winnername, setwinnername] = useState("");
  const [selectedUsers, setselectedUsers] = useState([]);

  const [getLocker, setLocker] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedLocker, setselectedLocker] = useState("");
  const [isModalOpen2, setIsModalOpen2] = useState(false);
  const [isModalOpen3, setIsModalOpen3] = useState(false);
  const [isModalOpen4, setIsModalOpen4] = useState(false);
  const [isReadyModalOpen, setIsReadyModalOpen] = useState(false);
  const [winnerLocker, setWinnerLocker] = useState();
  const queryParams = new URLSearchParams(window.location.search);
  const player = queryParams.get("p");
  const position = parseInt(queryParams.get("position")) || 0;
  const [seletedPlayers, setSeletedPlayers] = useState([]);
  const [relayNumber, setRelayNumber] = useState();

  useEffect(() => {
    return () => {
      // Clean up: Stop the background music when the component unmounts
      backgroundAudio.pause();
      backgroundAudio.currentTime = 0;
    };
  }, [backgroundAudio]);
  useEffect(() => {
    siteLockers();
  }, []);
  useEffect(() => {
    if (isGameEnd) {
      backgroundAudio.pause();
      backgroundAudio.currentTime = 0;
    }
  }, [isGameEnd, backgroundAudio]);
  useEffect(() => {
    winnerLockers();
    getPlayers();
  }, []);
  useEffect(() => {
    socket.emit("gameID", player);
    socket.on("showCashPrize", (data) => {
      setIsModalOpen2(true);
      setselectedLocker(data.locker);
      setPrize(data.prize);
      setwinnername(data.name)
      setIsModalOpen4(false);
    });
    socket.on("showMemesPrize", (data) => {
      setIsModalOpen3(true);
      setselectedLocker(data.locker);
      setPrize(data.prize);
      setwinnername(data.name)
      setIsModalOpen4(false);
    });
  }, []);
  // get all locker questions
  const userPrize = async () => {
    try {
      let headers = new Headers();
      headers.append("Content-Type", "application/json");
      headers.append("Access-Control-Allow-Origin", "*");
      headers.append("Access-Control-Allow-Credentials", "true");
      const response = await fetch(
        `${url}/api/multiplayer/winner/locker/status/${id}`,
        {
          mode: "cors",
          method: "GET",
          headers: headers,
        }
      );
      const json = await response.json();
      if (json.success) {
        backgroundAudio.pause();
        backgroundAudio.currentTime = 0;
      } else {
        toast.error(json.error);
        navigate("/");
      }
    } catch (error) {
      console.error(error);
    }
  };
  // get selected player to show user prizes
  const getPlayers = async () => {
    try {
      let headers = new Headers();
      headers.append("Content-Type", "application/json");
      headers.append("Access-Control-Allow-Origin", "*");
      headers.append("Access-Control-Allow-Credentials", "true");

      const response = await fetch(
        `${url}/api/multiplayer/get/loser/player/${id}`,
        {
          mode: "cors",
          method: "GET",
          headers: headers,
        }
      );
      const json = await response.json();
      if (json.success) {
        console.log(json, "json")
        setSeletedPlayers(json.selectedPlayers);
        setselectedUsers(json.selectedUsers);
        setIsModalOpen4(true)
      }
    } catch (error) {
      toast.error(error.message);
    }
  };
  useEffect(() => {
    setLoader(true);
    const timerId = setTimeout(() => {
      setLoader(false);
      userPrize();
    }, 5000); // 3000 milliseconds = 3 seconds
    return () => clearTimeout(timerId);
  }, []);

  // get all site
  const siteLockers = async () => {
    try {
      let headers = new Headers();
      headers.append("Content-Type", "application/json");
      headers.append("Access-Control-Allow-Origin", "*");
      headers.append("Access-Control-Allow-Credentials", "true");
      const response = await fetch(`${url}/api/sites/lockers/${slug}`, {
        mode: "cors",
        method: "GET",
        headers: headers,
      });
      const json = await response.json();

      if (json.success) {
        console.log(json.lockers[0]);
        setLocker(json.lockers);
        setselectedLocker("");
        setIsDisplay(true);
      } else {
        toast.error(json.error);
      }
    } catch (error) {
      toast.error(error.message);
    }
  };
  // open locker
  const openLocker = async (locker_id) => {
    let state = "2";
    let headers = new Headers();
    headers.append("Content-Type", "application/json");
    headers.append("Access-Control-Allow-Origin", "*");
    headers.append("Access-Control-Allow-Credentials", "true");
    setIsLockerOpen(true);
    setIsLoading(true);
    const response = await fetch(`${url}/api/prizes/open/multi/user/locker`, {
      mode: "cors",
      method: "POST",
      headers: headers,
      body: JSON.stringify({
        id: id,
        locker_id: locker_id,
        state: state,
      }),
    });

    setIsLockerOpen(false);
    const json = await response.json();
    if (json.success) {
      if (json.prizes.type === "money") {
        socket.emit("cashPrize", {
          player: seletedPlayers,
          locker: locker_id,
          prize: json.prizes.amount,
          name: winnerLocker ? winnerLocker.name : '',
        });
        setIsModalOpen2(true);
        setPrize(json.prizes.amount);
        backgroundAudioWin.play()
      } else {
        socket.emit("memePrize", {
          player: seletedPlayers,
          locker: locker_id,
          prize: json.prizes.memes,
          name: winnerLocker ? winnerLocker.name : '',
        });
        setIsModalOpen3(true);
        setPrize(json.prizes.memes);
        backgroundAudioLose.play()
      }
    } else {
      toast.error(json.error);
    }

    setIsLoading(false);
    siteLockers();
  };
  const backtohome = () => {
    navigate("/");
  }
  const handleConfirm = () => {
    openLocker(selectedLocker);
    setIsReadyModalOpen(false);
    // setselectedLocker("");
  };
  const displayOpen = async (id, relay) => {
    backgroundAudioClick.play();
    setIsReadyModalOpen(true);
    setselectedLocker(id);
    setRelayNumber(relay)
  };

  const displayOpen1 = async (id) => {
    setIsReadyModalOpen(true);
    setselectedLocker(id);
  };
  const closeModal = () => {
    setIsReadyModalOpen(false);
    setselectedLocker("");
    setIsModalOpen(false);
  };
  // return to available locker
  const returnLocker = async () => {
    let headers = new Headers();
    headers.append("Content-Type", "application/json");
    headers.append("Access-Control-Allow-Origin", "*");
    headers.append("Access-Control-Allow-Credentials", "true");
    setIsLoading(true);
    const response = await fetch(
      `${url}/api/sites/changeToAvailable/${selectedLocker}`,
      {
        mode: "cors",
        method: "GET",
        headers: headers,
      }
    );

    const json = await response.json();
    if (json.success) {
      toast.success(json.message);
      siteLockers();
      setIsLoading(false);
      setIsModalOpen(false);
    } else {
      toast.error(json.error);
    }
  };

  const winnerLockers = async () => {
    try {
      let headers = new Headers();
      headers.append("Content-Type", "application/json");
      headers.append("Access-Control-Allow-Origin", "*");
      headers.append("Access-Control-Allow-Credentials", "true");

      const response = await fetch(
        `${url}/api/multiplayer/winners/locker/${id}`,
        {
          mode: "cors",
          method: "GET",
          headers: headers,
        }
      );

      const json = await response.json();

      if (json.success) {
        console.log(json.winner)
        setWinnerLocker(json.winner);
      }
    } catch (error) {
      toast.error(error.message);
    }
  };
  const sortedUsers = selectedUsers.slice().sort((a, b) => b.score - a.score);
  return (
    <div className={styles.heading}>
      <div className="body-wrap">
        <div className="container-fluid px-0">
          {loader ? (
            <Loader />
          ) : (
            <>
              <Header />
              <div className="dashboard-locker">
                <div className="container-fluid text-center p-2 ">
                  <div className="row gap locker-row mt-3 overflow-auto ">
                    {getLocker && getLocker[0] && (
                      <>
                        <div className="col-1 "></div>
                        <div
                          className={`col-2  ${isLoading && selectedLocker !== getLocker[0]._id
                            ? "loading-disabled"
                            : ""
                            }`}
                        >
                          <div className="lockers-manual position-relative ">
                            <span className="dot">{1}</span>
                            <>
                              {selectedLocker === getLocker[0]._id && (
                                <div className="locker-button-text">OPEN</div>
                              )}
                              <img
                                src={
                                  selectedLocker === getLocker[0]._id
                                    ? nolocker
                                    : yeslocker
                                }
                                alt="yes"
                                onClick={() => displayOpen(getLocker[0]._id, 1)}
                              />

                              {winnerLocker && winnerLocker._id !== player && (
                                <div
                                  style={{
                                    position: "absolute",
                                    top: "0",
                                    left: "0",
                                    width: "100%",
                                    height: "100%",
                                  }}
                                ></div>
                              )}
                            </>
                          </div>
                        </div>
                      </>
                    )}
                    {getLocker && getLocker[3] && (
                      <div
                        className={`col-2  ${isLoading && selectedLocker !== getLocker[3]._id
                          ? "loading-disabled"
                          : ""
                          }`}
                      >
                        <div className="lockers-manual position-relative ">
                          <span className="dot">{4}</span>

                          <>
                            {selectedLocker === getLocker[3]._id && (
                              <div className="locker-button-text">OPEN</div>
                            )}
                            <img
                              src={
                                selectedLocker === getLocker[3]._id
                                  ? nolocker
                                  : yeslocker
                              }
                              alt="yes"
                              onClick={() => displayOpen(getLocker[3]._id, 4)}
                            />
                            {winnerLocker && winnerLocker._id !== player && (
                              <div
                                style={{
                                  position: "absolute",
                                  top: "0",
                                  left: "0",
                                  width: "100%",
                                  height: "100%",
                                }}
                              ></div>
                            )}
                          </>
                        </div>
                      </div>
                    )}
                    <div className="col-2 ">
                      <div className="lockers-manual position-relative ">
                        {getLocker && getLocker[6] && (
                          <div
                            className={`lockers-short ${isLoading && selectedLocker !== getLocker[6]._id
                              ? "loading-disabled"
                              : ""
                              }`}
                          >
                            <span className="dot1">{7}</span>

                            <>
                              {selectedLocker === getLocker[6]._id && (
                                <div className="locker-button-text ">OPEN</div>
                              )}
                              <img
                                src={
                                  selectedLocker === getLocker[6]._id
                                    ? shortno
                                    : shortyes
                                }
                                alt="yes"
                                onClick={() => displayOpen(getLocker[6]._id, 7)}
                              />
                              {winnerLocker && winnerLocker._id !== player && (
                                <div
                                  style={{
                                    position: "absolute",
                                    top: "0",
                                    left: "0",
                                    width: "100%",
                                    height: "100%",
                                  }}
                                ></div>
                              )}
                            </>
                          </div>
                        )}
                        {getLocker && getLocker[7] && (
                          <div
                            className={`lockers-short half-locker position-relative  ${isLoading && selectedLocker !== getLocker[7]._id
                              ? "loading-disabled"
                              : ""
                              }`}
                          >
                            <span className="dot2">{getLocker[7].relay}</span>

                            <>
                              {selectedLocker === getLocker[7]._id && (
                                <div className="locker-button-text locker-button-text-small">
                                  OPEN
                                </div>
                              )}
                              <img
                                src={
                                  selectedLocker === getLocker[7]._id
                                    ? shortno
                                    : shortyes
                                }
                                alt="yes"
                                onClick={() => displayOpen(getLocker[7]._id, 8)}
                              />
                              {winnerLocker && winnerLocker._id !== player && (
                                <div
                                  style={{
                                    position: "absolute",
                                    top: "0",
                                    left: "0",
                                    width: "100%",
                                    height: "100%",
                                  }}
                                ></div>
                              )}
                            </>
                          </div>
                        )}
                      </div>
                    </div>
                    {getLocker && getLocker[10] && (
                      <div
                        className={`col-2  ${isLoading && selectedLocker !== getLocker[10]._id
                          ? "loading-disabled"
                          : ""
                          }`}
                      >
                        <div className="lockers-manual position-relative ">
                          <span className="dot">{11}</span>

                          <>
                            {selectedLocker === getLocker[10]._id && (
                              <div className="locker-button-text">OPEN</div>
                            )}
                            <img
                              src={
                                selectedLocker === getLocker[10]._id
                                  ? nolocker
                                  : yeslocker
                              }
                              alt="yes"
                              onClick={() => displayOpen(getLocker[10]._id, 11)}
                            />
                            {winnerLocker && winnerLocker._id !== player && (
                              <div
                                style={{
                                  position: "absolute",
                                  top: "0",
                                  left: "0",
                                  width: "100%",
                                  height: "100%",
                                }}
                              ></div>
                            )}
                          </>
                        </div>
                      </div>
                    )}
                    {getLocker && getLocker[13] && (
                      <>
                        <div
                          className={`col-2  ${isLoading && selectedLocker !== getLocker[13]._id
                            ? "loading-disabled"
                            : ""
                            }`}
                        >
                          <div className="lockers-manual position-relative ">
                            <span className="dot">{14}</span>

                            <>
                              {selectedLocker === getLocker[13]._id && (
                                <div className="locker-button-text">OPEN</div>
                              )}
                              <img
                                src={
                                  selectedLocker === getLocker[13]._id
                                    ? nolocker
                                    : yeslocker
                                }
                                alt="yes"
                                onClick={() => displayOpen(getLocker[13]._id, 14)}
                              />
                              {winnerLocker && winnerLocker._id !== player && (
                                <div
                                  style={{
                                    position: "absolute",
                                    top: "0",
                                    left: "0",
                                    width: "100%",
                                    height: "100%",
                                  }}
                                ></div>
                              )}
                            </>
                          </div>
                        </div>
                        <div className="col-1 "></div>
                      </>
                    )}
                  </div>
                  <div className="row locker-row mt-3">
                    {getLocker && getLocker[1] && (
                      <>
                        <div className="col-1 "></div>
                        <div
                          className={`col-2  ${isLoading && selectedLocker !== getLocker[1]._id
                            ? "loading-disabled"
                            : ""
                            }`}
                        >
                          <div className="lockers-manual position-relative ">
                            <span className="dot">{2}</span>

                            <>
                              {selectedLocker === getLocker[1]._id && (
                                <div className="locker-button-text">OPEN</div>
                              )}
                              <img
                                src={
                                  selectedLocker === getLocker[1]._id
                                    ? nolocker
                                    : yeslocker
                                }
                                alt="yes"
                                onClick={() => displayOpen(getLocker[1]._id, 2)}
                              />
                              {winnerLocker && winnerLocker._id !== player && (
                                <div
                                  style={{
                                    position: "absolute",
                                    top: "0",
                                    left: "0",
                                    width: "100%",
                                    height: "100%",
                                  }}
                                ></div>
                              )}
                            </>
                          </div>
                        </div>
                      </>
                    )}
                    {getLocker && getLocker[4] && (
                      <div
                        className={`col-2  ${isLoading && selectedLocker !== getLocker[4]._id
                          ? "loading-disabled"
                          : ""
                          }`}
                      >
                        <div className="lockers-manual position-relative ">
                          <span className="dot">{5}</span>

                          <>
                            {selectedLocker === getLocker[4]._id && (
                              <div className="locker-button-text">OPEN</div>
                            )}
                            <img
                              src={
                                selectedLocker === getLocker[4]._id
                                  ? nolocker
                                  : yeslocker
                              }
                              alt="yes"
                              onClick={() => displayOpen(getLocker[4]._id, 5)}
                            />
                            {winnerLocker && winnerLocker._id !== player && (
                              <div
                                style={{
                                  position: "absolute",
                                  top: "0",
                                  left: "0",
                                  width: "100%",
                                  height: "100%",
                                }}
                              ></div>
                            )}
                          </>
                        </div>
                      </div>
                    )}
                    <div className="col-2 ">
                      <div className="lockers-manual ">
                        <img src={mockup} alt="yes" />
                      </div>
                    </div>
                    {getLocker && getLocker[11] && (
                      <div
                        className={`col-2  ${isLoading && selectedLocker !== getLocker[11]._id
                          ? "loading-disabled"
                          : ""
                          }`}
                      >
                        <div className="lockers-manual position-relative ">
                          <span className="dot">{12}</span>

                          <>
                            {selectedLocker === getLocker[11]._id && (
                              <div className="locker-button-text">OPEN</div>
                            )}
                            <img
                              src={
                                selectedLocker === getLocker[11]._id
                                  ? nolocker
                                  : yeslocker
                              }
                              alt="yes"
                              onClick={() => displayOpen(getLocker[11]._id, 12)}
                            />
                            {winnerLocker && winnerLocker._id !== player && (
                              <div
                                style={{
                                  position: "absolute",
                                  top: "0",
                                  left: "0",
                                  width: "100%",
                                  height: "100%",
                                }}
                              ></div>
                            )}
                          </>
                        </div>
                      </div>
                    )}
                    {getLocker && getLocker[14] && (
                      <>
                        <div
                          className={`col-2  ${isLoading && selectedLocker !== getLocker[14]._id
                            ? "loading-disabled"
                            : ""
                            }`}
                        >
                          <div className="lockers-manual position-relative ">
                            <span className="dot">{15}</span>

                            <>
                              {selectedLocker === getLocker[14]._id && (
                                <div className="locker-button-text">OPEN</div>
                              )}
                              <img
                                src={
                                  selectedLocker === getLocker[14]._id
                                    ? nolocker
                                    : yeslocker
                                }
                                alt="yes"
                                onClick={() => displayOpen(getLocker[14]._id, 15)}
                              />
                              {winnerLocker && winnerLocker._id !== player && (
                                <div
                                  style={{
                                    position: "absolute",
                                    top: "0",
                                    left: "0",
                                    width: "100%",
                                    height: "100%",
                                  }}
                                ></div>
                              )}
                            </>
                          </div>
                        </div>
                        <div className="col-1 "></div>
                      </>
                    )}
                  </div>

                  <div className="row locker-row mt-3">
                    {getLocker && getLocker[2] && (
                      <>
                        <div className="col-1 "></div>
                        <div
                          className={`col-2  ${isLoading && selectedLocker !== getLocker[2]._id
                            ? "loading-disabled"
                            : ""
                            }`}
                        >
                          <div className="lockers-manual position-relative ">
                            <span className="dot">{3}</span>

                            <>
                              {selectedLocker === getLocker[2]._id && (
                                <div className="locker-button-text">OPEN</div>
                              )}
                              <img
                                src={
                                  selectedLocker === getLocker[2]._id
                                    ? nolocker
                                    : yeslocker
                                }
                                alt="yes"
                                onClick={() => displayOpen(getLocker[2]._id, 3)}
                              />
                              {winnerLocker && winnerLocker._id !== player && (
                                <div
                                  style={{
                                    position: "absolute",
                                    top: "0",
                                    left: "0",
                                    width: "100%",
                                    height: "100%",
                                  }}
                                ></div>
                              )}
                            </>
                          </div>
                        </div>
                      </>
                    )}
                    {getLocker && getLocker[5] && (
                      <div
                        className={`col-2  ${isLoading && selectedLocker !== getLocker[5]._id
                          ? "loading-disabled"
                          : ""
                          }`}
                      >
                        <div className="lockers-manual position-relative ">
                          <span className="dot">{6}</span>

                          <>
                            {selectedLocker === getLocker[5]._id && (
                              <div className="locker-button-text">OPEN</div>
                            )}
                            <img
                              src={
                                selectedLocker === getLocker[5]._id
                                  ? nolocker
                                  : yeslocker
                              }
                              alt="yes"
                              onClick={() => displayOpen(getLocker[5]._id, 6)}
                            />
                            {winnerLocker && winnerLocker._id !== player && (
                              <div
                                style={{
                                  position: "absolute",
                                  top: "0",
                                  left: "0",
                                  width: "100%",
                                  height: "100%",
                                }}
                              ></div>
                            )}
                          </>
                        </div>
                      </div>
                    )}
                    <div className="col-2 ">
                      <div className="lockers-manual position-relative ">
                        {getLocker && getLocker[8] && (
                          <div
                            className={`lockers-short ${isLoading && selectedLocker !== getLocker[8]._id
                              ? "loading-disabled"
                              : ""
                              }`}
                          >
                            <span className="dot1">{9}</span>

                            <>
                              {selectedLocker === getLocker[8]._id && (
                                <div className="locker-button-text">OPEN</div>
                              )}
                              <img
                                src={
                                  selectedLocker === getLocker[8]._id
                                    ? shortno
                                    : shortyes
                                }
                                alt="yes"
                                onClick={() => displayOpen(getLocker[8]._id, 9)}
                              />
                              {winnerLocker && winnerLocker._id !== player && (
                                <div
                                  style={{
                                    position: "absolute",
                                    top: "0",
                                    left: "0",
                                    width: "100%",
                                    height: "100%",
                                  }}
                                ></div>
                              )}
                            </>
                          </div>
                        )}
                        {getLocker && getLocker[10] && (
                          <div
                            className={`lockers-manual-short half-locker position-relative  ${isLoading && selectedLocker !== getLocker[9]._id
                              ? "loading-disabled"
                              : ""
                              }`}
                          >
                            <span className="dot2">{10}</span>

                            <>
                              {selectedLocker === getLocker[9]._id && (
                                <div className="locker-button-text locker-button-text-small">
                                  OPEN
                                </div>
                              )}
                              <img
                                src={
                                  selectedLocker === getLocker[9]._id
                                    ? shortno
                                    : shortyes
                                }
                                alt="yes"
                                onClick={() => displayOpen(getLocker[9]._id, 10)}
                              />
                              {winnerLocker && winnerLocker._id !== player && (
                                <div
                                  style={{
                                    position: "absolute",
                                    top: "0",
                                    left: "0",
                                    width: "100%",
                                    height: "100%",
                                  }}
                                ></div>
                              )}
                            </>
                          </div>
                        )}
                      </div>
                    </div>
                    {getLocker && getLocker[12] && (
                      <div
                        className={`col-2  ${isLoading && selectedLocker !== getLocker[12]._id
                          ? "loading-disabled"
                          : ""
                          }`}
                      >
                        <div className="lockers-manual position-relative ">
                          <span className="dot">{13}</span>

                          <>
                            {selectedLocker === getLocker[12]._id && (
                              <div className="locker-button-text">OPEN</div>
                            )}
                            <img
                              src={
                                selectedLocker === getLocker[12]._id
                                  ? nolocker
                                  : yeslocker
                              }
                              alt="yes"
                              onClick={() => displayOpen(getLocker[12]._id, 13)}
                            />
                            {winnerLocker && winnerLocker._id !== player && (
                              <div
                                style={{
                                  position: "absolute",
                                  top: "0",
                                  left: "0",
                                  width: "100%",
                                  height: "100%",
                                }}
                              ></div>
                            )}
                          </>
                        </div>
                      </div>
                    )}
                    {getLocker && getLocker[15] && (
                      <>
                        <div
                          className={`col-2  ${isLoading && selectedLocker !== getLocker[15]._id
                            ? "loading-disabled"
                            : ""
                            }`}
                        >
                          <div className="lockers-manual position-relative ">
                            <span className="dot">{16}</span>

                            <>
                              {selectedLocker === getLocker[15]._id && (
                                <div className="locker-button-text">OPEN</div>
                              )}
                              <img
                                src={
                                  selectedLocker === getLocker[15]._id
                                    ? nolocker
                                    : yeslocker
                                }
                                alt="yes"
                                onClick={() => displayOpen(getLocker[15]._id, 16)}
                              />
                              {winnerLocker && winnerLocker._id !== player && (
                                <div
                                  style={{
                                    position: "absolute",
                                    top: "0",
                                    left: "0",
                                    width: "100%",
                                    height: "100%",
                                  }}
                                ></div>
                              )}
                            </>
                          </div>
                        </div>
                        <div className="col-1 "></div>
                      </>
                    )}
                  </div>
                </div>
              </div>
            </>
          )}
          <Modal
            isOpen={isModalOpen}
            onRequestClose={closeModal}
            contentLabel="Example Modal"
            className="modal-content"
            style={{
              overlay: {
                backgroundColor: "rgb(43 41 41 / 75%)", // Update the background color here
              },
              content: {
                backgroundColor: "white", // Update the modal content background color here
                // You can add other styling properties as needed
              },
            }}
            shouldCloseOnOverlayClick={false}
          >
            <img src={lock} alt="lock" className="modal-image" />
            <p className="modal-text mt-3">
              Do you want to release this locker?
            </p>
            <div className="modal-button">
              <button className="modal-btn1" onClick={returnLocker}>
                Yes
              </button>
              <button className="modal-btn2" onClick={closeModal}>
                No
              </button>
            </div>
          </Modal>

          <Modal
            isOpen={isModalOpen4}
            onRequestClose={closeModal}
            contentLabel="Example Modal"
            className="d-flex flex-column justify-content-center  p-3 col-lg-6 m-auto mt-5 h-100 align-items-center "
            style={{
              overlay: {
                backgroundColor: "rgb(43 41 41 / 75%)", // Update the background color here
              },
              content: {
                // Update the modal content background color here
                height: "200px"
              },
            }}
            shouldCloseOnOverlayClick={false}
          >

            <div
              className="p-3 w-sm-50 h-sm-50 col-10 rounded-3"
              style={{ background: "#537D0E" }} //"#456e01" 
            >
              <div style={{ fontFamily: "none" }} >
                <div className="d-flex flex-row gap-1">
                  <h2 className="win-heading">Scoreboard</h2>
                  {winnerLocker && winnerLocker._id === player &&
                    <img
                      src={CrossIcon}
                      alt="cross"
                      onClick={() => setIsModalOpen4(false)}
                      height={23}
                      className="mb-3"
                    />
                  }
                </div>
                <div
                  className="p-3 rounded-2 w-sm-25 " //h-75
                  style={{ background: "#7bb717" }}
                >
                  <div className="leaderboard table-responsive">
                    <table className="table table-sm">
                      <thead>
                        <tr style={{ backgroundColor: "#012267", color: "white" }}>
                          <th scope="col">Rank</th>
                          <th scope="col">Name</th>
                          <th scope="col">Status</th>
                          <th scope="col">Correct</th>
                          <th scope="col">Score</th>

                        </tr>
                      </thead>
                      <tbody>

                        {sortedUsers.map((user, index) => (
                          <tr key={index} style={{ backgroundColor: "#9FCA36", color: "black", opacity: user.status === "left" ? 0.4 : 1 }}>
                            <th scope="row">
                              {(index === 0 && user.score > 0 && (!sortedUsers[0].status === "left")) || (index === 1 && sortedUsers[0] && user.score > 0 && sortedUsers[0].status === "left") ? (
                                <span>
                                  {index + 1} <i className="fas fa-trophy" style={{ color: "rgb(167 144 10)", animation: "blink 0.8s ease-in infinite" }}></i>
                                </span>
                              ) : (
                                index + 1
                              )}
                            </th>
                            <td className="text-capitalize">
                              {user.name.split(" ")[0]}
                            </td>
                            <td className="text-capitalize" style={{ color: user.status === "join" ? "green" : "red" }}>{user.status === "join" ? "active" : "left"}</td>
                            <td>{user.correct}</td>
                            <td>{user.score}</td>

                          </tr>
                        ))}

                      </tbody>
                    </table>
                  </div>

                  {winnerLocker && winnerLocker._id === player &&
                    <div className="d-flex justify-content-center align-items-center">
                      <button className="btn btn-prim w-auto text-center" onClick={() => setIsModalOpen4(false)}>Open Locker</button>
                    </div>
                  }


                  {/* {winnerLocker && (winnerLocker._id === player || (sortedUsers[1] && sortedUsers[1]._id === player)) && (
                    <div className="d-flex justify-content-center align-items-center">
                      <button className="btn btn-prim w-auto text-center" onClick={() => setIsModalOpen4(false)}>Open Locker</button>
                    </div>
                  )} */}

                  {!sortedUsers.some(user => user.score > 0) && (
                    <div className="d-flex justify-content-center align-items-center">
                      <button className="btn btn-prim w-auto text-center" onClick={backtohome}>Retry</button>
                    </div>
                  )}

                </div>
              </div>
            </div>
          </Modal>

          <Modal
            isOpen={isModalOpen2}
            contentLabel="Example Modal"
            className="d-flex flex-column justify-content-center  p-3 col-lg-4 m-auto mt-5 h-100 align-items-center "
            shouldCloseOnOverlayClick={false}
            style={{
              content: {
                fontFamily: "Arial",
                fontWeight: "800",
                fontSize: "30px",
              },
              overlay: {
                backgroundColor: "rgba(0, 0, 0, 0.5)", // Set the overlay background color
              },
            }}
          >
            <div
              className="p-3 w-sm-50 h-sm-50 col-8 rounded-3"
              style={{ background: "#456e01" }}
            >
              <div className="d-flex flex-row">

                <h2 className="win-heading">
                  {winnerLocker && winnerLocker._id === player ? "You" : winnername}{" "}
                  Win
                </h2>
              </div>
              <div
                className="p-4 rounded-2 w-sm-25 d-flex justify-content-center align-items-center flex-column gap-3" //h-75
                style={{ background: "#7bb717" }}
              >
                {/* <img src={Gift} alt="gift" className="emoji-style-win" /> */}

                <div className="score-card-winner">
                  <p>${prize}</p>
                </div>
                <button className="btn btn-modal w-auto mt-4" onClick={backtohome}>
                  Play Again
                </button>
              </div>
            </div>
          </Modal>

          <Modal
            isOpen={isModalOpen3}
            contentLabel="Example Modal"
            className="d-flex flex-column justify-content-center  p-3 col-lg-4 m-auto mt-5 h-100 align-items-center "
            shouldCloseOnOverlayClick={false}
            style={{
              content: {
                // background: "#7BB717",
                fontFamily: "Arial",
                fontWeight: "800",
                fontSize: "30px",
              },
              overlay: {
                backgroundColor: "rgba(0, 0, 0, 0.5)", // Set the overlay background color
              },
            }}
          >
            <div
              className="p-3 w-sm-50 h-sm-50 col-10 rounded-3"
              style={{ background: "#456e01" }}
            >
              <div className="d-flex flex-row">
                <h2 className="win-heading">
                  {winnerLocker && winnerLocker._id === player ? "You" : winnername}{" "}
                  Lose
                </h2>
              </div>
              <div
                className="p-4 rounded-2 w-sm-25 d-flex justify-content-center align-items-center flex-column gap-3" //h-75
                style={{ background: "#7bb717" }}
              >
                {/* <video width="auto" height="200" className="video-area" controls>
                <source src={videoFile} type="video/mp4" />
                Your browser does not support the video tag.
              </video> */}
                <img
                  width="100%"
                  height="100%"
                  className="video-area  "
                  src={prize}
                />
                <button className="btn btn-modal w-auto" onClick={backtohome}>
                  Try Again
                </button>
              </div>
            </div>
          </Modal>
        </div>
      </div>

      <Modal
        isOpen={isReadyModalOpen}
        contentLabel="Example Modal"
        className="d-flex flex-column justify-content-center p-3 col-lg-6 m-auto mt-5 h-100 align-items-center "
        shouldCloseOnOverlayClick={false}
        style={{
          content: {
            fontFamily: "Arial",
            fontWeight: "800",
            fontSize: "30px",
          },
          overlay: {
            backgroundColor: "rgba(0, 0, 0, 0.5)", // Set the overlay background color
          },
        }}
      >
        <div
          className="p-3 w-sm-50 h-sm-50 col-8 rounded-3"
          style={{ background: "#456e01" }}
        >
          <div className="d-flex flex-row">
            <h2 className="win-heading ">Ready</h2>
          </div>
          <div
            className="p-4 rounded-2 w-sm-25 d-flex justify-content-center align-items-center flex-column gap-4" //h-75
            style={{ background: "#7bb717" }}
          >
            <h2 className="text-center ">Do you want to open locker no <span className="badge bg-warning">{relayNumber}</span> ?</h2>
            <div className="d-flex justify-content-center align-items-center flex-row gap-3">
              <button
                className="btn btn-modal w-auto px-4"
                onClick={handleConfirm}
              >
                Yes
              </button>
              <button
                className="btn btn-modal w-auto px-4"
                onClick={closeModal}
              >
                No
              </button>
            </div>
          </div>
        </div>
      </Modal>
    </div>
  );
};

export default DashboardScr;

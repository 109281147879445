import React from "react";
import Facebook from "../../assets/facebook.png";
import Twitter from "../../assets/twitter.png";
import Youtube from "../../assets/youtube.png";
import Twitch from "../../assets/twitch.png";
import { Link } from "react-router-dom";

const Footer = () => {
  return (
    <section className="footer-section py-4 bg-primary text-white">
      <div className="mx-2 mx-sm-5 mt-5 px-5">
        <div className="logo-footer"></div>
        <div className="row row-cols-2 row-cols-sm-4">
          <div className="my-3 d-flex flex-column align-items-center">
            <div>
              <h4 style={{ fontWeight: "700" }}>ABOUT US</h4>
              <p className="mt-4" style={{ textAlign: "left" }}>
                Unleash your knowledge, crack open the lockers, and let the cash
                flow in – all while having a good laugh along the way. Get ready
                for a quiz experience like no other
              </p>
            </div>
          </div>
          <div className="my-3 d-flex flex-column align-items-center">
            <div className="d-flex flex-column gap-3">
              <h4 style={{ fontWeight: "700" }}>LINKS</h4>
              <Link className="text-decoration-none text-white" to="/about-us">
                About Us{" "}
              </Link>
              <Link className="text-decoration-none text-white" to="/">
                How It Works{" "}
              </Link>
              <Link className="text-decoration-none text-white" to="/">
                Careers{" "}
              </Link>
              <Link className="text-decoration-none text-white" to="/">
                Customer Reviews
              </Link>
            </div>
          </div>
          <div className="my-3 d-flex flex-column align-items-center">
            <div className="d-flex flex-column gap-3">
              <h4 style={{ fontWeight: "700" }}>SUPPORT</h4>
              <Link
                to="/contact-us"
                className="text-decoration-none text-white"
              >
                Contact Us
              </Link>
              <Link className="text-decoration-none text-white" to="/">
                info@lockers.com
              </Link>
              <Link className="text-decoration-none text-white" to="/">
                (+988) 123-123-123
              </Link>
            </div>
          </div>
          <div className="my-3 d-flex flex-column align-items-center">
            <div className="d-flex flex-column gap-3">
              <h4 style={{ fontWeight: "700" }}>FOLLOW</h4>
              <div className="d-flex flex-column gap-3">
                <Link target="_blank" to="https://www.facebook.com/" className="d-flex text-decoration-none text-white align-items-center gap-2">
                  <img src={Facebook} /> Facebook
                </Link>
                <Link target="_blank" to="https://twitter.com/login" className="d-flex text-decoration-none text-white align-items-center gap-2">
                  <img src={Twitter} /> Twitter
                </Link>
                <Link target="_blank" to="https://www.twitch.tv/" className="d-flex text-decoration-none text-white align-items-center gap-2">
                  <img src={Twitch} /> Twitch
                </Link>
                <Link target="_blank" to="https://www.youtube.com/" className="d-flex text-decoration-none text-white align-items-center gap-2">
                  <img src={Youtube} /> Youtube
                </Link>
              </div>
            </div>
          </div>
        </div>
        <hr className="text-white "></hr>
        <div className="d-flex justify-content-between  flex-md-row flex-column">
          <div>
            <p>Copyright © 2024 All Rights Reserved By Lok3r Trivia</p>
          </div>
          <div className=""></div>
          <div className="d-flex align-items-center gap-2">
            Privacy Terms & Conditions
          </div>
        </div>
      </div>
    </section>
  );
};

export default Footer;

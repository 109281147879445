import React, { useEffect, useState } from "react";
import { Answer } from "../question/QuestionsAnswer";
import { Link, useNavigate } from "react-router-dom";
import logo from "../../assets/logo-dark.png";
import Profile from "../../images/question-profile.gif";
import Timer from "../../images/timer.gif";
import Emoji from "../../images/sad-emoji.gif";
import Mute from "../../images/new images/vol-on16.png";
import UnMute from "../../images/new images/vol-off16.png";
import Firework1 from "../../assets/Gift/firework3.gif";
import { toast } from "react-toastify";
import Modal from "react-modal";
import "../../puzzlestyle.css";
import "./style.css";
import backgroundMusic from "../../assets/audio/start_question.mp3";
import backgroundMusicWin from "../../assets/audio/win.mp3";
import backgroundMusicLost from "../../assets/audio/lost.mp3";
import backgroundMusicClick from "../../assets/audio/click.mp3";
import { useDispatch, useSelector } from "react-redux";
import { logout } from "../Redux/Authentication";
import IconLogout from "../../assets/icons/icon-logout.svg";
import Dashboard from "../../assets/icons/icon-doors.svg";
import CrossIcon from "../../assets/icons/cross_icon.svg";
import Home from "../../assets/icons/Home.svg";
import Coinses from "../../assets/icons/coin-btn.svg";
import Retry from "../../assets/icons/Retry.svg";
import Cash from "../../assets/icons/cash-btn.svg";
import Tick from "../../assets/icons/Tick.svg";
import Locker from "../../assets/icons/lockerss.svg";
import Adds from "../../assets/Testing/adds-img.png";
import Logo from "../../assets/Testing/logo_black.png";
import YellowStars from "../../assets/yellow-star.png";
import RedStars from "../../assets/red-star.png";
import MoneyBag from "../../assets/icons/Money-Bag.svg";
import Coins from "../../assets/icons/Coin-modal.svg";
import Loader from "../partials/Loader";
import List from "./../Lockers/List";
const url = process.env.REACT_APP_APP_BACK_URL;

const QuestionPage = () => {
  let navigate = useNavigate();
  const [loader, setLoader] = useState(false);
  const [questions, setQuestions] = useState([]);
  const [gif, setGif] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [gametype, setGameType] = useState("");
  const [currentQuestionIndex, setCurrentQuestionIndex] = useState(0);
  const [score, setScore] = useState(0);
  const [points, setPoints] = useState(0);
  const [winningNumber, setWinningNumber] = useState(0);
  const [timeRemaining, setTimeRemaining] = useState(10);
  const [adsTime, setAdsTime] = useState(0); // Set the initial time limit
  const [isWinModalOpen, setIsWinModalOpen] = useState(false);
  const [isLoseModalOpen, setIsLoseModalOpen] = useState(false);
  const [isReadyModalOpen, setIsReadyModalOpen] = useState(false);
  const currentQuestion = questions[currentQuestionIndex];
  const remainingNumberOfQuestions = questions.length - currentQuestionIndex;
  const [backgroundAudio] = useState(new Audio(backgroundMusic));
  const [backgroundAudioWin] = useState(new Audio(backgroundMusicWin));
  const [backgroundAudioLost] = useState(new Audio(backgroundMusicLost));
  const [backgroundAudioClick] = useState(new Audio(backgroundMusicClick));
  const [isGameEnd, setIsGameEnd] = useState(false);
  const [isGameStart, setIsGameStart] = useState(false);
  const [isAddModalOpen, setIsAddModalOpen] = useState(false);
  const isAuthenticated = useSelector((state) => state.user);
  const [coins, setCoins] = useState(
    isAuthenticated ? isAuthenticated.coin : 100
  );
  const dispatch = useDispatch();

  const gameType = async (game_type) => {
    setGameType(game_type);
    handleAgainPlay();

  };

  useEffect(() => {
    // getQuestions().then((res) => setQuestions(res));
    setIsReadyModalOpen(true);
    getAdds();
  }, []);
  useEffect(() => {
    return () => {
      // Clean up: Stop the background music when the component unmounts
      backgroundAudio.pause();
      backgroundAudio.currentTime = 0;
    };
  }, [backgroundAudio]);
  useEffect(() => {
    if (isGameEnd) {
      backgroundAudio.pause();
      backgroundAudio.currentTime = 0;
    }
  }, [isGameEnd, backgroundAudio]);
  useEffect(() => {
    if (adsTime > 0) {
      const timer = setInterval(() => {
        setAdsTime((prevTime) => prevTime - 1);
      }, 1000);
      return () => clearInterval(timer);
    }
    if (remainingNumberOfQuestions > 0 && adsTime === 0) {
      handleNextQuestion();
      setIsAddModalOpen(false);
    }
  }, [adsTime]);
  useEffect(() => {
    if (isGameStart) {
      // Set up the timer when the question changes
      const timer = setInterval(() => {
        setTimeRemaining((prevTime) => prevTime - 1);
      }, 1000);

      // When timeRemaining reaches 0, move to the next question
      if (remainingNumberOfQuestions > 0 && timeRemaining === 0) {
        handleNextQuestion();
      }

      // Clear the timer when the component unmounts or when moving to the next question
      return () => clearInterval(timer);
    }
  }, [timeRemaining, currentQuestionIndex, isGameStart]);
  const [credentials, setCredentials] = useState({ name: "" });
  const [isCorrect, setIsCorrect] = useState(false);
  const [isFalse, setIsFalse] = useState(false);
  const handleInput = (e) => {
    setCredentials({ ...credentials, [e.target.name]: e.target.value });
  };
  const [isMuted, setIsMuted] = useState(true);

  const openModal = () => {
    setIsLoseModalOpen(true);
  };

  const handleToggleMute = async () => {
    {
      if (!isMuted) {

        backgroundAudio.play();
      } else {
        backgroundAudio.pause();
      }
      setIsMuted(!isMuted);
    }
  };
  const getQuestions = async () => {
    try {
      const response = await fetch(
        "https://the-trivia-api.com/api/questions?limit=10&&region=US&&difficulties=easy&&categories=music,sport_and_leisure,film_and_tv,food_and_drink"
      );
      const questions = await response.json();
      // if (backgroundAudio.paused && backgroundAudio.currentTime === 0) {
      // backgroundAudio.play();
      // }
      setIsGameStart(true);
      return questions;
    } catch (error) {
      handleAgainPlay();
    }
  };
  const handleGuessAnswer = (guess) => {
    backgroundAudioClick.play();
    if (guess === currentQuestion.correctAnswer) {
      const c_score = parseInt(score) + 1;
      const g_ppints = parseInt(points) + 5;
      setScore(c_score, () => { });
      setPoints(g_ppints, () => { });
      setIsCorrect(true);
      // setCoins(coins + 5);
    } else {
      const d_pints = parseInt(points) - 5;
      setPoints(d_pints, () => {
      });
      setIsFalse(true);
      // setCoins(coins - 5);
    }
    if (score >= 10) {
      setPoints(points + 50);
    }
    setTimeout(() => {
      handleNextQuestion();
    }, 1500);
    // setCurrentQuestionIndex(currentQuestionIndex + 1);
  };
  const getGifForQuestion = () => {
    // Logic to determine the GIF path based on the current question number
    // Replace this with your actual logic
    return Timer;
  };
  const dispayAdds = async (number) => {
    try {
      let headers = new Headers();
      headers.append("Content-Type", "application/json");
      headers.append("Access-Control-Allow-Origin", "*");
      headers.append("Access-Control-Allow-Credentials", "true");

      const response = await fetch(`${url}/api/setting/get/ad/${number}`, {
        mode: "cors",
        method: "GET",
        headers: headers,
      });
      const json = await response.json();
      if (json.success) {
        setCurrentAd(json.ads);
        setAdsTime(json.ads.time);
        setIsAddModalOpen(true);
      }
    } catch (error) {
      toast.error("Internal Server Eror !");
    }
  };
  const handleNextQuestion = () => {
    const currentIndex = currentQuestionIndex + 1;
    const adToShow = ads.includes(currentIndex.toString());
    if (adToShow) {
      dispayAdds(currentIndex);
      setAds((prevAds) =>
        prevAds.filter((ad) => ad !== currentIndex.toString())
      );
    } else {
      setIsCorrect(false);
      setIsFalse(false);
      setTimeRemaining(10);
      if (backgroundAudio.paused && backgroundAudio.currentTime === 0) {
        backgroundAudio.play();
      }
      if (remainingNumberOfQuestions > 1) {
        setCurrentQuestionIndex(currentQuestionIndex + 1);
      } else {
        if (currentQuestionIndex > 0) {
          setGif(true);
          if (parseInt(score) >= parseInt(winningNumber)) {
            setIsGameEnd(true);
            setIsWinModalOpen(true);
            backgroundAudioWin.play();
          } else if (parseInt(score) < parseInt(winningNumber)) {
            setIsGameEnd(true);
            handleOpenLocker();
            backgroundAudioLost.play();
            setIsLoseModalOpen(true);
          }
        }
      }
    }
  };
  const handleAgainPlay = () => {
    setIsReadyModalOpen(false);
    setIsLoseModalOpen(false);
    setLoader(true);
    setGif(false);
    setIsMuted(true);
    getQuestions()
      .then((res) => {
        setQuestions(res);
        setLoader(false); // Hide the loader after questions are fetched
      })
      .catch((error) => {
        // Handle error if needed
        console.error("Error fetching questions:", error);
        setLoader(false); // Hide the loader in case of error
      });
    getAdds();
    setTimeRemaining(10);
    setScore(0);
    setPoints(0);
    setCurrentQuestionIndex(0);

    setIsGameEnd(false);

    backgroundAudio.play();
    setTimeout(() => {
      setLoader(false);
    }, 5000);
  };

  const [isDropdownVisible, setDropdownVisible] = useState(false);

  const handleImageClick = () => {
    setDropdownVisible(!isDropdownVisible);
  };

  const handleUserLogout = async (e) => {
    const logouts = localStorage.removeItem("token");
    dispatch(logout());
    navigate("/");
  };
  const handleOpenLocker = async () => {
    try {
      let headers = new Headers();
      headers.append("Content-Type", "application/json");
      headers.append("Access-Control-Allow-Origin", "*");
      headers.append("Access-Control-Allow-Credentials", "true");
      headers.append("auth-token", localStorage.getItem("token"));
      setIsLoading(true);
      const response = await fetch(`${url}/api/prizes/winer/add`, {
        mode: "cors",
        method: "POST",
        headers: headers,
        body: JSON.stringify({
          score: score,
          points: points,
          game_type: gametype,
          game_status: "lose",
        }),
      });
      setIsLoading(false);
      const json = await response.json();
      if (json.success) {
        if (score >= parseInt(winningNumber)) {
          navigate(`/winer/lockers/${json.winer}`);
        }
      }
    } catch (error) {
      toast.error("Internal Server Eror !");
    }
  };

  const handleOpenUpdatedLocker = async (newScore, newpoints) => {
    try {
      let headers = new Headers();
      headers.append("Content-Type", "application/json");
      headers.append("Access-Control-Allow-Origin", "*");
      headers.append("Access-Control-Allow-Credentials", "true");
      headers.append("auth-token", localStorage.getItem("token"));
      setIsLoading(true);
      const response = await fetch(`${url}/api/prizes/winer/add`, {
        mode: "cors",
        method: "POST",
        headers: headers,
        body: JSON.stringify({
          score: newScore,
          points: newpoints,
          game_type: gametype,
          game_status: "wins",
        }),
      });
      setIsLoading(false);
      const json = await response.json();
      if (json.success) {
        // if (score >= parseInt(winningNumber)) {
        navigate(`/winer/lockers/${json.winer}`);
        // }
      }
    } catch (error) {
      toast.error("Internal Server Eror !");
    }
  };
  const [ads, setAds] = useState([]);
  const [currentAd, setCurrentAd] = useState({});
  const getAdds = async () => {
    try {
      let headers = new Headers();
      headers.append("Content-Type", "application/json");
      headers.append("Access-Control-Allow-Origin", "*");
      headers.append("Access-Control-Allow-Credentials", "true");

      const response = await fetch(`${url}/api/setting/get/ads`, {
        mode: "cors",
        method: "GET",
        headers: headers,
      });
      setIsLoading(false);
      const json = await response.json();
      if (json.success) {
        setWinningNumber(json.number.number);
        const selectedValues = json.ads.map((option) => option.number);
        setAds(selectedValues);
      }
    } catch (error) {
      toast.error("Internal Server Eror !");
    }
  };

  const handleCloseModal = async () => {
    setIsReadyModalOpen(false)
    navigate("/")

  }

  return (
    <div className="question-container">
      {loader ? (
        <Loader />
      ) : (
        <>
          <header className="header postion-relative">
            <div className="container">
              <div className="row">
                <div className="d-flex justify-content-between align-items-center">
                  <div className="header__section">
                    <div className="d-flex justify-content-start">
                      <Link to={"/"} className="">
                        <img
                          src={logo}
                          alt="logo"
                          className="object-fit-contain w-75 mx-auto"
                        />
                      </Link>
                      {gametype === "coin" && (
                        <div className="coins____header d-flex justify-content-center align-items-center">
                          <div className="coins____body d-flex justify-content-center align-items-center">
                            <span className="coins-number">{coins}</span>
                          </div>
                        </div>
                      )}
                    </div>
                  </div>

                  <div className="header__section ">
                    <div className="d-flex  justify-content-center justify-content-sm-center">
                      <Link
                        className=" d-flex flex-column bg-white text-black p-2 text-decoration-none text-center rounded-3"
                        style={{ width: "auto", height: "auto" }}
                        onClick={handleToggleMute}
                      >
                        {isMuted ? (
                          <img src={Mute} alt="muted" />
                        ) : (
                          <img src={UnMute} alt="unmuted" />
                        )}
                      </Link>
                    </div>
                  </div>

                  <div className="header__section">
                    <div className="position-relative d-flex justify-content-end gap-5">
                      <img
                        src={isAuthenticated?.image || Profile}
                        alt="logo"
                        className="mt-2"
                        height={60}
                        style={{
                          cursor: "pointer",
                          width: "35px",
                          height: "35px",
                          borderRadius: "50px",
                          objectFit: "cover",
                        }}
                        onClick={handleImageClick}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </header>
          {isDropdownVisible && (
            <div
              style={{
                position: "absolute",
                top: "50px",
                right: "7rem",
                border: "1px solid #ccc",
                background: "#fff",
                zIndex: "99999999999",
              }}
              className="popup__menu rounded p-1"
            >
              <ul className="p-1 header__dropdownMenu">
                <li
                  onClick={() => navigate("/user/dashboard")}
                  className="header__dropdownMenuItem px-4 d-flex align-items-center gap-2"
                >
                  <img src={Dashboard} alt="icon" />
                  <span>Dashboard</span>
                </li>
                <li
                  onClick={handleUserLogout}
                  className="header__dropdownMenuItem px-4 d-flex align-items-center gap-2"
                >
                  <img src={IconLogout} alt="icon" />
                  <span>Logout</span>
                </li>
              </ul>
            </div>
          )}

          <div
            className="container"
            style={{ marginTop: "2vh", overflowY: "scroll" }}
          >
            {currentQuestion && (
              <>
                <Answer
                  isCorrect={isCorrect}
                  isFalse={isFalse}
                  question={currentQuestion}
                  index={currentQuestionIndex}
                  currentQuestionIndex={currentQuestionIndex}
                  onGuessAnswer={handleGuessAnswer}
                  gif={gif}
                  remainingTime={timeRemaining}
                  gametype={gametype}
                />
              </>
            )}
          </div>
        </>
      )}
      <Modal
        isOpen={isLoseModalOpen}
        contentLabel="Example Modal"
        className="d-flex flex-column justify-content-center p-3 col-lg-4 m-auto mt-5 h-100 align-items-center "
        shouldCloseOnOverlayClick={false}
        style={{
          content: {
            fontFamily: "Arial",
            fontWeight: "900",
            fontSize: "30px",
          },
          overlay: {
            backgroundColor: "rgba(0, 0, 0, 0.5)", // Set the overlay background color
          },
        }}
      >
        {parseInt(score) >= parseInt(winningNumber) ? (<div
          className="p-3 w-sm-50 h-sm-50  col-10 rounded-3 position-relative"
          style={{ background: "linear-gradient(to bottom, #96C720, #618F15)" }}
        >
          <h2 className="win-heading">You Win</h2>
          <div
            className="p-2 rounded-2 w-sm-25 "
            style={{ background: "#578115" }}
          >
            <img src={Firework1} alt="sad" className="emoji-style-win" />
            {/* <img src={Firework1} alt="sad" className="emoji-style-win-2" /> */}
            <div className="d-flex justify-content-center align-items-center">
              <img src={YellowStars} alt="star" />
            </div>
            <div
              className=" d-flex justify-content-center align-items-center"
              style={{
                fontFamily: "Arial",
                fontSize: "50px",
                color: "white",
              }}
            >
              <p>{score < 10 ? "0" + score : score}</p>
            </div>
            <h2
              className="text-center text-white mt-1"
              style={{ fontFamily: "Arial", fontWeight: "700" }}
            >
              Score
            </h2>
            <div className="d-flex flex-row gap-5 justify-content-center align-items-center mt-4">
              {/* <img
                src={Retry}
                alt="Home"
                onClick={() => navigate("/question-page")}
                height={50}
                width={50}
              /> */}
              <img
                src={Locker}
                alt="locker"
                onClick={() => handleOpenUpdatedLocker(score, points)}
                height={50}
                width={50}
              />
            </div>

            {/* <button
              className="btn btn-modal  position-relative "
              onClick={handleOpenLocker}
              disabled={isLoading}
            >
              {isLoading ? "Opening..." : " Open Locker"}
            </button> */}
          </div>
        </div>) : (
          <div
            className="p-3 w-sm-50 h-sm-50  col-10 rounded-3  position-relative"
            style={{ background: "linear-gradient(to bottom, #F94C4C, #A30909)" }}
          >
            <h2 className="win-heading">You Lose</h2>
            <div
              className="d-flex flex-column align-items-center p-2 rounded-2 w-sm-25  "
              style={{ background: "#600606" }}
            >
              <img src={Emoji} alt="sad" className="emoji-style-lose" />
              <div className="d-flex justify-content-center align-items-center">
                <img src={RedStars} alt="star" />
              </div>

              <div
                className=" d-flex justify-content-center align-items-center"
                style={{
                  fontFamily: "Arial",
                  fontSize: "50px",
                  color: "white",
                }}
              >
                <p>{score < 10 ? "0" + score : score}</p>
              </div>

              <h2
                className="text-center text-white mt-1"
                style={{ fontFamily: "Arial", fontWeight: "700" }}
              >
                Score
              </h2>
              <div className="d-flex flex-row gap-5 justify-content-center align-items-center mt-4">
                <img
                  src={Retry}
                  alt="Home"
                  onClick={handleAgainPlay}
                  height={50}
                  width={50}
                />
                <img
                  src={Home}
                  alt="Retry"
                  onClick={() => navigate("/user/dashboard")}
                  height={50}
                  width={50}
                />
              </div>
              {/* <button className="btn btn-modal " onClick={handleAgainPlay}>
              Play Again
            </button>
            <Link className="btn btn-modal " to={"/user/dashboard"}>
              Go To Dashboard
            </Link> */}
            </div>
          </div>
        )}
      </Modal>

      <Modal
        isOpen={isWinModalOpen}
        contentLabel="Example Modal"
        className="d-flex flex-column justify-content-center p-3 col-lg-4 m-auto mt-5 h-100 align-items-center "
        shouldCloseOnOverlayClick={false}
        style={{
          content: {
            fontFamily: "Arial",
            fontWeight: "800",
            fontSize: "30px",
            minHeight: "-webkit-fill-available",
          },
          overlay: {
            backgroundColor: "rgba(0, 0, 0, 0.5)",
          },
        }}
      >
        <div
          className="p-3 w-sm-50 h-sm-50  col-10 rounded-3 position-relative"
          style={{ background: "linear-gradient(to bottom, #96C720, #618F15)" }}
        >
          <h2 className="win-heading">You Win</h2>
          <div
            className="p-2 rounded-2 w-sm-25 "
            style={{ background: "#578115" }}
          >
            <img src={Firework1} alt="sad" className="emoji-style-win" />
            {/* <img src={Firework1} alt="sad" className="emoji-style-win-2" /> */}
            <div className="d-flex justify-content-center align-items-center">
              <img src={YellowStars} alt="star" />
            </div>
            <div
              className=" d-flex justify-content-center align-items-center"
              style={{
                fontFamily: "Arial",
                fontSize: "50px",
                color: "white",
              }}
            >
              <p>{score < 10 ? "0" + score : score}</p>
            </div>
            <h2
              className="text-center text-white mt-1"
              style={{ fontFamily: "Arial", fontWeight: "700" }}
            >
              Score
            </h2>
            <div className="d-flex flex-row gap-5 justify-content-center align-items-center mt-4">
              {/* <img
                src={Retry}
                alt="Home"
                onClick={() => navigate("/question-page")}
                height={50}
                width={50}
              /> */}
              <img
                src={Locker}
                alt="Retry"
                onClick={() => handleOpenUpdatedLocker(score, points)}
                height={50}
                width={50}
              />
            </div>

            {/* <button
              className="btn btn-modal  position-relative "
              onClick={handleOpenLocker}
              disabled={isLoading}
            >
              {isLoading ? "Opening..." : " Open Locker"}
            </button> */}
          </div>
        </div>
      </Modal>

      <Modal
        isOpen={isReadyModalOpen}
        contentLabel="Example Modal"
        className="d-flex flex-column justify-content-center p-3 col-lg-4 m-auto mt-5 h-100 align-items-center "
        shouldCloseOnOverlayClick={false}
        style={{
          content: {
            fontFamily: "Arial",
            fontWeight: "800",
            fontSize: "30px",
          },
          overlay: {
            backgroundColor: "rgba(0, 0, 0, 0.5)", // Set the overlay background color
          },
        }}
      >
        <div
          className="p-3 w-sm-50 h-sm-50 col-10 rounded-3"
          style={{ background: "#012269" }}
        >
          <div className="d-flex flex-row gap-2">
            <h6 className="win-heading ">Are you Ready?</h6>
            <div className="rounded-full" style={{ marginTop: "-10px" }}>
              <img src={CrossIcon} alt="cross" height={25} width={25} onClick={handleCloseModal} />
            </div>
          </div>
          <div
            className="p-4 rounded-2 w-sm-25 " //h-75
            style={{ background: "#031947" }}
          >
            <h5
              className="text-center text-white mt-2 "
              style={{
                fontFamily: "Arial",
                fontSize: "25px",
                fontWeight: "700",
              }}
            >
              Do You Want To Play?
            </h5>

            <div className="d-flex flex-row gap-5 justify-content-center align-items-center mt-4">
              <img
                src={Coinses}
                alt="Home"
                onClick={() => gameType("coin")}
                height={50}
                width={50}
              />
              <img
                src={Cash}
                alt="Retry"
                onClick={() => gameType("cash")}
                height={50}
                width={50}
              />
            </div>
          </div>
          <div className="d-flex justify-content-center flex-column ">
            <div className="d-flex align-items-center  flex-row gap-2">
              <img src={Coinses} alt="moneyBag" height={30} width={30} />
              <p
                className="text-white text-uppercase mt-3"
                style={{ fontSize: "9px", fontFamily: "Liberation Sans" }}
              >
                The coins game can be played for free!
              </p>
            </div>
            <div className="d-flex align-items-center  flex-row gap-2">
              <img src={Cash} alt="coins" height={30} width={30} />
              <p
                className="text-white text-uppercase  mt-3"
                style={{ fontSize: "9px", fontFamily: "Liberation Sans" }}
              >
                To play Cash Prize game, you must first credit your account with
                $10 and subscribe for $2 per month.
              </p>
            </div>
          </div>
        </div>
      </Modal>

      <div className="">  {/*  className="Ads modal" */}
        <Modal
          isOpen={isAddModalOpen}
          contentLabel="Example Modal"
          className="d-flex flex-column justify-content-center col-lg-12 col-sm-12 m-auto p-3 h-100 align-items-center "
          shouldCloseOnOverlayClick={false}
          style={{
            content: {
              fontFamily: "Arial",
              fontWeight: "800",
              fontSize: "30px",
              zIndex: "9999999",
              position: "absolute",
            },
            overlay: {
              backgroundColor: "rgba(0, 0, 0, 0.5)",
            },
          }}
        >
          <div
            className="p-2 w-sm-25 h-sm-50  col-sm-12 col-12 my-5"
            style={{
              background: "#fff",
              overflowY: "scroll",
              maxHeight: "100vh",
            }}
          >
            <div
              className="p-2 rounded-2 w-sm-25 "
              style={{ background: "#fff" }}
            >
              <div className="d-flex justify-content-between ">
                <a href={`${currentAd.link}`} target="_blank">
                  <img src={Logo} alt="logo" style={{ height: "30px" }} />
                </a>
                <div
                  style={{
                    height: "45px",
                    width: "45px",
                    border: "2px solid black",
                    borderRadius: "50px",
                  }}
                  className="ads-timer-circle"
                >
                  <span className="ads-timer">{adsTime}</span>
                </div>
              </div>
              <div className="col-12 mt-1">
                <div className="modal___text">
                  <h6
                    style={{ fontSize: "12px", fontWeight: "700" }}
                    className="text-center  "
                  >
                    <span
                      dangerouslySetInnerHTML={{
                        __html: currentAd.description,
                      }}
                    />
                  </h6>
                </div>
                <div className="w-full d-flex justify-content-center ">
                  <img
                    className="align-items-center mx-auto  object-fit-contain  mb-3 "
                    src={currentAd.file}
                    // src={Adds}
                    style={{
                      height: "350px",
                      width: "100%",
                      objectFit: "contain",
                    }}
                  />
                  {/* <img
                  className="align-items-center mx-auto  object-fit-contain  mb-3 "
                  src={Adds}
                  style={{ height: "350px", width: "100%", objectFit: "contain" }}
                /> */}
                </div>
              </div>
              <div className="d-flex justify-content-center align-items-center">
                <a
                  href={`${currentAd.link}`}
                  target="_blank"
                  className="btn btn-modal   mt-2 mb-3"
                  style={{ width: "200px" }}
                >
                  More Info
                </a>
              </div>
            </div>
          </div>
        </Modal>
      </div>
    </div>
  );
};

export default QuestionPage;

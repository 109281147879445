import React, { useEffect, useState, useRef } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import { Answer } from "../question/MultiQuestionAnswer.js";
import logo from "../../assets/logo-dark.png";
import ins1 from "../../assets/ins1.png";
import ins2 from "../../assets/ins2.png";
import ins3 from "../../assets/ins3.png";
import Profile from "../../images/question-profile.gif";
import Mute from "../../images/new images/vol-on16.png";
import UnMute from "../../images/new images/vol-off16.png";
import { toast } from "react-toastify";
import Modal from "react-modal";
import "../../puzzlestyle.css";
import "./style.css";
import backgroundMusic from "../../assets/audio/start_question.mp3";
import backgroundMusicClick from "../../assets/audio/click.mp3";
import backgroundMusicLose from "../../assets/audio/wahwah.mp3";
import { useDispatch, useSelector } from "react-redux";
import { logout } from "../Redux/Authentication";
import IconLogout from "../../assets/icons/icon-logout.svg";
import Dashboard from "../../assets/icons/icon-doors.svg";
import Play from "../../assets/icons/Play.svg";
import User from "../../assets/user-white.png";

import Loader from "../partials/Loader";
import io from "socket.io-client";

const url = process.env.REACT_APP_APP_BACK_URL;

const socket = io.connect(url);
const MultiQuestionPage = () => {
  let navigate = useNavigate();
  const { id } = useParams();
  const [game_new_id, setGame_new_id] = useState(null);
  const [loader, setLoader] = useState(false);
  const [questions, setQuestions] = useState([]);
  const [gif, setGif] = useState(false);
  const [, setIsLoading] = useState(false);
  const [isRefresh, setIsRefresh] = useState(true);
  const [gametype, setGameType] = useState("");
  const [currentQuestionIndex, setCurrentQuestionIndex] = useState(0);
  const [questionInc, setQuestionInc] = useState(0);
  const [score, setScore] = useState(0);
  const [points, setPoints] = useState(100);
  const [passpoints, setpasspoints] = useState(100);
  const [isQuestionHandled, setIsQuestionHandled] = useState(false);
  const [winningNumber, setWinningNumber] = useState(0);
  const [timeRemaining, setTimeRemaining] = useState(60);
  const [adsTime, setAdsTime] = useState(0); // Set the initial time limit
  const [isWinModalOpen, setIsWinModalOpen] = useState(false);
  const [isinstructionModalOpen, setisinstructionModalOpen] = useState(false);
  const [isLoseModalOpen, setIsLoseModalOpen] = useState(false);
  const [isReadyModalOpen, setIsReadyModalOpen] = useState(false);
  const [playerOnline, setIsPlayerOnline] = useState(false);
  const [handleQuestion, setHandleQuestion] = useState(false);
  const currentQuestion = questions[currentQuestionIndex];
  const remainingNumberOfQuestions = questions.length - currentQuestionIndex;
  const [backgroundAudio] = useState(new Audio(backgroundMusic));
  const [backgroundAudioClick] = useState(new Audio(backgroundMusicClick));
  const [backgroundAudioLose] = useState(new Audio(backgroundMusicLose));
  const [isGameEnd, setIsGameEnd] = useState(false);
  const [isGameStart, setIsGameStart] = useState(true);
  const [playerData, setPlayerData] = useState([]);
  const queryParams = new URLSearchParams(window.location.search);
  const player = queryParams.get("p");
  const position = parseInt(queryParams.get("position")) || 0;
  const [playersDetail, setPlayersDetail] = useState();
  const isAuthenticated = useSelector((state) => state.user);
  const [winnerLocker, setWinnerLocker] = useState();
  const [updatestatus, setupdatestatus] = useState();
  const [opacitylow, setopacitylow] = useState(1);
  const [seletedPlayers, setSeletedPlayers] = useState([]);
  const [stopModal, setStopModal] = useState(false);
  const [mainPlayrLeft, setMainPlayrLeft] = useState(false);
  const dispatch = useDispatch();

  const gameType = async (game_type) => {
    setGameType(game_type);
  };

  useEffect(() => {
    socket.emit("gameID", player);
    socket.on("userStartGame", () => {
      handleAgainPlay();

      backgroundAudio.play();
    });
    socket.on("startNewQuestion", () => {
      // setIsQuestionHandled(false);
      // getQuestions();
      handleNextQuestion();
    });

    socket.on("eliminateGame", () => {
      console.log('game eliminated');
      checkPlayer();
      // setMainPlayrLeft(true);
      // toast.error("Main Player left  the game");
      // navigate("/");
    });

    socket.on("displayOnlineUsers", () => {
      getPlayers();
    });

    // When timeRemaining reaches 0, move to the next question
  }, []);
  useEffect(() => {
    // if (isGameStart) {
    // Set up the timer when the question changes
    // const timer = setInterval(() => {
    //   setTimeRemaining((prevTime) => prevTime - 1);
    // }, 1000);

    const timer = setInterval(() => {
      setTimeRemaining((prevTime) => {
        if (prevTime % 10 === 0) {
          if (prevTime <= 50) {
            if (!handleQuestion) {
              setpasspoints((prevPoints) => prevPoints - 17);
            }
          }
        }
        return prevTime - 1;
      });
    }, 1000);

    // When timeRemaining reaches 0, move to the next question
    if (
      remainingNumberOfQuestions > 0 &&
      timeRemaining === 0 &&
      currentPlayer.type.toLowerCase() === "main"
    ) {
      updateQuestion();
      socket.emit("handleNext", seletedPlayers);
    }

    // Clear the timer when the component unmounts or when moving to the next question
    return () => clearInterval(timer);
    // }
  }, [
    timeRemaining,
    handleQuestion,
    currentQuestionIndex,
    isGameStart,
    passpoints,
  ]);
  useEffect(() => {
    // getCurrentPlayer();
    localStorage.setItem("gameID", id);
  }, [id]);
  useEffect(() => {
    // getCurrentPlayer();
    getQuestions("use effect");
  }, [game_new_id]);

  useEffect(() => {
    return () => {
      // Clean up: Stop the background music when the component unmounts
      backgroundAudio.pause();
      backgroundAudio.currentTime = 0;
    };
  }, [backgroundAudio]);
  useEffect(() => {
    const handlePageHide = async (event) => {
      event.preventDefault(); // Cancel the default behavior
      await UpdatePlayerStatus(seletedPlayers);

    };

    window.addEventListener("pagehide", handlePageHide);

    return () => {
      window.removeEventListener("pagehide", handlePageHide);
    };
  }, [seletedPlayers]);

  useEffect(() => {
    if (isGameEnd) {
      backgroundAudio.pause();
      backgroundAudio.currentTime = 0;
    }
  }, [isGameEnd, backgroundAudio]);

  const [credentials, setCredentials] = useState({ name: "" });
  const [isCorrect, setIsCorrect] = useState(false);
  const [isFalse, setIsFalse] = useState(false);
  const [currentPlayer, setCurrentPlayer] = useState(false);
  const handleInput = (e) => {
    setCredentials({ ...credentials, [e.target.name]: e.target.value });
  };
  const [isMuted, setIsMuted] = useState(true);

  const handleToggleMute = async () => {
    {
      if (!isMuted) {
        backgroundAudio.play();
      } else {
        backgroundAudio.pause();
      }
      setIsMuted(!isMuted);
    }
  };
  const getQuestions = async () => {
    try {
      let headers = new Headers();
      headers.append("Content-Type", "application/json");
      headers.append("Access-Control-Allow-Origin", "*");
      headers.append("Access-Control-Allow-Credentials", "true");

      const response = await fetch(
        `${url}/api/multiplayer/get/question/${localStorage.getItem(
          "gameID"
        )}/${player}`,
        {
          mode: "cors",
          method: "GET",
          headers: headers,
        }
      );
      const json = await response.json();
      if (json.success) {
        setCurrentPlayer(json.currentPlayer);
        if (json.currentPlayer.status == "left") {
          toast.error("You already left the game !");
          navigate(`/`);
        }

        if (json.mainPlayer.status == "left") {
          navigate('/');
        }

        if (
          json.game.current_question == 0 &&
          json.currentPlayer.name != "" &&
          json.game.start == "no"
        ) {
          setIsPlayerOnline(true);
          setIsGameStart(false);
        } else if (
          json.game.current_question > 9 &&
          json.game.openLocker == "no"
        ) {
          // toast.error("Game already played !");
          backgroundAudio.pause();
          setIsGameEnd(true);
          navigate(
            `/multiplayer/winner/lockers/${localStorage.getItem(
              "gameID"
            )}?p=${player}`
          );
        } else if (
          json.game.current_question > 9 &&
          json.game.openLocker == "yes"
        ) {
          // toast.error("Game already played !");
          setGame_new_id("");
          backgroundAudio.pause();
          setIsGameEnd(true);
          navigate(`/`);
        } else if (json.game.start == "yes" && json.currentPlayer.name != "") {
          const convertedData = await json.game.questions.map((item, index) =>
            JSON.parse(item)
          );
          setIsCorrect(false);
          setIsFalse(false);
          setTimeRemaining(60);
          setpasspoints(100);
          setCurrentQuestionIndex(parseInt(json.game.current_question));
          setQuestions(convertedData);
        }

        if (json.currentPlayer.name === "") {
          setIsReadyModalOpen(true);
          setIsGameStart(false);
        }
      }
    } catch (error) {
      toast.error(error.message);
    }
  };

  //  const savesetpoints = ()=>({
  const savesetpoints = async (points, answer) => {
    try {
      let headers = new Headers();
      headers.append("Content-Type", "application/json");
      headers.append("Access-Control-Allow-Origin", "*");
      headers.append("Access-Control-Allow-Credentials", "true");
      const response = await fetch(
        `${url}/api/multiplayer/update/points/${player}`,
        {
          mode: "cors",
          method: "Post",
          headers: headers,
          body: JSON.stringify({
            points: points,
            game_id: id,
            answer: answer,
          }),
        }
      );
      const json = await response.json();
      if (json.success) {
        setpasspoints(points);
        socket.emit("saveScore", seletedPlayers);
        if (json.mainLeft) {
          socket.emit("mianleft", seletedPlayers);
        }
        if (json.attemp) {
          displayNextQuestion();
        }
      }
    } catch (error) {
      toast.error("Internal Server Error awnswes !");
    }
  };
  const checkPlayer = async () => {
    try {

      let headers = new Headers();
      headers.append("Content-Type", "application/json");
      headers.append("Access-Control-Allow-Origin", "*");
      headers.append("Access-Control-Allow-Credentials", "true");
      console.log('backend');
      const response = await fetch(
        `${url}/api/multiplayer/update/game/playerleft/${player}`,
        {
          mode: "cors",
          method: "Post",
          headers: headers,
          body: JSON.stringify({
            game_id: id,
          }),
        }
      );
      console.log('waiting');
      const json = await response.json();
      console.log('logs', json, json.success, json.mainLeft, json.attemp);
      if (json.success) {

        if (json.mainLeft) {
          setMainPlayrLeft(true);
        }
        if (json.attemp) {
          socket.emit("handleNext", seletedPlayers);
        } else {
          socket.emit("saveScore", seletedPlayers);
        }
      }
    } catch (error) {
      toast.error("Internal Server Error awnswes !");
    }
  }
  const incorrect = async () => {
    try {
      let headers = new Headers();
      headers.append("Content-Type", "application/json");
      headers.append("Access-Control-Allow-Origin", "*");
      headers.append("Access-Control-Allow-Credentials", "true");
      const response = await fetch(
        `${url}/api/multiplayer/update/answer/${player}`,
        {
          mode: "cors",
          method: "Post",
          headers: headers,
          body: JSON.stringify({
            game_id: id,
          }),
        }
      );
      const json = await response.json();
      if (json.success) {
        setpasspoints(0);
        socket.emit("saveScore", seletedPlayers);
        console.log("attemp", json.attemp);
        if (json.attemp) {
          displayNextQuestion();
        }
      }
    } catch (error) {
      toast.error("Internal Server Error awnswes !");
    }
  };
  const handleGuessAnswer = (guess, time, player) => {

    backgroundAudioClick.play();
    setHandleQuestion(true);
    setQuestionInc(parseInt(questionInc) + 1);
    if (guess === currentQuestion.correctAnswer) {
      setIsCorrect(true);
      if (
        player.question == currentQuestionIndex &&
        player.attempted == "yes"
      ) {
        toast.error(
          "Already attempted to this question.Please wait for next question"
        );
      } else {
        setPoints(100 - 17 * (5 - Math.floor(parseInt(time) / 10)));
        savesetpoints(
          100 - 17 * (5 - Math.floor(parseInt(time) / 10)),
          "correct"
        );
      }
    } else {
      if (
        player.question == currentQuestionIndex &&
        player.attempted == "yes"
      ) {
        toast.error(
          "Already attempted to this question.Please wait for next question"
        );
      } else {
        savesetpoints(0, "incorrect");
      }

      setIsFalse(true);
    }
    if (score >= 10) {
      // setPoints(points + 50);
    }
    // setTimeout(() => {
    //   handleNextQuestion();
    // }, Math.floor(parseInt(time)) * 1000);
    // setCurrentQuestionIndex(currentQuestionIndex + 1);
  };
  const displayNextQuestion = async () => {
    // await updateQuestion();
    socket.emit("handleNext", seletedPlayers);
  };
  let i = 0;
  const handleNextQuestion = async () => {
    console.log(i, "question");
    // const currentIndex = currentQuestionIndex + 1;
    setHandleQuestion(false);
    await getQuestions();

    // setIsRefresh(true);
    // setIsCorrect(false);
    // setIsFalse(false);
    // setTimeRemaining(60);
    // setpasspoints(100);

    // if (parseInt(remainingNumberOfQuestions) > 1) {
    //   setCurrentQuestionIndex(parseInt(currentQuestionIndex) + 1);
    // } else {
    //   if (parseInt(currentQuestionIndex) > 0) {
    //     const gameID = localStorage.getItem("gameID");
    //     if (gameID === id) {
    //       setQuestionInc(0);
    //       backgroundAudio.pause();
    //       navigate(
    //         `/multiplayer/winner/lockers/${localStorage.getItem(
    //           "gameID"
    //         )}?p=${player}`
    //       );
    //     }
    //   }
    // }
  };
  const updateQuestion = async () => {
    try {
      let headers = new Headers();
      headers.append("Content-Type", "application/json");
      headers.append("Access-Control-Allow-Origin", "*");
      headers.append("Access-Control-Allow-Credentials", "true");
      const response = await fetch(
        `${url}/api/multiplayer/update/question/${id}`,
        {
          mode: "cors",
          method: "GET",
          headers: headers,
        }
      );
      const json = await response.json();
      if (json.success) {
        socket.emit("saveScore", seletedPlayers);
      }
    } catch (error) {
      toast.error("Internal Server Error ! update quest");
    }
  };
  const updateToStart = async (id) => {
    try {
      let headers = new Headers();
      headers.append("Content-Type", "application/json");
      headers.append("Access-Control-Allow-Origin", "*");
      headers.append("Access-Control-Allow-Credentials", "true");
      const response = await fetch(
        `${url}/api/multiplayer/update/to/start/${id}`,
        {
          mode: "cors",
          method: "GET",
          headers: headers,
        }
      );
      const json = await response.json();
      if (json.success) {
      }
    } catch (error) {
      toast.error("Internal Server Error ! update start");
    }
  };
  const handleAgainPlay = async () => {
    await getQuestions();
    setIsMuted(true);
    setTimeRemaining(60);
    setIsPlayerOnline(false);
    setIsGameStart(true);
    setScore(0);
    setPoints(0);
    setCurrentQuestionIndex(0);
    setIsGameEnd(false);
    backgroundAudio.play();
    backgroundAudio.volume = 0.2;
  };
  const [isDropdownVisible, setDropdownVisible] = useState(false);
  const handleImageClick = () => {
    setDropdownVisible(!isDropdownVisible);
  };
  const handleUserLogout = async (e) => {
    const logouts = localStorage.removeItem("token");
    dispatch(logout());
    navigate("/");
  };

  const letsPlay = async () => {
    setpasspoints(100);
    socket.emit("gamestart", seletedPlayers);
    updateToStart(id);

    handleAgainPlay();
  };

  const getCurrentPlayer = async () => {
    try {
      let headers = new Headers();
      headers.append("Content-Type", "application/json");
      headers.append("Access-Control-Allow-Origin", "*");
      headers.append("Access-Control-Allow-Credentials", "true");

      const response = await fetch(
        `${url}/api/multiplayer/get/current/player/${player}`,
        {
          mode: "cors",
          method: "GET",
          headers: headers,
        }
      );
      setIsLoading(false);
      const json = await response.json();
      if (json.success) {
        setCurrentPlayer(json.currentPlayer);
        // if (json.currentPlayer.name === '') {
        //   setIsReadyModalOpen(true);
        // } else {
        //   setIsPlayerOnline(true)
        // }
      }
    } catch (error) {
      toast.error("Internal Server Error ! current player");
    }
  };

  const getPlayers = async () => {
    try {
      let headers = new Headers();
      headers.append("Content-Type", "application/json");
      headers.append("Access-Control-Allow-Origin", "*");
      headers.append("Access-Control-Allow-Credentials", "true");

      const response = await fetch(
        `${url}/api/multiplayer/get/player/${localStorage.getItem("gameID")}`,
        {
          mode: "cors",
          method: "GET",
          headers: headers,
        }
      );

      const json = await response.json();
      if (json.success) {
        setPlayersDetail(json.players);
        setSeletedPlayers(json.selectedPlayers);
      }
    } catch (error) {
      toast.error("Internal Server Error !");
    }
  };

  useEffect(() => {
    getPlayers();
  }, []);

  const openLockers = async () => {
    try {
      let headers = new Headers();
      headers.append("Content-Type", "application/json");
      headers.append("Access-Control-Allow-Origin", "*");
      headers.append("Access-Control-Allow-Credentials", "true");

      const response = await fetch(
        `${url}/api/multiplayer/winners/locker/${id}`,
        {
          mode: "cors",
          method: "GET",
          headers: headers,
        }
      );

      const json = await response.json();
      if (json.success) {
        setWinnerLocker(json.winner);
      }
    } catch (error) {
      toast.error("Internal Server Eror locker !");
    }
  };
  useEffect(() => {
    openLockers();
  }, []);

  const handleOpenUpdatedLocker = async () => {
    try {
      if (currentPlayer.type.toLowerCase() == "main") {
        let headers = new Headers();
        headers.append("Content-Type", "application/json");
        headers.append("Access-Control-Allow-Origin", "*");
        headers.append("Access-Control-Allow-Credentials", "true");
        headers.append("auth-token", localStorage.getItem("token"));
        setIsLoading(true);
        const response = await fetch(`${url}/api/multiprizes/winer/add`, {
          mode: "cors",
          method: "POST",
          headers: headers,
          body: JSON.stringify({
            game_id: id,
          }),
        });
        setIsLoading(false);
        const json = await response.json();
        if (json.success) {
          navigate(`/multiplayer/winner/lockers/${id}?p=${player}`);
        }
      } else {
        navigate(`/multiplayer/winner/lockers/${id}?p=${player}`);
      }
    } catch (error) {
      toast.error("Internal Server Eror lockerrrr !");
    }
  };

  const formSubmit = async (e) => {
    try {
      e.preventDefault();
      // if (credentials.email != "" || credentials.number != "") {
      let headers = new Headers();
      headers.append("Content-Type", "application/json");
      headers.append("Access-Control-Allow-Origin", "*");
      headers.append("Access-Control-Allow-Credentials", "true");
      setIsLoading(true);
      const response = await fetch(
        `${url}/api/multiplayer/update/player/name/${player}`,
        {
          mode: "cors",
          method: "POST",
          headers: headers,
          body: JSON.stringify({
            name: credentials.name,
          }),
        }
      );
      setIsLoading(false);
      const json = await response.json();
      if (json.success) {
        toast.success("Name Updated Successfully");
        setCredentials({
          name: "",
        });
        setIsReadyModalOpen(false);
        setIsPlayerOnline(true);
        setIsGameStart(false);
        socket.emit("joinUser", seletedPlayers);
      } else {
        setIsLoading(false);
        toast.error(json.error);
      }
    } catch (error) {
      toast.error("Internal Server Error form submit");
    }
  };

  const UpdatePlayerStatus = async (seletedPlayers, currentPlayer) => {
    try {
      let headers = new Headers();
      headers.append("Content-Type", "application/json");
      headers.append("Access-Control-Allow-Origin", "*");
      headers.append("Access-Control-Allow-Credentials", "true");
      socket.emit("mianleft", seletedPlayers);
      const response = await fetch(
        `${url}/api/multiplayer/update/player/status/${player}`,
        {
          mode: "cors",
          method: "GET",
          headers: headers,
        }
      );
      setIsLoading(false);

      const json = await response.json();
    } catch (error) {
      toast.error("Internal Server Error ! current player");
    }
  };

  return (
    <div className="question-container">
      {loader ? (
        <Loader />
      ) : (
        <>
          <header className="header postion-relative">
            <div className="container">
              <div className="row">
                <div className="d-flex justify-content-between align-items-center">
                  <div className="header__section">
                    <div className="d-flex justify-content-start align-items-center">
                      <Link to={"/"} className="">
                        <img
                          src={logo}
                          alt="logo"
                          className="object-fit-contain w-75 mx-auto"
                        />
                      </Link>
                    </div>
                  </div>

                  <div className="header__section ">
                    <div className="d-flex  justify-content-center justify-content-sm-center">
                      <a
                        className=" d-flex flex-column bg-white text-black p-2 text-decoration-none text-center rounded-3"
                        style={{ width: "auto", height: "auto" }}
                        onClick={handleToggleMute}
                      >
                        {isMuted ? (
                          <img src={Mute} alt="muted" />
                        ) : (
                          <img src={UnMute} alt="unmuted" />
                        )}
                      </a>
                    </div>
                  </div>

                  <div className="header__section">
                    <div className="position-relative d-flex justify-content-end flex-row ">
                      <img
                        src={isAuthenticated?.image || Profile}
                        alt="logo"
                        className="mt-2"
                        height={60}
                        style={{
                          cursor: "pointer",
                          width: "50px",
                          height: "50px",
                          borderRadius: "50px",
                          objectFit: "cover",
                        }}
                        onClick={handleImageClick}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </header>
          {isDropdownVisible && (
            <div
              style={{
                position: "absolute",
                top: "60px",
                right: "7rem",
                border: "1px solid #ccc",
                background: "#fff",
                zIndex: "99999999999",
              }}
              className="popup__menu rounded p-1"
            >
              <ul className="p-1 header__dropdownMenu">
                <li
                  onClick={() => navigate("/user/dashboard")}
                  className="header__dropdownMenuItem px-4 d-flex align-items-center gap-2"
                >
                  <img src={Dashboard} alt="icon" />
                  <span>Dashboard</span>
                </li>
                <li
                  onClick={handleUserLogout}
                  className="header__dropdownMenuItem px-4 d-flex align-items-center gap-2"
                >
                  <img src={IconLogout} alt="icon" />
                  <span>Logout</span>
                </li>
              </ul>
            </div>
          )}

          {/* {isRefresh && ( */}

          <div
            className="container"
            style={{ marginTop: "2vh", overflowY: "scroll" }}
          >
            {isGameStart && currentQuestion && (
              <>
                <Answer
                  isCorrect={isCorrect}
                  isFalse={isFalse}
                  question={currentQuestion}
                  index={currentQuestionIndex}
                  currentQuestionIndex={currentQuestionIndex}
                  onGuessAnswer={handleGuessAnswer}
                  gif={gif}
                  remainingTime={timeRemaining}
                  gametype={gametype}
                  passpoints={passpoints}
                  opacitylow={opacitylow}
                />
              </>
            )}
          </div>
          {/* )} */}
        </>
      )}

      <Modal
        isOpen={isReadyModalOpen}
        contentLabel="Example Modal"
        className="d-flex flex-column justify-content-center p-3 col-lg-4 m-auto mt-5 h-100 align-items-center "
        shouldCloseOnOverlayClick={false}
        style={{
          content: {
            fontFamily: "Arial",
            fontWeight: "800",
            fontSize: "30px",
          },
          overlay: {
            backgroundColor: "rgba(0, 0, 0, 0.5)", // Set the overlay background color
          },
        }}
      >
        <div
          className="p-4 w-sm-50 h-sm-50 col-10 rounded-3"
          style={{ background: "#fff" }}
        >
          <div className="d-flex flex-row">
            <h4 className="win-heading-2 text-dark text-center">
              Enter Your Name
            </h4>
          </div>
          <form onSubmit={formSubmit}>
            <div
              className="p-1 rounded-2 w-sm-25 " //h-75
              style={{ background: "#fff" }}
            >
              <div>
                <label
                  htmlFor="text"
                  className="form-label"
                  style={{ fontSize: "14px", fontWeight: "500" }}
                >
                  Name
                </label>
                <input
                  type="text"
                  className="form-control"
                  id="name"
                  name="name"
                  value={credentials?.name}
                  onChange={handleInput}
                />
              </div>
              <div className="d-flex justify-content-between align-items-center flex-row flex-wrap gap-2 mt-3">
                <Link
                  onClick={() => setIsReadyModalOpen(false)}
                  className="bg-white border border-2 border-blue rounded-pill px-4 py-2 shadow "
                  style={{
                    fontWeight: "700",
                    fontFamily: "Arial",
                    fontSize: "12px",
                    textDecoration: "none",
                    color: "#012267",
                  }}
                >
                  Cancel
                </Link>
                <button
                  type="submit"
                  className="bg-blue text-white  border border-2 border-blue rounded-pill px-4 py-2 shadow"
                  style={{
                    fontWeight: "700",
                    fontFamily: "Arial",
                    fontSize: "12px",
                    textDecoration: "none",
                  }}
                >
                  Send
                </button>
              </div>
            </div>
          </form>
        </div>
      </Modal>

      <Modal
        isOpen={playerOnline}
        contentLabel="Example Modal"
        className="d-flex flex-column justify-content-center p-3 col-lg-4 m-auto mt-5 h-100 align-items-center "
        shouldCloseOnOverlayClick={false}
        style={{
          content: {
            fontFamily: "Arial",
            fontWeight: "800",
            fontSize: "30px",
          },
          overlay: {
            backgroundColor: "rgba(0, 0, 0, 0.5)", // Set the overlay background color
          },
        }}
      >
        <div
          className="p-3 w-sm-50 h-sm-50 col-10 rounded-3"
          style={{ background: "#012269" }}
        >
          <div className="d-flex flex-row">
            <h4 className="win-heading ">Waiting...</h4>
          </div>
          <div
            className="p-4 rounded-2 w-sm-25 " //h-75
            style={{ background: "#031947" }}
          >
            <h5
              className="text-center text-white mt-2 "
              style={{
                fontFamily: "Arial",
                fontSize: "25px",
                fontWeight: "700",
              }}
            >
              WAITING FOR PLAYERS TO JOIN
            </h5>

            <div className="d-flex flex-row flex-wrap  gap-3 justify-content-center align-items-center mt-4">
              {playersDetail?.map(
                (player, index) =>
                  player?.status === "join" &&
                  player?._id != currentPlayer._id && (
                    <div
                      className="d-flex justify-content-between align-items-center flex-row gap-2"
                      style={{
                        border: "2px solid white",
                        borderRadius: "10px",
                        padding: "0 10px",
                        width: "auto",
                        maxWidth: "400px",
                      }}
                    >
                      <img src={User} />
                      <h5
                        className="text-center text-white mt-2 text-capitalize"
                        key={index}
                      >
                        {player.name ? player.name : ""}
                      </h5>
                    </div>
                  )
              )}
            </div>

            {currentPlayer.type === "main" &&
              playersDetail &&
              playersDetail.filter((player) => player.status === "join")
                .length > 1 && (
                <div className="d-flex flex-row gap-5 justify-content-center align-items-center mt-4">
                  <img
                    src={Play}
                    alt="play"
                    onClick={letsPlay}
                    height={50}
                    width={50}
                  />
                </div>
              )}
          </div>
        </div>
      </Modal>

      {/* instruction modal   */}

      <Modal
        isOpen={isinstructionModalOpen}
        contentLabel="Example Modal"
        className="d-flex flex-column justify-content-center p-3 col-lg-4 m-auto mt-5 h-100 align-items-center "
        shouldCloseOnOverlayClick={false}
        style={{
          content: {
            fontFamily: "Arial",
            fontWeight: "800",
            fontSize: "30px",
          },
          overlay: {
            backgroundColor: "rgba(0, 0, 0, 0.5)", // Set the overlay background color
          },
        }}
      >
        <div
          className="p-3 w-sm-50 h-sm-50 col-10 rounded-3"
          style={{ background: "#012269" }}
        >
          <div className="d-flex flex-row">
            <h4 className="win-heading ">How to Play</h4>
            <Link
              className="mt-1"
              onClick={() => setisinstructionModalOpen(false)}
              style={{
                fontSize: "20px",
                textDecoration: "none",
              }}
            >
              X
            </Link>
          </div>
          <div
            className="p-4 rounded-2 w-sm-25 " //h-75
            style={{ background: "#031947" }}
          >
            <div
              id="carouselExampleIndicators"
              className="carousel slide"
              data-bs-ride="carousel"
            >
              <div className="carousel-indicators mb-0">
                <button
                  type="button"
                  data-bs-target="#carouselExampleIndicators"
                  data-bs-slide-to="0"
                  className="active"
                  aria-current="true"
                  aria-label="Slide 1"
                ></button>
                <button
                  type="button"
                  data-bs-target="#carouselExampleIndicators"
                  data-bs-slide-to="1"
                  aria-label="Slide 2"
                ></button>
                <button
                  type="button"
                  data-bs-target="#carouselExampleIndicators"
                  data-bs-slide-to="2"
                  aria-label="Slide 3"
                ></button>
              </div>
              <div className="carousel-inner">
                <div className="carousel-item active">
                  <p style={{ fontSize: "20px" }}>
                    Enter your name to start the game
                  </p>
                  <img
                    src={ins1}
                    alt="logo"
                    className="object-fit-contain w-100 mx-auto"
                  />
                </div>
                <div className="carousel-item">
                  <p style={{ fontSize: "20px" }}>
                    Answer the question with right answer to get maximum points
                  </p>
                  <img
                    src={ins2}
                    alt="logo"
                    className="object-fit-contain w-100 mx-auto"
                  />
                </div>
                <div className="carousel-item">
                  <p style={{ fontSize: "20px" }}>
                    Answer the question with right answer to get maximum points
                  </p>
                  <img
                    src={ins3}
                    alt="logo"
                    className="object-fit-contain w-100 mx-auto"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </Modal>
      <Modal
        isOpen={mainPlayrLeft}
        contentLabel="Example Modal"
        className="d-flex flex-column justify-content-center p-3 col-lg-4 m-auto mt-5 h-100 align-items-center "
        shouldCloseOnOverlayClick={false}
        style={{
          content: {
            fontFamily: "Arial",
            fontWeight: "800",
            fontSize: "30px",
          },
          overlay: {
            backgroundColor: "rgba(0, 0, 0, 0.5)", // Set the overlay background color
          },
        }}
      >
        <div
          className="p-3 w-sm-50 h-sm-50 col-10 rounded-3"
          style={{ background: "#012269" }}
        >
          <div className="d-flex flex-row">
            <h4 className="win-heading ">Game Ended</h4>
          </div>
          <div
            className="p-4 rounded-2 w-sm-25 " //h-75
            style={{ background: "#031947" }}
          >
            <h5
              className="text-center text-white mt-2 "
              style={{
                fontFamily: "Arial",
                fontSize: "25px",
                fontWeight: "700",
              }}
            >
              Main Player left the game
            </h5>

            <div className="d-flex flex-row gap-5 justify-content-center align-items-center mt-4">
              <Link to={"/"}>
                <img src={Play} alt="play" height={50} width={50} />
              </Link>
            </div>
          </div>
        </div>
      </Modal>
    </div>
  );
};

export default MultiQuestionPage;

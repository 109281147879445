import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { loginSuccess } from "../Redux/Authentication";
import { toast } from "react-toastify";
import bg from '../../images/bg-pic.png'
// import io from "socket.io-client";
import styles from "./style.module.css";
import logo from "../../assets/logo-dark.png";
import login_hero from "../../images/PuzzleSignup.jpg";

const SignupPage = () => {
  let navigate = useNavigate();
  const dispatch = useDispatch();
  const [getSite, setSite] = useState([]);
  const [credentials, setCredentials] = useState({
    name: "",
    email: "",
    password: "",
    password_con: "",
  });
  const url = process.env.REACT_APP_APP_BACK_URL;
  const [isLoading, setIsLoading] = useState(false);

  const handleInput = (e) => {
    setCredentials({ ...credentials, [e.target.name]: e.target.value });
  };

  const formSubmit = async (e) => {
    try {


      e.preventDefault();
      if (credentials.password === credentials.password_con) {
        let headers = new Headers();
        headers.append("Content-Type", "application/json");
        headers.append("Access-Control-Allow-Origin", "*");
        headers.append("Access-Control-Allow-Credentials", "true");

        setIsLoading(true);
        const prize = localStorage.getItem("prize");
        const response = await fetch(`${url}/api/auth/createUser`, {
          mode: "cors",
          method: "POST",
          headers: headers,
          body: JSON.stringify({
            name: credentials.name,
            email: credentials.email,
            password: credentials.password,
            status: "active",
            prize: prize,
          }),
        });
        setIsLoading(false);

        const json = await response.json();
        if (json.success) {
          const user = json.user;
          const expirationTime = Math.floor(Date.now() / 1000) + 7200;
          localStorage.setItem("token", json.authToken);
          localStorage.setItem("token-expiration", expirationTime);
          // socket.emit("login", json.user._id);
          dispatch(loginSuccess(json.user));
          localStorage.removeItem("prize");
          navigate("/user/dashboard");
        } else {
          toast.error(json.error);
        }
      } else {
        toast.error("Password not match");
      }
    } catch (error) {
      toast.error(error.message);
    }
  };
  const iocnStyle = {
    display: isLoading ? "inline-block" : "none",
  };

  return (
    <div className={styles.heading}>
      <div className="sign-up-user">
        <section className={styles.sign_sec}>

          <div className="container h-100 ">
            <div className="row ">
              <div className="col-12 my-auto">
                <div className="row justify-content-center">
                  {/* <div className="col-xl-6 col-lg-8 p-0 mob-2">
                  <div className={styles.sign_hero_wrap}>
                    <img
                      src={login_hero}
                      alt="logo hero"
                      style={{ height: "650px" }}
                    />
                  </div>
                </div> */}
                  <div className="col-lg-8 p-5 text-center mob-1">
                    <div className={styles.cont_form_wrap}>
                      <div className={styles.signup_wrap}>
                        <div className={styles.logo_wrap}>
                          <Link to={'/'}>
                            <img src={logo} alt="logo" />
                          </Link>
                        </div>
                        <h1>let’s register yourself</h1>
                        <form className="form-wrap" onSubmit={formSubmit}>
                          <div className="row justify-content-center">
                            <div className="col-xl-12 col-lg-12">
                              <div className="row">
                                <div className="col-xl-6 col-lg-8 col-6 ">
                                  <div className="mb-3">
                                    <label htmlFor="name" className="form-label">
                                      Full Name
                                    </label>
                                    <input
                                      type="name"
                                      className="form-control"
                                      id="name"
                                      name="name"
                                      value={credentials.name}
                                      onChange={handleInput}
                                    />
                                  </div>
                                </div>
                                <div className="col-xl-6 col-lg-8 col-6 ">
                                  <div className="mb-3">
                                    <label htmlFor="email" className="form-label">
                                      Email
                                    </label>
                                    <input
                                      type="email"
                                      className="form-control"
                                      id="email"
                                      name="email"
                                      value={credentials.email}
                                      onChange={handleInput}
                                    />
                                  </div>
                                </div>
                              </div>
                              <div className="row">
                                <div className="col-xl-6 col-lg-8 col-6 ">
                                  <div className="mb-3">
                                    <label htmlFor="pass" className="form-label">
                                      Password
                                    </label>
                                    <input
                                      type="password"
                                      className="form-control"
                                      id="password"
                                      name="password"
                                      value={credentials.password}
                                      onChange={handleInput}
                                    />
                                  </div>
                                </div>
                                <div className="col-xl-6 col-lg-8 col-6 ">
                                  <div className="mb-3">
                                    <label htmlFor="pass" className="form-label">
                                      Confirm Password
                                    </label>
                                    <input
                                      type="password"
                                      className="form-control"
                                      id="password_con"
                                      name="password_con"
                                      value={credentials.password_con}
                                      onChange={handleInput}
                                    />
                                  </div>
                                </div>
                              </div>


                              <button
                                type="submit"
                                className="btn btn-prim"
                                disabled={isLoading}
                              >
                                <i
                                  className="fa fa-spinner fa-spin"
                                  style={iocnStyle}
                                ></i>{" "}
                                SignUp
                              </button>
                              <div className="row">
                                <div className="col-xl-6 col-lg-8 col-6 text-start">
                                  <Link to="/puzzle-login">Login</Link>
                                </div>
                              </div>
                            </div>
                          </div>
                        </form>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>


        </section>
      </div>
    </div >
  );
};

export default SignupPage;

import React, { useEffect, useRef, useState } from "react";
// import "../../../Call.css";
import Modal from "../partials/Modal";
import AttendVideoIcon from "../../images/attend-video.png";
import DeclineIcon from "../../images/decline-call.png";
import { useParams } from "react-router-dom";
import Swal from "sweetalert2";
import io from "socket.io-client";
import { StringeeUtil, StringeeClient, StringeeCall } from "stringee";
// import VideoAttendant from "../../../assets/video.png";
// import ringTone from "../../../assets/ringcall.mp3";
// import backgroundMusicClick from "../../../assets/click.mp3";
// import "../../../index.css";

const url = process.env.REACT_APP_APP_BACK_URL;
const socket = io.connect(url);

const LockerPage = () => {
  const { slug } = useParams();
  const [callModalOpen, setCallModalOpen] = useState(false);
  const [modalIsOpen, setIsOpen] = React.useState(false);
  const [videoShow, setVideoShow] = useState(false);
  const [sdkVersion, setSdkVersion] = useState("");
  const [loggedUserId, setLoggedUserId] = useState("");
  const [accessToken, setAccessToken] = useState("");
  const [loggedUserId1, setLoggedUserId1] = useState("");
  const [accessToken1, setAccessToken1] = useState("");
  const [toNumber, setToNumber] = useState("842471098576");
  const [call, setCall] = useState(null);
  const [localStream, setLocalStream] = useState(null);
  const [remoteStream, setRemoteStream] = useState(null);
  const [stringeeClient, setStringeeClient] = useState(null);
  const [stringeeClient1, setStringeeClient1] = useState(null);
  const [muted, setMuted] = useState(false);
  const [videoEnabled, setVideoEnabled] = useState(true);
  const [timeoutStats, setTimeoutStats] = useState(null);
  const [callConnectionDestroy, setCallConnectionDestroy] = useState(false);
  const [isCallActive, setIsCallActive] = useState(false);
  const [ringingCall, setRingingCall] = useState(false);
  const [callerBusy, setCallerBusy] = useState(false);
  const [number, setNumber] = useState(0);
  const [duration, setDuration] = useState(0);
  const [connectionDestroy, setConnectionDestroy] = useState(false)
  const [callButtonShow, setCallButtonShow] = useState(false);
  const [backgroundAudio] = useState(new Audio(""));
  const [backgroundAudioClick] = useState(new Audio(""));
  const leaveCallButtonRef = useRef(null);
  const ringCall = useRef(null);
  const clickButton = useRef(null);
  const localVideoRef = useRef(null);
  const remoteVideoRef = useRef(null);
  const [elapsedTime, setElapsedTime] = useState(0);
  const queryParams = new URLSearchParams(window.location.search);
  const playerId = queryParams.get("p");

  useEffect(() => {
    setSdkVersion(
      `${StringeeUtil.version().version}_build_${
        StringeeUtil.version().build
      }_sample_1`
    );
    getAccessToken();
    getAccessToken1();
  }, []);
  useEffect(() => {
    socket.on("waitingResponse", (data) => {
      setCallerBusy(true);
      setNumber(data.number);
      console.log("total wait", data.duration);
      setDuration(data.duration);
    });
  }, [callConnectionDestroy]);
  function updateElapsedTime() {
    setElapsedTime((prevElapsedTime) => prevElapsedTime + 1);
  }
  const intervalIdRef = useRef(null);
  const generateUserId = async () => {
    return playerId;
  };
  const generateUserId1 = async () => {
    return playerId;
  };
  const getAccessToken = async () => {
    try {
      const loginUser = await generateUserId();
      const headers = new Headers();
      headers.append("Content-Type", "application/json");
      headers.append("Access-Control-Allow-Origin", "*");
      headers.append("Access-Control-Allow-Credentials", "true");
      const response = await fetch(
        `${url}/api/auth/get-token?userId=${loginUser}`,
        {
          mode: "cors",
          method: "GET",
          headers: headers,
        }
      );

      const json = await response.json();
      if (json.success) {
        setAccessToken(json.token);
        handleLogin(json.token);
      }
    } catch (error) {
      console.error(error);
    }
  };
  const getAccessToken1 = async () => {
    try {
      const loginUser = await generateUserId1();
      const headers = new Headers();
      headers.append("Content-Type", "application/json");
      headers.append("Access-Control-Allow-Origin", "*");
      headers.append("Access-Control-Allow-Credentials", "true");
      const response = await fetch(
        `${url}/api/auth/get-token?userId=${loginUser}`,
        {
          mode: "cors",
          method: "GET",
          headers: headers,
        }
      );

      const json = await response.json();
      if (json.success) {
        setAccessToken1(json.token);
        handleLogin1(json.token);
      }
    } catch (error) {
      console.error(error);
    }
  };
  // const addCall = async (id, type) => {
  //   try {
  //     const loginUser = await generateUserId1();
  //     const headers = new Headers();
  //     headers.append("Content-Type", "application/json");
  //     headers.append("Access-Control-Allow-Origin", "*");
  //     headers.append("Access-Control-Allow-Credentials", "true");
  //     const response = await fetch(`${url}/api/call/createCall`, {
  //       mode: "cors",
  //       method: "POST",
  //       headers: headers,
  //       body: JSON.stringify({
  //         caller_id: id,
  //         site: slug,
  //         type: type,
  //       }),
  //     });

  //     const json = await response.json();
  //     if (json.success) {
  //     }
  //   } catch (error) {
  //     console.error(error);
  //   }
  // };
  const handleLogin = async (token) => {
    const client = new StringeeClient();

    client.on("connect", () => {
      console.log("Connected to StringeeServer");
    });

    client.on("authen", (res) => {
      console.log("authen", res);
      if (res.r === 0) {
        socket.emit("login", res.userId);
        setLoggedUserId(res.userId);
      } else {
        setLoggedUserId(res.message);
      }
    });

    client.on("disconnect", () => {
      console.log("Disconnected");
    });

    client.on("incomingcall", (incomingcall) => {
      if (isCallActive) {
        alert("Busy with another call. Please wait.");
        return;
      }
      setCall(incomingcall);
      setIsCallActive(true);
      incomingcall.ringing(() => {});
      console.log("incomingcall", incomingcall);
      handleIncomingCall(incomingcall);
    });

    client.on("requestnewtoken", () => {
      console.log("Requesting new token");
    });

    setStringeeClient(client);
    client.connect(token);
    console.log("client token", client);
  };
  const handleLogin1 = (token) => {
    const client = new StringeeClient();

    client.on("connect", () => {
      console.log("Connected to StringeeServer");
    });

    client.on("authen", (res) => {
      console.log("authen", res);
      if (res.r === 0) {
        socket.emit("login", res.userId);
        setLoggedUserId1(res.userId);
      } else {
        setLoggedUserId1(res.message);
      }
    });

    client.on("disconnect", () => {
      console.log("Disconnected");
    });

    client.on("incomingcall", (incomingcall) => {
      if (isCallActive) {
        alert("Busy with another call. Please wait.");
        return;
      }
      setCall(incomingcall);
      setIsCallActive(true);
      incomingcall.ringing(() => {});
      console.log("incomingcall", incomingcall);
    });

    client.on("requestnewtoken", () => {
      console.log("Requesting new token");
    });
    setStringeeClient1(client);
    client.connect(token);
  };
  const handleHangup = () => {
    try {
      if (clickButton.current) {
        clickButton.current.click();
      }
      if (call) {
        call.hangup();
        setCall(null);
        setLocalStream(null);
        setRemoteStream(null);
        setCallModalOpen(false);
        setCallConnectionDestroy(false);
        onStop();
      }
    } catch (error) {
      console.error("Error hanging up the call:", error);
    }
  };

  useEffect(() => {
    socket.on("destroyConnection", () => {
      try {
        setCallConnectionDestroy(false);
      } catch (error) {
        console.error("Error handling destroyConnection event:", error);
      }
    });
  }, []);

  const ring = async () => {
    // backgroundAudio.play();
  };
  const clickButtonFun = async () => {
    // backgroundAudioClick.play();
  };

  const onStop = () => {
    if (timeoutStats) {
      clearTimeout(timeoutStats);
    }
  };

  const handleVideoCall = async () => {
    if (clickButton.current) {
      clickButton.current.click();
    }

    setCallModalOpen(false);
    setCallConnectionDestroy(true);
    setIsCallActive(true);
    callConnection();
    setCallButtonShow(true);
  };

  const callConnection = async () => {
    if (!stringeeClient) {
      console.error("StringeeClient is not initialized");
      return;
    }

    setVideoShow(true);
    const fromNumber = loggedUserId;
    let toNumber = "player2";

    const isVideoCall = true;

    const newCall = new StringeeCall(
      stringeeClient,
      fromNumber,
      toNumber,
      isVideoCall
    );

    newCall.on("addlocalstream", (stream) => {
      if (localVideoRef.current) {
        localVideoRef.current.srcObject = stream;
      }
    });
    newCall.on("addremotestream", (stream) => {
      if (remoteVideoRef.current) {
        remoteVideoRef.current.srcObject = stream;
      }
    });

    newCall.makeCall((res) => {
      console.log("make call callbackdd: " + JSON.stringify(res));
      // addCall(res.callId, "sales");
      setCall(newCall);
    });

    newCall.on("signalingstate", (state) => {
      console.log("signalstate", state.code);
      if (state.code === 2) {
        setRingingCall(true);
        setCallerBusy(false);
        setIsCallActive(false);
        setCallButtonShow(true);
      } else if (state.code === 3) {
        setRingingCall(false);
        setIsCallActive(true);
        setCallerBusy(false);
        setCallButtonShow(true);
        intervalIdRef.current = setInterval(updateElapsedTime, 1000);
      } else if (state.code === 5) {
        setRingingCall(false);
        setIsCallActive(false);
        setCallerBusy(true);
        setCallButtonShow(false);
      } else if (state.code === 6) {
        setRingingCall(false);
        setIsCallActive(false);
        setCallerBusy(false);
        setCallConnectionDestroy(false);
        setCallButtonShow(false);
        if (localVideoRef.current) {
          localVideoRef.current.srcObject = null;
        }
        if (remoteVideoRef.current) {
          remoteVideoRef.current.srcObject = null;
        }
        clearInterval(intervalIdRef.current);
        setElapsedTime(0);
        Swal.fire({
          icon: "info",
          title: "Call Ended",
          // text: "The call has been declined by video attendant.",
        });
      } else {
        setRingingCall(false);
        setIsCallActive(false);
        setCallerBusy(true);
      }
    });
  };
 

  const handleIncomingCall = async (incomingcall) => {
    // Set the current call to the incoming call
    setCall(incomingcall);
    // Handle the call connection
    await callConnectionRec(incomingcall);
    // Set call as active
    setIsCallActive(true);
    // Show call interface
    setIsOpen(true);

    // Auto answer if possible
    if (ringCall.current) {
      ringCall.current.click();
    }
    // Handle incoming call events
    incomingcall.ringing(function (res) {});
  };
  const callConnectionRec = async (newCall) => {
    console.log('sdasdasdasdas',newCall);
    newCall.on("addlocalstream", (stream) => {
      if (localVideoRef.current) {
        localVideoRef.current.srcObject = stream;
      }
    });
    newCall.on("addremotestream", (stream) => {
      if (remoteVideoRef.current) {
        remoteVideoRef.current.srcObject = stream;
      }
    });
    newCall.on("error", function (info) {
      console.log("on error: " + JSON.stringify(info));
    });

    newCall.on("signalingstate", function (state) {
      console.log(state.code, "state.code");

      if (state.code === 3) {
        // call connected
        setIsCallActive(true);
        console.log("Call state set to true");
      } else if (state.code === 5) {
        console.log("Call is busy");
      } else if (state.code === 6) {
        // Call declined
        // Close the video tab
        setConnectionDestroy(false);
        setIsCallActive(false);
      }
    });

    newCall.on("mediastate", function (state) {});

    newCall.on("info", function (info) {});

    newCall.on("otherdevice", function (data) {
      if (
        (data.type === "CALL_STATE" && data.code >= 200) ||
        data.type === "CALL_END"
      ) {
        // callEnded();
      }
    });
    // if (!modal) {
    //   setTimeout(() => {
    //     joinRoom();
    //   }, 3000); // 3000 milliseconds = 3 seconds
    // }
  };
  function joinRoom() {
    if (clickButton.current) {
      clickButton.current.click();
    }
    setIsOpen(false);
    setCallConnectionDestroy(true);
    setIsCallActive(true);
 
    call.answer(function (res) {});
  }

  return (
    <div className="heading">
      <div className="body-wrap">
        <div className="container-fluid px-0">
          {!callButtonShow && (
            <div className="row text-center p-5">
              <div className="col-md-6 mb-4 mb-md-0">
                <div className="d-flex justify-content-center align-items-center  flex-column gap-3">
                  <button
                    className="btn btn-stringee"
                    onClick={handleVideoCall}
                  >
                    <span>Sales Call</span>
                  </button>
                </div>
              </div>
              <div className="col-md-6">
                <div className="d-flex justify-content-center align-items-center flex-column gap-3">
                  <button
                    className="btn btn-stringee"
                   
                  >
                    <span>Technical Call</span>
                  </button>
                </div>
              </div>
            </div>
          )}

          {callConnectionDestroy && (
            <div className="d-flex justify-content-center align-items-center text-center gap-1 flex-row mt-3">
              {/* {videoShow && ( */}
              <>
                <div className="local-streaming-side position-relative">
                  {/* {!localVideoRef && (
                    <div
                      className="lds-spinner-local"
                      style={{ display: "flex", top: "125px" }}
                    >
                      <div></div>
                      <div></div>
                      <div></div>
                      <div></div>
                      <div></div>
                      <div></div>
                      <div></div>
                      <div></div>
                      <div></div>
                      <div></div>
                      <div></div>
                      <div></div>
                    </div>
                  )} */}

                  <video
                    ref={localVideoRef}
                    autoPlay
                    playsInline
                    muted
                    width="100%"
                  ></video>
                  {/* )} */}
                </div>
                <div className="remote-streaming-side d-flex justify-content-between align-items-center flex-column position-relative">
                  {/* {!remoteVideoRef && (
                    <div
                      className="lds-spinner"
                      style={{ display: "flex", top: "125px" }}
                    >
                      <div></div>
                      <div></div>
                      <div></div>
                      <div></div>
                      <div></div>
                      <div></div>
                      <div></div>
                      <div></div>
                      <div></div>
                      <div></div>
                      <div></div>
                      <div></div>
                    </div>
                  )} */}
                  <div className="call-time">
                    <p>{`${Math.floor(elapsedTime / 60)}:${(elapsedTime % 60)
                      .toString()
                      .padStart(2, "0")}`}</p>
                  </div>
                  {/* {remoteStream && ( */}
                  <video
                    width="100%"
                    ref={remoteVideoRef}
                    autoPlay
                    playsInline
                  />
                  {/* )} */}
                  {ringingCall && (
                    <h5 className="text-center">Call Ringing...</h5>
                  )}
                  {callerBusy && (
                    <>
                      <h6 className="text-center">
                        Attendant Busy On Another Call. You are added to the
                        queue. Your current number is: <b>{number}</b>.
                      </h6>
                      <p className="text-center">
                        Average Waiting time: {duration}
                      </p>
                    </>
                  )}
                </div>
              </>
              {/* )} */}
            </div>
          )}
        </div>
        <Modal isOpen={modalIsOpen} onClose={handleHangup}>
          <div className="modal-body">
            <div className="row h-100">
              <div className="col-12 mt-auto">
                <div className="incoming-call-sec">
                  <h3 className="text-center">
                    Sale user inviting you for a video chat <br />
                  </h3>
                  <div className="call-detail-bar">
                    <div className="call-options-wrap">
                      <div className="icons-wrap-options">
                        <div className="icon-dec">
                          <img
                            src={DeclineIcon}
                            alt=""
                            onClick={handleHangup}
                          />
                        </div>

                        <div className="icon-att-vid" onClick={joinRoom}>
                          <img src={AttendVideoIcon} alt="" />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Modal>
      </div>
      <a ref={leaveCallButtonRef} onClick={handleHangup}></a>
      <a ref={ringCall} onClick={ring}></a>
      <a ref={clickButton} onClick={clickButtonFun}></a>
    </div>
  );
};

export default LockerPage;

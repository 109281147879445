import React, { useEffect, useState } from "react";
import { CardElement, useStripe, useElements } from "@stripe/react-stripe-js";
import Header from "../partials/header/header";
import CoverPhoto from "../../images/dashboard-bg.png";
import ProfilePhoto from "../../images/dashboard-profile.png";
import Games from "../../images/games.svg";
import AtmFront from "../../images/atm-front.png";
import AtmBack from "../../images/atm-backside.png";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import { Link, useNavigate, useParams } from "react-router-dom";
import ReactPaginate from "react-paginate";
import InputMask from "react-input-mask";
import { useDispatch } from "react-redux";
import { loginSuccess } from "../Redux/Authentication";
import styles from "./style.module.css";
import avatar from "../../images/avatar1.png";
import profileImg from "../../images/editProfile.png";
const Add = () => {
  const stripe = useStripe();
  const elements = useElements();
  const { id } = useParams();
  const dispatch = useDispatch();

  const isAuthenticated = useSelector((state) => state.user);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);

  const [profileImage, setProfileImg] = useState(
    isAuthenticated?.image || ProfilePhoto
  );
  const [error, setError] = useState(null);
  const [uploadProgress, setUploadProgress] = useState(0);
  const [seletectedFile, setSelectedFile] = useState("");
  const [ImgLoading, setImgLoading] = useState(false);
  const itemsPerPage = 10;
  const [data, setData] = useState([]);
  const [isLoading1, setIsLoading1] = useState(false);
  const [isDisplay, setIsDisplay] = useState(false);
  const [isNotFound, setIsNotFound] = useState(false);
  const [isNewCustomer, setIsNewCustomer] = useState(false);
  const [isVenmo, setIsVenmo] = useState(true);
  const [transaction, setTransaction] = useState(false);
  const [isZelle, setIsZelle] = useState(false);
  const [credentials, setCredentials] = useState({
    new_password: "",
    confirm_password: "",
  });
  const [vemocredentials, setVemocredentials] = useState({
    v_name: "",
    z_name: "",
    c_name: "",
    phone: "",
  });
  const [cardCredentials, setCardCredentials] = useState(isAuthenticated);
  const [bankCredentials, setBankCredentials] = useState();
  const [winnerCount, setWinnerCount] = useState();
  const [phone, setPhone] = useState(isAuthenticated.phone);
  return (
    <div>
      <Header />
      <h1>Payments</h1>
      <div className="container">
        <div className="dashboard-profile position-relative">
          <div className="cover-photo-sec">
            <img src={CoverPhoto} alt="cover-img" className="cover-photo" />
          </div>
          <div className="profile-photo-sec">
            <img
              src={profileImage}
              alt={profileImage}
              className="profile-photo"
            />
          </div>
        </div>
        <div className="profile-name-container">
          <h2 className="text-capitalize">{isAuthenticated.name}</h2>
          <p className="text-capitalize">{isAuthenticated.role}</p>
        </div>
        <div className="dashboard-profile position-relative">
          <div className="row">
            <div className="col-lg-4">
              <div className="setting-detail-sec">
                <div className="top-setting-sec">
                  <div className="playing-button-sec">
                    <img src={Games} alt="games" />
                    <Link to="/question-page" className="link-style">
                      Play Game
                    </Link>
                  </div>

                  <div className="text-detail-sec">
                    <div className="primary-text">
                      <h6>Total Wins</h6>
                      <h5>{winnerCount?.totalGamesWon}</h5>
                    </div>
                    <div className="primary-text">
                      <h6>Pending Price</h6>
                      <h5>${winnerCount?.pending.toFixed(2)}</h5>
                    </div>
                    <div className="primary-text">
                      <h6>Approved Price</h6>
                      <h5>${winnerCount?.approved.toFixed(2)}</h5>
                    </div>
                  </div>
                </div>
                <div className="top-setting-sec mt-3">
                  <CardElement
                    id="card-element"
                    options={{
                      style: {
                        base: {
                          fontSize: "16px",
                          color: "#424770",
                          "::placeholder": {
                            color: "#aab7c4",
                          },
                        },
                        invalid: {
                          color: "#9e2146",
                        },
                      },
                    }}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Add;

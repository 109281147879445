import React, { useEffect, useState } from "react";
import { CardElement, useStripe, useElements } from "@stripe/react-stripe-js";
import Header from "../partials/header/header";
import CoverPhoto from "../../images/dashboard-bg.png";
import ProfilePhoto from "../../images/dashboard-profile.png";
import Games from "../../images/games.svg";
import Coins from "../../assets/icons/Coin.svg";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import { Link, useNavigate, useParams } from "react-router-dom";
import ReactPaginate from "react-paginate";
import InputMask from "react-input-mask";
import { useDispatch } from "react-redux";
import { loginSuccess } from "../Redux/Authentication";
import styles from "./style.module.css";
import Modal from "react-modal";
import FeaturedIcon from "../../assets/icons/Featured-icon.svg";
import Cross from "../../assets/icons/close_btn.svg";
import CheckBox from "../../assets/icons/Checkbox.svg";
import CheckIcon from "../../assets/icons/Check-icon.svg";
import CardIcon from "../../assets/icons/card-icons.svg";
import avatar from "../../images/avatar1.png";
import profileImg from "../../images/editProfile.png";
function PhoneInput(props) {
  return (
    <InputMask
      mask="+1999 999 9999"
      value={props.value}
      onChange={props.onChange}
      placeholder="+1123 123 1234"
      className="Input-text-field"
    ></InputMask>
  );
}

function PhoneInput2(props) {
  return (
    <InputMask
      mask="9999"
      value={props.value}
      onChange={props.onChange}
      placeholder="1234"
      className="Input-text-field"
    ></InputMask>
  );
}
function CardInput(props) {
  return (
    <InputMask
      mask="9999 9999 9999 9999"
      value={props.value}
      onChange={props.onChange}
      placeholder="Card Number"
      className="Input-text-field"
    ></InputMask>
  );
}
function CVVInput(props) {
  return (
    <InputMask
      mask="999"
      value={props.value}
      onChange={props.onChange}
      placeholder="123"
      className="Input-text-field"
    ></InputMask>
  );
}
function CardExpiryInput(props) {
  return (
    <InputMask
      mask="99/9999"
      value={props.value}
      onChange={props.onChange}
      placeholder="MM/YYYY"
      className="Input-text-field"
    ></InputMask>
  );
}
const PuzzleUser = () => {
  const { id } = useParams();
  const dispatch = useDispatch();
  const stripe = useStripe();
  const elements = useElements();
  const isAuthenticated = useSelector((state) => state.user);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);

  const [profileImage, setProfileImg] = useState(
    isAuthenticated?.image || ProfilePhoto
  );
  const [error, setError] = useState(null);
  const [uploadProgress, setUploadProgress] = useState(0);
  const [seletectedFile, setSelectedFile] = useState("");
  const [ImgLoading, setImgLoading] = useState(false);
  const itemsPerPage = 10;
  const [data, setData] = useState([]);
  const [isLoading1, setIsLoading1] = useState(false);
  const [isLoading2, setIsLoading2] = useState(false);
  const [isDisplay, setIsDisplay] = useState(false);
  const [isNotFound, setIsNotFound] = useState(false);
  const [isNewCustomer, setIsNewCustomer] = useState(false);
  const [isVenmo, setIsVenmo] = useState(true);
  const [transaction, setTransaction] = useState(false);
  const [isZelle, setIsZelle] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isModalOpenTwo, setIsModalOpenTwo] = useState(false);
  const [credentials, setCredentials] = useState({
    new_password: "",
    confirm_password: "",
  });

  const [vemocredentials, setVemocredentials] = useState({
    v_name: "",
    z_name: "",
    c_name: "",
    phone: "",
  });
  const [cardCredentials, setCardCredentials] = useState(isAuthenticated);
  const [bankCredentials, setBankCredentials] = useState();
  const [winnerCount, setWinnerCount] = useState();
  const [phone, setPhone] = useState(isAuthenticated.phone);
  const convertCash = () => {
    setIsModalOpen(true);
  }
  const cancelCashModal = () => {
    setIsModalOpen(false);
  }
  const handlePhoneInput = (e) => {
    setPhone(e.target.value);
  };
  const [bankphone, setbankphone] = useState("");
  const handleBankPhoneInput = (e) => {
    setbankphone(e.target.value);
  };
  const [card, setCard] = useState(isAuthenticated.card_number);
  const handleCardInput = (e) => {
    setCard(e.target.value);
  };
  const [cvv, setCvv] = useState(isAuthenticated.cvv);
  const handleCVVInput = (e) => {
    setCvv(e.target.value);
  };
  const [expiry, setExpiry] = useState(isAuthenticated.expireAt);
  const handleExpiryInput = (e) => {
    setExpiry(e.target.value);
  };
  const [winnerAmount, setWinnerAmount] = useState();
  const [winnerMemes, setWinnerMemes] = useState();
  const [authenticated, setAuthenticated] = useState();
  const url = process.env.REACT_APP_APP_BACK_URL;
  const [isLoading, setIsLoading] = useState(false);
  let navigate = useNavigate();

  const handleSwitchChange = () => {
    setIsNewCustomer((prevIsNewCustomer) => !prevIsNewCustomer);
    setIsVenmo(false);
    setTransaction(false);
  };
  const handleSwitchChangeVenmo = () => {
    setIsVenmo((prevIsVenmo) => !prevIsVenmo);
    setIsNewCustomer(false);
    setTransaction(false);
    fetchData();
  };
  const fetchData = async () => {
    try {
      let headers = new Headers();
      headers.append("Content-Type", "application/json");
      headers.append("Access-Control-Allow-Origin", "*");
      headers.append("Access-Control-Allow-Credentials", "true");
      const response = await fetch(
        `${url}/api/bank/get/${isAuthenticated._id}`,
        {
          mode: "cors",
          method: "GET",
          headers: headers,
        }
      );
      const json = await response.json();
      console.log(json.bank);
      if (json.success) {
        setVemocredentials(json.bank);
        setbankphone(json.bank.phone);
      } else {
      }
    } catch (error) {
      console.error(error);
    }
  };
  const handleTransactions = () => {
    setTransaction((prevIsTransaction) => !prevIsTransaction);
    setIsNewCustomer(false);
    setIsVenmo(false);
    fetchUserData();
  };

  const handleBank = () => {
    setIsVenmo(true);
    setTransaction(false);
    setIsNewCustomer(false);
  };

  const handleProfile = () => {
    setIsNewCustomer(true);
    setIsVenmo(false);
    setTransaction(false);
  };
  const handleInput = (e) => {
    setCredentials({ ...credentials, [e.target.name]: e.target.value });
  };
  const handleInputCard = (e) => {
    setCardCredentials({ ...cardCredentials, [e.target.name]: e.target.value });
  };
  const handleInputBank = (e) => {
    setBankCredentials({ ...bankCredentials, [e.target.name]: e.target.value });
  };
  const handleVemoInputBank = (e) => {
    setVemocredentials({ ...vemocredentials, [e.target.name]: e.target.value });
  };

  const handleInputProfile = (e) => {
    // Update the profile details
    setAuthenticated((prevUser) => ({
      ...prevUser,
      [e.target.name]: e.target.value,
    }));
  };

  const formSubmit = async (e) => {
    e.preventDefault();
    let headers = new Headers();
    headers.append("Content-Type", "application/json");
    headers.append("Access-Control-Allow-Origin", "*");
    headers.append("Access-Control-Allow-Credentials", "true");

    setIsLoading(true);

    const response = await fetch(
      `${url}/api/auth/update/password/${isAuthenticated._id}`,
      {
        mode: "cors",
        method: "POST",
        headers: headers,
        body: JSON.stringify({
          new_password: credentials.new_password,
          confirm_password: credentials.confirm_password,
        }),
      }
    );
    setIsLoading(false);

    const json = await response.json();
    if (json.success) {
      toast.success(json.message);
    } else {
      toast.error(json.error);
    }
  };

  const formSubmitCard = async (e) => {
    e.preventDefault();
    let headers = new Headers();
    headers.append("Content-Type", "application/json");
    headers.append("Access-Control-Allow-Origin", "*");
    headers.append("Access-Control-Allow-Credentials", "true");
    headers.append("auth-token", localStorage.getItem("token"));
    setIsLoading(true);
    const res = await fetch(
      `${url}/api/auth/user/update/${isAuthenticated._id}`,
      {
        mode: "cors",
        method: "POST",
        headers: headers,
        body: JSON.stringify({
          name: cardCredentials.name,
          email: cardCredentials.email,
          phone: phone,
          card_type: cardCredentials.card_type,
          card_name: cardCredentials.card_name,
          card_number: card,
          expireAt: expiry,
          cvv: cvv,
        }),
      }
    );
    setIsLoading(false);
    const json2 = await res.json();
    if (json2.success) {
      dispatch(loginSuccess(json2.user));
      toast.success("Successfully Add Card Detail");
    } else {
      toast.error(json2.error);
    }
  };

  const formSubmitBank = async (e) => {
    e.preventDefault();
    let headers = new Headers();
    headers.append("Content-Type", "application/json");
    headers.append("Access-Control-Allow-Origin", "*");
    headers.append("Access-Control-Allow-Credentials", "true");
    setIsLoading(true);
    const res = await fetch(`${url}/api/bank/create/${isAuthenticated._id}`, {
      mode: "cors",
      method: "POST",
      headers: headers,
      body: JSON.stringify({
        v_name: vemocredentials.v_name,
        z_name: vemocredentials.z_name,
        c_name: vemocredentials.c_name,
        phone: bankphone,
      }),
    });
    setIsLoading(false);
    const json2 = await res.json();

    if (json2.success) {
      toast.success("Successfully Add Bank Detail");
    } else {
      toast.error(json2.error);
    }
  };

  //stripe code
  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!stripe || !elements) {
      // Stripe.js has not loaded yet. Make sure to include it in your HTML file.
      return;
    }

    // Create a token or handle payment using the PaymentMethod.
    const result = await stripe.createToken(elements.getElement(CardElement));
    payment(result.token);
    if (result.error) {
      setError(result.error.message);
    } else {
      // Send the token to your server or handle it as needed.
      // You can call a function like stripeTokenHandler(result.token);
    }
  };
  const payment = async (token) => {
    try {
      let headers = new Headers();
      headers.append("Content-Type", "application/json");
      headers.append("Access-Control-Allow-Origin", "*");
      headers.append("Access-Control-Allow-Credentials", "true");
      setIsLoading2(true);
      const response = await fetch(`${url}/api/payments/purchase/plan`, {
        mode: "cors",
        method: "POST",
        headers: headers,
        body: JSON.stringify({
          stripeToken: token.id,
          id: isAuthenticated._id,
        }),
      });
      const json = await response.json();
      setIsLoading2(false);
      if (json.success) {

        callApi();
        setIsModalOpen(false);
        toast.success("Plan puchase successfully!");
      } else {
        toast.error(json.error);
      }
    } catch (error) {
      setIsLoading2(false);
      toast.error("Internal Server Error");
    }
  };
  const callApi = async () => {
    try {
      let headers = new Headers();
      headers.append("Content-Type", "application/json");
      headers.append("Access-Control-Allow-Origin", "*");
      headers.append("Access-Control-Allow-Credentials", "true");
      const response = await fetch(
        `${url}/api/prizes/winner/count/${isAuthenticated._id}`,
        {
          mode: "cors",
          method: "GET",
          headers: headers,
        }
      );
      if (response.ok) {
        const responseData = await response.json();
        setWinnerCount(responseData.data);

        // Do something with the response data
      } else {
        // Handle error cases
        console.error("Error:", response.status, response.statusText);
      }
    } catch (error) {
      console.error("Error:", error);
    }
  };
  const handleFileSelect = async (event) => {
    const file = event.target.files[0];

    if (file) {
      const allowedTypes = ["image/jpeg", "image/png"];

      if (allowedTypes.includes(file.type)) {
        setSelectedFile(file);
        setError(null);

        // Create a FileReader
        const reader = new FileReader();

        // Add a progress event listener to the FileReader
        reader.onprogress = (e) => {
          if (e.lengthComputable) {
            const percentUploaded = Math.round((e.loaded / e.total) * 100);
            setUploadProgress(percentUploaded);
          }
        };
        reader.onload = (e) => {
          const dataURL = e.target.result; // This is the data URL of the selected image
          setProfileImg(dataURL); // Set the data URL as the profileImg
        };

        // Read the file (you can also add other event listeners like 'onload')
        reader.readAsDataURL(file);
      } else {
        //setFile(null);

        setError("Invalid file type. Please select an image file.");
        setTimeout(() => {
          setError(null);
        }, 3000);
      }
    }
  };
  useEffect(() => {
    callApi();
    fetchData();
  }, []);
  const fetchUserData = async () => {
    try {
      let headers = new Headers();
      headers.append("Content-Type", "application/json");
      headers.append("Access-Control-Allow-Origin", "*");
      headers.append("Access-Control-Allow-Credentials", "true");
      setIsLoading(true);
      setIsDisplay(false);
      setIsNotFound(false);
      const response = await fetch(
        `${url}/api/prizes/user/games/details/${isAuthenticated._id}?page=${currentPage}&limit=${itemsPerPage}`,
        {
          mode: "cors",
          method: "GET",
          headers: headers,
        }
      );
      setIsLoading(false);
      if (!response.ok) {
        throw new Error("Network response was not ok");
      }
      const json = await response.json();
      if (json.prizes.length > 0) {
        setIsDisplay(true);
        setData(json.prizes);
      } else {
        setIsNotFound(true);
      }
      setTotalPages(json.totalPages);
    } catch (error) {
      console.error(error);
    }
  };
  const handlePageChange = (selectedPage) => {
    setCurrentPage(selectedPage.selected + 1); // react-paginate uses zero-based indexing
  };
  const formattedDate = (date) => {
    if (!date) {
      // If date is empty or null, use the current date
      date = new Date();
    }

    return new Date(date).toLocaleDateString("en-US", {
      year: "numeric",
      month: "short",
      day: "numeric",
    });
  };
  // add user profile image
  const imgFormSubmit = async (e) => {
    try {
      e.preventDefault();
      const formData = new FormData();
      if (seletectedFile) {
        formData.append("file", seletectedFile);
      }
      formData.append("id", isAuthenticated._id);
      setImgLoading(true);
      const response = await fetch(`${url}/api/profile/image/change`, {
        method: "POST",
        body: formData,
      });
      setImgLoading(false);

      const json = await response.json();
      if (json.success) {
        dispatch(loginSuccess(json.user));
        toast.success("Image uploaded Successfully");
      } else {
        toast.error("failed to upload image");
      }
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <div>
      <Header />
      <div className="container">
        <div className="dashboard-profile position-relative">
          <div className="cover-photo-sec" >
            <img src={CoverPhoto} alt="cover-img" className="cover-photo" />
          </div>
          <div className="profile-photo-sec">
            <img
              src={profileImage}
              alt={profileImage}
              className="profile-photo"
            />
          </div>
        </div>
        <div className="profile-name-container">
          <h2 className="text-capitalize">{isAuthenticated.name}</h2>
          <p className="text-capitalize">{isAuthenticated.role}</p>
        </div>

        <div className="row">
          <div className="col-lg-4">
            <div className="setting-detail-sec">
              <div className="top-setting-sec">
                <div className="playing-button-sec">
                  <img src={Games} alt="games" />
                  <Link to="/question-page" className="btn btn-prim-2 w-auto">
                    Play Game
                  </Link>
                </div>

                <div className="text-detail-sec">
                  <div className="primary-text">
                    <h6>Total Wins</h6>
                    <p>{winnerCount?.totalGamesWon}</p>
                  </div>
                  <div className="primary-text">
                    <h6>Pending Price</h6>
                    <p>${winnerCount?.pending.toFixed(2)}</p>
                  </div>
                  <div className="primary-text">
                    <h6>Approved Price</h6>
                    <p>${winnerCount?.approved.toFixed(2)}</p>
                  </div>
                </div>
              </div>
              <div className="top-setting-sec mt-3">
                <div className="playing-button-sec">
                  <img src={Coins} alt="games" />
                </div>
                <div className="d-flex justify-content-between flex-row align-items-center p-2 mt-3">
                  <div className="d-flex flex-column justify-content-center">
                    <h6 style={{ fontWeight: "700" }}>Total Cash</h6>
                    <p>${winnerCount?.pending.toFixed(2) || "$0"}</p>
                    <button
                      type="submit"
                      disabled={isLoading}
                      className="btn btn-prim-2 w-auto"
                      onClick={convertCash}
                    >
                      Convert Cash
                    </button>
                  </div>
                  <div className="d-flex flex-column justify-content-center ">
                    <h6 style={{ fontWeight: "700" }}>Total Coins</h6>
                    <p>{isAuthenticated.coin || "0"}</p>
                    <button
                      type="submit"
                      disabled={isLoading}
                      className="btn btn-prim-2 w-auto "
                    >
                      Convert Coins
                    </button>
                  </div>
                </div>
              </div>
              <div className="password-setting-sec">
                <form onSubmit={imgFormSubmit}>
                  <div className="text-center ">
                    <div className="inpt-wrap mt-2">
                      <input
                        type="file"
                        className="form-control"
                        name="file"
                        onChange={handleFileSelect}
                        required
                      />
                    </div>
                    <div
                      style={{
                        fontSize: "12px",
                        color: "red",
                        fontStyle: "italic",
                      }}
                    >
                      {uploadProgress > 0 && uploadProgress < 100 && (
                        <span>{uploadProgress}% uploaded</span>
                      )}
                    </div>
                    {error && (
                      <div
                        style={{
                          fontSize: "12px",
                          color: "red",
                          fontStyle: "italic",
                        }}
                      >
                        {error}
                      </div>
                    )}
                    <div className="col-12 text-end">
                      <button
                        type="submit"
                        className="btn btn-prim-2 w-auto"
                        disabled={ImgLoading}
                      >
                        Change Photo
                      </button>
                    </div>
                  </div>
                </form>
              </div>
              <div className="password-setting-sec mb-3">
                <h4>Password Setting</h4>
                <form onSubmit={formSubmit}>
                  <div className="input-field-section">
                    <input
                      type="password"
                      id="password"
                      name="current_password"
                      placeholder="Enter Current Password"
                      className="Input-text-field"
                    />
                    <input
                      type="password"
                      className="Input-text-field"
                      id="email"
                      name="new_password"
                      placeholder="Enter New Password"
                      value={credentials.new_password}
                      onChange={handleInput}
                      required
                    />

                    <input
                      type="password"
                      className="Input-text-field"
                      id="email"
                      name="confirm_password"
                      placeholder="Enter Confirm Password"
                      value={credentials.confirm_password}
                      onChange={handleInput}
                      required
                    />
                  </div>
                  <div className="button-section">
                    <button
                      type="submit"
                      disabled={isLoading}
                      className="btn btn-prim-2 w-auto "
                    >
                      Change Password
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
          <div className="col-lg-8">
            <div className="card-info-sec mb-3">
              <h4>Account Details</h4>

              <div className="row mt-3">
                <div className="col-lg-8">
                  <div
                    className="main-tabs-bar d-flex align-items-center text-center rounded "
                    style={{ background: "#EEF3F5" }}
                  >
                    <div className="py-1 tab-item" style={{ width: "33.33%" }}>
                      <div
                        className={`py-2 ${isNewCustomer && "active rounded"}`}
                      >
                        <Link
                          className={`profile-link text-decoration-none ${isNewCustomer && "active"
                            }`}
                          onClick={handleProfile}
                        >
                          Profile Details
                        </Link>
                      </div>
                    </div>
                    <div className="py-1 tab-item" style={{ width: "33.33%" }}>
                      <div className={`py-2 ${isVenmo && "active rounded"}`}>
                        <Link
                          className={`bank-link text-decoration-none ${isVenmo && "active"
                            }`}
                          onClick={handleSwitchChangeVenmo}
                        >
                          Claim Prize
                        </Link>
                      </div>
                    </div>

                    <div className="py-1 tab-item " style={{ width: "33.33%" }}>
                      <div
                        className={`py-2 ${transaction && "active rounded "}`}
                      >
                        <Link
                          className={`transaction-link text-decoration-none  ${transaction && "active"
                            }`}
                          onClick={handleTransactions}
                          activeClassName="active"
                        >
                          Transactions Details
                        </Link>
                      </div>
                    </div>
                  </div>

                  <div className="card-details-sec">
                    <div className="input-field-section">
                      {isVenmo && (
                        <form onSubmit={formSubmitBank}>
                          <div className="d-flex flex-column gap-3">
                            <div>
                              <label
                                htmlFor="venmo"
                                style={{ fontWeight: "700" }}
                              >
                                Venmo Username:
                              </label>
                              <input
                                type="text"
                                id="v_name"
                                name="v_name"
                                placeholder="Enter Venmo Name"
                                className="Input-text-field text-capitalize "
                                onChange={handleVemoInputBank}
                                value={vemocredentials.v_name}
                              />
                            </div>
                            <div>
                              <label
                                htmlFor="zelle"
                                style={{ fontWeight: "700" }}
                              >
                                Zelle Username:
                              </label>
                              <input
                                type="text"
                                id="z_name"
                                name="z_name"
                                placeholder="Enter Zelle Name"
                                className="Input-text-field "
                                onChange={handleVemoInputBank}
                                value={vemocredentials.z_name}
                              />
                            </div>
                            <div>
                              <label
                                htmlFor="cashApp"
                                style={{ fontWeight: "700" }}
                              >
                                Cash App Username:
                              </label>
                              <input
                                type="text"
                                id="cashApp"
                                name="c_name"
                                placeholder="Enter Cash App Name"
                                value={vemocredentials.c_name}
                                className="Input-text-field"
                                onChange={handleVemoInputBank}
                              />
                            </div>
                            <div>
                              <label style={{ fontWeight: "700" }}>
                                Last 4 Digits of your Cell Phone:
                              </label>
                              <PhoneInput2
                                value={bankphone}
                                onChange={handleBankPhoneInput}
                              ></PhoneInput2>
                            </div>

                            <div className="button-section">
                              <button
                                type="submit"
                                className="btn btn-prim-2 w-auto"
                              >
                                Update
                              </button>
                            </div>
                          </div>
                        </form>
                      )}
                    </div>
                  </div>

                  <div className="card-details-sec">
                    <div className="input-field-section">
                      {isNewCustomer && (
                        <>
                          <form onSubmit={formSubmitCard}>
                            <div className="d-flex flex-column gap-3">
                              <input
                                type="text"
                                id="name"
                                name="name"
                                placeholder="Enter Full Name"
                                className="Input-text-field text-capitalize "
                                onChange={handleInputCard}
                                value={cardCredentials.name}
                              />
                              <input
                                type="email"
                                id="email"
                                name="email"
                                placeholder="Enter Email"
                                className="Input-text-field "
                                onChange={handleInputCard}
                                value={cardCredentials.email}
                              />

                              <PhoneInput
                                value={phone}
                                onChange={handlePhoneInput}
                              ></PhoneInput>

                              <select
                                id="card_type"
                                name="card_type"
                                value={cardCredentials.card_type}
                                className="Input-text-field"
                                onChange={handleInputCard}
                              >
                                <option value="">Select Card</option>
                                <option value="mastercard">MasterCard</option>
                                <option value="visacard">VisaCard</option>
                                <option value="paypal">PayPal</option>
                                <option value="unionpay">UnionPay</option>
                              </select>
                              <input
                                type="text"
                                id="card_name"
                                name="card_name"
                                placeholder="Enter Card Name"
                                value={cardCredentials.card_name}
                                className="Input-text-field"
                                onChange={handleInputCard}
                              />

                              <CardInput
                                value={card}
                                onChange={handleCardInput}
                              ></CardInput>
                              <div className="row">
                                <div className="col-lg-6">
                                  <CardExpiryInput
                                    value={expiry}
                                    onChange={handleExpiryInput}
                                  ></CardExpiryInput>
                                </div>
                                <div className="col-lg-6">
                                  <CVVInput
                                    value={cvv}
                                    onChange={handleCVVInput}
                                  ></CVVInput>
                                </div>
                              </div>

                              <div className="button-section">
                                <button
                                  type="submit"
                                  className="btn btn-prim w-auto"
                                >
                                  Add Card
                                </button>
                              </div>
                            </div>
                          </form>
                        </>
                      )}
                    </div>
                  </div>
                  <div className="card-details-sec">
                    <div className="input-field-section">
                      {transaction && (
                        <>
                          <div className="table-wrap">
                            <div className="row">
                              <div className="col-12">
                                <div className={styles.card_box}>
                                  <div className={styles.tables_wrap}>
                                    <div className="table-responsive">
                                      <table className="table align-middle">
                                        <thead>
                                          <tr>
                                            <th>Amount</th>
                                            <th>Score</th>
                                            <th>Status</th>
                                            <th>Date</th>
                                          </tr>
                                        </thead>

                                        <tbody>
                                          {isDisplay &&
                                            data.map((item, i) => (
                                              <tr>
                                                <td>
                                                  {item.type === "money" && "$"}
                                                  {item.amount}
                                                </td>
                                                <td>{item.score}</td>

                                                <td>
                                                  {item.transaction_status ===
                                                    "pendding" && (
                                                      <button
                                                        type="button"
                                                        className="btn btn-edit tb-btn"
                                                      >
                                                        Pending
                                                      </button>
                                                    )}
                                                  {item.transaction_status ===
                                                    "approved" && (
                                                      <button
                                                        type="button"
                                                        className="btn btn-success tb-btn"
                                                      >
                                                        Approved
                                                      </button>
                                                    )}
                                                </td>
                                                <td>
                                                  {formattedDate(
                                                    item.createdAt
                                                  )}
                                                </td>
                                              </tr>
                                            ))}
                                          {isNotFound && (
                                            <tr>
                                              <td
                                                colSpan={6}
                                                className="text-center"
                                                style={{ color: "red", fontSize: "12px" }}
                                              >
                                                No Data Found
                                              </td>
                                            </tr>
                                          )}
                                        </tbody>
                                      </table>
                                    </div>
                                    <div className="table-num">
                                      {data.length > 0 && (
                                        <ReactPaginate
                                          previousLabel={"<"}
                                          nextLabel={">"}
                                          breakLabel={"..."}
                                          pageCount={totalPages}
                                          forcePage={currentPage - 1} // react-paginate uses zero-based indexing
                                          onPageChange={handlePageChange}
                                          containerClassName={" dig-num"}
                                          pageClassName={"dig-num"}
                                          previousClassName={"num-btns"}
                                          nextClassName={"num-btns"}
                                          disabledClassName={
                                            "pagination-disabled"
                                          }
                                          activeClassName={"pagination-active"}
                                        />
                                      )}
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </>
                      )}
                    </div>
                  </div>
                </div>
                <div className="col-lg-4">
                  <div className="card-photo-sec  ">
                    {/* <div className="card-front-side">
                        <img src={AtmFront} />
                      </div>
                      <div className="card-back-side">
                        <img src={AtmBack} />
                      </div> */}

                    <div
                      className="mx-auto p-3 text-center "
                      style={{ backgroundColor: "#00C2FF", height: "500px" }}
                    >
                      Google AdSense size 970x90 px
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <Modal
        isOpen={isModalOpen}
        contentLabel="Example Modal"
        className="d-flex flex-column justify-content-center p-3 col-lg-5 m-auto mt-5 h-100 align-items-center "
        shouldCloseOnOverlayClick={false}
        style={{
          content: {
            fontFamily: "Arial",
            fontWeight: "800",
          },
          overlay: {
            backgroundColor: "rgba(0, 0, 0, 0.5)", // Set the overlay background color
          },
        }}
      >
        <div
          className="p-3 w-sm-50 h-sm-50 col-10 rounded-3 "
          style={{
            background: "#fff",
          }}
        >
          <div className="d-flex flex-row gap-2 justify-content-between align-items-center">
            <div className="d-flex flex-row gap-2 justify-content-center align-items-center">
              <img src={FeaturedIcon} height={30} width={30} />
              <div className="d-flex flex-column gap-2">
                <h6
                  style={{
                    fontSize: "12px",
                    fontWeight: "600",
                    margin: "0px",
                    padding: "0px",
                  }}
                >
                  Subscription plan
                </h6>
                <p
                  style={{
                    fontSize: "10px",
                    fontWeight: "300",
                    margin: "0px",
                    padding: "0px",
                  }}
                >
                  Simple and flexible per-user pricing
                </p>
              </div>
            </div>
            <img src={Cross} onClick={cancelCashModal} height={20} width={20} />
          </div>
          <hr />
          <div
            className=""
            style={{ border: "2px solid #012269", borderRadius: "8px" }}
          >
            <div className="d-flex flex-row justify-content-between  p-2">
              <h6 style={{ fontSize: "16px", fontWeight: "700" }}>$2/mth</h6>
              <img src={CheckBox} height={10} width={10} />
            </div>
            <div className="p-2 m-0">
              <p
                style={{
                  fontSize: "14px",
                  fontWeight: "700",
                  margin: "0px",
                  padding: "0px",
                }}
              >
                Basic Plan
              </p>
              <p
                style={{
                  fontSize: "12px",
                  fontWeight: "500",
                  margin: "0px",
                  padding: "0px",
                }}
              >
                Billed Aunually $10
              </p>
            </div>
            <div className="list">
              <ul className="custom-list ">
                <li>
                  <img src={CheckIcon} height={15} width={15} />
                  <p>Basic Features</p>
                </li>
              </ul>
            </div>
          </div>
          <hr />
          <div className="d-flex flex-column justify-content-start p-1 gap-1 mt-2">
            <h6 style={{ fontSize: "16px", fontWeight: "700" }}>
              Payment Method
            </h6>
            <h6 style={{ fontSize: "14px", fontWeight: "500" }}>
              Your Card Details
            </h6>
          </div>
          <form onSubmit={handleSubmit} className="mt-3">
            <CardElement
              id="card-element"
              options={{
                style: {
                  base: {
                    fontSize: "12px",
                    color: "#424770",
                    "::placeholder": {
                      color: "#aab7c4",
                    },
                  },
                  invalid: {
                    color: "#9e2146",
                  },
                },
              }}
            />
            <div className="button-section">
              <button
                type="submit"
                disabled={isLoading2}
                className="btn btn-prim-2 w-auto "
              >
                Pay
              </button>
            </div>
          </form>
        </div>
      </Modal>

      <Modal
        isOpen={isModalOpenTwo}
        contentLabel="Example Modal"
        className="d-flex flex-column justify-content-center p-3 col-lg-5 m-auto mt-5 h-100 align-items-center "
        shouldCloseOnOverlayClick={false}
        style={{
          content: {
            fontFamily: "Arial",
            fontWeight: "800",
          },
          overlay: {
            backgroundColor: "rgba(0, 0, 0, 0.5)", // Set the overlay background color
          },
        }}
      >
        <div
          className="p-3 w-sm-50 h-sm-50 col-10 rounded-3 "
          style={{
            background: "#fff",
          }}
        >
          <div className="d-flex flex-row gap-2 justify-content-between align-items-center">
            <img src={CardIcon} height={30} width={30} />
            <img src={Cross} height={20} width={20} />
          </div>

          <div>
            <div className="d-flex flex-column justify-content-start p-1 gap-1 mt-2">
              <h6 style={{ fontSize: "16px", fontWeight: "700" }}>
                Payment Method
              </h6>
              <h6 style={{ fontSize: "14px", fontWeight: "500" }}>
                Your Card Details
              </h6>
            </div>
            <form onSubmit={handleSubmit} className="mt-3">
              <CardElement
                id="card-element"
                options={{
                  style: {
                    base: {
                      fontSize: "12px",
                      color: "#424770",
                      "::placeholder": {
                        color: "#aab7c4",
                      },
                    },
                    invalid: {
                      color: "#9e2146",
                    },
                  },
                }}
              />
              <div className="button-section">
                <button
                  type="submit"
                  disabled={isLoading2}
                  className="btn btn-prim-2 w-auto "
                >
                  Pay
                </button>
              </div>
            </form>
          </div>
        </div>
      </Modal>
    </div>
  );
};

export default PuzzleUser;

import React from "react";
import logo from "../../images/logo.svg";
// import "../../simplelocker.css";
import "../../index.css";
import { Link, useNavigate } from "react-router-dom";
import ProfileImg from "../../images/images/profile.png";
import { useSelector } from "react-redux";
const Header2 = () => {
  const isAuthenticated = useSelector((state) => state.user);
  return (
    <div>
      <header className="header2 mb-2">
        <div className="row mb-3">
          <div className="prof-sett w-100 px-3">
            <div className="col-lg-6">
              <div
                className="main-heading2 text-capitalize"
                style={{ color: "#012267", fontSize: "1000" }}
              >
                <h1 style={{ fontSize: "22px", fontWeight: "700" }}>Welcome Back {isAuthenticated.name}!</h1>
              </div>
            </div>
            <div className="col-lg-6 ">
              <div className="prof-wrap">
                <div className="det-prof">
                  <div className="name-wrap">
                    <h5 className="mb-0 text-capitalize">
                      {isAuthenticated.name}
                    </h5>
                    <p>{isAuthenticated.email}</p>
                  </div>
                </div>
                <div className="prof-img">
                  {isAuthenticated.image ? (
                    <Link to="/profile">
                      <div className="img-profile-wrap">
                        <img
                          src={isAuthenticated.image}
                          style={{
                            height: "50px",
                            width: "50px",
                            borderRadius: "5px",
                          }}
                        />
                      </div>
                    </Link>
                  ) : (
                    <Link to="/profile">
                      <img src={ProfileImg} alt="" />
                    </Link>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </header>
    </div>
  );
};
export default Header2;

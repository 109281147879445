import React, { useEffect, useState } from "react";
import Header2 from "../../Components/partials/Header2";
import Sidebar from "../../Components/partials/Sidebar";
import BackIcon from "../../images/icon-back.png";
import UploadIcon from "../../images/icon-upload.png";
import { useSelector } from "react-redux";
import styles from "./style.module.css";
import { Link, useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
const Edit = () => {
  const isAuthenticated = useSelector((state) => state.user);
  const { id } = useParams();
  const url = process.env.REACT_APP_APP_BACK_URL;
  const [appendedHTML, setAppendedHTML] = useState("");
  const [amount, setAmount] = useState(false);
  const [memes, setMemes] = useState(false);
  const [memesData, setmemesData] = useState([]);
  const [seletectedFile, setSelectedFile] = useState("");

  const [credentials, setCredentials] = useState({
    number: "",
    time: "",
    link:"",
    description: "",
  });
  const [isLoading, setIsLoading] = useState(false);
  useEffect(() => {
    addsData(id);
  }, [id]);
  const addsData= async (addsData)=>{
    try {
        let headers = new Headers();
        headers.append("Content-Type", "application/json");
        headers.append("Access-Control-Allow-Origin", "*");
        headers.append("Access-Control-Allow-Credentials", "true");
        const response = await fetch(`${url}/api/setting/getAdds/${id}`, {
          mode: "cors",
          method: "GET",
          headers: headers,
        });
        const json = await response.json();
        if (json.success) {
          setCredentials({ ...json.ads });
          setContent(json.ads.description);
        }
      } catch (error) {
        console.error(error);
      }
  }
  const handleInput = (e) => {
    setCredentials({ ...credentials, [e.target.name]: e.target.value });
  };
  const [content, setContent] = useState('');

  const handleEditorChange = (value) => {
    setContent(value);
  };

  const formSubmit = async (e) => {
    try {
      e.preventDefault();
      setIsLoading(true);
      const formData = new FormData();
      if (seletectedFile) {
        formData.append("file", seletectedFile);
      }
      formData.append("number", credentials.number);
      formData.append("id", id);
      formData.append("time", credentials.time);
      formData.append("link",credentials.link);
      formData.append("description",content);
      
      const response = await fetch(`${url}/api/setting/updateAdds`, {
        method: "POST",
        body: formData,
      });
      setIsLoading(false);

      const json = await response.json();
      if (json.success) {
        setSelectedFile("");
        toast.success("Ads Updated Successfully");
      } else {
        toast.error("failed to add record");
      }
    } catch (error) {
      setIsLoading(false);
      console.log(error);
    }
  };
  const handleFileSelect = async (event) => {
    const file = event.target.files[0];
    setSelectedFile(file);
  };

  const [isActive, setIsActive] = useState(false);
  // Function to toggle the active state
  const toggleActive = () => {
    setIsActive(!isActive);
  };
  // Define the CSS classes based on the isActive state
  const divClassName = isActive ? "sidebar active" : "sidebar";
  return (
    <div className={styles.heading}>
      <div className="body-wrap">
        <section className={styles.sidebar_wrap}>
          <div className="container-fluid px-0">
            <div className="d-flex">
              <Sidebar />
              <div className="p-3 w-100 main__content">
                <Header2 isActive={isActive} toggleActive={toggleActive} />
                <div className="general-dashboard mt-5">
                  <div className="container-fluid">
                    <div className="dashboard-top-row add-new-top-row">
                      <div className="row">
                        <div className="col-lg-6 my-auto">
                          <div className="user-wrap user-back-wrap">
                            <Link to="/game/settings">
                              <div className="back-icon">
                                <img src={BackIcon} alt="" />
                              </div>
                            </Link>
                            <div className="user-back-det">
                              <h3>Create Ads</h3>
                              <p>Add ads in the system</p>
                            </div>
                          </div>
                        </div>
                        <div className="col-lg-6 text-end"></div>
                      </div>
                    </div>
                    {/* row */}
                    <form onSubmit={formSubmit}>
                      <div className="form-box card-box">
                        <div className="row">
                          <div className="col-lg-6">
                            {/* input row */}
                            <div className="row">
                              <div className="col-xl-4 col-lg-4">
                                <div className="label-wrap">
                                  <h4>Question No</h4>
                                  <p>Enter the question</p>
                                </div>
                              </div>
                              <div className="col-xl-7 col-lg-7">
                                <div className="inpt-wrap">
                                  <input
                                    type="number"
                                    className="form-control"
                                    id="number"
                                    placeholder="Question number"
                                    name="number"
                                    value={credentials.number}
                                    onChange={handleInput}
                                    required
                                  />
                                </div>
                              </div>
                            </div>
                            {/* input row */}
                          </div>
                          <div className="col-lg-6">
                            {/* input row */}
                            <div className="row">
                              <div className="col-xl-4 col-lg-4">
                                <div className="label-wrap">
                                  <h4>Time</h4>
                                  <p>Enter time in seconeds</p>
                                </div>
                              </div>
                              <div className="col-xl-7 col-lg-7">
                                <div className="inpt-wrap">
                                  <input
                                    type="number"
                                    className="form-control"
                                    id="time"
                                    placeholder="Enter time in seconeds"
                                    name="time"
                                    value={credentials.time}
                                    onChange={handleInput}
                                    required
                                  />
                                </div>
                              </div>
                            </div>
                            {/* input row */}
                          </div>{" "}
                          <div className="col-lg-6">
                            {/* input row */}
                            <div className="row">
                              <div className="col-xl-4 col-lg-4">
                                <div className="label-wrap">
                                  <h4>Link</h4>
                                  <p>Enter link of site</p>
                                </div>
                              </div>
                              <div className="col-xl-7 col-lg-7">
                                <div className="inpt-wrap">
                                  <input
                                    type="text"
                                    className="form-control"
                                    id="link"
                                    placeholder="Enter the link of site"
                                    name="link"
                                    value={credentials.link}
                                    onChange={handleInput}
                                    required
                                  />
                                </div>
                              </div>
                            </div>
                            {/* input row */}
                          </div>{" "}
                          <div className="col-lg-6">
                            {/* input row */}
                            <div className="row">
                              <div className="col-xl-4 col-lg-4">
                                <div className="label-wrap">
                                  <h4>File</h4>
                                  <p>Upload media file display in ads</p>
                                </div>
                              </div>
                              <div className="col-xl-7 col-lg-7">
                                <div className="inpt-wrap">
                                  <input
                                    type="file"
                                    className="form-control"
                                    name="file"
                                    onChange={handleFileSelect}
                                  />
                                </div>
                              </div>
                            </div>
                            {/* input row */}
                          </div>
                          <div className="col-lg-12">
                            {/* input row */}
                            <div className="row">
                              <div className="col-xl-2 col-lg-2">
                                <div className="label-wrap">
                                  <h4>Description</h4>
                                  <p>Add text display in ads</p>
                                </div>
                              </div>
                              <div className="col-xl-8 col-lg-8">
                                <div className="inpt-wrap" style={{ width: '100%', height: '200px' }}>
                                <ReactQuill value={content} onChange={handleEditorChange} />
                                </div>
                              </div>
                            </div>
                            {/* input row */}
                          </div>
                          <div className="col-lg-12 text-end">
                            <div className="form-btn-wrap mb-5 pb-5">
                              <a
                                href=""
                                className="btn btn-prim btn-transparent w-auto"
                              >
                                Reset Form
                              </a>
                              <button
                                type="submit"
                                className="btn btn-prim w-auto ms-5"
                                disabled={isLoading}
                              >
                                {isLoading ? "Updating..." : "update add"}
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </div>
  );
};
export default Edit;

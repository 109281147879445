import React, { useEffect, useState } from "react";
import Header from "../../Components/partials/Header2";
import Sidebar from "../../Components/partials/Sidebar";
import BackIcon from "../../images/icon-back.png";
import UploadIcon from "../../images/icon-upload.png";
import { useSelector } from "react-redux";
import styles from "./style.module.css";
import { Link, useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import axios from "axios";
const baseUrl = process.env.REACT_APP_BASE_URL;
const Edit = () => {
  const isAuthenticated = useSelector((state) => state.user);
  const url = process.env.REACT_APP_APP_BACK_URL;
  const { id } = useParams();
  const [appendedHTML, setAppendedHTML] = useState("");
  const [logoImage, setLogoImage] = useState(null);
  useEffect(() => {
    getSite(id);
  }, [id]);
  const [credentials, setCredentials] = useState({
    name: "",
    address: "",
    status: "",
    ip_address: "",
    password: "",
    slug: "",
    port: "",
    cmd: "",
  });
  const [isLoading, setIsLoading] = useState(false);
  const [formData, setFormData] = useState({});
  const handleInput = (e) => {
    setCredentials({ ...credentials, [e.target.name]: e.target.value });
  };
  const handleLogo = (e) => {
    setLogoImage(e.target.files[0]);
  };
  const uploadLogo = async () => {
    try {
      const formData = new FormData();
      console.log("logoImage");
      console.log(logoImage);
      if (logoImage) {
        formData.append("file", logoImage);
        formData.append("id", id);
      }
      console.log(formData);
      const response = await axios.post(`${url}/api/sites/logo/add`, formData);
      const json = await response;
      if (json.success) {
        toast.success({
          message: "Success",
          description: "Image uploaded Successfully",
          duration: 3,
        });
      } else {
        toast.error({
          message: "Failed",
          description: "failed to upload image",
          duration: 3,
        });
      }
    } catch (error) {
      console.log(error);
    }
  };
  async function checkFileExists() {
    if (credentials?.image)
      try {
        const response = await fetch(
          `/api/sites/checkFile?path=${credentials?.image}`
        );

        if (response.ok) {
          const data = await response.json();

          if (data.exists) {
            console.log(`File ${credentials?.image} exists.`);
          } else {
            console.log(`File ${credentials?.image} does not exist.`);
          }
        } else {
          console.error(
            `Failed to check file existence. Status: ${response.status}`
          );
        }
      } catch (error) {
        console.log(error);
      }
  }

  const formSubmit = async (e) => {
    e.preventDefault();
    try {
      if (logoImage) {
        await uploadLogo();
      }
      let headers = new Headers();
      headers.append("Content-Type", "application/json");
      headers.append("Access-Control-Allow-Origin", "*");
      headers.append("Access-Control-Allow-Credentials", "true");
      setIsLoading(true);
      const response = await fetch(`${url}/api/sites/update/${id}`, {
        mode: "cors",
        method: "PUT",
        headers: headers,
        body: JSON.stringify({
          name: credentials.name,
          address: credentials.address,
          ip_address: credentials.ip_address,
          slug: credentials.slug,
          password: credentials.password,
          port: credentials.port,
        }),
      });
      setIsLoading(false);
      const json = await response.json();
      if (json.success) {
        toast.success("Site Updated Successfully");
      } else {
        setIsLoading(false);
        toast.error(json.error);
      }
    } catch (error) {
      console.log(error);
    }
  };
  // get site data
  // get role
  const getSite = async (id) => {
    try {
      let headers = new Headers();
      headers.append("Content-Type", "application/json");
      headers.append("Access-Control-Allow-Origin", "*");
      headers.append("Access-Control-Allow-Credentials", "true");
      const response = await fetch(`${url}/api/sites/getSiteData/${id}`, {
        mode: "cors",
        method: "GET",
        headers: headers,
      });
      const json = await response.json();
      console.log(json);
      setCredentials({ ...json.site });
    } catch (error) {
      console.error(error);
    }
  };
  const [isActive, setIsActive] = useState(false);
  // Function to toggle the active state
  const toggleActive = () => {
    setIsActive(!isActive);
  };
  // Define the CSS classes based on the isActive state
  const divClassName = isActive ? "sidebar active" : "sidebar";
  return (
    <div className={styles.heading}>
      <div className="body-wrap">
        <section className={styles.sidebar_wrap}>
          <div className="container-fluid px-0">
            <div className="d-flex">
              <Sidebar />
              <div className="p-3 w-100 main__content">
                <Header isActive={isActive} toggleActive={toggleActive} />
                <div className="general-dashboard">
                  <div className="container-fluid">
                    <div className="dashboard-top-row add-new-top-row">
                      <div className="row">
                        <div className="col-lg-6 my-auto">
                          <div className="user-wrap user-back-wrap">
                            <Link to="/sites">
                              <div className="back-icon">
                                <img src={BackIcon} alt="" />
                              </div>
                            </Link>
                            <div className="user-back-det">
                              <h3>Update site</h3>
                              <p>
                                List of all the sites registered in the system
                              </p>
                            </div>
                          </div>
                        </div>
                        <div className="col-lg-6 text-end"></div>
                      </div>
                    </div>
                    {/* row */}
                    <form onSubmit={formSubmit}>
                      <div className="form-box card-box">
                        <div className="row">
                          <div className="col-lg-6">
                            {/* input row */}
                            <div className="row">
                              <div className="col-xl-5 col-lg-5">
                                <div className="label-wrap">
                                  <h4>Site Name</h4>
                                  <p>Enter the name of the site</p>
                                </div>
                              </div>
                              <div className="col-xl-7 col-lg-7">
                                <div className="inpt-wrap">
                                  <input
                                    type="text"
                                    className="form-control"
                                    id="sitec"
                                    placeholder="Site Name"
                                    name="name"
                                    value={credentials.name}
                                    onChange={handleInput}
                                    required
                                  />
                                </div>
                              </div>
                            </div>
                            {/* input row */}
                          </div>
                          <div className="col-lg-6">
                            {/* input row */}
                            <div className="row">
                              <div className="col-xl-5 col-lg-5">
                                <div className="label-wrap">
                                  <h4>Site Slug</h4>
                                  <p>Enter the slug of the site</p>
                                </div>
                              </div>
                              <div className="col-xl-7 col-lg-7">
                                <div className="inpt-wrap">
                                  <input
                                    type="text"
                                    className="form-control"
                                    id="sitec"
                                    placeholder="Site Slug"
                                    name="slug"
                                    value={credentials.slug}
                                    onChange={handleInput}
                                    required
                                  />
                                </div>
                              </div>
                            </div>
                            {/* input row */}
                          </div>
                          <div className="col-lg-6">
                            {/* input row */}
                            <div className="row">
                              <div className="col-xl-5 col-lg-5">
                                <div className="label-wrap">
                                  <h4>Password</h4>
                                  <p>Enter the password of the site</p>
                                </div>
                              </div>
                              <div className="col-xl-7 col-lg-7">
                                <div className="inpt-wrap">
                                  <input
                                    type="text"
                                    className="form-control"
                                    id="sitec"
                                    placeholder="Site Password"
                                    name="password"
                                    value={credentials.password}
                                    onChange={handleInput}
                                    required
                                  />
                                </div>
                              </div>
                            </div>
                            {/* input row */}
                          </div>
                          <div className="col-lg-6">
                            {/* input row */}
                            <div className="row">
                              <div className="col-xl-5 col-lg-5">
                                <div className="label-wrap">
                                  <h4>Site IP</h4>
                                  <p>Enter the IP address of the site</p>
                                </div>
                              </div>
                              <div className="col-xl-7 col-lg-7">
                                <div className="inpt-wrap">
                                  <input
                                    type="text"
                                    placeholder="192.168.10.1"
                                    name="ip_address"
                                    required
                                    value={credentials.ip_address}
                                    onChange={handleInput}
                                  />
                                </div>
                              </div>
                            </div>
                            {/* input row */}
                          </div>
                          <div className="col-lg-6">
                            {/* input row */}
                            <div className="row">
                              <div className="col-xl-5 col-lg-5">
                                <div className="label-wrap">
                                  <h4>Port</h4>
                                  <p>Enter the Port of the site</p>
                                </div>
                              </div>
                              <div className="col-xl-7 col-lg-7">
                                <div className="inpt-wrap">
                                  <input
                                    type="text"
                                    placeholder="5000"
                                    name="port"
                                    required
                                    value={credentials.port}
                                    onChange={handleInput}
                                  />
                                </div>
                              </div>
                            </div>
                            {/* input row */}
                          </div>
                          <div className="col-lg-6">
                            {/* input row */}
                            <div className="row">
                              <div className="col-xl-5 col-lg-5">
                                <div className="label-wrap">
                                  <h4>Logo</h4>
                                  <p>Upload the image</p>
                                </div>
                              </div>
                              <div className="col-xl-7 col-lg-7">
                                <div className="inpt-wrap">
                                  <input
                                    type="file"
                                    name="file"
                                    onChange={handleLogo}
                                  />
                                </div>
                              </div>
                            </div>
                            {/* input row */}
                          </div>
                          <div className="col-lg-6">
                            {/* input row */}
                            <div className="row">
                              <div className="col-xl-5 col-lg-5">
                                <div className="label-wrap">
                                  <h4>Site Address</h4>
                                  <p>Enter complete address of the site</p>
                                </div>
                              </div>
                              <div className="col-xl-7 col-lg-7">
                                <div className="inpt-wrap">
                                  <textarea
                                    name="address"
                                    id=""
                                    cols="30"
                                    rows="7"
                                    value={credentials.address}
                                    onChange={handleInput}
                                  >
                                    2405 Wesbrook Mall, Vancouver, BC V6T 1Z4,
                                    Canada
                                  </textarea>
                                </div>
                              </div>
                            </div>
                            {/* input row */}
                          </div>
                          <div className="col-lg-6">
                            {/* input row */}
                            <div className="row">
                              <div className="col-xl-5 col-lg-5"></div>
                              <div className="col-xl-7 col-lg-7">
                                <div className="inpt-wrap">
                                  <img
                                    alt="No logo"
                                    style={{ width: "10rem" }}
                                    src={credentials.image}
                                  />
                                </div>
                              </div>
                            </div>
                            {/* input row */}
                          </div>

                          <div className="col-lg-12 text-end">
                            <div className="form-btn-wrap mb-5 pb-5">
                              <button
                                type="submit"
                                className="btn btn-prim w-auto ms-5"
                                disabled={isLoading}
                              >
                                {isLoading ? "Updating..." : "update site"}
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </div>
  );
};
export default Edit;

import React from "react";
import Header from "../partials/header/header";
import Lockers from "../../images/all-locker.png";
import Facebook from "../../images/facebook.png";
import Twitter from "../../images/twitter.png";
import Youtube from "../../images/youtube (1).png";
import { Link } from "react-router-dom";
import Footer from "../partials/footer";

const AboutUs = () => {
  return (
    <div className="about-us-page">
      <Header />
      <div className="container">
        <section className="about-us-section">
          <h1 className="about-us-heading fw-bold">About us</h1>
          <h4 className="text-about-main mt-3">
            Discover the Future of Storage with Locker.
          </h4>
          <p className="text-about-para mt-3">
            At LockerPro, we take pride in revolutionizing storage services for
            individuals and businesses. As a cutting-edge company, we aim to
            provide innovative locker solutions that redefine convenience and
            security. Our commitment to excellence is reflected in our
            user-friendly platform, ensuring a seamless experience for all your
            storage needs.
          </p>
        </section>

        <section>
          <div className="row mt-5">
            <div className="col-lg-6">
              <div className="left-side-sec ">
                <h2>Book Your Locker Anytime, Anywhere</h2>
                <p className="mt-3 text-justify">
                  Looking for your next appointment with a local barber, hair
                  stylist, massage therapist or nail artist? Need appointment
                  booking for a beard trim, an eyebrow wax, or a deep tissue
                  massage?
                </p>
                <p className="text-justify">
                  Booksy is a free beauty scheduling app and makes appointments
                  easy to find and book within seconds. No more phone tag. Book
                  anytime, from anywhere, 24/7.
                </p>
                <h4>
                  Discover top businesses in your area and book instantly with
                  Booksy.
                </h4>
              </div>
            </div>
            <div className="col-lg-6">
              <div className="right-side-sec d-flex justify-content-around align-item-center rounded  p-3 mb-3">
                <img src={Lockers} height={300} width={300} />
              </div>
            </div>
          </div>
        </section>
      </div>
      <Footer />
    </div>
  );
};

export default AboutUs;

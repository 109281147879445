import React, { useState } from "react";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import { useSelector } from "react-redux";
import styles from "./style.module.css";
import logo from "../../assets/logo-dark.png";
import frgt_hero from "../../images/locker.jpg";

const ForgotPassword = () => {
  const imageUrl1 = "/assets/images/logo/logo-fast-lobby-white.png";
  const [credentials, setCredentials] = useState({ email: "" });
  const url = process.env.REACT_APP_APP_BACK_URL;
  const base_url = process.env.REACT_APP_BASE_URL;
  const [isLoading, setIsLoading] = useState(false);
  const [isEmailSent, setIsEmailSent] = useState(false);

  const handleInput = (e) => {
    setCredentials({ ...credentials, [e.target.name]: e.target.value });
  };
  const formSubmit = async (e) => {
    e.preventDefault();
    let headers = new Headers();
    headers.append("Content-Type", "application/json");
    headers.append("Access-Control-Allow-Origin", "*");
    headers.append("Access-Control-Allow-Credentials", "true");

    setIsLoading(true);

    const response = await fetch(`${url}/api/auth/forgot/password`, {
      mode: "cors",
      method: "POST",
      headers: headers,
      body: JSON.stringify({
        email: credentials.email,
        password: credentials.password,
        base_url: base_url,
      }),
    });
    setIsLoading(false);

    const json = await response.json();
    if (json.success) {
      setIsEmailSent(true);
      toast.success(json.message);
    } else {
      toast.error(json.error);
    }
  };
  const iocnStyle = {
    display: isLoading ? "inline-block" : "none",
  };

  return (
    <div className={styles.heading}>
      <div className="sign-up-user">
        <section className={styles.sign_sec} style={{ marginLeft: "500px" }}>
          <div className="container h-100">
            <div className="row h-100">
              <div className="col-lg-8 my-auto">
                <div className="row">
                  <div className="col-lg-8 p-3 text-center mob-1">
                    <div className={styles.cont_form_wrap}>
                      <div className={styles.signup_wrap}>
                        <div className={styles.logo_wrap}>
                          <Link to={'/'}>
                            <img src={logo} alt="logo" />
                          </Link>
                        </div>
                        <h1>retrieve password</h1>
                        {!isEmailSent ? (
                          <form className="form-wrap" onSubmit={formSubmit}>
                            <div className="row justify-content-center">
                              <div className="col-lg-12">
                                <div className="mb-3">
                                  <label htmlFor="email" className="form-label">
                                    Email
                                  </label>
                                  <input
                                    type="email"
                                    className="form-control"
                                    id="email"
                                    name="email"
                                    placeholder="Email Address"
                                    value={credentials.email}
                                    onChange={handleInput}
                                  />
                                </div>
                                {/* <button type="submit" className="btn btn-prim">get reset link</button> */}
                                <button
                                  type="submit"
                                  className="btn btn-prim"
                                  disabled={isLoading}
                                >
                                  <i
                                    className="fa fa-spinner fa-spin"
                                    style={iocnStyle}
                                  ></i>{" "}
                                  get reset link
                                </button>
                                <div className="form-wrap">
                                  <Link to="/puzzle-login">Back to Login</Link>
                                </div>
                              </div>
                            </div>
                          </form>
                        ) : (
                          <div className="form-wrap">
                            <Link to="/puzzle-login">Back to Login</Link>
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </div>
  );
};

export default ForgotPassword;

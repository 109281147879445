import React, { useEffect, useState } from "react";
import styles from "./style.module.css";
import Header from "../partials/Header";
import login2 from "../../images/timer.svg";
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { loginSuccess } from "../Redux/Authentication";
import { useDispatch } from "react-redux";
const url = process.env.REACT_APP_APP_BACK_URL;
const TimeScr = () => {
  let { slug } = useParams();
  const dispatch = useDispatch();
  const [credentials, setCredentials] = useState({ idle_time: "" });
  const [isLoading, setIsLoading] = useState(false);
  const [isLoading1, setIsLoading1] = useState(false);
  const handleInput = (e) => {
    setCredentials({ ...credentials, [e.target.name]: e.target.value });
  };
  useEffect(() => {
    siteData();
  }, []);
  // get  site
  const siteData = async () => {
    try {
      let headers = new Headers();
      headers.append("Content-Type", "application/json");
      headers.append("Access-Control-Allow-Origin", "*");
      headers.append("Access-Control-Allow-Credentials", "true");
      const response = await fetch(`${url}/api/sites/getSite/${slug}`, {
        mode: "cors",
        method: "GET",
        headers: headers,
      });
      const json = await response.json();
      if (json.success) {
        setCredentials(json.site);
      }
    } catch (error) {
      console.error(error);
    }
  };
  // update time
  const updateTime = async () => {
    let headers = new Headers();
    headers.append("Content-Type", "application/json");
    headers.append("Access-Control-Allow-Origin", "*");
    headers.append("Access-Control-Allow-Credentials", "true");
    setIsLoading(true);

    const response = await fetch(`${url}/api/sites/updateSite/${slug}`, {
      mode: "cors",
      method: "PUT",
      headers: headers,
      body: JSON.stringify({
        time: credentials.idle_time,
      }),
    });
    setIsLoading(false);

    const json = await response.json();

    if (json.success) {
      const user = {
        role: "admin",
        name: "Admin",
        idle_time: credentials.idle_time ? credentials.idle_time : 0,
      };
      dispatch(loginSuccess(user));
      toast.success("Successfully idle time updated!");
    } else {
      toast.error(json.error);
    }
  };
  // open all lockers
  const openAllLockers = async () => {
    try {
      toast.info(
        "Locker Opening- step back from locker especially from 7 and 8"
      );

      setIsLoading1(true);

      // Use setTimeout to introduce a 5-second delay
      setTimeout(async () => {
        console.log("OPen");
        try {
          let headers = new Headers();
          headers.append("Content-Type", "application/json");
          headers.append("Access-Control-Allow-Origin", "*");
          headers.append("Access-Control-Allow-Credentials", "true");

          const response = await fetch(
            `${url}/api/sites/openAllLocker/${slug}`,
            {
              mode: "cors",
              method: "GET",
              headers: headers,
            }
          );

          const json = await response.json();

          if (json.success) {
            // Handle success
          } else {
            toast.error(json.error);
          }
        } catch (error) {
          // Handle errors in the inner try-catch block
        } finally {
          setIsLoading1(false);
        }
      }, 5000); // 5000 milliseconds (5 seconds)
    } catch (error) {
      // Handle errors in the outer try-catch block
    }
  };
  const iocnStyle = {
    display: isLoading ? "inline-block" : "none",
  };
  const iocnStyle1 = {
    display: isLoading1 ? "inline-block" : "none",
  };
  return (
    <div className={styles.heading}>
      <div className="body-wrap">
        <div className="container-fluid px-0">
          <Header />
          <div className={styles.body_wrap}>
            <div className="login-container">
              <div className="image-container">
                <img src={login2} alt="login2" />
              </div>
              <div className="">
                <div className="time-calc">
                  <p>Start Idle Time Second</p>
                  <input
                    type="text"
                    className="form-control text-center"
                    id="idle_time"
                    name="idle_time"
                    placeholder="00:00"
                    value={credentials.idle_time}
                    onChange={handleInput}
                    required
                  />
                </div>
                <button
                  disabled={isLoading}
                  onClick={updateTime}
                  className="btn btn-prim mt-3"
                >
                  <i className="fa fa-spinner fa-spin" style={iocnStyle}></i>{" "}
                  Update Time
                </button>
                <button
                  disabled={isLoading1}
                  onClick={openAllLockers}
                  className="btn btn-prim mt-3"
                >
                  <i className="fa fa-spinner fa-spin" style={iocnStyle1}></i>{" "}
                  Open All
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TimeScr;

import React, { useEffect, useState } from "react";
import styles from "./style.module.css";
import Header from "../partials/Header";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import yeslocker from "../../images/new images/longyes.png";
import nolocker from "../../images/new images/longno.png";
import shortyes from "../../images/new images/shortyes.png";
import shortno from "../../images/new images/shortno.png";
import lock from "../../images/Lock.svg";
import Swal from "sweetalert2";
import mockup from "../../images/new images/gray.png";
import "sweetalert2/dist/sweetalert2.min.css";
import Modal from "react-modal";
import { toast } from "react-toastify";
const url = process.env.REACT_APP_APP_BACK_URL;
const NewDashboard = () => {
  const isAuthenticated = useSelector((state) => state.user);
  let { slug } = useParams();
  const [isLoading, setIsLoading] = useState(false);
  const [locker_display, setlocker_display] = useState(true);
  const [isDisplay, setIsDisplay] = useState(false);
  const [isLockerOpen, setIsLockerOpen] = useState(false);
  const [getLocker, setLocker] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedLocker, setselectedLocker] = useState("");

  useEffect(() => {
    siteLockers();
  }, []);

  // get all site
  const siteLockers = async () => {
    try {
      let headers = new Headers();
      headers.append("Content-Type", "application/json");
      headers.append("Access-Control-Allow-Origin", "*");
      headers.append("Access-Control-Allow-Credentials", "true");
      const response = await fetch(`${url}/api/sites/lockers/${slug}`, {
        mode: "cors",
        method: "GET",
        headers: headers,
      });
      const json = await response.json();

      if (json.success) {
        setselectedLocker("");
        setIsDisplay(true);
        setLocker(json.lockers);
        console.log(json.lockers[0]._id);
      } else {
        toast.error(json.error);
      }
    } catch (error) {
      console.error(error);
    }
  };
  // open locker
  const openLocker = async (id) => {
    let state = "2";
    let headers = new Headers();
    headers.append("Content-Type", "application/json");
    headers.append("Access-Control-Allow-Origin", "*");
    headers.append("Access-Control-Allow-Credentials", "true");
    setIsLockerOpen(true);
    setIsLoading(true);
    const response = await fetch(`${url}/api/sites/relay/state/update`, {
      mode: "cors",
      method: "POST",
      headers: headers,
      body: JSON.stringify({
        id: id,
        state: state,
      }),
    });

    setIsLockerOpen(false);
    const json = await response.json();
    if (json.success) {
      toast.success(json.message);
    } else {
      toast.error(json.error);
    }
    setIsLoading(false);
    siteLockers();
  };
  //   modal functions
  const openModal = (id) => {
    setIsModalOpen(true);
    setselectedLocker(id);
  };
  // set id
  const displayOpen = async (id) => {
    const result = await Swal.fire({
      title: "Are you sure?",
      text: "You wont to open locker!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Yes!",
      cancelButtonText: "No",
      reverseButtons: true,
    });

    if (result.isConfirmed) {
      setselectedLocker(id);
      // if (selectedLocker === id) {
      openLocker(id);
      // }
    }
  };

  const displayOpen1 = async (id) => {
    const result = await Swal.fire({
      title: "Are you sure?",
      text: "You wont to close this locker!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Yes!",
      cancelButtonText: "No",
      reverseButtons: true,
    });

    if (result.isConfirmed) {
      setselectedLocker(id);
      // if (selectedLocker === id) {
      openModal(id);
      // }
    }
  };

  const closeModal = () => {
    setselectedLocker("");
    setIsModalOpen(false);
  };
  // return to available locker
  const returnLocker = async () => {
    let headers = new Headers();
    headers.append("Content-Type", "application/json");
    headers.append("Access-Control-Allow-Origin", "*");
    headers.append("Access-Control-Allow-Credentials", "true");
    setIsLoading(true);
    const response = await fetch(
      `${url}/api/sites/changeToAvailable/${selectedLocker}`,
      {
        mode: "cors",
        method: "GET",
        headers: headers,
      }
    );

    const json = await response.json();
    if (json.success) {
      toast.success(json.message);
      siteLockers();
      setIsLoading(false);
      setIsModalOpen(false);
    } else {
      toast.error(json.error);
    }
  };
  return (
    <div className={styles.heading}>
      <div className="body-wrap">
        <div className="container-fluid px-0">
          <Header />
          <div className="container text-center p-2">
            <div className="lockers-dashboard">
             <div className="form-box card-box">
              <div className="row locker-row mt-3">
                {getLocker[0] && (
                  <>
                    <div className="col-lg-1 col-sm-6"></div>
                    <div
                      className={`col-lg-2 col-sm-6 ${
                        isLoading && selectedLocker !== getLocker[0]._id
                          ? "loading-disabled"
                          : ""
                      }`}
                    >
                      <div className="lockers">
                        <span className="dot">{getLocker[0].relay}</span>
                        {getLocker[0].status === "available" ? (
                          <>
                          {selectedLocker === getLocker[0]._id && (
                          <div className="locker-button-text">OPEN</div>
                          )}
                          <img
                            src={yeslocker}
                            alt="yes"
                            onClick={() => displayOpen(getLocker[0]._id)}
                          />
                          </>
                        ) : (
                          <>
                          {selectedLocker === getLocker[0]._id && (
                          <div className="locker-button-text">CLOSE</div>
                          )}
                          <img
                            src={nolocker}
                            onClick={() => displayOpen1(getLocker[0]._id)}
                            alt="yes"
                          />
                          </>
                        )}
                      </div>
                    </div>
                  </>
                )}
                {getLocker[3] && (
                  <div
                    className={`col-lg-2 col-sm-6 ${
                      isLoading && selectedLocker !== getLocker[3]._id
                        ? "loading-disabled"
                        : ""
                    }`}
                  >
                    <div className="lockers">
                      <span className="dot">{getLocker[3].relay}</span>

                      {getLocker[3].status === "available" ? (
                        <>
                          {selectedLocker === getLocker[3]._id && (
                          <div className="locker-button-text">OPEN</div>
                          )}
                          <img
                            src={yeslocker}
                            alt="yes"
                            onClick={() => displayOpen(getLocker[3]._id)}
                          />
                        </>
                      ) : (
                        <>
                          {selectedLocker === getLocker[3]._id && (
                          <div className="locker-button-text">Close</div>
                          )}
                        <img
                          src={nolocker}
                          onClick={() => displayOpen1(getLocker[3]._id)}
                          alt="yes"
                        />
                        </>
                      )}
                    </div>
                  </div>
                )}
                <div className="col-lg-2 col-sm-6">
                  <div className="lockers">
                    {getLocker[6] && (
                      <div
                        className={`lockers-short ${
                          isLoading && selectedLocker !== getLocker[6]._id
                            ? "loading-disabled"
                            : ""
                        }`}
                      >
                        <span className="dot1">{getLocker[6].relay}</span>
                        {getLocker[6].status === "available" ? (
                          <>
                          {selectedLocker === getLocker[6]._id && (
                          <div className="locker-button-text">OPEN</div>
                          )}
                          <img
                            src={shortyes}
                            alt="yes"
                            onClick={() => displayOpen(getLocker[6]._id)}
                          />
                          </>
                        ) : (
                          <>
                          {selectedLocker === getLocker[6]._id && (
                          <div className="locker-button-text">CLOSE</div>
                          )}
                          <img
                            src={shortno}
                            onClick={() => displayOpen1(getLocker[6]._id)}
                            alt="yes"
                          />
                          </>
                        )}
                      </div>
                    )}
                    {getLocker[7] && (
                      <div
                        className={`lockers-short mt-4 ${
                          isLoading && selectedLocker !== getLocker[7]._id
                            ? "loading-disabled"
                            : ""
                        }`}
                      >
                        <span className="dot2">{getLocker[7].relay}</span>
                        {getLocker[7].status === "available" ? (
                          <>
                          {selectedLocker === getLocker[7]._id && (
                          <div className="locker-button-text">OPEN</div>
                          )}
                          <img
                            src={shortyes}
                            alt="yes"
                            onClick={() => displayOpen(getLocker[7]._id)}
                          />
                          </>
                        ) : (
                          <>
                          {selectedLocker === getLocker[7]._id && (
                          <div className="locker-button-text">CLOSE</div>
                          )}
                          <img
                            src={shortno}
                            onClick={() => displayOpen1(getLocker[7]._id)}
                            alt="yes"
                          />
                          </>
                        )}
                      </div>
                    )}
                  </div>
                </div>
                {getLocker[10] && (
                  <div
                    className={`col-lg-2 col-sm-6 ${
                      isLoading && selectedLocker !== getLocker[10]._id
                        ? "loading-disabled"
                        : ""
                    }`}
                  >
                    <div className="lockers">
                      <span className="dot">{getLocker[10].relay}</span>
                      {getLocker[10].status === "available" ? (
                        <>
                        {selectedLocker === getLocker[10]._id && (
                        <div className="locker-button-text">OPEN</div>
                        )}
                        <img
                          src={yeslocker}
                          alt="yes"
                          onClick={() => displayOpen(getLocker[10]._id)}
                        />
                        </>
                      ) : (
                        <>
                          {selectedLocker === getLocker[10]._id && (
                          <div className="locker-button-text">CLOSE</div>
                          )}
                        <img
                          src={nolocker}
                          onClick={() => displayOpen1(getLocker[10]._id)}
                          alt="yes"
                        />
                        </>
                      )}
                    </div>
                  </div>
                )}
                {getLocker[13] && (
                  <>
                    <div
                      className={`col-lg-2 col-sm-6 ${
                        isLoading && selectedLocker !== getLocker[13]._id
                          ? "loading-disabled"
                          : ""
                      }`}
                    >
                      <div className="lockers">
                        <span className="dot">{getLocker[13].relay}</span>
                        {getLocker[13].status === "available" ? (
                          <>
                          {selectedLocker === getLocker[13]._id && (
                          <div className="locker-button-text">OPEN</div>
                          )}
                          <img
                            src={yeslocker}
                            alt="yes"
                            onClick={() => displayOpen(getLocker[13]._id)}
                          />
                          </>
                        ) : (
<>
                          {selectedLocker === getLocker[13]._id && (
                          <div className="locker-button-text">CLOSE</div>
                          )}
                          <img
                            src={nolocker}
                            onClick={() => displayOpen1(getLocker[13]._id)}
                            alt="yes"
                          />
                          </>
                        )}
                      </div>
                    </div>
                    <div className="col-lg-1 col-sm-6"></div>
                  </>
                )}
              </div>
              <div className="row locker-row mt-3">
                {getLocker[1] && (
                  <>
                    <div className="col-lg-1 col-sm-6"></div>
                    <div
                      className={`col-lg-2 col-sm-6 ${
                        isLoading && selectedLocker !== getLocker[1]._id
                          ? "loading-disabled"
                          : ""
                      }`}
                    >
                      <div className="lockers">
                        <span className="dot">{getLocker[1].relay}</span>
                        {getLocker[1].status === "available" ? (
                          <>
                          {selectedLocker === getLocker[1]._id && (
                          <div className="locker-button-text">OPEN</div>
                          )}
                          <img
                            src={yeslocker}
                            alt="yes"
                            onClick={() => displayOpen(getLocker[1]._id)}
                          />
                          </>
                        ) : (
                          <>
                          {selectedLocker === getLocker[1]._id && (
                          <div className="locker-button-text">CLOSE</div>
                          )}
                          <img
                            src={nolocker}
                            onClick={() => displayOpen1(getLocker[1]._id)}
                            alt="yes"
                          />
                          </>
                        )}
                      </div>
                    </div>
                  </>
                )}
                {getLocker[4] && (
                  <div
                    className={`col-lg-2 col-sm-6 ${
                      isLoading && selectedLocker !== getLocker[4]._id
                        ? "loading-disabled"
                        : ""
                    }`}
                  >
                    <div className="lockers">
                      <span className="dot">{getLocker[4].relay}</span>
                      {getLocker[4].status === "available" ? (
                        <>
                        {selectedLocker === getLocker[4]._id && (
                        <div className="locker-button-text">OPEN</div>
                        )}
                        <img
                          src={yeslocker}
                          alt="yes"
                          onClick={() => displayOpen(getLocker[4]._id)}
                        />
                        </>
                      ) : (
                        <>
                          {selectedLocker === getLocker[4]._id && (
                          <div className="locker-button-text">CLOSE</div>
                          )}
                        <img
                          src={nolocker}
                          onClick={() => displayOpen1(getLocker[4]._id)}
                          alt="yes"
                        />
                        </>
                      )}
                    </div>
                  </div>
                )}
                <div className="col-lg-2 col-sm-6">
                  <div className="lockers">
                    <img src={mockup} alt="yes" />
                  </div>
                </div>
                {getLocker[11] && (
                  <div
                    className={`col-lg-2 col-sm-6 ${
                      isLoading && selectedLocker !== getLocker[11]._id
                        ? "loading-disabled"
                        : ""
                    }`}
                  >
                    <div className="lockers">
                      <span className="dot">{getLocker[11].relay}</span>
                      {getLocker[11].status === "available" ? (
                        <>
                        {selectedLocker === getLocker[11]._id && (
                        <div className="locker-button-text">OPEN</div>
                        )}
                        <img
                          src={yeslocker}
                          alt="yes"
                          onClick={() => displayOpen(getLocker[11]._id)}
                        />
                        </>
                      ) : (
                        <>
                          {selectedLocker === getLocker[11]._id && (
                          <div className="locker-button-text">CLOSE</div>
                          )}
                        <img
                          src={nolocker}
                          onClick={() => displayOpen1(getLocker[11]._id)}
                          alt="yes"
                        />
                        </>
                      )}
                    </div>
                  </div>
                )}
                {getLocker[14] && (
                  <>
                    <div
                      className={`col-lg-2 col-sm-6 ${
                        isLoading && selectedLocker !== getLocker[14]._id
                          ? "loading-disabled"
                          : ""
                      }`}
                    >
                      <div className="lockers">
                        <span className="dot">{getLocker[14].relay}</span>
                        {getLocker[14].status === "available" ? (
                          <>
                          {selectedLocker === getLocker[14]._id && (
                          <div className="locker-button-text">OPEN</div>
                          )}
                          <img
                            src={yeslocker}
                            alt="yes"
                            onClick={() => displayOpen(getLocker[14]._id)}
                          />
                          </>
                        ) : (
                          <>
                          {selectedLocker === getLocker[14]._id && (
                          <div className="locker-button-text">CLOSE</div>
                          )}
                          <img
                            src={nolocker}
                            onClick={() => displayOpen1(getLocker[14]._id)}
                            alt="yes"
                          />
                          </>
                        )}
                      </div>
                    </div>
                    <div className="col-lg-1 col-sm-6"></div>
                  </>
                )}
              </div>

              <div className="row locker-row mt-3">
                {getLocker[2] && (
                  <>
                    <div className="col-lg-1 col-sm-6"></div>
                    <div
                      className={`col-lg-2 col-sm-6 ${
                        isLoading && selectedLocker !== getLocker[2]._id
                          ? "loading-disabled"
                          : ""
                      }`}
                    >
                      <div className="lockers">
                        <span className="dot">{getLocker[2].relay}</span>
                        {getLocker[2].status === "available" ? (
                          <>
                          {selectedLocker === getLocker[2]._id && (
                          <div className="locker-button-text">OPEN</div>
                          )}
                          <img
                            src={yeslocker}
                            alt="yes"
                            onClick={() => displayOpen(getLocker[2]._id)}
                          />
                          </>
                        ) : (
                          <>
                          {selectedLocker === getLocker[2]._id && (
                          <div className="locker-button-text">CLOSE</div>
                          )}
                          <img
                            src={nolocker}
                            onClick={() => displayOpen1(getLocker[2]._id)}
                            alt="yes"
                          />
                          </>
                        )}
                      </div>
                    </div>
                  </>
                )}
                {getLocker[5] && (
                  <div
                    className={`col-lg-2 col-sm-6 ${
                      isLoading && selectedLocker !== getLocker[5]._id
                        ? "loading-disabled"
                        : ""
                    }`}
                  >
                    <div className="lockers">
                      <span className="dot">{getLocker[5].relay}</span>
                      {getLocker[5].status === "available" ? (
                        <>
                        {selectedLocker === getLocker[5]._id && (
                        <div className="locker-button-text">OPEN</div>
                        )}
                        <img
                          src={yeslocker}
                          alt="yes"
                          onClick={() => displayOpen(getLocker[5]._id)}
                        />
                        </>
                      ) : (
                        <>
                          {selectedLocker === getLocker[5]._id && (
                          <div className="locker-button-text">CLOSE</div>
                          )}
                        <img
                          src={nolocker}
                          onClick={() => displayOpen1(getLocker[5]._id)}
                          alt="yes"
                        />
                        </>
                      )}
                    </div>
                  </div>
                )}
                <div className="col-lg-2 col-sm-6">
                  <div className="lockers">
                    {getLocker[8] && (
                      <div
                        className={`lockers-short ${
                          isLoading && selectedLocker !== getLocker[8]._id
                            ? "loading-disabled"
                            : ""
                        }`}
                      >
                        <span className="dot1">{getLocker[8].relay}</span>
                        {getLocker[8].status === "available" ? (
                          <>
                          {selectedLocker === getLocker[8]._id && (
                          <div className="locker-button-text">OPEN</div>
                          )}
                          <img
                            src={shortyes}
                            alt="yes"
                            onClick={() => displayOpen(getLocker[8]._id)}
                          />
                          </>
                        ) : (
                          <>
                          {selectedLocker === getLocker[8]._id && (
                          <div className="locker-button-text">CLOSE</div>
                          )}
                          <img
                            src={shortno}
                            onClick={() => displayOpen1(getLocker[8]._id)}
                            alt="yes"
                          />
                          </>
                        )}
                      </div>
                    )}
                    {getLocker[9] && (
                      <div
                        className={`lockers-short mt-4 ${
                          isLoading && selectedLocker !== getLocker[9]._id
                            ? "loading-disabled"
                            : ""
                        }`}
                      >
                        <span className="dot2">{getLocker[9].relay}</span>
                        {getLocker[9].status === "available" ? (
                          <>
                          {selectedLocker === getLocker[9]._id && (
                          <div className="locker-button-text">OPEN</div>
                          )}
                          <img
                            src={shortyes}
                            alt="yes"
                            onClick={() => displayOpen(getLocker[9]._id)}
                          />
                          </>
                        ) : (
                          <>
                          {selectedLocker === getLocker[9]._id && (
                          <div className="locker-button-text">CLOSE</div>
                          )}
                          <img
                            src={shortno}
                            onClick={() => displayOpen1(getLocker[9]._id)}
                            alt="yes"
                          />
                          </>
                        )}
                      </div>
                    )}
                  </div>
                </div>
                {getLocker[12] && (
                  <div
                    className={`col-lg-2 col-sm-6 ${
                      isLoading && selectedLocker !== getLocker[12]._id
                        ? "loading-disabled"
                        : ""
                    }`}
                  >
                    <div className="lockers">
                      <span className="dot">{getLocker[12].relay}</span>
                      {getLocker[12].status === "available" ? (
                        <>
                        {selectedLocker === getLocker[12]._id && (
                        <div className="locker-button-text">OPEN</div>
                        )}
                        <img
                          src={yeslocker}
                          alt="yes"
                          onClick={() => displayOpen(getLocker[12]._id)}
                        />
                        </>
                      ) : (
                        <>
                          {selectedLocker === getLocker[12]._id && (
                          <div className="locker-button-text">CLOSE</div>
                          )}
                        <img
                          src={nolocker}
                          onClick={() => displayOpen1(getLocker[12]._id)}
                          alt="yes"
                        />
                        </>
                      )}
                    </div>
                  </div>
                )}
                {getLocker[15] && (
                  <>
                    <div
                      className={`col-lg-2 col-sm-6 ${
                        isLoading && selectedLocker !== getLocker[15]._id
                          ? "loading-disabled"
                          : ""
                      }`}
                    >
                      <div className="lockers">
                        <span className="dot">{getLocker[15].relay}</span>
                        {getLocker[15].status === "available" ? (
                          <>
                          {selectedLocker === getLocker[15]._id && (
                          <div className="locker-button-text">OPEN</div>
                          )}
                          <img
                            src={yeslocker}
                            alt="yes"
                            onClick={() => displayOpen(getLocker[15]._id)}
                          />
                          </>
                        ) : (
                          <>
                          {selectedLocker === getLocker[15]._id && (
                          <div className="locker-button-text">CLOSE</div>
                          )}
                          <img
                            src={nolocker}
                            onClick={() => displayOpen1(getLocker[15]._id)}
                            alt="yes"
                          />
                          </>
                        )}
                      </div>
                    </div>
                    <div className="col-lg-1 col-sm-6"></div>
                  </>
                )}
              </div>
            </div>
            </div>
          </div>
        </div>
        <Modal
          isOpen={isModalOpen}
          onRequestClose={closeModal}
          contentLabel="Example Modal"
          className="modal-content"
          style={{
            overlay: {
              backgroundColor: "rgb(43 41 41 / 75%)", // Update the background color here
            },
            content: {
              backgroundColor: "white", // Update the modal content background color here
              // You can add other styling properties as needed
            },
          }}
          shouldCloseOnOverlayClick={false}
        >
          <img src={lock} alt="lock" className="modal-image" />
          <p className="modal-text mt-3">End</p>
          <div className="modal-button">
            <button className="modal-btn1" onClick={returnLocker}>
              Yes
            </button>
            <button className="modal-btn2" onClick={closeModal}>
              No
            </button>
          </div>
        </Modal>
      </div>
    </div>
  );
};

export default NewDashboard;
